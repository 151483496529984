<template>
    <div class="page-wrapper">
        <div class="page-header" :class=" sidebarCollapsed? 'collapsed':'' ">
            <Header />
        </div>
        <div class="page-body-wrapper">
            <div class="page-body" :class=" sidebarCollapsed? 'collapsed':'' ">
                <router-view></router-view>
            </div>
            <div class="sidebar-wrapper" :class="[ sidebarCollapsed? 'collapsed':'', windowWidth < 1199 && sidebarCollapsed? 'collapsed-sm': '' ]">
            <Sidebar @toggle-sidebar="toggleSidebarStatus" :sidebarCollapsed="sidebarCollapsed"/>
            </div>
        </div>
        
    </div>
</template>



<script>
import Header from './Header'
import Sidebar from './Sidebar'
 
export default {
    data() {
        return{
            sidebarCollapsed: false,
            windowWidth: window.innerWidth
        }
    },
    components:{
        Header,
        Sidebar,
    },
    methods: {
        toggleSidebarStatus(){
            this.sidebarCollapsed = !this.sidebarCollapsed;
        },
        onResize() {
            this.windowWidth = window.innerWidth
        }
    },
    created() {
         if(this.windowWidth < 1199) {
             this.sidebarCollapsed = true;
         }else{
             this.sidebarCollapsed = false;
         }
    },
    watch: {
        windowWidth(newWidth) {
         if(newWidth < 1199) {
             this.sidebarCollapsed = true;
         }else{
             this.sidebarCollapsed = false;
         }
        }
    },
    mounted() {
        this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
        })
    },

    beforeUnmount() { 
        window.removeEventListener('resize', this.onResize); 
    },
};
</script>
