<template>
   <BodyHeading v-if="$route.name.includes('Create')" :borderColor="'rgb(230, 127, 216)'"
      :title="$t('page.addButtonTitle')" @handleClick="create" :multiLanguageDisabled="true"></BodyHeading>

   <BodyHeading v-if="$route.name.includes('Details')" :borderColor="'rgb(230, 127, 216)'" :title="$t('page.pageDetails')"
      v-on="isExist ? { handleClick: edit } : { handleClick: createTranslation }" :multiLanguageDisabled="false">
   </BodyHeading>
   <div class="page-body-main-create">
      <div class="card-wrapper">
        <nav class="nav nav-stepper">
          <a class="nav-link" :class="{active: activeTabIndex === 0}" @click="activeTabIndex = 0">{{$t('generalInformation')}}</a>
          <a class="nav-link" :class="{active: activeTabIndex === 1, 'disabled': inTranslationMode}" @click="activeTabIndex = 1">SEO</a>
        </nav>
        <div class="card" v-if="activeTabIndex === 0">
          <div class="card-body">
              <div class="row" v-if="!isLoading">
                <div class="mb-4">
                    <v-input type="text" name="title" label="page.title" :placeholder="defaultLanguagePage.title"
                      v-model="page.title" :isRequired="true"
                      :validateError="v$.page.title.$error ? v$.page.title.$errors[0].$message : null"></v-input>
                </div>
                <div class="mb-4">
                    <v-input type="text" name="title" label="page.footer_alias" :placeholder="defaultLanguagePage.footer_alias"
                      v-model="page.footer_alias"></v-input>
                </div>
                <div class="mb-4">
                    <v-input type="text" name="slug" label="page.slug" :placeholder="defaultLanguagePage.slug"
                      v-model="page.slug" :isRequired="true"
                      :validateError="v$.page.slug.$error ? v$.page.slug.$errors[0].$message : null"
                      @input="checkSlug"></v-input>
                    <small v-if="!uniqueSlugCheckerStatus && is_unique != null" class="pt-1 d-inline-block fw-bold"
                      :class="is_unique ? 'text-success' : 'text-danger'">{{ is_unique ? 'VALID' : 'NOT VALID' }}</small>
                    <small v-if="uniqueSlugCheckerStatus" class="fw-bold mini-loader pt-1">. . .</small>
                </div>
                <div class="mb-4">
                    <TextEditor name="content" label="page.content" v-model="page.content" :isRequired="true"
                      :validateError="v$.page.content && v$.page.content.$error ? v$.page.content.$errors[0].$message : null">
                    </TextEditor>
                </div>
                <div class="mb-4" v-if="!isInputDisable">
                    <MultiSelect label="page.sidebar" :options="async (query) => { return await getSidebars(query) }"
                      :modelValue="page.sidebar_id" @update:modelValue="page.sidebar_id = $event" :searchable="true"
                      :delay="0" :resolveOnLoad="true" :object="true" />
                </div>
                <div class="mb-4">
                    <div class="form-check form-switch">
                      <input v-model="useAccordionSections" class="form-check-input" type="checkbox"
                          id="useAccordionSectionsSwitch">
                      <label class="form-check-label" for="useAccordionSectionsSwitch"> {{ $t('page.useAccordionSection')
                      }} </label>
                    </div>
                </div>
                <div v-if="useAccordionSections">
                    <v-button :buttonAction="true" @click="sections.push({ title: null, accordions: [] })"
                      class="ms-0 mb-4">
                      {{ $t('page.sections.addSection') }}
                    </v-button>
                    <div v-if="sections.length === 0">
                      {{ $t('page.sections.noSection') }}
                    </div>
                    <ValidateEach v-else v-for="(section, sectionIndex) in sections" :key="sectionIndex" :state="section"
                      :rules="sectionRule">
                      <template #default="{ v }">
                          <div class="card-wrapper m-0">
                            <div class="card-title d-flex justify-content-between align-items-end">
                                <span>{{ `${$t('page.sections.section')} ${sectionIndex+1}` }}</span>
                                <v-button buttonInline @click="sections.splice(sectionIndex, 1)" style="color:red">
                                  {{ $t('page.sections.removeSection') }}
                                </v-button>
                            </div>
                            <div class="card">
                                <div class="card-body">
                                  <div class="d-flex align-items-start mb-4">
                                      <div class="flex-grow-1 flex-shrink-0">
                                        <v-input type="text" :name="`section${sectionIndex}-title`" label="page.title"
                                            v-model="v.title.$model" :isRequired="true"
                                            :validateError="v.title.$error ? v.title.$errors[0].$message : null"
                                            class="d-block"></v-input>
                                      </div>
                                      <v-button :buttonAction="true"
                                        @click="section.accordions.push({ header: null, body: '' })"
                                        style="margin-top: 29px;">{{ $t('page.sections.addQuestion') }}</v-button>
                                  </div>
                                  <div class="accordion" :id="`accordion${sectionIndex}`">
                                      <ValidateEach v-for="(accordion, accordionIndex) in section.accordions"
                                        :key="accordionIndex" :state="accordion" :rules="accordionRule">
                                        <template #default="{ v: va }">
                                            <div class="accordion-item">
                                              <h2 class="accordion-header"
                                                  :id="`heading${sectionIndex}${accordionIndex}`">
                                                  <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                                    :data-bs-target="`#collapse${sectionIndex}${accordionIndex}`"
                                                    aria-expanded="true"
                                                    :aria-controls="`collapseOne${sectionIndex}${accordionIndex}`">
                                                    <div class="d-flex align-items-start flex-grow-1 me-4">
                                                        <div class="flex-grow-1 me-4">
                                                          <v-input v-model="va.header.$model" type="text" name="title"
                                                              :isRequired="true"
                                                              :placeholder="$t('page.sections.question')"
                                                              :validateError="va.header.$error ? va.header.$errors[0].$message : null"></v-input>
                                                        </div>
                                                        <v-button buttonInline
                                                          @click="section.accordions.splice(accordionIndex, 1)"
                                                          style="color:red">
                                                          {{ $t('page.sections.removeQuestion') }}
                                                        </v-button>
                                                    </div>
                                                  </button>
                                              </h2>
                                              <div :id="`collapse${sectionIndex}${accordionIndex}`"
                                                  class="accordion-collapse collapse show"
                                                  :aria-labelledby="`heading${sectionIndex}${accordionIndex}`"
                                                  :data-bs-parent="`#accordion${sectionIndex}`">
                                                  <div class="accordion-body">
                                                    <TextEditor v-model="va.body.$model"
                                                        :placeholder="$t('page.sections.answer')"
                                                        :validateError="va.body.$error ? va.body.$errors[0].$message : null">
                                                    </TextEditor>
                                                  </div>
                                              </div>
                                            </div>
                                        </template>
                                      </ValidateEach>
                                  </div>
                                </div>
                            </div>
                          </div>
                      </template>
                    </ValidateEach>
                </div>
              </div>
          </div>
        </div>
        <div class="card" v-if="activeTabIndex === 1">
          <div class="card-body">
            <div class="mb-4">
              <v-input type="text" name="title" label="Meta Titel"
              v-model="page.seo_title" :isRequired="true"></v-input>
            </div>
            <div class="mb-4">
              <v-textarea row="10" name="seo_description" label="Meta Description"
              v-model="page.seo_description"></v-textarea>
            </div>
          </div>
        </div>
      </div>
   </div>
</template>
<script>
import BodyHeading from '../../components/BodyHeading.vue';
import TextEditor from '../../components/TextEditor.vue';
import Textarea from '../../components/Textarea.vue';
import Input from '../../components/Input.vue';
import MultiSelect from '../../components/MultiSelect.vue';
import useValidate from '@vuelidate/core';
import { ValidateEach } from '@vuelidate/components';
import { required } from '@/utilities/i18n-validators.js';
import _ from 'lodash'; 
import useCkeditorHelper from '../../composables/useCkeditorHelper.js';

export default {
  components: {
    BodyHeading,
    'v-input': Input,
    'v-textarea': Textarea,
    TextEditor,
    MultiSelect,
    ValidateEach
  },
  setup() {
    const { parseForEditor, parseForServer } = useCkeditorHelper();
    return { parseForEditor, parseForServer };
  },
  data() {
    return {
      v$: useValidate(),
      page: {
        title: '',
        footer_alias: '',
        slug: '',
        content: '',
        sidebar_id: null,
        seo_title: '',
        seo_description: ''
      },
      defaultLanguagePage: {},
      isExist: true,
      isLoading: true,
      timer: null,
      uniqueSlugCheckerStatus: false,
      is_unique: null,
      lastSlug: null,
      isInputDisable: false,
      useAccordionSections: false,
      sections: [],
      initialPageData: null,
      hasChanges: false,
      sectionRule: {
        title: { required },
      },
      activeTabIndex: 0,
      accordionRule: {
        header: { required },
        body: { required },
      }
    };
  },
  validations() {
    return {
      page: {
        title: { required },
        slug: { required },
        content: { required }
      }
    };
  },
  computed: {
    currentLanguage() {
      return this.$store.state.locale.selectedLocale;
    },
    languages() {
      return this.$store.state.locale.languages;
    },
    currentLocale() {
      return this.$store.state.locale.appLocale;
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler() {
        if (this.$route.name.includes('Details')) {
          this.getPage(this.$route.params.id);
        } else {
          this.isLoading = false;
        }
      }
    },
    'languages': {
      immediate: true,
      handler() {
        if (this.$route.name.includes('Details') && this.languages) {
          if (!this.isExist && this.currentLocale === this.currentLanguage) {
            this.$router.push({ name: 'durations', query: { page: 1 } });
          }
          this.getPage(this.$route.params.id, this.currentLocale);
          if (this.currentLocale !== this.currentLanguage) {
            if (!this.isInputDisable) {
              this.isInputDisable = true;
            }
          } else if (this.currentLocale === this.currentLanguage && this.isInputDisable) {
            this.isInputDisable = false;
          }
        }
      }
    },
    currentLanguage() {
      if (this.$route.name.includes('Details')) {
        this.isLoading = true;
        this.v$.$reset();
        this.page = {};
        this.is_unique = null;
        this.getPage(this.$route.params.id, this.currentLanguage);
        this.getPage(this.$route.params.id, this.currentLocale);
        if (this.languages) {
          if (this.currentLocale !== this.currentLanguage && !this.isInputDisable) {
            this.isInputDisable = true;
          } else if (this.currentLocale === this.currentLanguage && this.isInputDisable) {
            this.isInputDisable = false;
          }
        }
      }
    },
    'page.title': {
      handler() {
        if (!this.isLoading) {
          this.hasChanges = true;
        }
      }
    },
    'page.footer_alias': {
      handler() {
        if (!this.isLoading) {
          this.hasChanges = true;
        }
      }
    },
    'page.slug': {
      handler() {
        if (!this.isLoading) {
          this.hasChanges = true;
        }
      }
    },
    'page.content': {
      handler() {
        if (!this.isLoading) {
          this.hasChanges = true;
        }
      }
    },
    'page.sidebar_id': {
      handler() {
        if (!this.isLoading) {
          this.hasChanges = true;
        }
      }
    },
    useAccordionSections: {
      handler() {
        if (!this.isLoading) {
          this.hasChanges = true;
        }
      }
    },
    sections: {
      deep: true,
      handler() {
        if (!this.isLoading) {
          this.hasChanges = true;
        }
      }
    }
  },
  methods: {
    setInitialPageData() {
      this.initialPageData = _.cloneDeep(this.page);
      this.hasChanges = false;
      setTimeout(() => {
         this.isLoading = false;
      },2000)
    },
    checkSlug() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.uniqueSlugChecker();
      }, 800);
    },
    uniqueSlugChecker() {
      if (this.page.slug) {
        if (this.lastSlug == this.page.slug) {
          this.is_unique = true;
        } else {
          this.uniqueSlugCheckerStatus = true;
          const params = { slug: this.page.slug };
          this.$store.dispatch("page/check", params).then(
            (response) => {
              this.is_unique = response.is_unique;
              this.uniqueSlugCheckerStatus = false;
            }
          );
        }
      } else {
        this.is_unique = null;
      }
    },
    create() {
      if (_.isEqual(this.page, this.initialPageData)) {
        this.$notify({
          text: 'Keine Änderungen zu speichern.',
          group: 'info'
        });
        return;
      }

      this.v$.$validate();
      if (!this.v$.$error && this.is_unique) {
        let page = { ...this.page };
        page.language = this.currentLanguage;
        page.content = this.parseForServer(page.content);
        page.slug = page.slug.trim();
        page.slug = this.slugFormatter(page.slug);
        if (page.sidebar_id) {
          page.sidebar_id = page.sidebar_id.value;
        } else {
          page.sidebar_id = null;
        }
        const parsedSections = this.sections.map(section => ({
          title: section.title,
          accordions: section.accordions.map(accordion => ({
            header: accordion.header,
            body: this.parseForServer(accordion.body)
          }))
        }));
        page.section = this.useAccordionSections ? parsedSections : [];
        this.$store.dispatch("page/create", page).then(
          (data) => {
            this.lastSlug = this.page.slug;
            this.$notify({
              text: data.message,
              group: 'success'
            });
            this.$router.push({ name: 'pageDetails', params: { id: data.body.page_id } });
            this.setInitialPageData(); // Reset initial data after submission
          },
          (error) => {
            let errorMessage = '';
            for (const err in error) {
              errorMessage += `${error[err]} <br>`;
            }
            this.$notify({
              text: errorMessage,
              group: 'failed'
            });
          }
        );
      }
    },
    createTranslation() {
      if (_.isEqual(this.page, this.initialPageData)) {
        this.$notify({
          text: 'Keine Änderungen zu speichern.',
          group: 'info'
        });
        return;
      }

      this.v$.$validate();
      if (!this.v$.$error && this.is_unique) {
        let page = { ...this.page };
        page.language = this.currentLanguage;
        page.content = this.parseForServer(page.content);
        page.page_id = this.defaultLanguagePage.page_id;
        if (this.defaultLanguagePage.sidebar_id) {
          page.sidebar_id = this.defaultLanguagePage.sidebar_id.value;
        } else {
          this.page.sidebar_id = null;
        }
        page.slug = page.slug.trim();
        page.slug = this.slugFormatter(page.slug);
        const parsedSections = this.sections.map(section => ({
          title: section.title,
          accordions: section.accordions.map(accordion => ({
            header: accordion.header,
            body: this.parseForServer(accordion.body)
          }))
        }));
        page.section = this.useAccordionSections ? parsedSections : [];
        this.$store.dispatch("page/createTranslation", page).then(
          (message) => {
            this.lastSlug = this.page.slug;
            this.isExist = true;
            this.$notify({
              text: message,
              group: 'success'
            });
            this.setInitialPageData(); // Reset initial data after submission
          },
          (error) => {
            let errorMessage = '';
            for (const err in error) {
              errorMessage += `${error[err]} <br>`;
            }
            this.$notify({
              text: errorMessage,
              group: 'failed'
            });
          }
        );
      }
    },
    edit() {
      if (_.isEqual(this.page, this.initialPageData)) {
        this.$notify({
          text: 'Keine Änderungen zu speichern.',
          group: 'info'
        });
        return;
      }

      this.v$.$validate();
      if (!this.v$.$error && this.is_unique != false) {
          let page = { ...this.page };
          page.language = this.currentLanguage;
          page.content = this.parseForServer(page.content);
          if (this.defaultLanguagePage.sidebar_id) {
            page.sidebar_id = this.defaultLanguagePage.sidebar_id.value;
          } else {
            page.sidebar_id = null;
          }

          if (this.lastSlug == page.slug) {
            delete page.slug;
          } else {
            page.slug = page.slug.trim();
            page.slug = this.slugFormatter(page.slug);
          }
          const parsedSections = this.sections.map(section => ({
            title: section.title,
            accordions: section.accordions.map(accordion => ({
              header: accordion.header,
              body: this.parseForServer(accordion.body)
            }))
          }));
          page.section = this.useAccordionSections ? parsedSections : [];
          this.$store.dispatch("page/update", page).then(
            (message) => {
              this.lastSlug = this.page.slug;
              this.$notify({
                text: message,
                group: 'success'
              });
              this.setInitialPageData(); // Reset initial data after submission
            },
            (error) => {
              let errorMessage = '';
              for (const err in error) {
                errorMessage += `${error[err]} <br>`;
              }
              this.$notify({
                text: errorMessage,
                group: 'failed'
              });
            }
          );
      }
    },
    getPage(id, language = this.currentLanguage) {
      const params = { language: language, page_id: id };
      this.$store.dispatch("page/fetch", params).then(
        (response) => {
          var seo = response.seo_meta;
          if (this.currentLocale === language) {
            this.defaultLanguagePage = response;
            if (response.page_info.sidebar) {
              this.defaultLanguagePage.sidebar_id = { label: response.page_info.sidebar.title, value: response.page_info.sidebar.id };
            }
            if (this.currentLanguage === this.currentLocale) {
              this.isExist = true;
              this.page = response;
              this.page.content = this.parseForEditor(this.page.content);
              if (response.page_info.sidebar) {
                this.page.sidebar_id = { label: response.page_info.sidebar.title, value: response.page_info.sidebar.id };
              }
              this.lastSlug = this.page.slug;
              this.setInitialPageData(); // Set initial data after loading the page
            }
          } else {
            this.isExist = true;
            this.page = response;
            this.page.content = this.parseForEditor(this.page.content);
            if (response.page_info.sidebar) {
              this.page.sidebar_id = { label: response.page_info.sidebar.title, value: response.page_info.sidebar.id };
            }
            this.lastSlug = this.page.slug;
            this.setInitialPageData(); // Set initial data after loading the page
          }
          this.useAccordionSections = response.section ? true : false;
          let parsedSections = response.section ?? [];
          if (parsedSections.length > 0) {
            parsedSections = response.section.map(section => ({
              title: section.title,
              accordions: section.accordions.map(accordion => ({
                header: accordion.header,
                body: this.parseForEditor(accordion.body)
              }))
            }));
          }
          this.sections = parsedSections;
          this.page.seo_title = seo?.title ?? '';
          this.page.seo_description = seo?.description ?? '';
        },
        () => {
          this.isExist = false;
          if (this.currentLocale === this.currentLanguage) {
            this.$router.push({ name: 'durations', query: { page: 1 } });
          }
          this.isLoading = false;
        }
      );
    },
    slugFormatter(value) {
      if (!value) return false;
      return value
        .toLowerCase()
        .replace(/ä/g, "ae")
        .replace(/Ä/g, "Ae")
        .replace(/ö/g, "oe")
        .replace(/Ö/g, "Oe")
        .replace(/ü/g, "ue")
        .replace(/Ü/g, "Ue")
        .replace(/ß/g, "ss")
        .replace(/ẞ/g, "SS")
        .replace(/\s+/g, "-")
        .replace(/&/g, `-and-`)
        .replace(/_/g, "-")
        .replace(/--/g, "-")
        .replace(/,/g, "");
    },
    getSidebars(query) {
      const params = { language: this.currentLanguage, per_page: 30 };
      if (query) {
        params.title = query;
      }
      return this.$store.dispatch("page/getAllSidebars", params).then(
        (response) => {
          let sidebars = [];
          response.data.forEach(el => {
            sidebars.push({ label: el.title, value: el.id });
          });
          return sidebars;
        },
        (error) => {
          this.$notify({
            text: error,
            group: 'failed'
          });
        }
      );
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasChanges) {
      const answer = window.confirm('Änderungen würden nicht gespeichert werden. Möchten Sie trotzdem die Seite verlassen?');
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
};
</script>