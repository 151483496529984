<template>
	<BodyHeading v-if="$route.name.includes('Create')" :borderColor="'rgb(154, 168, 181)'"
		:title="$t('menu.addButtonTitle')" @handleClick="create" :multiLanguageDisabled="true" :saveAndCloseBtn="false">
	</BodyHeading>

	<BodyHeading v-if="$route.name.includes('Details')" :borderColor="'rgb(154, 168, 181)'" :title="$t('menu.menuDetails')"
		v-on="isExist ? { handleClick: edit } : { handleClick: createTranslation }" :multiLanguageDisabled="false">
	</BodyHeading>
	<div class="page-body-main-create">
		<div class="card-wrapper">
			<nav class="nav nav-stepper">
				<a class="nav-link" :class="{active: activeTabIndex === 0}" @click="activeTabIndex = 0">{{$t('generalInformation')}}</a>
				<a class="nav-link" :class="{active: activeTabIndex === 1, 'disabled': inTranslationMode}" @click="activeTabIndex = 1">SEO</a>
			</nav>
			<div v-if="activeTabIndex === 0" class="card">
				<div class="card">
					<div class="card-body">
						<!--  -->
						<div class="row" v-if="!isLoading">
							<div class="mb-4">
								<v-input type="text" name="title" label="menu.title" :placeholder="defaultLanguageMenu.title"
									v-model="menu.title" :isRequired="true"
									:validateError="v$.menu.title.$error ? v$.menu.title.$errors[0].$message : null"></v-input>
							</div>
							<div class="mb-4">
								<TextEditor name="shortDescription" label="menu.shortDescription" title v-model="menu.description">
								</TextEditor>
							</div>
							<div v-if="menu.menu_info.level == 1" class="mb-4">
								<TextEditor name="longDescription" label="menu.longDescription" v-model="menu.lower_description">
								</TextEditor>
							</div>
							<div class="mb-4" v-if="!isMenuInputDisable">
								<MultiSelect label="menu.level" :options="[{ value: 1, label: 'One' }, { value: 2, label: 'Two' }]"
									v-model="menu.menu_info.level" :isRequired="true"
									:disabled="menu.menu_info.level == 1 && $route.name.includes('Details')"
									:validateError="v$.menu.menu_info.level.$error ? v$.menu.menu_info.level.$errors[0].$message : null" />
							</div>
							<div class="mb-4" v-if="menu.menu_info.level == 2 && menus.length && !isMenuInputDisable">
								<MultiSelect label="menu.parent-id" :options="menus" v-model="menu.menu_info.parent_id" :isRequired="true"
									:validateError="v$.menu.menu_info.parent_id.$error ? v$.menu.menu_info.parent_id.$errors[0].$message : null" />
							</div>
							<div class="mb-4" v-if="!isMenuInputDisable">
								<v-switch label="menu.is-active" name="isActive" v-model="menu.menu_info.is_active"></v-switch>
							</div>
						</div>
					</div>
				</div>
				<div class="card-title" v-if="!isMenuInputDisable">{{ $t('images') }}</div>
				<div class="card">
					<div class="card-body">
						<div class="row" v-if="!isLoading">
							<FileUploader v-if="!isMenuInputDisable" v-model="thumbnail_image" :name="'menu-thumbnail-uploader'"
								label="menu.thumbnail" uploadUrl="menus/images/upload/thumbnail" deleteUrl="menus/images/delete/thumbnail"
								fetchUrl="image/menu" :multiple="false"
								:ownerId="{ menu_id: this.$route.name.includes('Details') ? this.$route.params.id : null }"
								:validateError="v$.thumbnail_image.$error ? v$.thumbnail_image.$errors[0].$message : null" />
						</div>
						<div class="row" v-if="!isLoading">
							<label class="form-label">{{ $t('menu.uploaderBoxLabel') }}</label>
							<div class="accordion accordion-uploader" id="accordionPanelsStayOpenExample">
								<div class="accordion-item" v-for="(i, index) in slides" :key="i">
									<h2 class="accordion-header" :id="`uploaderBox-heading-${index}`">
										<button class="accordion-button" :class="index != 0 ? 'collapsed' : ''" type="button"
											data-bs-toggle="collapse" :data-bs-target="`#uploaderBox-${index}`" aria-expanded="true"
											:aria-controls="`uploaderBox-${index}`" :style="{ borderColor: slidesWithError[index] ? 'red' : '' }">
											{{ `${$t('menu.slideTitle')} #${index + 1}` }}
										</button>
									</h2>
									<div :id="`uploaderBox-${index}`" class="accordion-collapse collapse" :class="index == 0 ? 'show' : ''"
										:aria-labelledby="`uploaderBox-heading-${index}`" data-bs-parent="#accordionPanelsStayOpenExample">
										<div class="accordion-body">
											<FileUploader v-model="desktopImages[index]" :name="'home-slider-uploader'" label="menu.coverDesktop"
												:language="selectedLocale" uploadUrl="menus/images/upload/cover"
												deleteUrl="menus/images/delete/cover" fetchUrl="image/menu" :multiple="false"
												:couple="{ id: checkSlideId(index), for: 'desktop' }"
												@uploaderStatus="disableDeleteBtn = $event == 1 ? false : true" />
											<FileUploader v-model="mobileImages[index]" :name="'home-slider-uploader'" label="menu.coverMobile"
												:language="selectedLocale" uploadUrl="menus/images/upload/mobile/cover"
												deleteUrl="menus/images/delete/mobile/cover" fetchUrl="image/menu" :multiple="false"
												:couple="{ id: checkSlideId(index), for: 'mobile' }"
												@uploaderStatus="disableDeleteBtn = $event == 1 ? false : true" />
											<div class="mb-4">
												<v-input type="text" name="title" label="menu.link" v-model="links[index]"
													:placeholder="'https://'"></v-input>
											</div>
											<div class="mt-4 mb-2" v-if="index != 0">
												<span @click="disableDeleteBtn ? '' : deleteSlide(index)"
													class="text-danger cursor-pointer text-decoration-underline"
													:style="{ opacity: disableDeleteBtn ? '.5' : '1' }">{{ $t('delete') }}</span>
											</div>
										</div>
									</div>
								</div>
								<div class="d-flex justify-content-center pt-4">
									<button @click="addSlide" class="btn button-action px-3">
										<font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="activeTabIndex === 1" class="card">
				<div class="card-body">
				<div v-if="!isLoading" class="row">
					<div class="mb-4">
					<v-input type="text" name="title" label="Meta Titel"
						v-model="menu.seo_title" :isRequired="true"></v-input>
					</div>
					<div class="mb-4">
					<v-textarea name="benefitDescription" rows="6" label="Meta Description"
						v-model="menu.seo_description" :isRequired="true">
					</v-textarea>
					</div>
				</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BodyHeading from '../../components/BodyHeading.vue';
import TextEditor from '../../components/TextEditor.vue';
import Textarea from '../../components/Textarea.vue';
import Input from '../../components/Input.vue';
import MultiSelect from '../../components/MultiSelect.vue'
import Switch from '../../components/Switch.vue';
import FileUploader from '../../components/FileUploader.vue'
import useValidate from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators'
import { required } from '@/utilities/i18n-validators.js'

// Import TextEditor helpers
import useCkeditorHelper from '../../composables/useCkeditorHelper.js'

export default {
	components: {
		BodyHeading,
		'v-input': Input,
		TextEditor,
		'v-switch': Switch,
		'v-textarea': Textarea,
		MultiSelect,
		FileUploader
	},
	setup() {
		const { parseForEditor, parseForServer } = useCkeditorHelper()
		return { parseForEditor, parseForServer }
	},
	data() {
		return {
			v$: useValidate(),
			// menu: { menu_info: {} },
			menu: { menu_info: {} , seo_title: '', seo_description: ''},
			defaultLanguageMenu: { menu_info: {} },
			isExist: true,
			isLoading: true,
			isMenuInputDisable: false,
			menus: [],
			thumbnail_image: [],
			mobileImages: {},
			desktopImages: {},
			slides: [0],
			links: {},
			disableDeleteBtn: false,
			slidesWithError: {},
			activeTabIndex: 0
		}
	},
	validations() {
		return {
			menu: {
				title: { required },
				menu_info: {
					level: {
						required: requiredIf(() => {
							return !this.isMenuInputDisable
						}),
					},
					parent_id: {
						required: requiredIf(() => {
							return this.menu.menu_info.level == 2 && !this.isMenuInputDisable
						})
					}
				},
				// seo_title: {required},
				// seo_description: {required}
			},
			thumbnail_image: {
				required: requiredIf(() => {
					return !this.isMenuInputDisable && this.menu.menu_info.level != 1
				})
			}
		}
	},
	computed: {
		selectedLocale() {
			return this.$store.state.locale.selectedLocale
		},
		languages() {
			return this.$store.state.locale.languages
		},
		appLocale() {
			return this.$store.state.locale.appLocale
		}
	},
	watch: {
		'$route': {
			immediate: true,
			async handler() {
				this.getMenus();
				if (this.$route.name.includes('Details')) {
					if (!this.defaultLanguageMenu.title)
						await this.getMenu(this.$route.params.id, this.appLocale)
					if (this.selectedLocale !== this.appLocale)
						await this.getMenu(this.$route.params.id, this.selectedLocale)
				} else {
					this.isLoading = false
				}
			}
		},
		selectedLocale() {
			if (this.$route.name.includes('Details')) {
				this.isLoading = true
				this.v$.$reset();
				this.menu = { menu_info: {} }
				this.thumbnail_image = []
				this.mobileImages = {}
				this.desktopImages = {}
				this.links = {}
				this.getMenu(this.$route.params.id, this.selectedLocale)
				// disable menu input
				if (this.languages) {
					if (this.appLocale !== this.selectedLocale && !this.isMenuInputDisable) {
						this.isMenuInputDisable = true;
					} else if (this.appLocale === this.selectedLocale && this.isMenuInputDisable) {
						this.isMenuInputDisable = false;
					}
				}
			}
		}
	},
	methods: {
		addSlide() {
			this.slides.push(this.slides.length)
		},
		deleteSlide(index) {
			if (this.desktopImages[index] && this.desktopImages[index][0]) {
				this.$swal().then((result) => {
					if (result.isConfirmed) {
						this.$store.dispatch("menu/deleteSlide", this.desktopImages[index][0].id).then(
							(message) => {
								this.$notify({
									text: message,
									group: 'success'
								});
								delete this.mobileImages[index];
								delete this.desktopImages[index];
								this.slides.splice(index, 1);
							},
							(error) => {
								let errorMessage = ''
								for (const err in error) {
									errorMessage += `${error[err]} <br>`;
								}
								this.$notify({
									text: errorMessage,
									group: 'failed'
								});
							}
						)
					}
				})
			} else if (this.mobileImages[index] && this.mobileImages[index][0]) {
				this.$swal().then((result) => {
					if (result.isConfirmed) {
						this.$store.dispatch("menu/deleteSlide", this.mobileImages[index][0].id).then(
							(message) => {
								this.$notify({
									text: message,
									group: 'success'
								});
								delete this.mobileImages[index];
								delete this.desktopImages[index];
								this.slides.splice(index, 1)
							},
							(error) => {
								let errorMessage = ''
								for (const err in error) {
									errorMessage += `${error[err]} <br>`;
								}
								this.$notify({
									text: errorMessage,
									group: 'failed'
								});
							}
						)
					}
				})
			} else {
				this.slides.splice(index, 1)
			}
		},
		checkSlideId(index) {
			if (this.desktopImages[index] && this.desktopImages[index][0]) {
				return this.desktopImages[index][0].id
			} else if (this.mobileImages[index] && this.mobileImages[index][0]) {
				return this.mobileImages[index][0].id
			} else {
				return null
			}
		},
		customValidation() {
			this.slidesWithError = {}
			if (this.slides.length != 1) {
				this.slides.forEach(key => {
					if (!this.desktopImages[key] || this.desktopImages[key] && !this.desktopImages[key][0].desktopName) {
						if (!this.mobileImages[key] || this.mobileImages[key] && !this.mobileImages[key][0].mobileName) {
							this.slidesWithError[key] = true
						}
					}
				})
			}
			if (Object.keys(this.slidesWithError) == 0) {
				return true
			} else {
				return true
			}
		},
		create() {
			this.v$.$validate()
			this.customValidation()
			if (!this.v$.$error && !Object.keys(this.slidesWithError).length) {
				let menu = { ...this.menu };
				menu.language = this.selectedLocale;
				if (menu.description) {
					menu.description = this.parseForServer(menu.description)
				}
				if (menu.lower_description) {
					menu.lower_description = this.parseForServer(menu.lower_description)
				}
				menu.cover_images = []
				menu.level = menu.menu_info.level;
				if (this.thumbnail_image[0]) {
					menu.thumbnail_image = this.thumbnail_image[0].name;
				} else {
					menu.thumbnail_image = null
				}

				Object.keys(this.desktopImages).forEach(key => {
					menu.cover_images.push({ id: this.desktopImages[key][0].id, link: this.links[key] ? this.links[key] : null })
				})
				Object.keys(this.mobileImages).forEach(key => {
					if (this.mobileImages[key] && !this.desktopImages[key]) {
						menu.cover_images.push({ id: this.mobileImages[key][0].id, link: this.links[key] ? this.links[key] : null })
					}
				})
				if (menu.menu_info.level == 2) {
					menu.parent_id = menu.menu_info.parent_id;
				}
				if (menu.menu_info.is_active) {
					menu.is_active = menu.menu_info.is_active;
				} else {
					menu.is_active = 0;
				}
				this.$store.dispatch("menu/create", menu).then(
					(data) => {
						this.$notify({
							text: data.message,
							group: 'success'
						});
						this.$router.push({ name: 'menuDetails', params: { id: data.body.menu_id } })
					},
					(error) => {
						let errorMessage = ''
						for (const err in error) {
							errorMessage += `${error[err]} <br>`;
						}
						this.$notify({
							text: errorMessage,
							group: 'failed'
						});
					}
				)
			}
		},
		createTranslation() {
			this.v$.$validate()
			this.customValidation()
			if (!this.v$.$error && !Object.keys(this.slidesWithError).length) {
				let menu = { ...this.menu };
				menu.language = this.selectedLocale;
				if (menu.description) {
					menu.description = this.parseForServer(menu.description)
				}
				if (menu.lower_description) {
					menu.lower_description = this.parseForServer(menu.lower_description)
				}
				menu.menu_id = this.defaultLanguageMenu.menu_id;
				menu.cover_images = []
				Object.keys(this.desktopImages).forEach(key => {
					menu.cover_images.push({ id: this.desktopImages[key][0].id, link: this.links[key] ? this.links[key] : null })
				})
				Object.keys(this.mobileImages).forEach(key => {
					if (this.mobileImages[key] && !this.desktopImages[key]) {
						menu.cover_images.push({ id: this.mobileImages[key][0].id, link: this.links[key] ? this.links[key] : null })
					}
				})
				this.$store.dispatch("menu/createTranslation", menu).then(
					(message) => {
						this.isExist = true;
						this.$notify({
							text: message,
							group: 'success'
						});
						this.getMenu(this.$route.params.id, this.selectedLocale)
					},
					(error) => {
						let errorMessage = ''
						for (const err in error) {
							errorMessage += `${error[err]} <br>`;
						}
						this.$notify({
							text: errorMessage,
							group: 'failed'
						});
					}
				)
			}
		},
		edit() {
			this.v$.$validate()
			this.customValidation()
			if (!this.v$.$error && !Object.keys(this.slidesWithError).length) {
				let menu = { ...this.menu };
				menu.language = this.selectedLocale;
				if (menu.description) {
					menu.description = this.parseForServer(menu.description)
				}
				if (menu.lower_description) {
					menu.lower_description = this.parseForServer(menu.lower_description)
				}
				menu.cover_images = []
				if (this.thumbnail_image[0]) {
					menu.thumbnail_image = this.thumbnail_image[0].name;
				} else {
					menu.thumbnail_image = null
				}
				Object.keys(this.desktopImages).forEach(key => {
					menu.cover_images.push({ id: this.desktopImages[key][0].id, link: this.links[key] ? this.links[key] : null })
				})
				Object.keys(this.mobileImages).forEach(key => {
					if (this.mobileImages[key] && !this.desktopImages[key]) {
						menu.cover_images.push({ id: this.mobileImages[key][0].id, link: this.links[key] ? this.links[key] : null })
					}
				})
				menu.level = this.menu.menu_info.level;
				if (menu.menu_info.level == 2) {
					menu.parent_id = this.menu.menu_info.parent_id;
				}
				if (menu.menu_info.is_active) {
					menu.is_active = this.menu.menu_info.is_active;
				} else {
					menu.is_active = 0;
				}
				menu.menu_id = this.defaultLanguageMenu.menu_id;
				this.$store.dispatch("menu/update", menu).then(
					(message) => {
						this.$notify({
							text: message,
							group: 'success'
						});
					},
					(error) => {
						let errorMessage = ''
						for (const err in error) {
							errorMessage += `${error[err]} <br>`;
						}
						this.$notify({
							text: errorMessage,
							group: 'failed'
						});
					}
				)
			}
		},
		async getMenu(id, language = this.selectedLocale) {
			const params = { language: language, menu_id: id }
			return this.$store.dispatch("menu/fetch", params).then(
				(response) => {
					this.isExist = true;
					if (this.appLocale === language) {
						this.defaultLanguageMenu = response;
						if (response.menu_info.menu) {
							this.defaultLanguageMenu.menu = response.menu_info.menu
						}
						if (this.selectedLocale === this.appLocale) {
							this.menu = response;
							if (this.menu.description) {
								this.menu.description = this.parseForEditor(this.menu.description)
							}
							if (this.menu.lower_description) {
								this.menu.lower_description = this.parseForEditor(this.menu.lower_description)
							}
							if (response.menu_info.thumbnail_image && !this.thumbnail_image.length) {
								this.thumbnail_image.push({ id: null, name: response.menu_info.thumbnail_image });
							}
							if (response.cover_image.length && !Object.keys(this.desktopImages).length && !Object.keys(this.mobileImages).length) {
								this.slides = []
								response.cover_image.forEach((el, index) => {
									// this.images.push({id: el.id, name: el.path});
									if (el.path) {
										this.desktopImages[index] = [];
										this.desktopImages[index][0] = { id: el.id, desktopName: el.path };
									}
									if (el.mobile_path) {
										this.mobileImages[index] = [];
										this.mobileImages[index][0] = { id: el.id, mobileName: el.mobile_path };
									}
									if (el.link) {
										this.links[index] = el.link
									}
									this.slides.push(index)
								})
							}
							this.isLoading = false
						}
					} else {
						this.isMenuInputDisable = true
						this.menu = response;
						if (this.menu.description) {
							this.menu.description = this.parseForEditor(this.menu.description)
						}
						if (this.menu.lower_description) {
							this.menu.lower_description = this.parseForEditor(this.menu.lower_description)
						}
						if (response.menu_info.thumbnail_image && !this.thumbnail_image.length) {
							this.thumbnail_image.push({ id: null, name: response.menu_info.thumbnail_image });
						}
						if (response.cover_image.length && !Object.keys(this.desktopImages).length && !Object.keys(this.mobileImages).length) {
							this.slides = []
							response.cover_image.forEach((el, index) => {
								if (el.path) {
									this.desktopImages[index] = [];
									this.desktopImages[index][0] = { id: el.id, desktopName: el.path };
								}
								if (el.mobile_path) {
									this.mobileImages[index] = [];
									this.mobileImages[index][0] = { id: el.id, mobileName: el.mobile_path };
								}
								if (el.link) {
									this.links[index] = el.link
								}
								this.slides.push(index)
							})
						}
						this.isLoading = false
					}

					var seo = response.seo_meta;
					this.menu.seo_title = seo?.title ?? '';
					this.menu.seo_description = seo?.description ?? '';					
				},
				() => {
					this.isExist = false;
					this.isLoading = false;
					if (this.defaultLanguageMenu)
						this.isMenuInputDisable = true
					else {
						if (this.appLocale === this.selectedLocale) {
							this.$router.push({ name: 'menus', query: { page: 1 } })
						}
					}
				}
			)
		},
		getMenus() {
			const params = { language: this.selectedLocale, per_page: 30, level: 1 }
			this.$store.dispatch("menu/getAll", params).then(
				(response) => {
					if (response.data.length) {
						response.data.forEach((el) => {
							this.menus.push({ value: el.menu_id, label: el.title })
						});
					}
				},
				(error) => {
					this.$notify({
						text: error,
						group: 'failed'
					});
				}
			)
		},
	}
}
</script>
