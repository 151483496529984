import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18nPlugin from './plugins/vue-i18n-plugin.js'
import dayjsPlugin from './plugins/dayjs-plugin'
import fontAwesomePlugin from './plugins/fontawesome-plugin'
import { i18n } from './plugins/vue-i18n-plugin.js'

// importing the helper
import interceptorsSetup from './helpers/interceptor'
interceptorsSetup()

//Multiselect
import '@vueform/multiselect/themes/default.css';

// CKeditor
import CKEditor from '@ckeditor/ckeditor5-vue';

//Sweet Alert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// Countries Flags Icons
import "flag-icons/css/flag-icons.min.css";

// Bootstrap Styles
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

// Global Styles
import './assets/scss/style.scss'

// Global Components
import Button from './components/Button'

import Notifications from '@kyvg/vue3-notification'

const { t } = i18n.global || i18n

const app = createApp(App)

app.use(router)
app.use(store)
app.use(Notifications)
app.use(i18nPlugin)
app.use(dayjsPlugin)
app.use(fontAwesomePlugin)
const SweetalertOptions = {
    showCancelButton: true,
    confirmButtonText: `${t('deleteAlert.deleteButton')}`,
    showCloseButton: true,
    cancelButtonText: `${t('deleteAlert.cancelButton')}`,
    title: 'Warning',
    text: 'Are you sure you want to delete this item?'
};
app.use(VueSweetalert2, SweetalertOptions);
app.use(CKEditor)

app.component("v-button", Button)

app.mount('#app')
 