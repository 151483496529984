import technicalInfoService from '../../services/technicalInfo.service'


const technicalInfo = {
    namespaced: true,
    state: {
    },
    getters: {
        
    },
    actions: {
        create(_,technicalInfo){
            return technicalInfoService.create(technicalInfo).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        createTranslation(_,technicalInfo){
            return technicalInfoService.createTranslation(technicalInfo).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data.message);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        update(_,technicalInfo){
            return technicalInfoService.edit(technicalInfo).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data.message);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        delete(_,id){
            return technicalInfoService.delete(id).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        getAll(_,params){
            return technicalInfoService.getAll(params).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data.body);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error);
                }
            )
        },
        fetch(_,params){
            return technicalInfoService.fetch(params).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data.body);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        }
    },
    mutations: {
        
    }
}

export default technicalInfo
