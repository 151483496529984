<script setup>
import { ref, inject, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useNotification } from "@kyvg/vue3-notification";
import Swal from 'sweetalert2'
import BodyHeading from '../../components/BodyHeading.vue';
import Pagination from '../../components/Pagination.vue';
import VInput from '../../components/Input.vue';
import Datepicker from 'vue3-datepicker';
import { ITEMS_PER_PAGE } from '../../constants';

const route = useRoute()
const router = useRouter()
const store = useStore()
const { notify } = useNotification()
const dayjs = inject('dayjs')

const timer = ref(null)
const filterOpened = ref(false)
const id = ref(null)
const date = ref(null)
const from = ref(null)
const to = ref(null)
const holidays = ref([])
const ParentZindex = ref(null)

if (Object.entries(route.query).length === 0 || route.query.page === '') {
	router.push({ name: 'holidays', query: { page: 1 } })
}

getHolidays()

id.value = route.query.id ?? null
date.value = route.query.date ? new Date(route.query.date) : null
from.value = route.query.from ? new from(route.query.from) : null
to.value = route.query.to ? new to(route.query.to) : null
filterOpened.value = id.value || date.value || from.value || to.value

watch(() => route.query, () => {
	getHolidays()
})

function getHolidays() {
	const queryParams = { per_page: ITEMS_PER_PAGE, ...route.query }
	store.dispatch("holiday/search", queryParams).then(
		(response) => {
			holidays.value = response
			// eslint-disable-next-line no-undef
			// holidays.value.data = holidays.value.data.map(el => ({ date: el.date.split(' ')[0], ...rest}))
		},
		(error) => {
			notify({
				text: error,
				group: 'failed'
			});
		}
	)
}

function searchQueue() {
	clearTimeout(timer.value);
	timer.value = setTimeout(() => {
		const query = {
			page: 1,
			...(id.value && { id: id.value }),
			...(date.value && { date: dayjs(date.value).format('yyyy-MM-DD') + ' 00:00:00' }),
			...(from.value && { from: dayjs(from.value).format('yyyy-MM-DD') + ' 00:00:00' }),
			...(to.value && { to: dayjs(to.value).format('yyyy-MM-DD') + ' 00:00:00' }),
		}
		router.push({
			query
		})
	}, 500);
}

function changeParentZindex(id) {
	ParentZindex.value = id
}

function deleteItem(id) {
	Swal.fire({
		title: '<div class="w-100 text-center"><strong class="text-danger">Be cautious!!!</strong></div>',
		icon: 'warning', text: 'Are you sure?'
	}).then((result) => {
		if (result.isConfirmed) {
			store.dispatch("holiday/delete", id).then(
				(message) => {
					notify({
						text: message,
						group: 'success'
					});
					getHolidays()
				},
				(error) => {
					let errorMessage = ''
					for (const err in error) {
						errorMessage += `${error[err]} <br>`;
					}
					notify({
						text: errorMessage,
						group: 'failed'
					});
				}
			)
		}
	})
}

function changePage(page = 1) {
	router.push({ name: 'holidays', query: { ...route.query, page: page } });
}

</script>

<template>
	<BodyHeading :borderColor="'rgb(87, 217, 163)'" :title="$t('holiday.entityName', 2)" :count="holidays.total"
		:multiLanguageDisabled="true" :listHeading="true" :btnTitle="$t('holiday.addButtonTitle')"
		:createBtnLink="'holidayCreate'">
	</BodyHeading>

	<div class="page-body-main-list d-grid grid-columns">
		<div class="table-responsive">
			<table class="table table-striped table-hover table-bordered">
				<thead>
					<tr>
						<th scope="col" style="width: 5%"><span>#</span></th>
						<th scope="col" style="width: 5%"><span>TITLE</span></th>
						<th scope="col"><span>{{ $t('holiday.date') }}</span></th>
						<th scope="col" style="width: 5%"><span>ID</span></th>
						<th scope="col" style="width: 5%"><span>{{ $t('actions') }}</span></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(holiday, index) in holidays.data" :key="holiday.id">
						<td>{{ index + 1 + (holidays.current_page - 1) * ITEMS_PER_PAGE }}</td>
						<td>{{ holiday.title }}</td>
						<td>{{ holiday.date }}</td>
						<td>{{ holiday.id }}</td>
						<td :style="holiday.id === ParentZindex ? { zIndex: 2 } : null">
							<div class="dropdown actions-dropdown">
								<button @click="changeParentZindex(holiday.id)" class="btn btn-light" type="button"
									id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
									<font-awesome-icon :icon="['fas', 'ellipsis-h']" size="sm"></font-awesome-icon>
								</button>
								<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
									<li>
										<a @click="$router.push({ name: 'holidayDetails', params: { id: holiday.id } })"
											class="dropdown-item">{{ $t('edit') }}</a>
									</li>
									<li>
										<a class="dropdown-item warning" @click="deleteItem(holiday.id)">{{ $t('delete') }}</a>
									</li>
								</ul>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<!-- Filter Box -->
		<div class="filter-bar">
			<aside :class="{ 'opened': filterOpened }">
				<nav>
					<ul>
						<li>
							<button :class="{ 'active': filterOpened }" @click="filterOpened = !filterOpened">
								<font-awesome-icon :icon="['fas', 'filter']"></font-awesome-icon>
							</button>
						</li>
					</ul>
				</nav>
				<div class="filter-content">
					<div class="filter-panel">
						<div class="headline">
							<h3>{{ $t('filters.title') }}</h3>
							<button @click="filterOpened = !filterOpened">
								<font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon>
							</button>
						</div>
						<div class="content">
							<div>
								<div>
									<div class="element">
										<v-input type="text" name="id" label="holiday.id" v-model="id"
											@update:modelValue="searchQueue"></v-input>
									</div>
									<div class="element">
										<label for="date" class="form-label">{{ $t('holiday.date') }}</label>
										<Datepicker id="date" v-model="date" class="form-control" clearable
											@update:modelValue="searchQueue" />
									</div>
									<div class="element">
										<label for="from-date" class="form-label">{{ $t('holiday.from') }}</label>
										<Datepicker id="from-date" v-model="from" class="form-control" clearable
											@update:modelValue="searchQueue" />
									</div>
									<div class="element">
										<label for="to-date" class="form-label">{{ $t('holiday.to') }}</label>
										<Datepicker id="to-date" v-model="to" class="form-control" clearable
											@update:modelValue="searchQueue" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</aside>
		</div>
		<!-- // Filter Box -->

		<Pagination v-if="holidays.total > holidays.per_page" :lastPage="holidays.last_page"
			:activePage="holidays.current_page" @handleClick="changePage"></Pagination>
</div></template>