import menuService from '../../services/menu.service'


const menu = {
    namespaced: true,
    state: {
    },
    getters: {
        
    },
    actions: {
        getProducts(_,params){
            return menuService.getProducts(params).then(
                data => {
                    return Promise.resolve(data.body);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        attachProducts(_,menu){
            return menuService.attachProducts(menu).then(
                data => {
                    return Promise.resolve(data);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        activate(_,id) {
            return menuService.activate(id).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        deactivate(_,id) {
            return menuService.deactivate(id).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        create(_,menu){
            return menuService.create(menu).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        createTranslation(_,menu){
            return menuService.createTranslation(menu).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data.message);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        update(_,menu){
            return menuService.edit(menu).then(
                data => {
                    console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data.message);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        sort(_,menus){
            console.log("🚀 ~ sort ~ menus:", menus)
            return menuService.sort(menus).then(
                data => {
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data.message);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        delete(_,id){
            return menuService.delete(id).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        deleteSlide(_,id){
            return menuService.deleteSlide(id).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        getAll(_,params){
            return menuService.getAll(params).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data.body);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error);
                }
            )
        },
        fetch(_,params){
            return menuService.fetch(params).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data.body);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        }
    },
    mutations: {
        
    }
}

export default menu
