<template>
    <BodyHeading :borderColor="'rgb(87, 217, 163)'" :title="$t('Medienzentrum')"
        :multiLanguageDisabled="false" :listHeading="true" :btnTitle="$t('product.addButtonTitle')"
        :createBtnLink="'productCreate'"></BodyHeading>
        <div class="page-body-main-list d-grid grid-columns">
            <div class="table-responsive">
                <table class="table table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" style="width:5%"><span>#</span></th>
                            <th scope="col" style="width:25%"><span>File</span></th>
                            <th scope="col" style="width:30%"><span>Titel</span></th>
                            <th scope="col" style="width:20%"><span>Produkt</span></th>
                            <th scope="col" style="width:10%"><span>Combination</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(comb, index) in combinations.data" :key="comb.id">
                            <td>{{index + 1 + (combinations.current_page - 1) * 15}}</td>
                            <td>
                                <img width="200" :src="getImageUrl(comb.path)" alt="product combination image">
                            </td>
                            <td>
                                {{ comb.path }} <br>
                                <v-input 
                                    style="width:50%;float:left" 
                                    v-model="comb.newFileName" 
                                    @input="addExtensionToInput($event, comb)"
                                ></v-input>
                                <button @click="savecombination(comb)" class="btn button-action d-none d-sm-inline-block">Save</button>
                            </td>
                            <td>{{ comb.product_title }}</td>
                            <td><a :href="comb.combination" target="_blank">View Combination</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>  
            
            
        <div class="filter-bar">
            <aside :class="{ 'opened': filterOpened }">
                <nav>
                    <ul>
                        <li>
                            <button :class="{ 'active': filterOpened }" @click="filterOpened = !filterOpened">
                                <font-awesome-icon :icon="['fas', 'filter']"></font-awesome-icon>
                            </button>
                        </li>
                    </ul>
                </nav>
                <div class="filter-content">
                    <div class="filter-panel">
                        <div class="headline">
                            <h3>{{ $t('filters.title') }}</h3>
                            <button @click="filterOpened = !filterOpened"><font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon></button>
                        </div>
                        <div class="content">
                            <div>
                                <div>
                                    <div class="element">
                                        <v-input type="text" name="title" label="Produkt Titel" v-model="product_title"
                                            @keyup="searchQueue()"></v-input>
                                    </div>
                                    <div class="element">
                                        <v-input type="text" name="title" label="common.title" v-model="image_name"
                                            @keyup="searchQueue()"></v-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </div>

            <Pagination v-if="combinations.total > combinations.per_page" :lastPage="combinations.last_page"
                :activePage="combinations.current_page" @handleClick="changePageFile"></Pagination>
        </div>
    </template>
<script>
import BodyHeading from '../../components/BodyHeading.vue';
import Pagination from '../../components/Pagination.vue';
import Constants from '../../constants/index.js'
import VInput from '../../components/Input.vue'
export default {
    components: {
        BodyHeading,
        Pagination,
        'v-input': VInput
    },
    data() {
        return {
            activeTabIndex: 0,
            timer: null,
            filterOpened: false,
            title: null,
            code: null,
            product_id: null,
            combinations: {},
            title_selected: null,
            categories: [],
            default_menu_id: null,
            selected_product_id: null,
            productMenus: [],
            ParentZindex: null,
            isSwitchLocked: false,
            selectedCategories: null,
            image: '',
            product_title: '',
            image_name: ''
            
        }
    },
    created() {
        this.getProducts()
    },
    watch: {
        '$route.query'(){
            this.getProducts()
        }
    },
    methods: {
        changePageFile(page) {
            this.$router.push({ query: { ...this.$route.query, page } });
        },
        initializeNewFileNames() {
            this.combinations.data.forEach(comb => {
                comb.newFileName = comb.path.split('/').pop();
            });
        },
        getImageUrl(image) {
            return Constants['SERVER_PUBLIC_URL'] + `fetch/image?path=/image/product/combination&image=${image}`
        },
        getProducts() {
            const params = { language: this.selectedLocale, per_page: 15, ...this.$route.query }
            this.$store.dispatch("media/combinations", params).then(
                (response) => {
                    this.combinations = response;
                    this.initializeNewFileNames();
                },
                (error) => {
                    this.$notify({
                        text: error,
                        group: 'failed'
                    });
                }
            )
        },
        addExtensionToInput(event, comb) {
            let inputValue = event.target.value;
            const currentExtension = comb.path.split('.').pop(); // Get the original file extension
            
            // Ensure the user hasn't removed the extension
            if (!inputValue.includes('.')) {
                inputValue = `${inputValue}.${currentExtension}`;
                event.target.value = inputValue; // Update the input field with the extension
            }

            // Set the cursor position before the extension
            const baseName = inputValue.slice(0, inputValue.lastIndexOf('.'));
            const cursorPosition = baseName.length;
            event.target.setSelectionRange(cursorPosition, cursorPosition);

            // Store the updated name separately without modifying the original path
            comb.newFileName = inputValue;
        },
        savecombination(comb) {
            const updatedData = {
                id: comb.id,
                path: comb.newFileName, 
            };

            this.$store.dispatch("media/combination_update", updatedData).then(
                (data) => {
                    this.$notify({
                        text: data.message,
                        group: 'success'
                    })
                },
                (error) => {
                    let errorMessage = ''
                    for (const err in error) {
                        errorMessage += `${error[err]} <br>`
                    }
                    this.$notify({
                        text: errorMessage,
                        group: 'failed'
                    })
                }
            );
        },
        searchQueue() {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                const query = {
                    page: 1,
                    ...(this.product_title && { product_title: this.product_title }),
                    ...(this.image_name && { image_name: this.image_name })
                }
                this.$router.push({
                    query
                })
            }, 500);
        }
    }
}
</script>
