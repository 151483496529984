import DownloadFiles from '../../services/download.files'

const downloadfiles = {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        create(_, downloadfiles) {
            console.log("🚀 ~ create ~ downloadfiles:", downloadfiles)
            return DownloadFiles.create(downloadfiles).then(
                data => {
                    return Promise.resolve(data);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        createTranslation(_, {urlParams, downloadfiles}) {
            return DownloadFiles.addTranslation(urlParams, downloadfiles).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        update(_, {urlParams, downloadfiles}) {
            return DownloadFiles.update(urlParams, downloadfiles).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        delete(_, urlParams) {
            return DownloadFiles.delete(urlParams).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        deleteValue(_, urlParams) {
            return DownloadFiles.deleteValue(urlParams).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        getAll(_, queryParams) {
            return DownloadFiles.getAll(queryParams).then(
                data => {
                    return Promise.resolve(data.body);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        fetch(_, urlParams) {
            return DownloadFiles.fetch(urlParams).then(
                data => {
                    return Promise.resolve(data.body);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
    },
    
    mutations: {}
}

export default downloadfiles
