import axios from 'axios';
import Constants from '../constants';
import authHeader from './auth-header'


class ContactUsService {
    fetch(params) {
      return axios
          .get(Constants['SERVER_URL']+'footer/fetch/'+`${params.language}`, {headers:authHeader()})
          .then(response => {
              return response.data;
          })
    }
    edit([footer, params]){
      return axios
        .post(Constants['SERVER_URL']+'footer/update/'+`${params.language}`, footer, {headers:authHeader()})
        .then(response => {    
          return response.data;
      })
    }
}



export default new ContactUsService();