import axios from 'axios';
import Constants from '../constants';
import authHeader from './auth-header'


class TechnicalInfoService {
    fetch(params) {
      return axios
          .get(Constants['SERVER_URL']+'technical-info/fetch/'+`${params.technical_info_id}/${params.language}`, {headers:authHeader()})
          .then(response => {
              return response.data;
          })
    }
    getAll(params) {
        return axios
            .get(Constants['SERVER_URL']+'technical-info/search', {params: params,headers:authHeader()})
            .then(response => {
                return response.data;
            })
    }
    create(technicalInfo) {
        return axios
          .post(Constants['SERVER_URL']+'technical-info/create', technicalInfo, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    createTranslation(technicalInfo) {
        return axios
          .post(Constants['SERVER_URL']+'technical-info/add/translation/'+ technicalInfo.technical_info_id, technicalInfo, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    edit(technicalInfo){
      return axios
        .patch(Constants['SERVER_URL']+'technical-info/update/'+ `${technicalInfo.technical_info_id}/${technicalInfo.language}`, technicalInfo, {headers:authHeader()})
        .then(response => {    
          return response.data;
      })
    }
    delete(technical_info_id) {
        return axios
          .delete(Constants['SERVER_URL']+'technical-info/delete/'+ technical_info_id, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
}



export default new TechnicalInfoService();