import orderService from '../../services/order.service'


const order = {
    namespaced: true,
    state: {
        unseenOrdersCount: 0
    },
    getters: {
        getUnseenOrdersCount : state => state.unseenOrdersCount
    },
    actions: {
        getOrdersCount(){
            return orderService.getOrdersCount().then(
                data => {
                    return Promise.resolve(data);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            ) 
        },
        getImage(_,params){
            return orderService.getImage(params).then(
                data => {
                    return Promise.resolve(data);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            ) 
        },
        deleteImage(_,imageId){
            return orderService.deleteImage(imageId).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            ) 
        },
        setNeededImagesCount(_, { paymentIntent, body }){
            return orderService.setNeededImagesCount(paymentIntent, body).then(
                data => {
                    return Promise.resolve(data);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            ) 
        },
        update(_,order){
            return orderService.edit(order).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data.message);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        get_csv(_,params){
            return orderService.getCsv(params).then(
                data => {
                    return Promise.resolve(data.body);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        getAll(_,params){
            return orderService.getAll(params).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data.body);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error);
                }
            )
        },
        getAddresses(_,params){
            return orderService.getAddresses(params).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data.body);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error);
                }
            )
        },
        getAddressProducts(_,params){
            return orderService.getAddressProducts(params).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data.body);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error);
                }
            )
        },
        fetch(_,params){
            return orderService.fetch(params).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data.body);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        downloadCSV(_, filename) {
            return orderService.downloadCSV(filename).catch(error => Promise.reject(error.response.data.errors))
        },
        cancelOrder(_, paymentIntent) {
            return orderService.cancelOrder(paymentIntent).then(
                data => Promise.resolve(data.message),
                error => Promise.reject(error.response.data.errors)
            )
        },
        completeOrder(_, paymentIntent) {
            return orderService.completeOrder(paymentIntent).then(
                data => Promise.resolve(data.message),
                error => Promise.reject(error.response.data.errors)
            )
        },
        getLogs(_, paymentIntent) {
            return orderService.getLogs(paymentIntent).then(
                data => Promise.resolve(data.body.data),
                error => Promise.reject(error)
            )
        }
    },
    mutations: {
        setUnseenOrdersCount(state, count){
            state.unseenOrdersCount = count
        }
    }
}

export default order
