<template>
   <BodyHeading v-if="$route.name.includes('Details')" :borderColor="'rgb(154, 168, 181)'" :title="$t('tax.title')" 
     @handleClick="edit" :multiLanguageDisabled="true" :backLink="'settings'" :backIcon="'cog'"></BodyHeading>
   <div class="page-body-main-create">
      <div class="card-wrapper">
         <div class="card-title">{{$t('generalInformation')}}</div>
         <div class="card">
            <div class="card-body">
               <!--  -->
              <div v-if="!isLoading" class="row">
                  <div class="mb-4">
                     <v-input type="text" name="tax" label="tax.tax" v-model="tax.tax" :isRequired="true"
                        :validateError="v$.tax.tax && v$.tax.tax.$error? v$.tax.tax.$errors[0].$message : null"></v-input>
                  </div>
              </div>
            </div>
         </div>
      </div>
   </div>
</template>



<script>
import BodyHeading from '../../components/BodyHeading.vue'
import Input from '../../components/Input.vue'
import useValidate from '@vuelidate/core';
import { numeric } from '@/utilities/i18n-validators.js'

export default {
      components: {
         BodyHeading,
         'v-input': Input
      },
      data() {
         return{
            v$: useValidate(),
            tax: {},
            isLoading: true
         }
      },
      validations() {
         return {
            tax: {
               tax: {numeric}
            }
         }
      },
      created() {
         this.gettax()
      },
      methods: {
         edit() {
            this.v$.$validate()
            if(!this.v$.$error){
               this.tax.tax = this.tax.tax || this.tax.tax != '' ? this.tax.tax : 0;
               this.$store.dispatch("tax/update", this.tax).then(
                  (message) => {
                     this.$notify({
                           text: message,
                           group: 'success'
                     });
                     // this.$router.push({name: 'dashboard'})
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         },
         gettax() {
            this.$store.dispatch("tax/fetch").then(
                (response) => {
                  this.tax = response.tax? response : {tax: 0};
                  this.isLoading = false
                },
                () => {
                  this.isLoading = false;
                  // let errorMessage = ''
                  // for (const err in error) {
                  //    errorMessage += `${error[err]} <br>`;
                  // }
                  // this.$notify({
                  //    text: errorMessage,
                  //    group: 'failed'
                  // });
                }
            )
        },
      }
   }
</script>
