<script setup>
import { ref, inject } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import BodyHeading from '../../components/BodyHeading.vue'
import Datepicker from 'vue3-datepicker';
import { useNotification } from "@kyvg/vue3-notification"
import useValidate from '@vuelidate/core';
import { required } from '@/utilities/i18n-validators.js';
const holiday = ref(null)
const title = ref(null)
const isExist = ref(true)
const isLoading = ref(true)
const holidayRule = {
	holiday: {
		required
	}
}

const TitleRule = {
	title: {
		required
	}
}

const v$ = useValidate(holidayRule, { holiday });
const v1$ = useValidate(TitleRule, { title });
const dayjs = inject('dayjs');
const { notify } = useNotification();

const route = useRoute();
const router = useRouter();
const store = useStore();

function getHoliday() {
	store.dispatch("holiday/fetch", route.params.id).then(
		(response) => {
			holiday.value = new Date(response.date)
			title.value = response.title
			isExist.value = true;
			isLoading.value = false;
		}).catch((error) => {
			notify({
				text: error,
				group: 'failed'
			});
			isExist.value = false;
			isLoading.value = false;
			router.push({ name: 'holidays', query: { page: 1 } })
		})
}

if (route.name.includes('Details'))
	getHoliday()
else isLoading.value = false

async function create() {
	console.log(title);
	const valid = await v$.value.$validate();
	if (valid) {
		const holidayDate = {
			date: dayjs(holiday.value).format('YYYY-MM-DD') + ' 00:00:00',
			title: title.value
		}
		console.log(holidayDate);
		store.dispatch("holiday/create", holidayDate).then(
			(data) => {
				notify({
					text: data.message,
					group: 'success'
				});
				router.push({ name: 'holidayDetails', params: { id: data.body.id } })
			},
			(error) => {
				let errorMessage = ''
				for (const err in error) {
					errorMessage += `${error[err]} <br>`;
				}
				notify({
					text: errorMessage,
					group: 'failed'
				});
			}
		)
	}
}
async function edit() {
	const valid = await v$.value.$validate()
	if (valid) {
		const urlParams = { id: route.params.id }
		const holidayDate = {
			date: dayjs(holiday.value).format('YYYY-MM-DD') + ' 00:00:00',
			title: title.value
		}
		store.dispatch("holiday/update", { urlParams, holidayDate }).then(
			(message) => {
				notify({
					text: message,
					group: 'success'
				})
			},
			(error) => {
				let errorMessage = ''
				for (const err in error) {
					errorMessage += `${error[err]} <br>`;
				}
				notify({
					text: errorMessage,
					group: 'failed'
				})
			}
		)
	}
}

</script>

<template>
	<BodyHeading :borderColor="'rgb(154, 168, 181)'"
		:title="$route.name.includes('Details') ? $t('holiday.editTitle') : $t('holiday.addButtonTitle')"
		v-on="$route.name.includes('Details') ? { handleClick: edit } : { handleClick: create }" :multiLanguageDisabled="true"></BodyHeading>
	<div class="page-body-main-create">
		<div class="card-wrapper">
			<div class="card-title">{{ $t('generalInformation') }}</div>
			<div class="card">
				<div class="card-body">
					<div v-if="!isLoading" class="row">
						<div class="mb-4">
							<label for="date" class="form-label is-required">Titel</label>
							<input type="text" name="title" v-model="v1$.title.$model" label="" class="form-control">
						</div>
						<div class="mb-4">
							<label for="date" class="form-label is-required">{{ $t('holiday.date') }}</label>
							<Datepicker id="date" v-model="v$.holiday.$model" :lower-limit="new Date()" class="form-control"
								:class="v$.holiday && v$.holiday.$error ? 'form-controll-invalid' : ''" />
							<div class="invalid-feedback" v-if="v$.holiday && v$.holiday.$error">
								{{ v$.holiday.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
