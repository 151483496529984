import {createStore} from 'vuex'
import auth from './modules/auth'
import locale from './modules/locale'
import technicalInfo from './modules/technicalInfo'
import shippingInfo from './modules/shippingInfo'
import postMethod from './modules/postMethod'
import duration from './modules/duration'
import country from './modules/country'
import option from './modules/option'
import portfolio from './modules/portfolio'
import user from './modules/user'
import role from './modules/role'
import tax from './modules/tax'
import menu from './modules/menu'
import product from './modules/product/index'
import home from './modules/home'
import homeSection from './modules/homeSection'
import homeSlider from './modules/homeSlider'
import coupon from './modules/coupon'
import order from './modules/order'
import page from './modules/page'
import footer from './modules/footer';
import fileUploader from './modules/fileUploader'
import popup from './modules/popup'
import newsletter from './modules/newsletter'
import service from './modules/service'
import holiday from './modules/holiday'
import redirects from './modules/redirects'
import download from './modules/download'
import downloadfiles from './modules/downloadfiles'
import media from './modules/media'

const store = createStore({
    state: {},
    modules: {
        auth,
        locale,
        technicalInfo,
        shippingInfo,
        postMethod,
        duration,
        country,
        option,
        portfolio,
        user,
        role,
        tax,
        menu,
        product,
        home,
        homeSection,
        homeSlider,
        coupon,
        order,
        page,
        footer,
        fileUploader,
        popup,
        newsletter,
        service,
        holiday,
        redirects,
        download,
        downloadfiles,
        media
    }
})

export default store
