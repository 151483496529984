<template>
   <label :for="name" :class="isRequired ? 'is-required' : ''" class="form-label">{{ $t(label) }}</label>
   <ckeditor :editor="editor" :config="{ placeholder: placeholder, ...editorConfig }" :model-value="modelValue"
      @input="this.$emit('update:modelValue', $event)"></ckeditor>
   <div class="invalid-feedback" v-if="validateError">
      {{ validateError }}
   </div>
</template>

<script>

import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic'

import { Essentials } from '@ckeditor/ckeditor5-essentials'
import { Link } from '@ckeditor/ckeditor5-link'
import { Paragraph } from '@ckeditor/ckeditor5-paragraph'
import { Bold, Code, Italic, Strikethrough, Subscript, Superscript, Underline } from '@ckeditor/ckeditor5-basic-styles';

import { Alignment } from '@ckeditor/ckeditor5-alignment'
import { Base64UploadAdapter } from '@ckeditor/ckeditor5-upload'
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote'
import { Heading } from '@ckeditor/ckeditor5-heading'
import { HorizontalLine } from '@ckeditor/ckeditor5-horizontal-line'
import { HtmlEmbed } from '@ckeditor/ckeditor5-html-embed'
import { Image } from '@ckeditor/ckeditor5-image'
import { ImageUpload } from '@ckeditor/ckeditor5-image'
import { DocumentListProperties } from '@ckeditor/ckeditor5-list'
import AdjacentListsSupport from '@ckeditor/ckeditor5-list/src/documentlist/adjacentlistssupport.js';
import { MediaEmbed } from '@ckeditor/ckeditor5-media-embed'
import { Table } from '@ckeditor/ckeditor5-table'
import { TableToolbar } from '@ckeditor/ckeditor5-table'
import { Title } from '@ckeditor/ckeditor5-heading'
import { WordCount } from '@ckeditor/ckeditor5-word-count'

import { TextTransformation } from '@ckeditor/ckeditor5-typing'
import { FontBackgroundColor } from '@ckeditor/ckeditor5-font'
import { FontColor } from '@ckeditor/ckeditor5-font'

import { SourceEditing } from '@ckeditor/ckeditor5-source-editing'
import { GeneralHtmlSupport } from '@ckeditor/ckeditor5-html-support'
import { Style } from '@ckeditor/ckeditor5-style'

export default {
   props: {
      modelValue: {
         default: ''
      },
      name: {
         type: String,
         default: ''
      },
      label: {
         default: ''
      },
      placeholder: {
         type: String,
         default: ''
      },
      validateError: {
         default: null
      },
      isRequired: {
         default: false
      },
      title: {
         type: Boolean,
         default: false
      }
   },
   data() {
      return {
         editor: ClassicEditor,
         editorConfig: {
            plugins: [
               Essentials,
               Bold,
               Code,
               Italic,
               Strikethrough,
               Subscript,
               Superscript,
               Underline,
               Link,
               Paragraph,
               Alignment,
               Base64UploadAdapter,
               BlockQuote,
               Heading,
               HorizontalLine,
               HtmlEmbed,
               Image,
               ImageUpload,
               DocumentListProperties,
               AdjacentListsSupport,
               MediaEmbed,
               Table,
               TableToolbar,
               WordCount,
               TextTransformation,
               FontBackgroundColor,
               FontColor,
               SourceEditing,
               GeneralHtmlSupport,
               Style
            ],
            toolbar: {
               items: [
                  'style',
                  'heading',
                  'bold',
                  'italic',
                  'underline',
                  'strikethrough',
                  'subscript',
                  'superscript',
                  '|',
                  'fontColor',
                  'fontBackgroundColor',
                  '|',
                  'alignment',
                  '|',
                  'bulletedList',
                  'numberedList',
                  '|',
                  'blockQuote',
                  'insertTable',
                  '|',
                  'horizontalLine',
                  '|',
                  'link',
                  'mediaEmbed',
                  'imageUpload',
                  '|',
                  'undo',
                  'redo',
                  'sourceEditing'
               ]
            },
            fontColor: {
               colors: [
                  {
                     color: '#fc6c00',
                     label: 'Seven Orange'
                  },
               ]
            },
            list: {
               properties: {
                  styles: true,
                  startIndex: true,
                  reversed: true,
               }
            },
            table: {
               contentToolbar: [
                  'tableColumn',
                  'tableRow',
                  'mergeTableCells'
               ]
            },
            htmlSupport: {
               allow: [
                  {
                     name: /.*/,
                     attributes: true,
                     classes: true,
                     styles: true
                  }
               ]
            },
            style: {
               definitions: [
                  {
                     name: 'Heading 1 Orange',
                     element: 'h2',
                     classes: ['pd-orange']
                  },
                  {
                     name: 'Heading 2 Orange',
                     element: 'h3',
                     classes: ['pd-orange']
                  },
                  {
                     name: 'Bulleted List with solid-cross icon',
                     element: 'ul',
                     classes: ['sd-list', 'sd-list-cross-solid']
                  },
                  {
                     name: 'Green Checked List',
                     element: 'ul',
                     classes: ['sd-list', 'done']
                  },
                  {
                     name: 'Orange Numbered List',
                     element: 'ol',
                     classes: ['sd-number', 'sd-number-solid', 'sd-orange']
                  }
               ]
            }
         }
      }
   },
   created() {
      if (this.title) {
         this.editorConfig.plugins.push(Title)
      }
   }
}
</script>

<style lang="scss">
$orange-color: #fc6c00;

.ck.ck-toolbar {
   background-color: #f0f2f5 !important;
   border: 1px solid #d1d5db;
   border-top-left-radius: 0.25rem !important;
   border-top-right-radius: 0.25rem !important;
}

.ck.ck-editor__main>.ck-editor__editable {
   max-height: 50vh;
   border-color: #d1d5db !important;
}

.ck-editor__editable_inline {
   min-height: 300px;
   max-height: 700px;
}

.ck.ck-content {
   .pd-orange {
      color: $orange-color;
   }

   img {
      max-width: 100%;
   }

   p.text-small {
      font-size: 14px
   }

   .contact-det {
      font-size: 26px;
      margin-bottom: 1rem
   }

   h1 {
      margin-bottom: 36px;
      display: flex;
      font-size: 2rem;
   }

   h1 i {
      font-size: 38px;
      margin-right: .25rem;
      color: #999
   }

   h1 span {
      font-weight: 400;
      font-size: 85%
   }

   ol {
      /*    font-weight: 600;
      font-size: 18px*/
      padding-left: 1rem;
   }

   ol li {
      margin-bottom: 1rem;
   }

   ol p {
      margin-bottom: 0
   }

   ol ul {
      padding-left: 16px;
      padding-bottom: 16px;
      font-size: 16px;
      font-weight: 400
   }

   ol ul li {
      margin-bottom: 8px
   }

   a {
      color: #666 !important;
      border-bottom: 1px dashed #999
   }

   a:hover {
      color: #fc6c00 !important;
      border-color: #fc6c00;
      text-decoration: none
   }

   h2 {
      margin-top: 36px
   }

   h2:first-child {
      margin-top: 0;
      margin-bottom: 2rem;
   }

   h3 {
      font-size: 1.25rem;
   }

   /*Accordion Style*/
   div.accordion.sd-Seven {
      margin: 20px 0 30px;
   }

   div.accordion.sd-Seven h2 {
      margin-top: 50px;
      margin-bottom: 25px;
      color: #fc6c00;
   }

   div.accordion.sd-Seven h3 {
      font-size: 22px;
   }

   div.accordion.sd-Seven h4 {
      font-size: 20px;
   }

   div.accordion.sd-Seven .accItem {
      margin-bottom: 25px;
   }

   div.accordion.sd-Seven .accItem h3.accItem-header {
      color: #fc6c00;
      font-size: 18px;
   }

   div.accordion.sd-Seven .accItem h3.accItem-header:before {
      display: inline-block;
      vertical-align: middle;
      color: $orange-color;
      margin-right: 10px;
      width: 16px;
      height: 16px;
      content: " ";
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 448 512'%3E%3C!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cpath fill='%23fc6c00' d='M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM92 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H92z'/%3E%3C/svg%3E");
   }

   div.accordion.sd-Seven .accItem h3.accItem-header:hover {
      cursor: pointer;
   }

   div.accordion.sd-Seven .accItem h3.accItem-header[aria-expanded=false] {
      color: #333;
      font-weight: 400;
   }

   div.accordion.sd-Seven .accItem h3.accItem-header[aria-expanded=false]:before {
      display: inline-block;
      vertical-align: middle;
      color: $orange-color;
      margin-right: 10px;
      width: 16px;
      height: 16px;
      content: " ";
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 448 512' %3E%3Cpath d='M64 32C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64h320c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm136 312v-64h-64c-13.3 0-24-10.7-24-24s10.7-24 24-24h64v-64c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24h-64v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z'/%3E%3C/svg%3E");
   }

   div.accordion.sd-Seven .accItem h3.accItem-header[aria-expanded=false]:hover {
      color: #fc6c00;
      cursor: pointer;

      &::before {
         display: inline-block;
         vertical-align: middle;
         color: $orange-color;
         margin-right: 10px;
         width: 16px;
         height: 16px;
         content: " ";
         background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 448 512' %3E%3Cpath fill='%23fc6c00' d='M64 32C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64h320c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm136 312v-64h-64c-13.3 0-24-10.7-24-24s10.7-24 24-24h64v-64c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24h-64v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z'/%3E%3C/svg%3E");
      }
   }

   div.accordion.sd-Seven .accItem h3.accItem-header i {
      margin-right: 10px;
      font-size: 18px;
   }

   div.accordion.sd-Seven .accItem .accItem-body {
      padding: 10px 30px 15px 25px;
      background: rgba(0, 0, 0, 0.015);
      border: 1px solid #f5f5f5;
      margin-top: 1rem;
   }

   div.accordion.sd-Seven .accItem .accItem-body p {
      margin-top: .5rem;
      margin-bottom: .5rem;
   }

   div.accordion.sd-Seven .accItem .accItem-body .sd-list {
      margin-top: .5rem;
      margin-bottom: .5rem;
   }

   div.accordion.sd-Seven .accItem .accItem-body .sd-list li {
      margin-bottom: 1rem;
      flex-direction: column;
   }

   div.accordion.sd-Seven .accItem .accItem-body .sd-list li:last-child {
      margin-bottom: 0;
   }

   div.accordion.sd-Seven .accItem .accItem-body .sd-list li p {
      margin: 0 0 7px 0;
      line-height: 24px;
      font-size: 16px;
   }

   div.accordion.sd-Seven .accItem .accItem-body .sd-list li p b {
      margin-bottom: 4px;
      display: inline-block;
   }

   div.accordion.sd-Seven .accItem .accItem-body .sd-list.sd-list-tick li {
      margin-bottom: 8px;
      flex-direction: initial;
   }

   div.accordion.sd-Seven .accItem .accItem-body .sd-number li {
      text-indent: -15px;
      display: inline-block;
      padding-left: 15px;
      margin-bottom: 0;
      line-height: 22px;
   }

   div.accordion.sd-Seven .accItem .accItem-body .sd-number li:last-child {
      margin-bottom: 0;
   }

   div.accordion.sd-Seven .accItem .accItem-body .sd-number li:before {
      display: none;
   }

   .sd-list {
      padding-left: 0;
      list-style: none;
   }

   .sd-list li {
      display: inline-block;
      line-height: 20px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      font-size: 15px;
      white-space: pre-wrap;
   }

   .sd-list li ul {
      list-style: circle;
      white-space: normal;
      padding-bottom: .5rem;
   }

   .sd-list li ul li {
      margin-bottom: 0 !important;
      display: list-item;
      line-height: 22px;
   }

   .sd-list-tick li:before {
      content: "\2713";
      font-size: 16px;
      font-weight: bolder;
      margin-right: 10px;
      color: green;
   }

   // .sd-list-tick li:before {
   //    content: '\f00c';
   //    font-size: 14px;
   // }

   .sd-list-cross li:before {
      content: '\f00d';
   }

   .sd-list-cross-solid li:before {
      width: 15px;
      height: 15px;
      margin-right: 10px;
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='15px' width='15px' fill='%23fc6c00' viewBox='0 0 512 512' %3E%3C!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cpath d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z'/%3E%3C/svg%3E");
   }

   /* Number Styles */
   .sd-number.sd-orange li:before {
      background-color: #fc6c00;
      background-image: linear-gradient(to right, #f15e3e, #f47b2e);
   }

   .sd-number.sd-blue li:before {
      background-color: #2abbe7;
   }

   .sd-number {
      list-style: none;
      padding-left: 0 !important;
      counter-reset: my-awesome-counter;
   }

   .sd-number li {
      counter-increment: my-awesome-counter;
      display: flex;
      line-height: 20px;
      position: relative;
      padding-left: 30px;
   }

   .sd-number li::before {
      content: counter(my-awesome-counter) "";
      color: #fff;
      font-weight: 500;
      margin-right: 7px;
      width: 18px;
      height: 18px;
      display: inline-block;
      text-align: center;
      line-height: 17px;
      font-size: 12px;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
   }

   div.material h4 {
      font-size: 20px
   }

   div.material label {
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 5px
   }

   div.material .tiny {
      font-size: 13px;
      color: green
   }

   // a.pdf2 {
   //    line-height: 24px;
   //    background-image: url(../images/icon_list_pdf.png);
   //    background-position: left 4px;
   //    background-repeat: no-repeat;
   //    padding: 0 0 0 22px;
   //    display: block
   // }

   /* Download Style */
   .sd-list-download {
      list-style: none;

      h3 {
         margin-top: 1rem;

         &:first-child {
            margin-top: 0;
         }
      }

      li {
         line-height: 28px;
         font-size: 15px;
         display: flex;
         align-items: center;

         &::before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1.25em' viewBox='0 0 384 512'%3E%3C!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cstyle%3Esvg%7Bfill:%23ff0000%7D%3C/style%3E%3Cpath d='M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z'/%3E%3C/svg%3E");
            width: 15px;
            height: 20px;
            margin-right: 10px;
            content: "";
         }
      }
   }

   .sd-list-download li a {
      border: none;

      &:hover {
         color: #333 !important;
         text-decoration: underline;
      }
   }

   .teamCard {
      background: rgba(0, 0, 0, 0.01);
      transition: all .3s;
      margin-bottom: 2rem;
      border-left: 3px solid #e9e9e9;

      * {
         transition: all .3s;
      }

      .card-body {
         h3 {
            text-align: center;
            text-transform: uppercase;
            font-size: 17px;
         }

         h4 {
            text-align: center;
            font-size: 17px;
            font-weight: 400;
         }

         a {
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            font-weight: 300;
            letter-spacing: .25px;
            font-size: 15px;
         }

         p {
            margin: 0
         }
      }

      .image {
         width: 160px;
         margin: 0 auto 1.5rem;

         img {
            width: 100%;
            border-radius: 100%;
            filter: grayscale(1);
         }
      }

      &:hover {
         background: #fff;
         border-left-color: #fc6c00;
         box-shadow: 0 0 10px #ccc;
      }
   }

   .teamCard:hover .card-body h3 {
      color: #fc6c00;
   }

   .teamCard:hover .image img {
      filter: grayscale(0);
   }

   label {
      margin-bottom: 0;
      font-weight: 600
   }

   .pd-orange {
      color: #fe642e
   }

   .pd-red {
      color: red
   }

   .pd-lightGreen {
      color: #32cd32
   }

   a.pd-lightGreen:hover {
      color: #32cd32 !important;
      border-color: #32cd32 !important
   }

   .pd-center {
      text-align: center !important
   }

   .pd-medium {
      font-size: medium
   }

   .pd-marginBottom {
      margin-bottom: 15px
   }

   .pd-imageMargin {
      margin: 30px 0 60px 0 !important
   }

   .pd-imageMargin img {
      max-width: 100%;
   }

   .pd-italic {
      font-style: italic
   }

   .pd-bold {
      font-weight: 700
   }

   .pd-normal {
      font-weight: 400 !important
   }

   p.typo-code-header {
      border: 1px solid #eee;
      display: inline-flex;
      padding: 10px 15px;
      margin-top: 1rem;
      background: rgba(0, 0, 0, 0.03);
   }


   /* Corona Page */
   .prodItem img {
      width: 100%;
      margin-bottom: 1rem;
   }

   .prodItem a {
      border: none;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: .5px;
      font-weight: 500;
      padding-right: 1rem;
      padding-left: 1rem;
   }

   ul.done {
      list-style: none;
      padding-left: 0;
      line-height: 25px;

      li:before {
         background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24'%3E%3Cpath fill='%2332cd32' d='M9 16.2L4.8 12l-1.4 1.4L9 19L21 7l-1.4-1.4L9 16.2z'/%3E%3C/svg%3E");
         width: 16px;
         height: 16px;
         margin-right: 10px;
         content: "";
      }

      li {
         b {
            font-weight: 700;
         }
      }
   }
}
</style>
