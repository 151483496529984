<template>
    <button v-bind="$attrs" class="btn" :class="[{'button-action':buttonAction},{'button-stop':buttonStop},{'button-inline':buttonInline},{'small':size === 'sm'}]" :disabled="disabled">
        <slot></slot>
    </button>
</template>



<script>
export default {
    props: ['disabled', 'buttonAction', 'buttonStop', 'buttonInline','size'],
}
</script>