<template>
   <BodyHeading v-if="$route.name.includes('Details')" :borderColor="'rgb(230, 127, 216)'" :title="$t('footer.footerTitle')" 
     @handleClick="edit" :multiLanguageDisabled="false" :backLink="'content'" :backIcon="'image'" :saveAndCloseBtn="false"></BodyHeading>
   <div class="page-body-main-create">
      <div class="card-wrapper">
         <div class="row">
            <ValidateEach v-for="(footerSection, index) in footer" :key="footerSection" :state="footerSection" :rules="rules">
               <template #default="{ v }">
                     <div class="col-lg-6 col-xl-4">
                        <div class="card-title">{{$t('footer.sectionTitle')+' '+ (index+1)}}</div>
                        <div class="card">
                           <div class="card-body">
                              <!--  -->
                              <div v-if="!isLoading" class="row">
                                    <div class="mb-4">
                                       <v-input type="text" name="title" label="footer.title" :placeholder="defaultLanguageFooter[index]? defaultLanguageFooter[index].title : ''" v-model="v.title.$model"
                                          :validateError="v.title.$error? v.title.$errors[0].$message : null" :disabled="isInputDisable && defaultLanguageFooter[index] && !defaultLanguageFooter[index].title"></v-input>
                                    </div>
                                    <div class="mb-4" v-if="!isInputDisable">
                                       <MultiSelect label="footer.type" :options="[{value: 'menu', label: 'Menu'}, {value: 'page', label: 'Page'}]" v-model="v.type.$model" @update:modelValue="resetItems($event, index)"
                                          :validateError="v.type.$error? v.type.$errors[0].$message : null" />
                                    </div>
                                    <div class="mb-4" v-if="!isInputDisable && footer[index].type == 'menu' ">
                                       <MultiSelect label="footer.menus" 
                                          :options="async (query) => {return await getMenus(query)}" :isRequired="true" class="mb-2"
                                          v-model="v.menus.$model"
                                          :searchable="true" :delay="0" :resolveOnLoad="true" mode="tags" :object="true"
                                          :validateError="v.menus.$errors[0]? v.menus.$errors[0].$message : null"
                                       />
                                    </div>
                                    <div class="mb-4" v-if="!isInputDisable && footer[index].type == 'page' ">
                                       <MultiSelect label="footer.pages" 
                                          :options="async (query) => {return await getPages(query)}" :isRequired="true" class="mb-2"
                                          v-model="v.pages.$model"
                                          :searchable="true" :delay="0" :resolveOnLoad="true" mode="tags" :object="true"
                                          :validateError="v.pages.$errors[0]? v.pages.$errors[0].$message : null"
                                       />
                                    </div>
                                    <div class="mt-2 mb-2" v-if="!isInputDisable && footer[index].pages && footer[index].pages.length && footer[index].type == 'page' ">
                                       <h5 class="h6">{{`${$t('footer.sortSelectedItems')}:`}}</h5>
                                       <ul class="list-group my-2">
                                          <draggable tag="ul" item-key="value" class="list-group my-2" :list="v.pages.$model" handle=".drag-handler">
                                             <template #item="{element}">
                                                <li class="list-group-item">
                                                   <span class="drag-handler me-3 mp-1 d-inline-block cursor-move">
                                                      <font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
                                                      <font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
                                                   </span>
                                                   {{`${element.label}`}}
                                                </li>
                                             </template>
                                          </draggable>
                                       </ul>
                                    </div>
                                    <div class="mt-2 mb-2" v-if="!isInputDisable && footer[index].menus && footer[index].menus.length && footer[index].type == 'menu' ">
                                       <h5 class="h6">{{`${$t('footer.sortSelectedItems')}:`}}</h5>
                                          <draggable tag="ul" item-key="value" class="list-group my-2" :list="v.menus.$model" handle=".drag-handler">
                                             <template #item="{element}">
                                                <li class="list-group-item">
                                                   <span class="drag-handler me-3 mp-1 d-inline-block cursor-move">
                                                      <font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
                                                      <font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
                                                   </span>
                                                   {{`${element.label}`}}
                                                </li>
                                             </template>
                                          </draggable>
                                    </div>
                              </div>
                           </div>
                        </div>
                     </div>
               </template>
            </ValidateEach>
         </div>
      </div>
   </div>
</template>



<script>
import BodyHeading from '../../components/BodyHeading.vue';
import Input from '../../components/Input.vue';
import MultiSelect from '../../components/MultiSelect.vue'
import useValidate from '@vuelidate/core';
import { ValidateEach } from '@vuelidate/components';
import { requiredIf } from '@vuelidate/validators'
import draggable from 'vuedraggable'

   export default {
      components: {
         BodyHeading,
         'v-input': Input,
         MultiSelect,
         ValidateEach,
         draggable,
      },
      data() {
         return{
            v$: useValidate(),
            footer: [],
            defaultLanguageFooter: [],
            isExist:true,
            isLoading: true,
            isInputDisable: false,
            rules: {
               title: {required: requiredIf((_,footerItem) => {
                     return !this.isInputDisable && footerItem.type
                  })
               },
               type:  {required: requiredIf((value) => {
                     return !this.isInputDisable && value!==""
                  })
               },
               pages: {required: requiredIf((_,footerItem) => {
                     return !this.isInputDisable && footerItem.type == 'page'
                  })
               },
               menus: {required: requiredIf((_,footerItem) => {
                     return !this.isInputDisable && footerItem.type == 'menu'
                  })
               },
            }
         }
      },
      computed: {
         currentLanguage() {
            return this.$store.state.locale.selectedLocale
         },
         languages() {
            return this.$store.state.locale.languages
         },
         currentLocale() {
            return this.$store.state.locale.appLocale
         }
      },
      watch: {
         '$route': {
            immediate: true,
            handler() {
               this.getFooter()
            }
         },
         'languages': {
            immediate: true,
            handler(){
               if(this.$route.name.includes('Details') && this.languages){
                  // if(this.currentLocale !== this.currentLanguage){
                  //    this.getFooter(this.currentLanguage);
                  // }
                  this.getFooter(this.currentLocale);
                  if(this.currentLocale !== this.currentLanguage){
                     // disable input
                     if(!this.isInputDisable){
                        this.isInputDisable = true;
                     }
                  }else if(this.currentLocale === this.currentLanguage && this.isInputDisable){
                     this.isInputDisable = false;
                  }
               }
            }
         },
         currentLanguage() {
            if(this.$route.name.includes('Details')){
               this.isLoading = true
               this.v$.$reset();
               this.footer = []
               this.getFooter(this.currentLanguage)
               this.getFooter(this.currentLocale)
               // disable input
               if(this.languages){
                  if(this.currentLocale !== this.currentLanguage && !this.isInputDisable){
                        this.isInputDisable = true;
                     }else if(this.currentLocale === this.currentLanguage && this.isInputDisable){
                        this.isInputDisable = false;
                  }
               }
            }
         }
      },
      methods: {
         async edit() {
            await this.v$.$validate()
            if(!this.v$.$error){
               let params= {};
               params.language = this.currentLanguage;
               let footer = JSON.parse(JSON.stringify(this.footer));
               // footer = footer.filter(value => Object.keys(value).length !== 0 && value.title != "");
               footer.forEach((footerItem,index) => {
                  footerItem.arrange = index+1;
                  footerItem.footer_section_id = index+1;
                  if(!footerItem.title || footerItem.title == ""){
                     footerItem.items = [],
                     footerItem.title = null,
                     footerItem.type = null
                  }else{
                     if(this.currentLocale !== this.currentLanguage){
                        if(this.defaultLanguageFooter[index].type == 'page'){
                           footerItem.type = this.defaultLanguageFooter[index].type;
                           footerItem.items = []
                           this.defaultLanguageFooter[index].pages.forEach((item, i) => {
                              footerItem.items.push({id: item.page_id, arrange: i+1})
                           })
                           delete footerItem.pages
                        }
                        if(this.defaultLanguageFooter[index].type == 'menu'){
                           footerItem.type = this.defaultLanguageFooter[index].type;
                           footerItem.items = []
                           this.defaultLanguageFooter[index].menus.forEach((item, i) => {
                              footerItem.items.push({id: item.menu_id, arrange: i+1})
                           })
                           delete footerItem.menus
                        }
                     }else{
                        if(footerItem.type == 'page'){
                           footerItem.items = []
                           footerItem.pages.forEach((item, i) => {
                              footerItem.items.push({id: item.value, arrange: i+1})
                           })
                           delete footerItem.pages
                        }
                        if(footerItem.type == 'menu'){
                           footerItem.items = []
                           footerItem.menus.forEach((item, i) => {
                              footerItem.items.push({id: item.value, arrange: i+1})
                           })
                           delete footerItem.menus
                        }
                     }
                     
                  }
               })
               this.$store.dispatch("footer/update", [{"footer_sections": footer},params]).then(
                  (message) => {
                     // console.log(...message);
                     this.$notify({
                           text: message,
                           group: 'success'
                     });
                     // this.$router.push({name: 'dashboard'})
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
               
            }
         },
         getFooter(language= this.currentLanguage) {
            // response = response.sort((a,b) => (a.arrange > b.arrange) ? 1 : ((b.arrange > a.arrange) ? -1 : 0))
            const params = {language: language}
            this.$store.dispatch("footer/fetch", params).then(
                (response) => {
                   response = response.length == 0 ? [{}, {}, {}] : response;
                   if(this.currentLocale === language){
                      this.defaultLanguageFooter = response;
                      if(this.currentLanguage === this.currentLocale){
                         this.isExist = true;
                         response.forEach(item => {
                           if(item.type == 'page'){
                              item.pages.forEach(page => {
                                 page.value = page.page_id;
                                 page.label = page.title;
                              })
                              this.footer = response
                           }else if(item.type == 'menu'){
                              item.menus.forEach(menu => {
                                 menu.value = menu.menu_id;
                                 menu.label = menu.title;
                              })
                              this.footer = response
                           }
                         })
                        this.footer = response
                         this.isLoading = false
                      }
                   }else{
                      this.isExist = true;
                      this.footer = response;
                      this.isLoading = false
                   }
                },
                () => {
                  this.isExist = false;
                  this.isLoading = false;
                  // let errorMessage = ''
                  // for (const err in error) {
                  //    errorMessage += `${error[err]} <br>`;
                  // }
                  // this.$notify({
                  //    text: errorMessage,
                  //    group: 'failed'
                  // });
                }
            )
         },
         getPages(query) {
            const params = {language: this.currentLanguage, per_page: 30}
            if(query){
                params.title = query
            }
            return this.$store.dispatch("page/getAll", params).then(
                (response) => {
                    let pages = []
                    response.data.forEach(el => {
                        pages.push({label:el.title+((el.footer_alias) ? '|'+el.footer_alias: '') ,value: el.page_id})
                    })
                    return pages
                },
                (error) => {
                   console.log(error);
               //  this.$notify({
               //          text: error,
               //          group: 'failed'
               //      });
                }
            )
         },
         getMenus(query) {
            const params = {language: this.currentLanguage, per_page: 30}
            if(query){
                params.title = query
            }
            return this.$store.dispatch("menu/getAll", params).then(
                (response) => {
                    let menus = []
                    response.data.forEach(el => {
                        menus.push({label:el.title,value: el.menu_id})
                    })
                    return menus
                },
                (error) => {
                   console.log(error);
               //  this.$notify({
               //          text: error,
               //          group: 'failed'
               //      });
                }
            )
         },
         resetItems(type, sectionIndex) {
            if (type === 'menu') {
               this.footer[sectionIndex].menus = []
            }
            else {
               this.footer[sectionIndex].pages = []
            }
         }
      }
   }
</script>
