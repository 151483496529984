import axios from 'axios';
import Constants from '../constants';
import authHeader from './auth-header'


class CountryService {
    fetch(params) {
      return axios
          .get(Constants['SERVER_URL']+'countries/fetch/'+`${params.country_id}/${params.language}`, {headers:authHeader()})
          .then(response => {
              return response.data;
          })
    }
    getAll(params) {
        return axios
            .get(Constants['SERVER_URL']+'countries/search', {params: params,headers:authHeader()})
            .then(response => {
                return response.data;
            })
    }
    getCountryAllPostMethods(params){
      return axios
        .get(Constants['SERVER_URL']+'countries/fetch/post-methods/' + `${params.country_id}/${params.language}`, {headers:authHeader()})
        .then(response => {
            return response.data;
        })
    }
    updateCountryAllPostMethods(postMethods, country_id){
      return axios
        .post(Constants['SERVER_URL']+'countries/attach/post-methods/'+ country_id, postMethods, {headers:authHeader()})
        .then(response => {    
          return response.data;
      })
    }
    create(country) {
        return axios
          .post(Constants['SERVER_URL']+'countries/create', country, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    createTranslation(country) {
        return axios
          .post(Constants['SERVER_URL']+'countries/add/translation/'+ country.country_id, country, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    edit(country){
      return axios
        .patch(Constants['SERVER_URL']+'countries/update/'+ `${country.country_id}/${country.language}`, country, {headers:authHeader()})
        .then(response => {    
          return response.data;
      })
    }
    delete(country_id) {
        return axios
          .delete(Constants['SERVER_URL']+'countries/delete/'+ country_id, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    activate(params) {
      return axios
          .get(Constants['SERVER_URL']+'countries/activate/'+`${params.country_id}`, {headers:authHeader()})
          .then(response => {
              return response.data;
          })
    }
    deactivate(params) {
      return axios
          .get(Constants['SERVER_URL']+'countries/deactivate/'+`${params.country_id}`, {headers:authHeader()})
          .then(response => {
              return response.data;
          })
    }
}



export default new CountryService();