<template>
   <BodyHeading v-if="$route.name.includes('Create')" :borderColor="'rgb(230, 127, 216)'" :title="$t('page.sidebarTitle')" 
      @handleClick="create" :multiLanguageDisabled="true" :saveAndCloseBtn="false"></BodyHeading>
   <BodyHeading v-if="$route.name.includes('Details')" :borderColor="'rgb(230, 127, 216)'" :title="$t('page.sidebarDetails')" 
     @handleClick="edit" :multiLanguageDisabled="true"></BodyHeading>
   <div class="page-body-main-create">
      <div class="card-wrapper">
         <div class="card-title">{{$t('generalInformation')}}</div>
         <div class="card">
            <div class="card-body">
               <!--  -->
              <div v-if="!isLoading" class="row">
                  <div class="mb-4">
                     <v-input type="text" name="title" label="page.sidebarItemTitle" v-model="title" :isRequired="true"
                        :validateError="v$.title.$error? v$.title.$errors[0].$message : null"></v-input>
                  </div>
                  <div class="mb-4">
                     <MultiSelect label="page.sidebarItems"
                        :options="async (query) => {return await getPages(query)}" :isRequired="true"
                        :modelValue="sidebar" @update:modelValue="sidebar = $event" @select="setArrange" @clear="setArrange" @deselect="setArrange"
                        :searchable="true" :delay="0" :resolveOnLoad="true" mode="tags" :object="true"
                        :validateError="v$.sidebar.$errors[0]? v$.sidebar.$errors[0].$message : null"
                     />
                  </div>
                  <div class="mt-2 mb-2" v-if="sidebar.length">
                     <h5 class="h6">{{`${$t('page.sortSelectedItems')}:`}}</h5>
                     <draggable v-model="sidebar" item-key="value" class="list-group my-2" tag="ul" handle=".drag-handler" @change="setArrange">
                        <template #item="{element}">
                           <li class="list-group-item">
                              <span class="drag-handler me-3 mp-1 d-inline-block cursor-move">
                                 <font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
                                 <font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
                              </span>
                              {{`${element.label}`}}
                           </li>
                        </template>
                     </draggable>
                  </div>
              </div>
            </div>
         </div>
      </div>
   </div>
</template>



<script>
import BodyHeading from '../../components/BodyHeading.vue'
import useValidate from '@vuelidate/core';
import Input from '../../components/Input.vue';
import MultiSelect from '../../components/MultiSelect.vue';
import { required } from '@/utilities/i18n-validators.js';
import draggable from 'vuedraggable'

export default {
      components: {
         BodyHeading,
         'v-input': Input,
         MultiSelect,
         draggable,
      },
      data() {
         return{
            v$: useValidate(),
            sidebar: [],
            title: null,
            isLoading: true,
            isLoadingModal: true,
            sidebarArrangeMap: []
         }
      },
      validations() {
         return {
            title: {required},
            sidebar: {required}
         }
      },
      computed: {
         currentLanguage() {
            return this.$store.state.locale.selectedLocale
         },
         languages() {
            return this.$store.state.locale.languages
         },
         currentLocale() {
            return this.$store.state.locale.appLocale
         }
      },
      watch:{
         'languages': {
            immediate: true,
            handler(){
               if(this.$route.name.includes('Details') && this.languages){
                  this.getSidebar(this.$route.params.id , this.currentLocale)
               }
               if(!this.$route.name.includes('Details')){
                  this.isLoading = false
               }
            }
         },
      },
      methods: {
         setArrange(){
            if(this.sidebar.length > 0){
               this.sidebar.forEach((sidebarItem, index) => {
                  this.sidebar[index].arrange = index+1
               })
            }
         },
         create(){
            this.v$.$validate()
            if(!this.v$.$error){
               let sidebar = JSON.parse(JSON.stringify(this.sidebar));
               sidebar.forEach(sidebarItem => {
                  sidebarItem.page_id = sidebarItem.value;
                  delete sidebarItem.value;
                  delete sidebarItem.label;
               })
               this.$store.dispatch("page/createSidebar", {title: this.title, sidebar}).then(
                  (data) => {
                     this.$notify({
                           text: data.message,
                           group: 'success'
                     });
                     this.$router.push({ name: 'pagesSidebars', params: { id: data.body.page_id}})
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         },
         edit() {
            this.v$.$validate()
            if(!this.v$.$error){
               let sidebar = JSON.parse(JSON.stringify(this.sidebar));
               sidebar.forEach(sidebarItem => {
                  sidebarItem.page_id = sidebarItem.value;
                  delete sidebarItem.value;
                  delete sidebarItem.label;
               })
               this.$store.dispatch("page/updateSidebar", {title: this.title, sidebar, id: this.$route.params.id}).then(
                  (message) => {
                     this.$notify({
                           text: message,
                           group: 'success'
                     });
                     // this.$router.push({name: 'dashboard'})
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         },
         getSidebar(id, language) {
            const params = {language, id: id}
            this.$store.dispatch("page/fetchSidebar", params).then(
                (response) => {
                  this.title = response.sidebar_info.title
                  response.sidebar_info.sidebar_items = response.sidebar_info.sidebar_items.sort((a,b) => (a.arrange > b.arrange) ? 1 : ((b.arrange > a.arrange) ? -1 : 0))
                  response.sidebar_info.sidebar_items.forEach(item => {
                     this.sidebar.push({label:item.pages.title ,value: item.page_id, arrange: item.arrange})
                  })
                  this.isLoading = false
                },
                () => {
                  this.isLoading = false;
                  // let errorMessage = ''
                  // for (const err in error) {
                  //    errorMessage += `${error[err]} <br>`;
                  // }
                  // this.$notify({
                  //    text: errorMessage,
                  //    group: 'failed'
                  // });
                }
            )
        },
         getPages(query) {
            const params = {language: this.currentLanguage, per_page: 30}
            if(query){
                params.title = query
            }
            return this.$store.dispatch("page/getAll", params).then(
                (response) => {
                    let pages = []
                    response.data.forEach(el => {
                        pages.push({label:el.title ,value: el.page_id})
                    })
                    return pages
                },
                (error) => {
                this.$notify({
                        text: error,
                        group: 'failed'
                    });
                }
            )
         },
      }
   }
</script>
