<template>
<label v-if="label" :for="name" class="form-label" :class="isRequired?'is-required' : ''">{{$t(label)}}</label>
    <file-pond :id="name" v-bind:files="images" :accepted-file-types="acceptedFileTypes"
        :server="server" :maxFileSize="maxFileSize" :labelMaxFileSizeExceeded="maxSizeExceededLabel" :allow-multiple="multiple"
        label-idle="Drop files here or <span class='filepond--label-action'>Browse</span>"
        @updatefiles="$emit('update:modelValue', imagesMap)" @processfilestart="$store.commit('fileUploader/setUploadStatus', 2)" @processfile="$store.commit('fileUploader/setUploadStatus', 1)"
        v-if="!isLoading"
    />
    <div class="invalid-feedback" v-if="validateError">
        {{validateError}}
    </div>
    <!-- accepted-file-types="image/jpeg, image/png, video/mp4" -->
</template>


<script>
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css';
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginMediaPreview from "filepond-plugin-media-preview";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";
import axios from 'axios';
import authHeader from '../services/auth-header';
import Constants from '../constants';

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginMediaPreview,
  FilePondPluginPdfPreview,
  FilePondPluginFileValidateSize
);


export default {
    emits: ["uploaderStatus", "update:modelValue"],
    components: {
        FilePond
    },
    props: {
        type: {
            default: 'image'
        },
        modelValue:{
            default: []
        },
        label: {
            default: ''
        },
        isRequired: {
            default: false
        },
        name: {
            type: String,
            default: ''
        },
        language: {
            type: String,
            default: 'en'
        },
        validateError: {
            default: null
        },
        uploadUrl: {
            default: null
        },
        deleteUrl: {
            default: null
        },
        fetchUrl: {
            default: null
        },
        multiple: {
            default: true
        },
        couple: {
            default: null
        },
        ownerId: {
            default: null
        }
    },
    mounted(){
        if(this.couple){
           if(this.modelValue.length){
                this.imagesMap = [...this.modelValue]
                if(this.couple.for == 'desktop'){
                    this.images.push(
                        {
                            source: this.modelValue[0].desktopName,
                            options: {
                                type: 'local'
                            }
                        }
                    ); 
                }else if(this.couple.for == 'mobile'){
                    this.images.push(
                        {
                            source: this.modelValue[0].mobileName,
                            options: {
                                type: 'local'
                            }
                        }
                    ); 
                }
           } 
        }else{
            if(this.modelValue.length){
                this.imagesMap = [...this.modelValue]
                this.modelValue.forEach(el => {
                    this.images.push(
                        {
                            source: el.name,
                            options: {
                                type: 'local'
                            }
                        }
                    );             
                });
            }
        }
        this.isLoading = false
    },
    data(){
        return{
            isLoading: true,
            imagesMap: [],
            images: [],
            server: {
                process: (fieldName, file, metadata, load, error, progress) => {
                    
                    // set data
                    const formData = new FormData();
                    formData.append(this.uploadFileType, file, file.name);
                    formData.append('language', this.language);
                    if(this.couple && this.couple.id){
                        formData.append('id', this.couple.id);
                    }
                    // related to aborting the request
                    const CancelToken = axios.CancelToken;
                    const source = CancelToken.source();
                    // the request itself
                    let headers = authHeader();
                    headers['Content-Type'] = 'multipart/form-data';
                    axios.post(Constants['SERVER_URL'] + this.uploadUrl,
                    formData,
                    {headers:headers},
                    {cancelToken: source.token},
                    {
                        onUploadProgress: (e) => {
                            // updating progress indicator
                            progress(e.lengthComputable, e.loaded, e.total);
                        }
                    })
                    .then(response => {
                        // passing the file id to FilePond
                        if(this.couple){
                            if(this.couple.for == 'desktop'){
                                this.imagesMap.splice(0, this.imagesMap.length)
                                this.imagesMap.push({id: response.data.body.id, desktopName: response.data.body.path})
                                load(response.data.body.path)
                            }else if(this.couple.for == 'mobile'){
                                this.imagesMap.splice(0, this.imagesMap.length)
                                this.imagesMap.push({id: response.data.body.id, mobileName: response.data.body.mobile_path})
                                load(response.data.body.mobile_path)
                            }
                        }else{
                            this.imagesMap.push({id: response.data.body.id, name: response.data.body.path})
                            load(response.data.body.path)
                        }                   
                    })
                    .catch((error) => {
                        if (axios.isCancel(error)) {
                            console.log('Request canceled', error.message);
                        } else {
                            // handle error
                        }
                    });
                    // Setup abort interface
                    return {
                        abort: () => {
                            source.cancel('Operation canceled by the user.');
                        },
                    };
                    
                },
                revert: (uniqueFileId, load, error) => {
                    if(this.couple){
                        axios.delete(Constants['SERVER_URL'] +`${this.deleteUrl}/${this.imagesMap[0].id}`, {headers:authHeader()})
                        .then(() => {
                            if(this.couple.for == 'desktop'){
                                delete this.imagesMap[0].desktopName
                            }else if(this.couple.for == 'mobile'){
                                delete this.imagesMap[0].mobileName
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });

                    }else{
                        let fileId = null;
                        let imageMapIndex = null;
                        this.imagesMap.forEach((el, index) => {
                            if(el.name === uniqueFileId){
                                if(el.id){
                                    fileId = el.id;
                                }else{
                                    fileId = el.name;
                                }
                                imageMapIndex = index;
                                return false
                            }
                        });
                        // Should remove the earlier created temp file here
                        if(!this.multiple){
                            axios.delete(Constants['SERVER_URL'] +`${this.deleteUrl}`, {params: {[this.uploadFileType]: fileId }, headers:authHeader()})
                            .then(() => {
                                this.imagesMap.splice(imageMapIndex,1)
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                        }else{
                            axios.delete(Constants['SERVER_URL'] +`${this.deleteUrl}/` + fileId, {headers:authHeader()})
                            .then(() => {
                                this.imagesMap.splice(imageMapIndex,1)
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                        }
                    }
                    // Can call the error method if something is wrong, should exit after
                    error('oh something is wrong');

                    // Should call the load method when done, no parameters required
                    load();
                },
                load: (uniqueFileId, load) => {
                    // you would get the file data from your server here
                    if(this.type == 'file' || this.type == 'zip'){
                        axios.get(Constants['SERVER_PUBLIC_URL'] +`fetch/file?path=${this.fetchUrl}&file=${uniqueFileId}`, {headers:authHeader()})
                        .then((res) => {
                            let temp = null
                            if(this.type == 'file'){
                                temp =`data:application/${uniqueFileId.split('.')[uniqueFileId.split('.').length-1]};base64,${res.data}`;
                            }
                            if(this.type == 'zip'){
                                temp =`data:application/x-zip-compressed;base64,${res.data}`;
                            }
                            if(this.type == 'image'){
                                console.log(uniqueFileId.split('.')[uniqueFileId.split('.').length-1]);
                                temp =`data:image/${uniqueFileId.split('.')[uniqueFileId.split('.').length-1]};base64,${res.data}`;
                            }
                            if(this.type == 'video'){
                                temp =`data:video/${uniqueFileId.split('.')[uniqueFileId.split('.').length-1]};base64,${res.data}`;
                            }
                            fetch(temp)
                            .then((res) => 
                                res.blob() )
                            .then(load);
                        })
                    }else{
                        fetch(Constants['SERVER_PUBLIC_URL'] +`fetch/image?path=${this.fetchUrl}&image=${uniqueFileId}`)
                        .then((res) => 
                            res.blob() )
                        .then(load);
                    }
                        
                },
                remove: (source, load, error) => {
                    if(this.couple){
                        axios.delete(Constants['SERVER_URL'] +`${this.deleteUrl}/${this.imagesMap[0].id}`, {headers:authHeader()})
                        .then(() => {
                            if(this.couple.for == 'desktop'){
                                delete this.imagesMap[0].desktopName
                            }else if(this.couple.for == 'mobile'){
                                delete this.imagesMap[0].mobileName
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });

                    }else{
                        let fileId = null;
                        let imageMapIndex = null;
                        this.imagesMap.forEach((el, index) => {
                            if(el.name === source){
                                if(el.id){
                                    fileId = el.id;
                                }else{
                                    fileId = el.name;
                                }
                                imageMapIndex = index;
                                return false
                            }
                        });
                        // Should remove the earlier created temp file hereelse{
                        if(this.ownerId){
                            axios.delete(Constants['SERVER_URL'] +`${this.deleteUrl}`, {params: {[this.uploadFileType]: fileId, ...this.ownerId },headers:authHeader()})
                            .then(() => {
                                this.imagesMap.splice(imageMapIndex,1)
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                        }else{
                            axios.delete(Constants['SERVER_URL'] +`${this.deleteUrl}/` + fileId, {headers:authHeader()})
                            .then(() => {
                                this.imagesMap.splice(imageMapIndex,1)
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                        }
                    }
                    // Can call the error method if something is wrong, should exit after
                    error('oh something is wrong');

                    // Should call the load method when done, no parameters required
                    load();
                },
            },
            
        }
    },
    computed: {
        acceptedFileTypes(){
            const fileTypes = []
            if (this.type == 'image')
                fileTypes.push('image/png', 'image/jpg', 'image/jpeg')
            if (this.type == 'video')
                fileTypes.push('video/mp4')
            if (this.type == 'zip')
                fileTypes.push('application/zip', 'application/octet-stream', 'application/x-zip-compressed', 'multipart/x-zip')
            if (this.type == 'file')
                fileTypes.push('application/pdf')
            return fileTypes
        },
        maxFileSize() {
            if (this.type === 'image')
                return '1MB'
            else if (this.type === 'video')
                return '50MB'
            else return '3MB'
        },
        maxSizeExceededLabel() {
            return this.$t('fileSize', {max: this.maxFileSize})
        },
        uploadFileType(){
            if (this.type === 'video')
                return 'video'
            else if (this.type === 'image')
                return 'image'
            else return 'file'
        }
    }
}
</script>

<style scoped>
/* .filepond--drop-label {
	color: #4c4e53;
}

.filepond--label-action {
	text-decoration-color: #babdc0;
}

.filepond--panel-root {
	border-radius: 2em;
	background-color: #edf0f4;
	height: 1em;
}

.filepond--item-panel {
	background-color: #595e68;
}

.filepond--drip-blob {
	background-color: #7f8a9a;
} */
</style>