import axios from 'axios';
import Constants from '../constants';
import authHeader from './auth-header'

class RedirectsService {
  fetch() {
    return axios
      .get(Constants['SERVER_URL'] + 'front-info/redirections/fetch', { headers: authHeader() })
      .then(response => {
        return response.data;
      })
  }
  edit(redirects) {
    return axios
      .post(Constants['SERVER_URL'] + 'front-info/redirections/add', redirects, { headers: authHeader() })
      .then(response => {
        return response.data;
      })
  }
}

export default new RedirectsService();
