<template>
   <BodyHeading v-if="$route.name.includes('Create')" :borderColor="'rgb(87, 217, 163)'" :title="$t('technicalInfo.addButtonTitle')" 
      @handleClick="create" :multiLanguageDisabled="true" :saveAndCloseBtn="false"></BodyHeading>
   
   <BodyHeading v-if="$route.name.includes('Details')" :borderColor="'rgb(87, 217, 163)'" :title="$t('technicalInfo.editTitle')" 
     v-on="isExist ? { handleClick: edit } : { handleClick: createTranslation }" :multiLanguageDisabled="false"></BodyHeading>
   <div class="page-body-main-create">
      <div class="card-wrapper">
         <div class="card-title">{{$t('generalInformation')}}</div>
         <div class="card">
            <div class="card-body">
               <!--  -->
              <div v-if="!isLoading" class="row"> 
                  <div class="mb-4">
                     <v-input type="text" name="title" label="technicalInfo.title" :placeholder="defaultLanguageTechnicalInfo.title" v-model="technicalInfo.title" :isRequired="true"
                        :validateError="v$.technicalInfo.title.$error? v$.technicalInfo.title.$errors[0].$message : null"></v-input>
                  </div>
                  <div class="mb-4">
                     <TextEditor name="description" label="technicalInfo.description" v-model="technicalInfo.description"
                        :validateError="v$.technicalInfo.description.$error? v$.technicalInfo.description.$errors[0].$message : null"></TextEditor>
                  </div>
              </div>
            </div>
         </div>
      </div>
   </div>
</template>



<script>
import BodyHeading from '../../components/BodyHeading.vue'
import Input from '../../components/Input.vue';
import TextEditor from '../../components/TextEditor.vue';
import useValidate from '@vuelidate/core';
import { required } from '@/utilities/i18n-validators.js'

// Import TextEditor helpers
import useCkeditorHelper from '../../composables/useCkeditorHelper.js'

   export default {
      components: {
         BodyHeading,
         'v-input': Input,
         TextEditor
      },
      setup() {
         const { parseForEditor, parseForServer } = useCkeditorHelper()
         return { parseForEditor, parseForServer }
      },
      data() {
         return{
            v$: useValidate(),
            technicalInfo: {},
            defaultLanguageTechnicalInfo: {},
            isExist:true,
            isLoading: true
         }
      },
      validations() {
         return {
            technicalInfo: {
               title: {required},
               description: {required}
            }
         }
      },
      // created() {
      //    if(this.$route.name.includes('Details') && this.languages){
      //       if(this.languages.default !== this.currentLanguage){
      //          this.gettechnicalInfo(this.$route.params.id, this.languages.default)
      //       }
      //    }
      // },
      computed: {
         currentLanguage() {
            return this.$store.state.locale.selectedLocale
         },
         languages() {
            return this.$store.state.locale.languages
         },
         currentLocale() {
            return this.$store.state.locale.appLocale
         }
      },
      watch: {
         '$route': {
            immediate: true,
            handler() {
               if(this.$route.name.includes('Details')){
                  this.gettechnicalInfo(this.$route.params.id)
               }else{
                  this.isLoading = false
               }
            }
         },
         'languages': {
            immediate: true,
            handler(){
               if(this.$route.name.includes('Details') && this.languages){
                  if(!this.isExist && this.currentLocale === this.currentLanguage){
                     this.$router.push({ name: 'technicalInfos', query: { page: 1 } })
                  }
                  this.gettechnicalInfo(this.$route.params.id, this.currentLocale)
               }
            }
         },
         currentLanguage() {
            if(this.$route.name.includes('Details')){
               this.isLoading = true
               this.v$.$reset();
               this.technicalInfo = {}
               this.gettechnicalInfo(this.$route.params.id, this.currentLanguage)
               this.gettechnicalInfo(this.$route.params.id, this.currentLocale)
            }
         }
      },
      methods: {
         create() {
            this.v$.$validate()
            if(!this.v$.$error){
               let technicalInfo = {};
               technicalInfo.language = this.currentLanguage;
               technicalInfo.title = this.technicalInfo.title;
               technicalInfo.description = this.parseForServer(this.technicalInfo.description)
               this.$store.dispatch("technicalInfo/create", technicalInfo).then(
                  (data) => {
                     // console.log(...message);
                     this.$notify({
                           text: data.message,
                           group: 'success'
                     });
                     this.$router.push({ name: 'technicalInfoDetails', params: { id: data.body.technical_info_id }})
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         },
         createTranslation(){
            this.v$.$validate()
            if(!this.v$.$error){
               let technicalInfo = {};
               technicalInfo.language = this.currentLanguage;
               technicalInfo.title = this.technicalInfo.title;
               technicalInfo.technical_info_id = this.defaultLanguageTechnicalInfo.technical_info_id
               technicalInfo.description = this.parseForServer(this.technicalInfo.description)
               this.$store.dispatch("technicalInfo/createTranslation", technicalInfo).then(
                  (message) => {
                     // console.log(...message);
                     this.isExist = true;
                     this.$notify({
                           text: message,
                           group: 'success'
                     });
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         },
         edit() {
            this.v$.$validate()
            if(!this.v$.$error){
               let technicalInfo = {};
               technicalInfo.language = this.currentLanguage;
               technicalInfo.title = this.technicalInfo.title;
               technicalInfo.technical_info_id = this.defaultLanguageTechnicalInfo.technical_info_id
               technicalInfo.description = this.parseForServer(this.technicalInfo.description)
               
               this.$store.dispatch("technicalInfo/update", technicalInfo).then(
                  (message) => {
                     // console.log(...message);
                     this.$notify({
                           text: message,
                           group: 'success'
                     });
                     // this.$router.push({name: 'dashboard'})
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         },
         gettechnicalInfo(id, language = this.currentLanguage) {
            // console.log(this.currentLanguage);
            const params = {language: language, technical_info_id: id}
            this.$store.dispatch("technicalInfo/fetch", params).then(
                (response) => {
                   if(this.currentLocale === language){
                      this.defaultLanguageTechnicalInfo = response;
                      if(this.currentLanguage === this.currentLocale){
                         this.isExist = true;
                         this.technicalInfo = response;
                         this.technicalInfo.description = this.parseForEditor(this.technicalInfo.description)
                         this.isLoading = false
                      }
                   }
                   else{
                      this.isExist = true;
                      this.technicalInfo = response;
                      this.technicalInfo.description = this.parseForEditor(this.technicalInfo.description)
                      this.isLoading = false
                   }
                },
                () => {
                  this.isExist = false;
                  if(this.currentLocale === this.currentLanguage){
                     this.$router.push({ name: 'technicalInfos', query: { page: 1 } })
                  }
                  this.isLoading = false;
                  // let errorMessage = ''
                  // for (const err in error) {
                  //    errorMessage += `${error[err]} <br>`;
                  // }
                  // this.$notify({
                  //    text: errorMessage,
                  //    group: 'failed'
                  // });
                }
            )
        },
      }
   }
</script>
