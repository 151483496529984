import serviceService from '../../services/service.service'

const service = {
    namespaced: true,

    state: {},

    getters: {},

    actions: {
        create(_, service) {
            return serviceService.create(service).then(
                data => {
                    return Promise.resolve(data);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        createTranslation(_, {urlParams, service}) {
            return serviceService.addTranslation(urlParams, service).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        update(_, {urlParams, service}) {
            return serviceService.update(urlParams, service).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        delete(_, urlParams) {
            return serviceService.delete(urlParams).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        deleteValue(_, urlParams) {
            return serviceService.deleteValue(urlParams).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        getAll(_, queryParams) {
            return serviceService.getAll(queryParams).then(
                data => {
                    return Promise.resolve(data.body);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        fetch(_, urlParams) {
            return serviceService.fetch(urlParams).then(
                data => {
                    return Promise.resolve(data.body);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
    },
    
    mutations: {}
}

export default service
