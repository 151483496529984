<template>
    <label v-if="label" :for="name" class="form-label" :class="isRequired ? 'is-required' : ''">{{$t(label)}}</label>
    <textarea :disabled="disabled" :placeholder="placeholder" v-bind="$attrs" :id="name" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" class="form-control" :class="validateError ? 'form-control-invalid' : ''"
    ></textarea>
    <div class="invalid-feedback" v-if="validateError">
        {{ validateError }}
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String, Number],
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        validateError: {
            type: String,
            default: null
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>
.form-control-invalid {
    border-color: red;
}
.invalid-feedback {
    color: red;
    font-size: 0.875em;
}
</style>
