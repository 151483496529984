import axios from 'axios';
import Constants from '../constants';
import authHeader from './auth-header'


class MenuService {
    attachProducts(menu) {
      return axios
        .post(Constants['SERVER_URL']+'menus/attach/products/'+`${menu.menu_id}`, menu, {headers:authHeader()})
        .then(response => {    
          return response.data;
        })
    }
    getProducts(params) {
      console.log(params);
      if(params.custom === "yes") {
        return axios
        .get(Constants['SERVER_URL']+'menus/get_all', {headers:authHeader()})
        .then(response => {
            return response.data;
        })
      } else if(params.custom === "done") {
        return axios
        .get(Constants['SERVER_URL']+'menus/get_all/'+`${params.sub_id}`, {headers:authHeader()})
        .then(response => {
            return response.data;
        })
      } else {
        return axios
        .get(Constants['SERVER_URL']+'menus/fetch/products/'+`${params.menu_id}/${params.language}`, {headers:authHeader()})
        .then(response => {
            return response.data;
        })
      }
    }
    activate(params) {
      return axios
          .get(Constants['SERVER_URL']+'menus/activate/'+`${params.menu_id}`, {headers:authHeader()})
          .then(response => {
              return response.data;
          })
    }
    deactivate(params) {
      return axios
          .get(Constants['SERVER_URL']+'menus/deactivate/'+`${params.menu_id}`, {headers:authHeader()})
          .then(response => {
              return response.data;
          })
    }
    fetch(params) {
      return axios
          .get(Constants['SERVER_URL']+'menus/fetch/'+`${params.menu_id}/${params.language}`, {headers:authHeader()})
          .then(response => {
              return response.data;
          })
    }
    getAll(params) {
        return axios
            .get(Constants['SERVER_URL']+'menus/search', {params: params,headers:authHeader()})
            .then(response => {
                return response.data;
            })
    }
    create(menu) {
        return axios
          .post(Constants['SERVER_URL']+'menus/create', menu, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    createTranslation(menu) {
        return axios
          .post(Constants['SERVER_URL']+'menus/add/translation/'+ menu.menu_id, menu, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    edit(menu){
      return axios
        .patch(Constants['SERVER_URL']+'menus/update/'+ `${menu.menu_id}/${menu.language}`, menu, {headers:authHeader()})
        .then(response => {    
          return response.data;
      })
    }
    delete(menu_id) {
        return axios
          .delete(Constants['SERVER_URL']+'menus/delete/'+ menu_id, {headers:authHeader()})
          .then(response => {    
            return response.data;
        })
    }
    deleteSlide(slider_id) {
      return axios
        .delete(Constants['SERVER_URL']+'menus/images/delete/slider/'+ slider_id, {headers:authHeader()})
        .then(response => {    
          return response.data;
      })
    }
    sort(menus) {
      return axios
        .post(Constants['SERVER_URL']+'menus/update/arrange', { menus } , {headers:authHeader()})
        .then(response => {    
          return response.data;
      })
    }
}



export default new MenuService();