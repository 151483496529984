<script setup>
// import Components
import { useNotification } from "@kyvg/vue3-notification"
import BodyHeading from '../../components/BodyHeading.vue'
import VInput from '../../components/Input.vue'
import MultiSelect from "../../components/MultiSelect.vue";

import { ref } from 'vue'
import { useStore } from 'vuex';
import useValidate from '@vuelidate/core'
import { required } from '@/utilities/i18n-validators.js'

const store = useStore()
const { notify } = useNotification()

// state
const fromInput = ref(null)
const toInput = ref(null)
const codeInput = ref(null)
const redirects = ref([])
const isLoading = ref(true)

const redirectRules = {
  fromInput: {
    required
  },
  toInput: {
    required
  },
  codeInput: {
    required,
  }
}
const v$ = useValidate(redirectRules, { fromInput, toInput, codeInput })

async function getRedirects() {
  store.dispatch('redirects/fetch').then((res) => {
    if (Array.isArray(res))
      redirects.value = res
  })
    .catch((error) => {
      notify({
        text: error,
        group: 'failed'
      });
    }).finally(() => {
      isLoading.value = false
    })
}

function saveRedirects() {
  store.dispatch('redirects/update', { info: redirects.value }).then((res) => {
    notify({
      text: res,
      group: 'success'
    })
  }).catch(error => {
    let errorMessage = ''
    for (const err of error) {
      errorMessage += `${err} <br>`;
    }
    notify({
      text: errorMessage,
      group: 'failed'
    })
  })
}

async function addToList() {
  const valid = await v$.value.$validate()
  if (valid) {
    redirects.value.push({
      from: fromInput.value,
      to: toInput.value,
      code: codeInput.value
    })
    fromInput.value = null
    toInput.value = null
    codeInput.value = null
    v$.value.$reset()
  }
}

getRedirects()
</script>

<template>
  <BodyHeading :borderColor="'rgb(154, 168, 181)'" :title="$t('redirects.title')" @handleClick="saveRedirects"
    :multiLanguageDisabled="true" :backLink="'settings'" :backIcon="'cog'"></BodyHeading>
  <div class="page-body-main-create">
    <div class="card-wrapper">
      <slot name="nav-stepper"></slot>
      <div class="card">
        <div class="card-header d-flex">
          <div class="flex-grow-1">
            <div class="row">
              <div class="col-lg-4">
                <v-input type="text" name="from" label="redirects.from" v-model="fromInput"
                  :validateError="v$.fromInput.$error ? v$.fromInput.$errors[0].$message : null"></v-input>
              </div>
              <div class="col-lg-4">
                <v-input type="text" name="to" label="redirects.to" v-model="toInput"
                  :validateError="v$.toInput.$error ? v$.toInput.$errors[0].$message : null"></v-input>
              </div>
              <div class="col-lg-2">
                <MultiSelect v-model="codeInput" :options="[{ label: '301', value: 301 }, { label: '302', value: 302 }]"
                  name="code" label="redirects.code"
                  :validateError="v$.codeInput.$error ? v$.codeInput.$errors[0].$message : null"
                  style="padding: .05rem 0.7rem .05rem 0 !important;" />
              </div>
              <div class="col-lg-2 col-12 align-self-end">
                <button class="btn button-action m-0 w-100" @click="addToList">
                  {{ $t('redirects.addBtnLabel') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="collapse show">
          <div v-if="isLoading" class="py-4 text-center">
            <font-awesome-icon :icon="['fas', 'circle-notch']" spin></font-awesome-icon>
          </div>
          <div v-else class="table-responsive mt-0">
            <table class="table table-striped table-hover table-bordered">
              <thead>
                <tr>
                  <th scope="col" style="width: 5%"><span>#</span></th>
                  <th scope="col"><span>{{ $t('redirects.from') }}</span></th>
                  <th scope="col"><span>{{ $t('redirects.to') }}</span></th>
                  <th scope="col"><span>{{ $t('redirects.code') }}</span></th>
                  <th scope="col" style="min-width: fit-content"><span>{{ $t('actions') }}</span></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(redirectItem, index) in redirects" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ redirectItem.from }}</td>
                  <td>{{ redirectItem.to }}</td>
                  <td>{{ redirectItem.code }}</td>
                  <td>
                    <button @click="redirects.splice(index, 1)" class="btn btn-light" type="button">
                      <font-awesome-icon :icon="['fas', 'trash']" size="sm"></font-awesome-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
