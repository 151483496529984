<script setup>
// import Components
import MultiSelect from '../../components/MultiSelect.vue'
import VSwitch from '../../components/Switch.vue'
import { useNotification } from "@kyvg/vue3-notification"

import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex';

const route = useRoute()
const store = useStore()
const { notify } = useNotification()

// state
const productServices = ref([])

// computed
const selectedLocale = computed(() => store.state.locale.selectedLocale)

// methods
function getProductServices() {
  const urlParams = { product_id: route.params.id }
  return store.dispatch('product/services/getServices', urlParams).then((res) => {
    productServices.value = res.map(el => ({label: el.service_tranlation.title, value: el.pivot.service_id, has_no_select: el.pivot.has_no_select}))
  })
    .catch((error) => {
      notify({
        text: error,
        group: 'failed'
      });
    })
}
function getServices(title) {
  const queryParams = { language: selectedLocale.value, per_page: 50, ...(title || { title }) }
  return store.dispatch("service/getAll", queryParams).then(
    (response) => {
      const services = response.data.map(el => ({ label: el.title, value: el.service_id }))
      return services
    },
    (error) => {
      notify({
        text: error,
        group: 'failed'
      });
    }
  )
}

function editProductServices() {
  const urlParams = { product_id: route.params.id }
  const serviceIdList = { services: {} }
  productServices.value.forEach((item)=>{
    serviceIdList.services[item.value] = {has_no_select: item.has_no_select}
  })
  store.dispatch("product/services/setServices", { urlParams, serviceIdList })
    .then((message) => {
      notify({
        text: message,
        group: 'success'
      })
    })
    .catch((error) => {
      notify({
        text: error,
        group: 'failed'
      })
    })
}

getProductServices()

defineExpose({
  save: editProductServices
})
</script>

<template>
  <div class="page-body-main-create">
    <div class="card-wrapper">
      <slot name="nav-stepper"></slot>
      <div class="card-title">{{ $t('product.chooseServices') }}</div>
      <div class="card">
        <div class="card-body">
          <div class="row justify-content-center">
            <div class="col">
              <MultiSelect v-model="productServices" label="product.services" object value-prop="value" label-prop="label" mode="tags" searchable
                :options="async (query) => { return await getServices(query) }" :delay="0"
                :resolveOnLoad="true" class="mb-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-wrapper">
      <div class="card">
        <div class="card-body">
          <ul class="list-group">
            <li v-for="service in productServices" :key="service.value" class="list-group-item">
              <div class="d-flex align-items-center justify-content-between">
                <span>{{ service.label }}</span>
                <v-switch label="product.addOptions.no-select" name="noselect" v-model="service.has_no_select"
                  class="mb-md-4 mb-2 flex-nowrap" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
