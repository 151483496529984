const fileUploader = {
    namespaced: true,
    state: {
        // 1.everything is OK  2.wait man
        uploadStatus: 1
    },
    getters: {
        getUploadStatus(state){
            return state.uploadStatus
        }
    },
    mutations: {
        setUploadStatus(state, status){
            state.uploadStatus = status
        }
    },
    actions: {}
}

export default fileUploader