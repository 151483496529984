import axios from 'axios';
import Constants from '../constants';
import authHeader from './auth-header'


class RoleService {
    fetch(params) {
      return axios
          .get(Constants['SERVER_URL']+'roles/fetch/'+`${params.role_id}/${params.language}`, {headers:authHeader()})
          .then(response => {
              return response.data;
          })
    }
    getAll(params) {
        return axios
            .get(Constants['SERVER_URL']+'roles/search', {params: params,headers:authHeader()})
            .then(response => {
                return response.data;
            })
    }
    create(role) {
        return axios
          .post(Constants['SERVER_URL']+'roles/create', role, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    createTranslation(role) {
        return axios
          .post(Constants['SERVER_URL']+'roles/add/translation/'+ role.role_id, role, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    edit(role){
      return axios
        .patch(Constants['SERVER_URL']+'roles/update/'+ `${role.role_id}/${role.language}`, role, {headers:authHeader()})
        .then(response => {    
          return response.data;
      })
    }
    delete(role_id) {
        return axios
          .delete(Constants['SERVER_URL']+'roles/delete/'+ role_id, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
}



export default new RoleService();