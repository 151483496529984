<template>
   <BodyHeading v-if="$route.name.includes('Create')" :borderColor="'rgb(154, 168, 181)'" :title="$t('duration.addButtonTitle')" 
      @handleClick="create" :multiLanguageDisabled="true" :saveAndCloseBtn="false"></BodyHeading>
   
   <BodyHeading v-if="$route.name.includes('Details')" :borderColor="'rgb(154, 168, 181)'" :title="$t('duration.durationDetails')" 
     v-on="isExist ? { handleClick: edit } : { handleClick: createTranslation }" :multiLanguageDisabled="false"></BodyHeading>
   <div class="page-body-main-create">
      <div class="card-wrapper">
         <div class="card-title">{{$t('generalInformation')}}</div>
         <div class="card">
            <div class="card-body">
               <!--  -->
              <div class="row" v-if="!isLoading">
                  <div class="mb-4">
                     <v-input type="text" name="title" label="duration.title" :placeholder="defaultLanguageDuration.title" v-model="duration.title" :isRequired="true"
                        :validateError="v$.duration.title.$error? v$.duration.title.$errors[0].$message : null"></v-input>
                  </div>
                  <div class="mb-4">
                     <v-input type="number" name="duration" label="duration.duration" :placeholder="defaultLanguageDuration.duration" v-model="duration.duration" :isRequired="true"
                        :validateError="v$.duration.duration && v$.duration.duration.$error? v$.duration.duration.$errors[0].$message : null" :disabled="isDurationInputDisable"></v-input>
                  </div>
              </div>
            </div>
         </div>
      </div>
   </div>
</template>



<script>
import BodyHeading from '../../components/BodyHeading.vue'
import Input from '../../components/Input.vue';
import useValidate from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators'
import { required } from '@/utilities/i18n-validators.js'


   export default {
      components: {
         BodyHeading,
         'v-input': Input
      },
      data() {
         return{
            v$: useValidate(),
            duration: {},
            defaultLanguageDuration: {},
            isExist:true,
            isLoading: true,
            isDurationInputDisable: false
         }
      },
      validations() {
         return {
            duration: {
               title: {required},
               duration: {required: requiredIf(() => {
                     return !this.isDurationInputDisable
                  }) 
               },
            }
         }
      },
      computed: {
         currentLanguage() {
            return this.$store.state.locale.selectedLocale
         },
         languages() {
            return this.$store.state.locale.languages
         },
         currentLocale() {
            return this.$store.state.locale.appLocale
         }
      },
      watch: {
         '$route': {
            immediate: true,
            handler() {
               if(this.$route.name.includes('Details')){
                  this.getDuration(this.$route.params.id)
               }else{
                  this.isLoading = false
               }
            }
         },
         'languages': {
            immediate: true,
            handler(){
               if(this.$route.name.includes('Details') && this.languages){
                  if(!this.isExist && this.currentLocale === this.currentLanguage){
                     this.$router.push({ name: 'durations', query: { page: 1 } })
                  }
                  this.getDuration(this.$route.params.id, this.currentLocale)
                  if(this.currentLocale !== this.currentLanguage){
                     // disable duration input
                     if(!this.isDurationInputDisable){
                        this.isDurationInputDisable = true;
                     }
                  }else if(this.currentLocale === this.currentLanguage && this.isDurationInputDisable){
                     this.isDurationInputDisable = false;
                  }
               }
            }
         },
         currentLanguage() {
            if(this.$route.name.includes('Details')){
               this.isLoading = true
               this.v$.$reset();
               this.duration = {}
               this.getDuration(this.$route.params.id, this.currentLanguage)
               this.getDuration(this.$route.params.id, this.currentLocale)
               // disable duration input
               if(this.languages){
                  if(this.currentLocale !== this.currentLanguage && !this.isDurationInputDisable){
                        this.isDurationInputDisable = true;
                     }else if(this.currentLocale === this.currentLanguage && this.isDurationInputDisable){
                        this.isDurationInputDisable = false;
                  }
               }
            }
         }
      },
      methods: {
         create() {
            this.v$.$validate()
            if(!this.v$.$error){
               this.duration.language = this.currentLanguage;
               // console.log(this.duration)
               this.$store.dispatch("duration/create", this.duration).then(
                  (data) => {
                     // console.log(...message);
                     this.$notify({
                           text: data.message,
                           group: 'success'
                     });
                     this.$router.push({ name: 'durationDetails', params: { id: data.body.duration_id}})
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         },
         createTranslation(){
            this.v$.$validate()
            if(!this.v$.$error){
               this.duration.language = this.currentLanguage;
               this.duration.duration_id = this.defaultLanguageDuration.duration_id;
               // console.log(this.duration)
               this.$store.dispatch("duration/createTranslation", this.duration).then(
                  (message) => {
                     // console.log(...message);
                     this.isExist = true;
                     this.$notify({
                           text: message,
                           group: 'success'
                     });
                     // this.$router.push({name: 'dashboard'})
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         },
         edit() {
            this.v$.$validate()
            if(!this.v$.$error){
              this.duration.language = this.currentLanguage;
               // console.log(this.duration)
               this.$store.dispatch("duration/update", this.duration).then(
                  (message) => {
                     // console.log(...message);
                     this.$notify({
                           text: message,
                           group: 'success'
                     });
                     // this.$router.push({name: 'dashboard'})
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         },
         getDuration(id, language = this.currentLanguage) {
            // console.log(this.currentLanguage);
            const params = {language: language, duration_id: id}
            this.$store.dispatch("duration/fetch", params).then(
                (response) => {
                   if(this.currentLocale === language){
                      this.defaultLanguageDuration = response;
                      if(response.duration_info.duration) {
                            this.defaultLanguageDuration.duration = response.duration_info.duration
                         } 
                      if(this.currentLanguage === this.currentLocale){
                         this.isExist = true;
                         this.duration = response;
                         if(response.duration_info.duration) {
                           this.duration.duration = response.duration_info.duration
                         } 
                         this.isLoading = false
                      }
                   }else{
                      this.isExist = true;
                      this.duration = response;
                      if(response.duration_info.duration) {
                           this.duration.duration = response.duration_info.duration
                        } 
                      this.isLoading = false
                   }
                },
                () => {
                  this.isExist = false;
                  if(this.currentLocale === this.currentLanguage){
                     this.$router.push({ name: 'durations', query: { page: 1 } })
                  }
                  this.isLoading = false;
                  // let errorMessage = ''
                  // for (const err in error) {
                  //    errorMessage += `${error[err]} <br>`;
                  // }
                  // this.$notify({
                  //    text: errorMessage,
                  //    group: 'failed'
                  // });
                }
            )
        },
      }
   }
</script>
