import axios from 'axios';
import Constants from '../constants';
import authHeader from './auth-header'


class ServiceService {
  fetch(urlParams) {
    return axios
      .get(Constants['SERVER_URL'] + 'services/fetch/' + `${ urlParams.service_id }/${ urlParams.language }`, { headers: authHeader() })
      .then(response => {
        return response.data;
      })
  }
  
  getAll(queryParams) {
    return axios
      .get(Constants['SERVER_URL'] + 'services/search', { params: queryParams, headers: authHeader() })
      .then(response => {
        return response.data;
      })
  }
  
  create(service) {
    return axios
      .post(Constants['SERVER_URL'] + 'services/create', service , { headers: authHeader() })
      .then(response => {
        return response.data;
      })
  }
  
  addTranslation(urlParams, service) {
    return axios
      .post(Constants['SERVER_URL'] + `services/add/translation/${urlParams.service_id}`, service, { headers: authHeader() })
      .then(response => {
        return response.data;
      })
  }
  
  update(urlParams, service) {
    return axios
      .patch(Constants['SERVER_URL'] + `services/update/${urlParams.service_id}/${urlParams.language}`, service, { headers: authHeader() })
      .then(response => {
        return response.data;
      })
  }
  
  delete(urlParams) {
    return axios
      .delete(Constants['SERVER_URL'] + `services/delete/${urlParams.service_id}`, { headers: authHeader() })
      .then(response => {
        return response.data;
      })
  }
  
  deleteValue(urlParams) {
    return axios
      .delete(Constants['SERVER_URL'] + `service-values/delete/${urlParams.service_value_id}`, { headers: authHeader() })
      .then(response => {
        return response.data;
      })
  }
}

export default new ServiceService();
