<template>
   <div class="page-body-heading p-0" style="border-bottom-color: rgb(154, 168, 181)"></div>
   <div class="page-body-main-create mt-3">
      <div class="card-wrapper">
         <h1 class="h2 mb-5">{{$t('settings.headTitle')}}</h1>
         <div class="row">
            <div class="col-lg-12">
               <div class="card">
                  <div class="card-body card-body-grid">
                     <a @click="$router.push({ name: 'users', query: { page: 1 }})" class="item-thumbnail">
                        <div class="item-thumbnail-icon">
                           <span>
                              <font-awesome-icon :icon="['fas', 'user']"></font-awesome-icon>
                           </span>
                        </div> 
                        <div>
                           {{$t('sidebar.users')}}
                        </div>
                     </a>
                     <!--  -->
                     <a @click="$router.push({ name: 'menus', query: { page: 1 }})" class="item-thumbnail">
                        <div class="item-thumbnail-icon">
                           <span>
                              <font-awesome-icon :icon="['fas', 'bars']"></font-awesome-icon>
                           </span>
                        </div> 
                        <div>
                           {{$t('sidebar.menus')}}
                        </div>
                     </a>
                     <!--  -->
                     <a @click="$router.push({ name: 'countries', query: { page: 1 }})" class="item-thumbnail">
                        <div class="item-thumbnail-icon">
                           <span>
                              <font-awesome-icon :icon="['fas', 'globe-americas']"></font-awesome-icon>
                           </span>
                        </div> 
                        <div>
                           {{$t('sidebar.countries')}}
                        </div>
                     </a>
                     <!--  -->
                     <a @click="$router.push({ name: 'postMethods', query: { page: 1 }})" class="item-thumbnail">
                        <div class="item-thumbnail-icon">
                           <span>
                              <font-awesome-icon :icon="['fas', 'truck']"></font-awesome-icon>
                           </span>
                        </div> 
                        <div>
                           {{$t('sidebar.postMethods')}}
                        </div>
                     </a>
                     <!--  -->
                     <a @click="$router.push({ name: 'durations', query: { page: 1 }})" class="item-thumbnail">
                        <div class="item-thumbnail-icon">
                           <span>
                              <font-awesome-icon :icon="['fas', 'clock']"></font-awesome-icon>
                           </span>
                        </div> 
                        <div>
                           {{$t('sidebar.durations')}}
                        </div>
                     </a>
                     <!--  -->
                     <a @click="$router.push({ name: 'productionDelayDetails', query: { page: 1 }})" class="item-thumbnail">
                        <div class="item-thumbnail-icon">
                           <span>
                              <font-awesome-icon :icon="['fas', 'hourglass']"></font-awesome-icon>
                           </span>
                        </div> 
                        <div>
                           {{$t('sidebar.productionDelay')}}
                        </div>
                     </a>
                     <!--  -->
                     <a @click="$router.push({ name: 'taxDetails', query: { page: 1 }})" class="item-thumbnail">
                        <div class="item-thumbnail-icon">
                           <span>
                              <font-awesome-icon :icon="['fas', 'coins']"></font-awesome-icon>
                           </span>
                        </div> 
                        <div>
                           {{$t('sidebar.tax')}}
                        </div>
                     </a>
                     <!--  -->
                     <a @click="$router.push({ name: 'popups', query: { page: 1 }})" class="item-thumbnail">
                        <div class="item-thumbnail-icon">
                           <span>
                              <font-awesome-icon :icon="['fas', 'eye']"></font-awesome-icon>
                           </span>
                        </div> 
                        <div>
                           {{$t('sidebar.popups')}}
                        </div>
                     </a>
                     <!--  -->
                     <a @click="$router.push({ name: 'subscribers', query: { page: 1 }})" class="item-thumbnail">
                        <div class="item-thumbnail-icon">
                           <span>
                              <font-awesome-icon :icon="['fas', 'user-plus']"></font-awesome-icon>
                           </span>
                        </div> 
                        <div>
                           {{$t('sidebar.subscribers')}}
                        </div>
                     </a>
                     <a @click="$router.push({ name: 'holidays', query: { page: 1 }})" class="item-thumbnail">
                        <div class="item-thumbnail-icon">
                           <span>
                              <font-awesome-icon :icon="['fas', 'calendar-alt']"></font-awesome-icon>
                           </span>
                        </div> 
                        <div>
                           {{$t('sidebar.holidays')}}
                        </div>
                     </a>
                     <a @click="$router.push({ name: 'redirects'})" class="item-thumbnail">
                        <div class="item-thumbnail-icon">
                           <span>
                              <font-awesome-icon :icon="['fas', 'share-square']"></font-awesome-icon>
                           </span>
                        </div> 
                        <div>
                           {{$t('sidebar.redirects')}}
                        </div>
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {}
</script>
