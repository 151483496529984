import axios from 'axios';
import Constants from '../constants';
import authHeader from './auth-header'


class PostMethodService {
    fetch(params) {
      return axios
          .get(Constants['SERVER_URL']+'post-methods/fetch/'+`${params.post_method_id}/${params.language}`, {headers:authHeader()})
          .then(response => {
              return response.data;
          })
    }
    getAll(params) {
        return axios
            .get(Constants['SERVER_URL']+'post-methods/search', {params: params,headers:authHeader()})
            .then(response => {
                return response.data;
            })
    }
    create(postMethod) {
        return axios
          .post(Constants['SERVER_URL']+'post-methods/create', postMethod, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    createTranslation(postMethod) {
        return axios
          .post(Constants['SERVER_URL']+'post-methods/add/translation/'+ postMethod.post_method_id, postMethod, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    edit(postMethod){
      return axios
        .patch(Constants['SERVER_URL']+'post-methods/update/'+ `${postMethod.post_method_id}/${postMethod.language}`, postMethod, {headers:authHeader()})
        .then(response => {    
          return response.data;
      })
    }
    delete(post_method_id) {
        return axios
          .delete(Constants['SERVER_URL']+'post-methods/delete/'+ post_method_id, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
}



export default new PostMethodService();