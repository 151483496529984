import redirectsService from '../../services/redirects.service'

const redirects = {
    namespaced: true,
    actions: {
        update(_, redirects) {
            return redirectsService.edit(redirects).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        fetch() {
            return redirectsService.fetch().then(
                data => {
                    return Promise.resolve(data.body.info);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        }
    },
}

export default redirects
