<template>
	<BodyHeading :borderColor="'rgb(87, 217, 163)'" :title="`${$route.query.name} ${$t('product.discounts')}`"
		@handleClick="edit" :multiLanguageDisabled="true"></BodyHeading>
	<div class="page-body-main-create">
		<div class="card-wrapper">
			<div class="card-title">{{ $t('generalInformation') }}</div>
			<div class="card">
				<div class="card-body">
					<div class="row flex-column-reverse">
						<ValidateEach v-for="(discount, index) in discounts" :key="index" :state="discount" :rules="validations">
							<template #default="{ v }">
								<div class="mb-4 col-sm-8 m-auto">
									<div class="input-group">
										<input class="form-control" type="number" v-model="v.quantity.$model"
											:placeholder="$t('product.discount.quantity')"
											:class="{ 'form-controll-invalid': v.quantity.$error }">
										<input class="form-control" type="number" v-model="v.percent.$model"
											:placeholder="$t('product.discount.amount')"
											:class="{ 'form-controll-invalid': v.percent.$error }">
										<button @click="this.delete(discount.id, index)"
											class="btn button-stop px-3"><font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon></button>
										<button v-if="index == discounts.length - 1" @click="addNewDiscounts"
											class="btn button-action px-3"><font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon></button>
									</div>
								</div>
							</template>
						</ValidateEach>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BodyHeading from '../../components/BodyHeading.vue'
import { ValidateEach } from '@vuelidate/components'
import useVuelidate from '@vuelidate/core'
import { required } from '@/utilities/i18n-validators.js'

export default {
	components: {
		BodyHeading,
		ValidateEach
	},
	data() {
		return {
			v$: useVuelidate(),
			validations: {
				quantity: {
					required
				},
				percent: {
					required
				}
			},
			discounts: [],
			isExist: true,
			isLoading: true,
		}
	},
	beforeMount(){
		this.getDiscounts()
	},
	methods: {
		addNewDiscounts() {
			this.discounts.push({ quantity: '', percent: '' })
		},
		async edit() {
			const formIsCorrect = await this.v$.$validate()
			if (formIsCorrect) {
				const urlParams = { product_id: this.$route.params.id }
				const discountsList = {
					discounts: this.discounts
				}
				this.$store.dispatch("product/discounts/setDiscounts", { urlParams, discountsList }).then(
					(message) => {
						this.$notify({
							text: message,
							group: 'success'
						});
						this.getDiscounts()
					},
					(error) => {
						let errorMessage = ''
						for (const err in error) {
							errorMessage += `${error[err]} <br>`;
						}
						this.$notify({
							text: errorMessage,
							group: 'failed'
						});
					}
				)
			}
		},
		getDiscounts() {
			const urlParams = { product_id: this.$route.params.id }
			const queryParams = { per_page: 30 }
			this.$store.dispatch("product/discounts/getDiscounts", { urlParams, queryParams }).then(
				(response) => {
					this.isExist = true;
					this.discounts = response;
					if (!this.discounts.length) {
						this.addNewDiscounts()
					}
					this.isLoading = false
				},
				() => {
					this.isExist = false;
						this.$router.push({ name: 'products', query: { page: 1 } })
					this.isLoading = false;
				}
			)
		},
		delete(id = null, index) {
			if (id) {
				this.$swal().then((result) => {
					if (result.isConfirmed) {
						const urlParams = { product_id: id }
						this.$store.dispatch("product/discounts/deleteDiscount", urlParams).then(
							(message) => {
								this.$notify({
									text: message,
									group: 'success'
								});
								this.getDiscounts()
							},
							(error) => {
								let errorMessage = ''
								for (const err in error) {
									errorMessage += `${error[err]} <br>`;
								}
								this.$notify({
									text: errorMessage,
									group: 'failed'
								});
							}
						)
					}
				})
			} else {
				this.discounts.splice(index, 1);
			}
		}
	}
}
</script>
