<script setup>
import { ref, computed, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

// import components
import MultiSelect from '../../components/MultiSelect.vue'
import { useNotification } from "@kyvg/vue3-notification"

const { notify } = useNotification()
const route = useRoute()
const store = useStore()

// state
const productOptions = ref([])
const excludedOptionValues = ref([])
const selectedExclude = ref(null) // first selector
const selectedExcludeFrom = ref(null) // second selector
const excludeFromSelectorItems = ref([])
const firstSelectorIsLoading = ref(true)

// computed
const appLocale = computed(() => store.state.locale.appLocale)
const productOptionValues = computed(() => {
	const array = []
	productOptions.value.forEach(el => {
		array.push(...el.option_values)
	})
	return array
})

watchEffect(() => {
	if (selectedExclude.value) {
		const clonedProductOptions = structuredClone(productOptions.value)
		const excludeFrom_listItems = []
		let matchedExcludeFromItem, matchedFromExcludeItem
		for (let option of clonedProductOptions) {
			if (option.option_id === selectedExclude.value.option_id) { // skip option values of the first selector 
				continue
			}
			for (let i = option.option_values.length - 1; i >= 0; i--) {
				// skip the item already in the excluded list
				matchedExcludeFromItem = excludedOptionValues.value.find(item => item.exclude === selectedExclude.value.option_value_id && item.from === option.option_values[i].option_value_id)
				matchedFromExcludeItem = excludedOptionValues.value.find(item => item.from === selectedExclude.value.option_value_id && item.exclude === option.option_values[i].option_value_id)
				if (matchedExcludeFromItem || matchedFromExcludeItem) {
					option.option_values.splice(i, 1)
				}
			}
			excludeFrom_listItems.push(option)
		}
		excludeFromSelectorItems.value = excludeFrom_listItems
	}
})

async function getProductOptionValues() {
	const urlParams = { product_id: route.params.id, language: appLocale.value }
	store.dispatch('product/options/getOptions', urlParams).then(async res => { // get product options with value
		const sortedOptions = Object.values(res).sort((a, b) => +a.arrange - +b.arrange)
		sortedOptions.forEach(option => {
			option.option_values = option.option_values.sort((a, b) => +a.arrange - +b.arrange)
		})
		productOptions.value = sortedOptions
	})
		.catch((error) => {
			notify({
				text: error,
				group: 'failed'
			});
		}).finally(() => {
			firstSelectorIsLoading.value = false
		})
}

function getExcludedOptionValues() {
	store.dispatch('product/options/getExcludedOptionValues', { product_id: route.params.id }).then(res => {
		excludedOptionValues.value = res
	}).catch(error => {
		notify({
			text: error,
			group: 'failed'
		})
	})
}

function setExcludedOptionValues() {
	const urlParams = { product_id: route.params.id }
	const excludedValues = {
		optionValues: excludedOptionValues.value
	}
	store.dispatch('product/options/setExcludedOptionValues', { urlParams, excludedValues }).then(res => {
		notify({
			text: res,
			group: 'success'
		})
	}).catch(error => {
		notify({
			text: error,
			group: 'failed'
		})
	})
}

function addToExcluded() {
	if (selectedExclude.value && selectedExcludeFrom.value) {
		excludedOptionValues.value.push({ exclude: selectedExclude.value.option_value_id, from: selectedExcludeFrom.value.option_value_id })
		selectedExcludeFrom.value = null
	}
}

getProductOptionValues()
getExcludedOptionValues()

defineExpose({
	save: setExcludedOptionValues
})
</script>

<template>
	<div class="page-body-main-create">
		<div class="card-wrapper">
			<slot name="nav-stepper" />
			<div class="card-title">{{ $t('product.excludeOptionValues') }}</div>
			<div class="card">
				<div class="card-header d-flex">
					<div class="flex-grow-1">
						<label class="form-label fw-bold">{{
							$t('product.addOptions.excludeValuesLabel')
						}}</label>
						<div class="row">
							<div class="col-md-5">
								<MultiSelect v-model="selectedExclude" mode="single" object groupLabel="title"
									groupOptions="option_values" :close-on-select="false" :groups="true" :options="productOptions"
									:loading="firstSelectorIsLoading" label-prop="title" value-prop="option_value_id" track-by="title" />
							</div>
							<div class="col-md-5">
								<MultiSelect v-model="selectedExcludeFrom" mode="single" object groupLabel="title"
									groupOptions="option_values" :close-on-select="false" :groups="true" :options="excludeFromSelectorItems"
									label-prop="title" value-prop="option_value_id" track-by="title" />
							</div>
							<div class="col-md-2">
								<button class="btn button-action my-auto" @click="addToExcluded">{{
									$t('product.addOptions.excludeButtonLabel') }}</button>
							</div>
						</div>
					</div>
				</div>
				<div class="collapse show">
					<div class="card-body">
						<ul class="list-group">
							<li v-for="(item, index) in excludedOptionValues" :key="index" class="list-group-item">
								<div class="row py-2">
									<div class="col-md-5 d-flex align-items-center">
										{{ productOptionValues.find(el => el.option_value_id === item.exclude).title }}
									</div>
									<div class="col-md-5 d-flex align-items-center">
										{{ productOptionValues.find(el => el.option_value_id === item.from).title }}
									</div>
									<div class="col-md-2">
										<button class="btn" @click="excludedOptionValues.splice(index, 1)">
											<font-awesome-icon :icon="['fas', 'trash']"></font-awesome-icon>
										</button>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
