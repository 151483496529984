<template>
         <BodyHeading :borderColor="'rgb(154, 168, 181)'" :title="`${$route.query.name} ${$t('country.postMethodsTitle')}`" 
            @handleClick="updateCountryPostMethods" :multiLanguageDisabled="true"></BodyHeading>
    <div class="page-body-main-list d-grid">
        <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered" style="margin-bottom: 60px">
                    <thead>
                        <tr>
                            <th scope="col">
                                <span>{{$t('country.postMethod')}}</span>
                                <br>
                                <small v-if="!postMethods[0] && !isLoading" class="text-danger">
                                   {{$t('country.alertPostMethod')}} 
                                </small>
                            </th>
                            <th scope="col">
                                <span>{{$t('country.freeShippingMinPrice')}}</span>
                            </th>
                            <th scope="col"><span>{{$t('country.price')}}</span></th>
                            <th scope="col"><span>{{$t('actions')}}</span></th>
                        </tr>
                    </thead>
                    <tbody v-if="!isLoading">
                        <tr>
                            <td>
                                <multi-select
                                    v-model="new_v$.post_id.$model"     
                                    :options="postMethods"
                                    :validateError="new_v$.post_id.$error ?  new_v$.post_id.$errors[0].$message : null"
                                />
                            </td>
                            <td>
                                <currency-input
                                    v-model="new_v$.min_price.$model"
                                    name="min-price-new"
                                    :validateError="new_v$.min_price.$error ?  new_v$.min_price.$errors[0].$message : null"
                                />
                            </td>
                            <td>
                                <currency-input
                                    v-model="new_v$.price.$model"
                                    name="price-new"
                                    :validateError="new_v$.price.$error ?  new_v$.price.$errors[0].$message : null"
                                />
                            </td>
                            <td :style="ParentZindex === -1 ? { zIndex: 2}: null">
                                <div class="dropdown actions-dropdown">
                                    <button @click="changeParentZindex(-1)" class="btn btn-light" type="button"
                                    id="newDropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                        <font-awesome-icon :icon="['fas', 'ellipsis-h']" size="sm"></font-awesome-icon>
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="newDropdownMenuButton">
                                        <li><a class="dropdown-item" @click="addNewPostMethod">{{$t('country.addBtnText')}}</a></li>
                                   </ul>
                                </div>
                            </td>
                        </tr>
                        <ValidateEach v-for="(countryPostMethod, index) in countryPostMethods" :key="countryPostMethod" :state="countryPostMethod" :rules="postMethodRules">
                            <template #default="{ v }">
                                <tr>
                                    <td>
                                        <multi-select
                                        v-model="v.post_id.$model"     
                                        :options="postMethods"
                                        :validateError="v.post_id.$error ? v.post_id.$errors[0].$message : null"
                                        />
                                    </td>
                                    <td>
                                        <currency-input
                                        :name="`min-price-${index}`"
                                        v-model="v.min_price.$model"
                                        :validateError="v.min_price.$error ? v.min_price.$errors[0].$message : null"
                                        />
                                    </td>
                                    <td>
                                        <currency-input
                                        :name="'price-' + index"
                                        v-model="v.price.$model"
                                        :validateError="v.price.$error ? v.price.$errors[0].$message : null"
                                        />
                                    </td>
                                    <td :style="index === ParentZindex ? { zIndex: 2 } : null">
                                        <div class="dropdown actions-dropdown">
                                            <button @click="changeParentZindex(index)" class="btn btn-light" type="button"
                                            :id="`dropdownMenuButton${index}`" data-bs-toggle="dropdown" aria-expanded="false">
                                            <font-awesome-icon :icon="['fas', 'ellipsis-h']" size="sm"></font-awesome-icon>
                                            </button>
                                            <ul class="dropdown-menu" :aria-labelledby="`dropdownMenuButton${index}`">
                                                <li><a class="dropdown-item warning" @click="deleteItem(index)">{{ $t('delete') }}</a></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </ValidateEach>
                    </tbody>
            </table>
        </div>
    </div>
</template>


<script>
import { ref } from 'vue';
import BodyHeading from '../../components/BodyHeading.vue';
import MultiSelect from '../../components/MultiSelect.vue'
import CurrencyInput from '../../components/CurrencyInput.vue'
import useValidate from '@vuelidate/core';
import { ValidateEach } from '@vuelidate/components'
import { required } from '../../utilities/i18n-validators';

export default {
    components: {
        BodyHeading,
        'multi-select': MultiSelect,
        CurrencyInput,
        ValidateEach
    },
    setup() {
        const newPostMethod = ref({ post_id: null, min_price: null, price: null })
        const postMethodRules = {
            post_id: { required },
            min_price: { required },
            price: { required }
        }
        const new_v$ = useValidate(postMethodRules, newPostMethod, {$scope: false}) // new post method
        const v$ = useValidate()
        return {v$, new_v$, newPostMethod, postMethodRules}
    },
    data() {
        return {
            countries: {},
            postMethods: [],
            countryPostMethods: [],
            ParentZindex: null,
            isLoading: true,
        }
    },
    created() {
        if(Object.entries(this.$route.query).length === 0 || this.$route.query.page === ''){
            this.$router.push({ name: 'countries', query: { page: 1 } })
        }
        this.getPostMethods()
    },
    
    computed: {
        selectedLocale() {
            return this.$store.state.locale.selectedLocale
        },
    },
    methods: {
        changeParentZindex(id) {
            this.ParentZindex = id
        },
        getPostMethods() {
            const params = {language: this.selectedLocale, page: 1, per_page: 30}
            this.$store.dispatch("postMethod/getAll", params).then(
                (response) => {
                    // console.log(response)
                    if(response.data.length){
                        response.data.forEach(el => {
                            this.postMethods.push({value: el.post_method_id, label: el.title})
                        });
                    }
                    if(this.postMethods.length){
                        this.getCountryPostMethods(this.$route.query.id)
                    }
                },
                (error) => {
                this.$notify({
                        text: error,
                        group: 'failed'
                    });
                }
            )
        },
        getCountryPostMethods(id) {
            const params = {language: this.selectedLocale, country_id: id}
            this.$store.dispatch("country/getCountryAllPostMethods", params).then(
                (response) => {
                    this.countryPostMethods = response;
                    this.isLoading = false
                },
                (error) => {
                this.$notify({
                        text: error,
                        group: 'failed'
                    });
                }
            )
        },
        async updateCountryPostMethods(){
            const valid = await this.v$.$validate()
            if (!valid) {
                return
            }
            const countryPostMethods = {
                post_methods: [...this.countryPostMethods]
            }
            this.$store.dispatch("country/updateCountryAllPostMethods", [countryPostMethods,this.$route.query.id]).then(
                (message) => {
                    this.$notify({
                        text: message,
                        group: 'success'
                    });
                },
                (error) => {
                    let errorMessage = ''
                    for (const err in error) {
                    errorMessage += `${error[err]} <br>`;
                    }
                    this.$notify({
                        text: errorMessage,
                        group: 'failed'
                    });
                }
            )
        },
        async addNewPostMethod() {
            const valid = await this.new_v$.$validate()
            if (valid) {
                this.countryPostMethods.push(this.newPostMethod)
                this.newPostMethod = { post_id: null, min_price: null, price: null }
                this.new_v$.$reset()
            }
        },
        deleteItem(index){
            this.$swal().then((result) => {
                 if (result.isConfirmed) {
                    this.countryPostMethods.splice(index, 1);
                    const countryPostMethods = {
                        post_methods: [...this.countryPostMethods]
                    }
                    this.$store.dispatch("country/updateCountryAllPostMethods", [countryPostMethods,this.$route.query.id]).then(
                        (message) => {
                            this.$notify({
                                text: message,
                                group: 'success'
                            });
                        },
                        (error) => {
                            let errorMessage = ''
                            for (const err in error) {
                            errorMessage += `${error[err]} <br>`;
                            }
                            this.$notify({
                                text: errorMessage,
                                group: 'failed'
                            });
                        }
                    )

                }
            })
        }
    }
}
</script>

