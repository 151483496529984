import pageService from '../../services/page.service'


const page = {
    namespaced: true,
    state: {
    },
    getters: {
        
    },
    actions: {
        create(_,page){
            return pageService.create(page).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        createSidebar(_,sidebar){
            return pageService.createSidebar(sidebar).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        createTranslation(_,page){
            return pageService.createTranslation(page).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data.message);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        update(_,page){
            return pageService.edit(page).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data.message);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        updateSidebar(_,sidebar){
            return pageService.editSidebar(sidebar).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        fetchSidebar(_,params){
            return pageService.fetchSidebar(params).then(
                data => {
                    return Promise.resolve(data.body);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        getAllSidebars(_,params){
            return pageService.getAllSidebars(params).then(
                data => {
                    return Promise.resolve(data.body);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error);
                }
            )
        },
        deleteSidebar(_,id){
            return pageService.deleteSidebar(id).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        delete(_,id){
            return pageService.delete(id).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        getAll(_,params){
            return pageService.getAll(params).then(
                data => {
                    return Promise.resolve(data.body);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error);
                }
            )
        },
        fetch(_,params){
            return pageService.fetch(params).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data.body);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        check(_,params){
            return pageService.check(params).then(
                data => {
                    return Promise.resolve(data.body);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        }
    },
    mutations: {
        
    }
}

export default page
