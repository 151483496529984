<template>
    <div class="form-check form-switch">
        <input class="form-check-input" :checked="modelValue == 1" type="checkbox" :id="name" @click="$emit('update:modelValue', $event.target.checked? 1: 0)" v-bind="$attrs" :disabled="disabled">
        <label v-if="label" class="form-check-label" :for="name">{{$t(label)}}</label>
    </div>
    <div class="invalid-feedback" v-if="validateError">
        {{validateError}}
    </div>
</template>


<script>

export default {
    inheritAttrs: false,
    props:{
        name: {
            type: String,
            default: ''
        },
        modelValue: {
            default: '0'
        },
        label: {
            default: null
        },
        validateError: {
            default: null
        },
        disabled: {
            default: false
        }
    }
}
</script>
