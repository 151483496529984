import axios from 'axios';
import Constants from '../constants';
import authHeader from './auth-header';

class PageService {
    fetchSidebar(params) {
        return axios.get(Constants['SERVER_URL'] + 'page/sidebar/fetch/' + `${params.id}/${params.language}`, { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
    editSidebar(sidebar) {
        return axios.patch(Constants['SERVER_URL'] + 'page/sidebar/update/' + `${sidebar.id}`, sidebar, { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
    check(params) {
        return axios.get(Constants['SERVER_URL'] + 'pages/is/unique/', { params, headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
    fetch(params) {
        return axios.get(Constants['SERVER_URL'] + 'pages/fetch/' + `${params.page_id}/${params.language}`, { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
    getAll(params) {
        return axios.get(Constants['SERVER_URL'] + 'pages/search', { params: params, headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
    getAllSidebars(params) {
        return axios.get(Constants['SERVER_URL'] + 'page/sidebar/search', { params: params, headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
    createSidebar(sidebar) {
        return axios.post(Constants['SERVER_URL'] + 'page/sidebar/create', sidebar, { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
    create(page) {
        return axios.post(Constants['SERVER_URL'] + 'pages/create', page, { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
    createTranslation(page) {
        return axios.post(Constants['SERVER_URL'] + 'pages/add/translation/' + page.page_id, page, { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
    edit(page) {
        return axios.patch(Constants['SERVER_URL'] + 'pages/update/' + `${page.page_id}/${page.language}`, page, { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
    delete(page_id) {
        return axios.delete(Constants['SERVER_URL'] + 'pages/delete/' + page_id, { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
    deleteSidebar(page_id) {
        return axios.delete(Constants['SERVER_URL'] + 'page/sidebar/delete/' + page_id, { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
}

export default new PageService();
