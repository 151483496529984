<template>
  <BodyHeading v-if="$route.name.includes('Create')" :borderColor="'rgb(230, 127, 216)'"
    :title="$t('homeSection.addButtonTitle')" @handleClick="create" :multiLanguageDisabled="true"></BodyHeading>

  <BodyHeading v-if="$route.name.includes('Details')" :borderColor="'rgb(230, 127, 216)'"
    :title="$t('homeSection.editTitle')" v-on="isExist ? { handleClick: edit } : { handleClick: createTranslation }"
    :multiLanguageDisabled="false"></BodyHeading>
  <div class="page-body-main-create">
    <div class="card-wrapper">
      <div class="card-title">{{ $t('generalInformation') }}</div>
      <div class="card">
        <div class="card-body">
          <!--  -->
          <div class="row" v-if="!isLoading">
            <div class="mb-4">
              <v-input type="text" name="title" label="homeSection.title" :placeholder="defaultLanguageHomeSection.title"
                v-model="homeSection.title" :isRequired="true"
                :validateError="v$.homeSection.title.$error ? v$.homeSection.title.$errors[0].$message : null"></v-input>
            </div>
            <div class="mb-4" v-if="!isMenuInputDisable">
              <MultiSelect label="homeSection.type"
                :options="[{ value: 'manual', label: 'Manual' }, { value: 'latest', label: 'Latest' }]"
                v-model="homeSection.section_info.type" :isRequired="true"
                :validateError="v$.homeSection.section_info.type.$error ? v$.homeSection.section_info.type.$errors[0].$message : null" />
            </div>
            <div class="mb-4" v-if="!isMenuInputDisable && homeSection.section_info.type == 'manual'">
              <MultiSelect label="homeSection.products" :options="async (query) => { return await getProducts(query) }"
                :isRequired="true" class="mb-2" :modelValue="homeSection.product_t"
                @update:modelValue="homeSection.product_t = $event" :searchable="true" :delay="0" :resolveOnLoad="true"
                mode="tags" :object="true"
                :validateError="v$.homeSection.product_t.$errors[0] ? v$.homeSection.product_t.$errors[0].$message : null" />
            </div>
            <div class="mb-4" v-if="!isMenuInputDisable && homeSection.section_info.type == 'manual'">
              <draggable v-model="homeSection.product_t" item-key="product_id" tag="ul" class="list-group">
                <template #item="{ element }">
                  <li class="list-group-item">
                    <div>
                      <span class="drag-handler me-3 mp-1 d-inline-block cursor-move">
                        <font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
                        <font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
                      </span>
                      {{ element.label }}
                    </div>
                  </li>
                </template>
              </draggable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BodyHeading from '../../components/BodyHeading.vue';
import Input from '../../components/Input.vue';
import MultiSelect from '../../components/MultiSelect.vue'
import draggable from 'vuedraggable'
import useValidate from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators'
import { required } from '@/utilities/i18n-validators.js'

export default {
  components: {
    BodyHeading,
    'v-input': Input,
    MultiSelect,
    draggable
  },
  data() {
    return {
      v$: useValidate(),
      homeSection: { section_info: {}, product_t: [] },
      defaultLanguageHomeSection: { section_info: {} },
      isExist: true,
      isLoading: true,
      isMenuInputDisable: false
    }
  },
  validations() {
    return {
      homeSection: {
        title: { required },
        section_info: {
          type: {
            required: requiredIf(() => {
              return !this.isMenuInputDisable
            }),
          },
        },
        product_t: {
          required: requiredIf(() => {
            return this.homeSection.section_info.type == 'manual' && !this.isMenuInputDisable
          })
        }
      }
    }
  },
  computed: {
    currentLanguage() {
      return this.$store.state.locale.selectedLocale
    },
    languages() {
      return this.$store.state.locale.languages
    },
    currentLocale() {
      return this.$store.state.locale.appLocale
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler() {
        if (this.$route.name.includes('Details')) {
          this.getHomeSection(this.$route.params.id)
        } else {
          this.isLoading = false
        }
      }
    },
    'languages': {
      immediate: true,
      handler() {
        if (this.$route.name.includes('Details') && this.languages) {
          if (!this.isExist && this.currentLocale === this.currentLanguage) {
            this.$router.push({ name: 'homeSections', query: { page: 1 } })
          }
          this.getHomeSection(this.$route.params.id, this.currentLocale)
          if (this.currentLocale !== this.currentLanguage) {
            // disable homeSection input
            if (!this.isMenuInputDisable) {
              this.isMenuInputDisable = true;
            }
          } else if (this.currentLocale === this.currentLanguage && this.isMenuInputDisable) {
            this.isMenuInputDisable = false;
          }
        }
      }
    },
    currentLanguage() {
      if (this.$route.name.includes('Details')) {
        this.isLoading = true
        this.v$.$reset();
        this.homeSection = { section_info: {}, products_id: [] }
        this.getHomeSection(this.$route.params.id, this.currentLanguage)
        this.getHomeSection(this.$route.params.id, this.currentLocale)
        // disable homeSection input
        if (this.languages) {
          if (this.currentLocale !== this.currentLanguage && !this.isMenuInputDisable) {
            this.isMenuInputDisable = true;
          } else if (this.currentLocale === this.currentLanguage && this.isMenuInputDisable) {
            this.isMenuInputDisable = false;
          }
        }
      }
    }
  },
  methods: {
    create() {
      this.v$.$validate()
      if (!this.v$.$error) {
        this.homeSection.language = this.currentLanguage;
        this.homeSection.type = this.homeSection.section_info.type;
        if (this.homeSection.type == 'latest') {
          delete this.homeSection.products_id
        } else {
          this.homeSection.products_id = []
          this.homeSection.product_t.forEach((el, index) => {
            this.homeSection.products_id.push({ product_id: el.value, arrange: index + 1 })
          })
        }
        this.$store.dispatch("homeSection/create", this.homeSection).then(
          (data) => {
            this.$notify({
              text: data.message,
              group: 'success'
            });
            // this.$router.push({ name: 'homeSectionDetails', params: { id: data.body.h_section_id}})
          },
          (error) => {
            let errorMessage = ''
            for (const err in error) {
              errorMessage += `${error[err]} <br>`;
            }
            this.$notify({
              text: errorMessage,
              group: 'failed'
            });
          }
        )
      }
    },
    createTranslation() {
      this.v$.$validate()
      if (!this.v$.$error) {
        this.homeSection.language = this.currentLanguage;
        this.homeSection.section_id = this.defaultLanguageHomeSection.h_section_id;
        this.$store.dispatch("homeSection/createTranslation", this.homeSection).then(
          (message) => {
            // console.log(...message);
            this.isExist = true;
            this.$notify({
              text: message,
              group: 'success'
            });
            // this.$router.push({name: 'dashboard'})
          },
          (error) => {
            let errorMessage = ''
            for (const err in error) {
              errorMessage += `${error[err]} <br>`;
            }
            this.$notify({
              text: errorMessage,
              group: 'failed'
            });
          }
        )
      }
    },
    edit() {
      this.v$.$validate()
      if (!this.v$.$error) {
        this.homeSection.language = this.currentLanguage;
        this.homeSection.type = this.homeSection.section_info.type;
        if (this.homeSection.type == 'latest') {
          delete this.homeSection.products_id
        } else {
          this.homeSection.products_id = []
          this.homeSection.product_t.forEach((el, index) => {
            this.homeSection.products_id.push({ product_id: el.value, arrange: index + 1 })
          })
        }
        this.homeSection.section_id = this.defaultLanguageHomeSection.h_section_id;
        // console.log(this.homeSection)
        this.$store.dispatch("homeSection/update", this.homeSection).then(
          (message) => {
            // console.log(...message);
            this.$notify({
              text: message,
              group: 'success'
            });
            // this.$router.push({name: 'dashboard'})
          },
          (error) => {
            let errorMessage = ''
            for (const err in error) {
              errorMessage += `${error[err]} <br>`;
            }
            this.$notify({
              text: errorMessage,
              group: 'failed'
            });
          }
        )
      }
    },
    getHomeSection(id, language = this.currentLanguage) {
      // console.log(this.currentLanguage);
      const params = { language: language, section_id: id }
      this.$store.dispatch("homeSection/fetch", params).then(
        (response) => {
          if (this.currentLocale === language) {
            this.defaultLanguageHomeSection = response;
            if (this.currentLanguage === this.currentLocale) {
              this.isExist = true;
              this.homeSection.title = response.title;
              this.homeSection.section_info.type = response.section_info.type
              if (response.product_t) {
                this.homeSection.product_t = []
                response.product_t.forEach(el => {
                  this.homeSection.product_t.push({ label: el.title, value: el.product_id })
                })
              }
              this.isLoading = false
            }
          } else {
            this.isExist = true;
            this.homeSection.title = response.title;
            this.homeSection.section_info.type = response.section_info.type
            if (response.product_t) {
              this.homeSection.product_t = []
              response.product_t.forEach(el => {
                this.homeSection.product_t.push({ label: el.title, value: el.product_id })
              })
            }
            this.isLoading = false
          }
        },
        () => {
          this.isExist = false;
          if (this.currentLocale === this.currentLanguage) {
            this.$router.push({ name: 'homeSections', query: { page: 1 } })
          }
          this.isLoading = false;
        }
      )
    },
    getProducts(query) {
      const params = { language: this.currentLanguage, per_page: 30 }
      if (query) {
        params.title = query
      }
      return this.$store.dispatch("product/getAll", params).then(
        (response) => {
          let products = []
          response.data.forEach(el => {
            products.push({ label: el.title, value: el.product_id })
          })
          return products
        },
        (error) => {
          this.$notify({
            text: error,
            group: 'failed'
          });
        }
      )
    },
  }
}
</script>
