import productService from '../../../../services/product.service'

const productMenus = {
	namespaced: true,
	actions: {
		getDiscounts(_, { urlParams, queryParams }) {
			return productService.getDiscounts(urlParams, queryParams).then(
				data => {
					return Promise.resolve(data.body)
				},
				error => {
					return Promise.reject(error.response.data.errors)
				}
			)
		},
		setDiscounts(_, { urlParams, discountsList }) {
			return productService.setDiscounts(urlParams, discountsList).then(
				data => {
					return Promise.resolve(data.message)
				},
				error => {
					return Promise.reject(error.response.data.errors)
				}
			)
		},
		deleteDiscount(_, urlParams) {
			return productService.deleteDiscounts(urlParams).then(
				data => {
					return Promise.resolve(data.message)
				},
				error => {
					return Promise.reject(error.response.data.errors)
				}
			)
		}
	}
}

export default productMenus
