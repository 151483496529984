<template>
   <BodyHeading v-if="$route.name.includes('Create')" :borderColor="'rgb(154, 168, 181)'" :title="$t('popup.addButtonTitle')" 
      @handleClick="create" :multiLanguageDisabled="true"></BodyHeading>
   
   <BodyHeading v-if="$route.name.includes('Details')" :borderColor="'rgb(154, 168, 181)'" :title="$t('popup.editTitle')" 
     v-on="isExist ? { handleClick: edit } : { handleClick: createTranslation }" :multiLanguageDisabled="false"></BodyHeading>
   <div class="page-body-main-create">
      <div class="card-wrapper">
         <div class="card-title">{{$t('generalInformation')}}</div>
         <div class="card">
            <div class="card-body">
               <!--  -->
              <div v-if="!isLoading" class="row"> 
                  <div class="mb-4">
                     <v-input type="text" name="title" label="popup.title" :placeholder="defaultLanguagePopup.title" v-model="popup.title" :isRequired="true"
                        :validateError="v$.popup.title.$error? v$.popup.title.$errors[0].$message : null"></v-input>
                  </div>
                  <div class="mb-4">
                     <TextEditor name="content" label="popup.content" v-model="popup.content" :isRequired="true"
                        :validateError="v$.popup.content.$error? v$.popup.content.$errors[0].$message : null"></TextEditor>
                  </div>
                  <div class="mb-4" v-if="!isInputDisable">
                     <label for="expires_at" class="form-label is-required">{{$t('popup.expiresAt')}}</label>
                     <Datepicker id="expires_at" v-model="popup.expires_at" :lower-limit="new Date()" class="form-control"
                     :class="v$.popup.expires_at && v$.popup.expires_at.$error? 'form-controll-invalid': ''" />
                     <div class="invalid-feedback" v-if="v$.popup.expires_at && v$.popup.expires_at.$error">
                        {{v$.popup.expires_at.$errors[0].$message}}
                     </div>
                  </div>
              </div>
            </div>
         </div>
      </div>
   </div>
</template>



<script>
import BodyHeading from '../../components/BodyHeading.vue'
import Input from '../../components/Input.vue';
import TextEditor from '../../components/TextEditor.vue';
import useValidate from '@vuelidate/core';
import { required } from '@/utilities/i18n-validators.js';
import { requiredIf } from '@vuelidate/validators';
import Datepicker from 'vue3-datepicker';

// Import TextEditor helpers
import useCkeditorHelper from '../../composables/useCkeditorHelper.js'

   export default {
      inject: ['dayjs'],
      components: {
         BodyHeading,
         'v-input': Input,
         TextEditor,
         Datepicker
      },
      setup() {
         const { parseForEditor, parseForServer } = useCkeditorHelper()
         return { parseForEditor, parseForServer }
      },
      data() {
         return{
            v$: useValidate(),
            popup: {},
            defaultLanguagePopup: {},
            isExist:true,
            isLoading: true,
            isInputDisable: false
         }
      },
      validations() {
         return {
            popup: {
               title: {required},
               content: {required},
               expires_at: {required: requiredIf(() => {
                     return !this.isInputDisable
                  }) 
               }
            }
         }
      },
      // created() {
      //    if(this.$route.name.includes('Details') && this.languages){
      //       if(this.languages.default !== this.currentLanguage){
      //          this.getPopup(this.$route.params.id, this.languages.default)
      //       }
      //    }
      // },
      computed: {
         currentLanguage() {
            return this.$store.state.locale.selectedLocale
         },
         languages() {
            return this.$store.state.locale.languages
         },
         currentLocale() {
            return this.$store.state.locale.appLocale
         }
      },
      watch: {
         '$route': {
            immediate: true,
            handler() {
               if(this.$route.name.includes('Details')){
                  this.getPopup(this.$route.params.id)
               }else{
                  this.isLoading = false
               }
            }
         },
         'languages': {
            immediate: true,
            handler(){
               if(this.$route.name.includes('Details') && this.languages){
                  if(!this.isExist && this.currentLocale === this.currentLanguage){
                     this.$router.push({ name: 'popups', query: { page: 1 } })
                  }
                  this.getPopup(this.$route.params.id, this.currentLocale)
                  if(this.currentLocale !== this.currentLanguage){
                     // disable inputs
                     if(!this.isInputDisable){
                        this.isInputDisable = true;
                     }
                  }else if(this.currentLocale === this.currentLanguage && this.isInputDisable){
                     this.isInputDisable = false;
                  }
               }
            }
         },
         currentLanguage() {
            if(this.$route.name.includes('Details')){
               this.isLoading = true
               this.v$.$reset();
               this.popup = {}
               this.getPopup(this.$route.params.id, this.currentLanguage)
               this.getPopup(this.$route.params.id, this.currentLocale)
               // disable duration input
               if(this.languages){
                  if(this.currentLocale !== this.currentLanguage && !this.isInputDisable){
                        this.isInputDisable = true;
                     }else if(this.currentLocale === this.currentLanguage && this.isInputDisable){
                        this.isInputDisable = false;
                  }
               }
            }
         }
      },
      methods: {
         dateTime(value){
            return this.dayjs(value).format('YYYY-MM-DD');
         },
         create() {
            this.v$.$validate()
            if(!this.v$.$error){
               let popup = {};
               popup.language = this.currentLanguage;
               popup.title = this.popup.title;
               popup.expires_at = this.dateTime(this.popup.expires_at)
               popup.content = this.parseForServer(this.popup.content)
               this.$store.dispatch("popup/create", popup).then(
                  (data) => {
                     // console.log(...message);
                     this.$notify({
                           text: data.message,
                           group: 'success'
                     });
                     this.$router.push({ name: 'popupDetails', params: { id: data.body.popup_id }})
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         },
         createTranslation(){
            this.v$.$validate()
            if(!this.v$.$error){
               let popup = {};
               popup.language = this.currentLanguage;
               popup.title = this.popup.title;
               popup.expires_at = this.dateTime(this.popup.expires_at)
               popup.popup_id = this.defaultLanguagePopup.popup_id;
               popup.content = this.parseForServer(this.popup.content)
               this.$store.dispatch("popup/createTranslation", popup).then(
                  (message) => {
                     // console.log(...message);
                     this.isExist = true;
                     this.$notify({
                           text: message,
                           group: 'success'
                     });
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         },
         edit() {
            this.v$.$validate()
            if(!this.v$.$error){
               let popup = {};
               popup.language = this.currentLanguage;
               popup.title = this.popup.title;
               popup.expires_at = this.dateTime(this.popup.expires_at)
               popup.popup_id = this.defaultLanguagePopup.popup_id;
               popup.content = this.parseForServer(this.popup.content)
               
               this.$store.dispatch("popup/update", popup).then(
                  (message) => {
                     // console.log(...message);
                     this.$notify({
                           text: message,
                           group: 'success'
                     });
                     // this.$router.push({name: 'dashboard'})
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         },
         getPopup(id, language = this.currentLanguage) {
            // console.log(this.currentLanguage);
            const params = {language: language, popup_id: id}
            this.$store.dispatch("popup/fetch", params).then(
                (response) => {
                   if(this.currentLocale === language){
                      this.defaultLanguagePopup = response;
                      if(this.currentLanguage === this.currentLocale){
                         this.isExist = true;
                         this.popup = response;
                         this.popup.expires_at = new Date(response.popup_info.expires_at);
                         this.popup.content = this.parseForEditor(this.popup.content)
                         this.isLoading = false
                      }
                   }
                   else{
                      this.isExist = true;
                      this.popup = response;
                      this.popup.expires_at = new Date(response.popup_info.expires_at);
                      this.popup.content = this.parseForEditor(this.popup.content)
                      this.isLoading = false
                   }
                },
                () => {
                  this.isExist = false;
                  if(this.currentLocale === this.currentLanguage){
                     this.$router.push({ name: 'popups', query: { page: 1 } })
                  }
                  this.isLoading = false;
                  // let errorMessage = ''
                  // for (const err in error) {
                  //    errorMessage += `${error[err]} <br>`;
                  // }
                  // this.$notify({
                  //    text: errorMessage,
                  //    group: 'failed'
                  // });
                }
            )
        },
      }
   }
</script>
