import { createRouter, createWebHistory} from 'vue-router';
import store from '@/store';

import Body from '../components/Body';
import ProductDetailsLayout from '../components/ProductDetailsLayout'
import Dashboard from '../pages/dashboard/Dashboard';
import CreateProduct from '../pages/product/CreateProduct'
import ProductsList from '../pages/product/ProductsList';
import AddDiscounts from '../pages/product/AddDiscounts'
import WorkingDay from '../pages/product/WorkingDay'
import AddServices from '../pages/product/AddServices'
import ExcludeServiceValues from '../pages/product/ExcludeServiceValues'
import AddOptions from '../pages/product/AddOptions'
import ExcludeOptionValues from '../pages/product/ExcludeOptionValues'
import ProductCombinations from '../pages/product/Combinations'
import CreateTechnicalInfo from '../pages/technicalInfo/CreateTechnicalInfo';
import TechnicalInfosList from '../pages/technicalInfo/TechnicalInfosList';
import CreateShippingInfo from '../pages/shippingInfo/CreateShippingInfo'; 
import ShippingInfosList from '../pages/shippingInfo/ShippingInfosList'; 
import CreatePostMethod from '../pages/postMethod/CreatePostMethod'; 
import PostMethodsList from '../pages/postMethod/PostMethodsList';
import CreateDuration from '../pages/duration/CreateDuration';
import DurationsList from '../pages/duration/DurationsList';
import CreateCountry from '../pages/country/CreateCountry';
import CountriesList from '../pages/country/CountriesList';
import PostMethods from '../pages/country/PostMethods';
import CreateOption from '../pages/option/CreateOption';
import OptionsList from '../pages/option/OptionsList';
import CreatePortfolio from '../pages/portfolio/CreatePortfolio';
import PortfoliosList from '../pages/portfolio/PortfoliosList';
import CreateUser from '../pages/user/CreateUser';
import UsersList from '../pages/user/UsersList';
import CreateTax from '../pages/tax/CreateTax';
import CreateProductionDelay from '../pages/productionDelay/CreateProductionDelay';
import CreateMenu from '../pages/menu/CreateMenu';
import MenusList from '../pages/menu/MenusList';
import CreateHome from '../pages/home/CreateHome';
import HomesList from '../pages/home/HomesList';
import CreateSection from '../pages/home/CreateSection';
import SectionsList from '../pages/home/SectionsList';
import CreateSlider from '../pages/home/CreateSlider';
import SlidersList from '../pages/home/SlidersList';
import CreateCoupon from '../pages/coupon/CreateCoupon';
import CouponsList from '../pages/coupon/CouponsList';
import OrderDetails from '../pages/order/OrderDetails';
import OrdersList from '../pages/order/OrdersList';
import CreatePage from '../pages/pages/CreatePage';
import PagesList from '../pages/pages/PagesList';
import CreateSidebar from '../pages/pages/CreateSidebar';
import SidebarsList from '../pages/pages/SidebarsList';
import CreateFooter from '../pages/footer/CreateFooter';
import Settings from '../pages/settings/Settings';
import Content from '../pages/content/Content';
import CreatePopup from '../pages/popup/CreatePopup';
import PopupsList from '../pages/popup/PopupsList';
import SubscribersList from '../pages/newsletter/SubscribersList';
import Login from '../pages/auth/Login';
import ServicesList from '../pages/services/ServicesList.vue'
import CreateService from '../pages/services/CreateService.vue'
import HolidaysList from '../pages/holiday/HolidaysList.vue'
import CreateHoliday from '../pages/holiday/CreateHoliday.vue'
import CreateRedirects from '../pages/redirects/CreateRedirects.vue'
import CreateDownload from '../pages/download/CreateDownload.vue'
import DownloadList from '../pages/download/DownloadList.vue'
import AddFiles from '../pages/download/AddFiles.vue'
import CreateFile from '../pages/download/CreateFile.vue'
import Media from '../pages/media/Media.vue'
import Portfolio from '../pages/media/Portfolio.vue'
import Menu from '../pages/media/Menu.vue'
import Slider from '../pages/media/Slider.vue'

const routes = [
    { path: '/', component: Body, children: [
       {path:'', component: Dashboard, name: 'dashboard'},
       {path:'product/create', component: CreateProduct, name:'productCreate'},
       {path:'product/details/:id', component: CreateProduct, name:'productDetails'},
       {path:'product/index', component: ProductsList, name: 'products'},
       {path:'addDiscounts/:id', component: AddDiscounts, name:'productAddDiscounts'},
       {path:'product/', component: ProductDetailsLayout, children: [
           {path:'addDetails/:id', component: WorkingDay, name:'productAddDetails'},
           {path:'addServices/:id', component: AddServices, name:'productAddServices'},
           {path:'excludeServiceValues/:id', component: ExcludeServiceValues, name:'productExcludeServiceValues'},
           {path:'addOptions/:id', component: AddOptions, name:'productAddOptions'},
           {path:'excludeOptionValues/:id', component: ExcludeOptionValues, name:'productExcludeOptionValues'},
           {path:'combinations/:id', component: ProductCombinations, name: 'productCombinations'},
        ]},
       {path:'technicalInfo/index', component: TechnicalInfosList, name: 'technicalInfos'},
       {path:'technicalInfo/create', component: CreateTechnicalInfo, name:'technicalInfoCreate'},
       {path:'technicalInfo/details/:id', component: CreateTechnicalInfo, name:'technicalInfoDetails'},
       {path:'shippingInfo/index', component: ShippingInfosList, name: 'shippingInfos'},
       {path:'shippingInfo/create', component: CreateShippingInfo, name:'shippingInfoCreate'},
       {path:'shippingInfo/details/:id', component: CreateShippingInfo, name:'shippingInfoDetails'},
       {path:'postMethod/index', component: PostMethodsList, name: 'postMethods'},
       {path:'postMethod/create', component: CreatePostMethod, name:'postMethodCreate'},
       {path:'postMethod/details/:id', component: CreatePostMethod, name:'postMethodDetails'},
       {path:'duration/index', component: DurationsList, name: 'durations'},
       {path:'duration/create', component: CreateDuration, name:'durationCreate'},
       {path:'duration/details/:id', component: CreateDuration, name:'durationDetails'},
       {path:'country/index', component: CountriesList, name: 'countries'},
       {path:'country/postMetheds', component: PostMethods, name: 'countryPostMethods'},
       {path:'country/create', component: CreateCountry, name:'countryCreate'},
       {path:'country/details/:id', component: CreateCountry, name:'countryDetails'},
       {path:'option/index', component: OptionsList, name:'options'},
       {path:'option/create', component: CreateOption, name:'optionCreate'},
       {path:'option/details/:id', component: CreateOption, name:'optionDetails'},
       {path:'portfolio/index', component: PortfoliosList, name:'portfolios'},
       {path:'portfolio/create', component: CreatePortfolio, name:'portfolioCreate'},
       {path:'portfolio/details/:id', component: CreatePortfolio, name:'portfolioDetails'},
       {path:'user/index', component: UsersList, name: 'users'},
       {path:'user/create', component: CreateUser, name:'userCreate'},
       {path:'user/details/:id', component: CreateUser, name:'userDetails'},
       {path:'tax/details', component: CreateTax, name:'taxDetails'},
       {path:'productionDelay/details', component: CreateProductionDelay, name:'productionDelayDetails'},
       {path:'menu/index', component: MenusList, name: 'menus'},
       {path:'menu/create', component: CreateMenu, name:'menuCreate'},
       {path:'menu/details/:id', component: CreateMenu, name:'menuDetails'},
       {path:'home/index', component: HomesList, name: 'homes'},
       {path:'home/create', component: CreateHome, name:'homeCreate'},
       {path:'home/details/:id', component: CreateHome, name:'homeDetails'},
       {path:'slider/index', component: SlidersList, name: 'homeSliders'},
       {path:'slider/create', component: CreateSlider, name:'homeSliderCreate'},
       {path:'slider/details/:id', component: CreateSlider, name:'homeSliderDetails'},
       {path:'section/index', component: SectionsList, name: 'homeSections'},
       {path:'section/create', component: CreateSection, name:'homeSectionCreate'},
       {path:'section/details/:id', component: CreateSection, name:'homeSectionDetails'},
       {path:'coupon/index', component: CouponsList, name: 'coupons'},
       {path:'coupon/create', component: CreateCoupon, name:'couponCreate'},
       {path:'coupon/details/:id', component: CreateCoupon, name:'couponDetails'},
       {path:'order/index', component: OrdersList, name: 'orders'},
       {path:'order/details/:id', component: OrderDetails, name:'orderDetails'},
       {path:'pages/index', component: PagesList, name: 'pages'},
       {path:'pages/create', component: CreatePage, name:'pageCreate'},
       {path:'pages/details/:id', component: CreatePage, name:'pageDetails'},
       {path:'pages/sidebar/index', component: SidebarsList, name: 'pagesSidebars'},
       {path:'pages/sidebar/create', component: CreateSidebar, name:'pagesSidebarCreate'},
       {path:'pages/sidebar/details/:id', component: CreateSidebar, name:'pagesSidebarDetails'},
       {path:'footer/details', component: CreateFooter, name:'footerDetails'},
       {path:'/settings', component: Settings, name: 'settings'},
       {path:'/content', component: Content, name: 'content'},
       {path:'popup/index', component: PopupsList, name: 'popups'},
       {path:'subscribers/index', component: SubscribersList, name: 'subscribers'},
       {path:'popup/create', component: CreatePopup, name:'popupCreate'},
       {path:'popup/details/:id', component: CreatePopup, name:'popupDetails'},
       {path:'services/index', component: ServicesList, name:'services'},
       {path:'services/create', component: CreateService, name:'serviceCreate'},
       {path:'service/details/:id', component: CreateService, name:'serviceDetails'},
       {path:'holiday/index', component: HolidaysList, name:'holidays'},
       {path:'holiday/create', component: CreateHoliday, name:'holidayCreate'},
       {path:'holiday/details/:id', component: CreateHoliday, name:'holidayDetails'},
       {path:'redirects', component: CreateRedirects, name:'redirects'},
       {path:'downloads/create', component: CreateDownload, name:'createDownload'},
       {path:'downloads/details/:id', component: CreateDownload, name:'downloadDetails'},
       {path:'downloads/list', component: DownloadList, name:'downloads'},
       {path:'downloads/addfiles/:id', component: AddFiles, name:'AddFiles'},
       {path:'downloads/addfiles/create/:id', component: CreateFile, name:'createFile'},
       {path:'media', component: Media, name:'media'},
       {path:'portfolio', component: Portfolio, name:'portfolio'},
       {path:'slider', component: Slider, name:'slider'},
       {path:'menu', component: Menu, name:'menu'}   
    ]},
    { path: '/login', component: Login, name:'login'},
];

const router = createRouter({
    routes,
    history: createWebHistory(),
    linkActiveClass: 'active-link',
    linkExactActiveClass: 'exact-active-link',
});

router.beforeEach((to, from, next) => {
    document.title = 'SEVEN displays - Admin dashboard';
    const publicPages = ['login'];
    const authRequired = !publicPages.includes(to.name);
    const loggedIn = localStorage.getItem('user');
    if(authRequired && !loggedIn){
        next({name: 'login'});
    } else {
        next();
    }
})
router.afterEach((to) => {
    if(to.name != 'login'){
        store.dispatch("order/getOrdersCount").then(
            response => store.commit('order/setUnseenOrdersCount', response.body.count)
        );
    }
})

export default router
