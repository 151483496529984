<template>
   <div class="page-body-heading p-0" style="border-bottom-color: #6ad6f0;"></div>
   <div class="page-body-main-create">
      <div class="card-wrapper">
         <div class="card">
            <div class="card-body text-center">
               <img src="@/assets/images/welcome.svg" width="250" class="mb-5 mw-100">
               <h1>{{$t('dashboard.title')}}</h1>
               <span>{{$t('dashboard.desc')}}</span>
            </div>
         </div>
      </div>
   </div>
</template>



<script>
export default {}
</script>
