<template>
    <div class="page-body-heading" :style="{borderBottomColor: borderColor}">
      <div class="divider"></div>
      <ul class="nav">
         <li class="nav-item back">
            <a v-if="backLink" @click="$router.push({ name: backLink })">
                <span :style="{color: borderColor}"><font-awesome-icon :icon="['fas', 'chevron-left']"></font-awesome-icon></span>
                <span :style="{color: borderColor}"><font-awesome-icon :icon="['fas', backIcon]"></font-awesome-icon></span>
            </a>
         </li>
         <li class="nav-item">
            <h2>{{name}} {{title}} <span v-if="count">({{count}})</span></h2>
         </li>
         <li class="nav-item">
            <select class="form-select page-body-heading-select" id="languageSelect" v-if="!isLoading"
            :value="currentLanguage" @change="changeCurrentLanguage($event.target.value)" :disabled="multiLanguageDisabled"> 
               <option v-for="(language, LanguageKey) in languagesInfo.all" :key="LanguageKey" :value="LanguageKey">{{language}}</option>
            </select>
         </li>
         <li class="nav-item" v-if="listHeading">
             <v-button v-if="cancel" :buttonStop="true" @click="close">{{$t('cancel')}}</v-button>
             <router-link v-if="!createDisabled" :to="{name: createBtnLink}" type="button" class="btn button-action d-none d-sm-inline-block">{{btnTitle}}</router-link>
             <v-button v-else :buttonAction="true" :disabled="true">{{btnTitle}}</v-button>
         </li>
         <li class="nav-item" v-else>
            <v-button :buttonStop="true" @click="close" :disabled="uploaderStatus == 2">{{$t('cancel')}}</v-button>
            <v-button :buttonAction="true" :disabled="actionBtnVisibility || uploaderStatus == 2" @click="save">{{$t('save')}}</v-button>
            <v-button v-if="saveAndCloseBtn" :buttonAction="true" :disabled="actionBtnVisibility || uploaderStatus == 2" @click="saveAndClose">{{$t('saveAndClose')}}</v-button>
         </li>
         <li></li>
      </ul>
   </div>
</template>


<script>

export default {
    data() {
        return {
            languagesInfo:'',
            isLoading: true
        }
    },
    
    props:{
        name: {
            default: ''
        },
        borderColor: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        cancel: {
            type: Boolean,
            default: false
        },
        multiLanguageDisabled: {
            default: false
        },
        createDisabled: {
            default: false
        },
        count: {
            type: Number,
        },
        listHeading: {
             default: false
        },
        btnTitle: {
            type: String,
        },
        createBtnLink: {
            type: String,
        },
        actionBtnVisibility: {
            type: Boolean,
            default: false
        },
        backLink: {
            type: String
        },
        backIcon: {
            type: String
        },
        saveAndCloseBtn: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        uploaderStatus() {
            return this.$store.getters['fileUploader/getUploadStatus'];
        },
        langueges() {
            return this.$store.state.locale.languages;
        },
        currentLanguage() {
            return this.$store.state.locale.selectedLocale
        },
        currentLocale() {
            return this.$store.state.locale.appLocale
        }
    },

    watch: {
         langueges() {
            this.languagesInfo = this.langueges;
         },
         isLoading(){
            if((this.$route.name.includes('Create') || this.$route.name.includes('create') || this.$route.name.includes('Add')) &&  !this.isLoading){
                this.changeCurrentLanguage(this.currentLocale);
            }else if(this.multiLanguageDisabled){
                this.changeCurrentLanguage(this.currentLocale);
            }
         } 
    },

    created() {
        if(!this.langueges){
           this.getLanguges();
        }else{
            this.languagesInfo = this.langueges;
            this.isLoading = false
        }
    },

    methods:{
        changeCurrentLanguage(lang){
            this.$store.commit('locale/changeCurrentLanguage', lang)
        },

        getLanguges() {
                 this.$store.dispatch("locale/getAllLanguages").then(
                () => {
                    this.isLoading = false
                    // console.log(this.langueges)
                },
                (error) => {
                this.$notify({
                        text: error,
                        group: 'failed'
                    });
                }
            )
        },

        close(){
            this.$router.back()
        },

        save(){
            this.$emit('handleClick')
        },

        saveAndClose(){
            this.save()
            this.close()
        }

        
    }
}
</script>
