import productService from '../../../services/product.service'
import productCombinations from './modules/productCombinations'
import productDiscounts from './modules/productDiscounts'
import productMenus from './modules/productMenus'
import productPortfolio from './modules/productPortfolio'
import productOptions from './modules/productOptions'
import productServices from './modules/productServices'
import productTechnicalInfo from './modules/productTechnicalInfos'
import productWorkingDays from './modules/productWorkdays'

const product = {
	namespaced: true,
	modules: {
		combinations: productCombinations,
		discounts: productDiscounts,
		menus: productMenus,
		portfolio: productPortfolio,
		options: productOptions,
		services: productServices,
		technicalInfo: productTechnicalInfo,
		workingDays: productWorkingDays
	},
	actions: {
		activate(_, product_id) {
			return productService.activate(product_id).then(
				data => {
					return Promise.resolve(data.message)
				},
				error => {
					return Promise.reject(error.response.data.errors)
				}
			)
		},
		deactivate(_, product_id) {
			return productService.deactivate(product_id).then(
				data => {
					return Promise.resolve(data.message)
				},
				error => {
					return Promise.reject(error.response.data.errors)
				}
			)
		},
		create(_, product) {
			return productService.create(product).then(
				data => {
					return Promise.resolve(data)
				},
				error => {
					return Promise.reject(error.response.data.errors)
				}
			)
		},
		createTranslation(_, { urlParams, product }) {
			return productService.createTranslation(urlParams, product).then(
				data => {
					return Promise.resolve(data.message)
				},
				error => {
					return Promise.reject(error.response.data.errors)
				}
			)
		},
		update(_, { urlParams, editedProduct }) {
			return productService.edit(urlParams, editedProduct).then(
				data => {
					return Promise.resolve(data.message)
				},
				error => {
					return Promise.reject(error.response.data.errors)
				}
			)
		},
		delete(_, product_id) {
			return productService.delete(product_id).then(
				data => {
					return Promise.resolve(data.message)
				},
				error => {
					return Promise.reject(error.response.data.errors)
				}
			)
		},
		duplicate(_, product_id) {
			return productService.duplicate(product_id).then(
				data => {
					return Promise.resolve(data.message)
				},
				error => {
					return Promise.reject(error.response.data.errors)
				}
			)
		},
		getAll(_, queryParams) {
			return productService.getAll(queryParams).then(
				data => {
					return Promise.resolve(data.body)
				},
				error => {
					return Promise.reject(error)
				}
			)
		},
		fetch(_, urlParams) {
			return productService.fetch(urlParams).then(
				data => {
					return Promise.resolve(data.body)
				},
				error => {
					return Promise.reject(error.response.data.errors)
				}
			)
		}
	}
}

export default product
