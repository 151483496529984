<template>
    <BodyHeading :borderColor="'rgb(154, 168, 181)'" :title="$t('subscribers.subscribersList')" :count="users.total" 
      :multiLanguageDisabled="true" :listHeading="true" :btnTitle="'. . .'" :createDisabled="true" :backLink="'settings'" :backIcon="'cog'"></BodyHeading>
    <div class="page-body-main-list d-grid grid-columns">
        <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" style="width:5%"><span>#</span></th>
                            <th scope="col"><span>{{$t('subscribers.firstName')}}</span></th>
                            <th scope="col"><span>{{$t('subscribers.lastName')}}</span></th>
                            <th scope="col"><span>{{$t('subscribers.email')}}</span></th>
                            <th scope="col"><span>{{$t('subscribers.role')}}</span></th>
                            <th scope="col" style="width:5%"><span>{{$t('actions')}}</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(user, index) in users.data" :key="user.id">
                            <td>{{index + 1 + (users.current_page - 1) * ITEMS_PER_PAGE}}</td>
                            <td>{{user.user? user.user.first_name : user.first_name}}{{!user.user && !user.first_name ? '-': ''}}</td>
                            <td>{{user.user? user.user.last_name : user.last_name}}{{!user.user && !user.last_name ? '-': ''}}</td>
                            <td>{{user.user? user.user.email : user.email}}{{!user.user && !user.email ? '-': ''}}</td>
                            <td>{{user.user? 'User' : 'Guest'}}</td>
                            <!-- user -->
                            <td v-if="user.user" :style="user.user.email === ParentZindex? { zIndex: 2}: null">
                                <div class="dropdown actions-dropdown">
                                    <button @click="changeParentZindex(user.user.email)" class="btn btn-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <font-awesome-icon :icon="['fas', 'ellipsis-h']" size="sm"></font-awesome-icon>
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a class="dropdown-item warning" @click="deleteItem(user.user.email)">{{$t('delete')}}</a></li>
                                    </ul>
                                </div>
                            </td>
                            <!-- guest -->
                            <td v-if="!user.user" :style="user.email === ParentZindex? { zIndex: 2}: null">
                                <div class="dropdown actions-dropdown">
                                    <button @click="changeParentZindex(user.email)" class="btn btn-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <font-awesome-icon :icon="['fas', 'ellipsis-h']" size="sm"></font-awesome-icon>
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a class="dropdown-item warning" @click="deleteItem(user.email)">{{$t('delete')}}</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
            </table>
        </div>

        <!-- Filter Box -->
        <div class="filter-bar">
            <aside :class="{'opened': filterOpened}">
                <nav>
                    <ul>
                        <li><button :class="{'active': filterOpened}" @click="filterOpened = !filterOpened"><font-awesome-icon :icon="['fas', 'filter']"></font-awesome-icon></button></li>
                    </ul>
                </nav>
                <div class="filter-content">
                    <div class="filter-panel">
                        <div class="headline">
                            <h3>{{$t('filters.title')}}</h3>
                            <button @click="filterOpened = !filterOpened"><font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon></button>
                        </div>
                        <div class="content">
                            <div>
                                <div>
                                    <div class="element">
                                        <v-input type="text" name="email" label="subscribers.email" v-model="email" @keyup="searchQueue()"></v-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </div>
        <!-- // Filter Box -->

        <Pagination v-if="users.total>users.per_page" :lastPage="users.last_page"
        :activePage="users.current_page" @handleClick="changePage"></Pagination>
    </div>
    
</template>


<script>
import BodyHeading from '../../components/BodyHeading.vue';
import Pagination from '../../components/Pagination.vue';
import Input from '../../components/Input.vue';
import { ITEMS_PER_PAGE } from '../../constants';

export default {
    components: {
        BodyHeading,
        Pagination,
        'v-input': Input
    },
    data() {
        return{
            timer: null,
            filterOpened: false,
            // first_name: null,
            // last_name: null,
            email: null,
            users: {},
            ParentZindex: null
        }
    },
    created() {
        this.ITEMS_PER_PAGE = ITEMS_PER_PAGE
        if(Object.entries(this.$route.query).length === 0 || this.$route.query.page === ''){
            this.$router.push({ name: 'users', query: { page: 1 } })
        }
        this.getSubscribers()
        if(this.$route.query.email){
            this.email = this.$route.query.email
            this.filterOpened = true
        }
    },
    watch: {
        '$route.query'(){
            this.getSubscribers()
        },
        currentLanguage() {
            this.getSubscribers()
        }
    },
    computed: {
        currentLanguage() {
            return this.$store.state.locale.selectedLocale
        },
    },
    methods: {
        searchQueue(){
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                const query = {
                    page: 1,
                    ...(this.email && {email: this.email}),
                }
                this.$router.push({
                    query
                })
            }, 500);
        },
        changeParentZindex(id) {
            this.ParentZindex = id
        },
        getSubscribers() {
            const params = {language: this.currentLanguage, per_page: ITEMS_PER_PAGE, ...this.$route.query}
            this.$store.dispatch("newsletter/getAll", params).then(
                (response) => {
                    this.users = response;
                },
                (error) => {
                this.$notify({
                        text: error,
                        group: 'failed'
                    });
                }
            )
        },
        deleteItem(email){
            this.$swal().then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("newsletter/delete", {email}).then(
                        (message) => {
                            this.$notify({
                                text: message,
                                group: 'success'
                            });
                            this.getSubscribers()
                        },
                        (error) => {
                            let errorMessage = ''
                            for (const err in error) {
                                errorMessage += `${error[err]} <br>`;
                            }
                        this.$notify({
                                text: errorMessage,
                                group: 'failed'
                            });
                        }
                    )
                } 
            })            
        },
        changePage(page=1){
            this.$router.push({ name: 'users', query: { ...this.$route.query, page: page } })
        }
    }
}
</script>
