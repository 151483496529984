export default function useCkeditorHelper() {
  function parseForEditor(string){
    if (string)
      return string.replace(/&amp;/g, "&").replace(/&gt;/g, ">").replace(/&lt;/g, "<").replace(/&quot;/g, `"`)
    else return ''
  }

  function parseForServer(string){
    if (string)
      return string.replace(/&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").replace(/"/g, "&quot;")
    else return ''
  }

  return { parseForEditor, parseForServer }
}