<template>
    <BodyHeading :borderColor="'rgb(87, 217, 163)'" :title="$t('technicalInfo.technicalInfosTitle')" :count="technicalInfos.total" 
      :multiLanguageDisabled="false" :listHeading="true" :btnTitle="$t('technicalInfo.addButtonTitle')" :createBtnLink="'technicalInfoCreate'" ></BodyHeading>
    <div class="page-body-main-list d-grid grid-columns">
        <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" style="width: 5%"><span>#</span></th>
                            <th scope="col"><span>{{$t('technicalInfo.title')}}</span></th>
                            <th scope="col" style="width: 5%"><span>ID</span></th>
                            <th scope="col" style="width: 5%"><span>{{$t('actions')}}</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(technicalInfo, index) in technicalInfos.data" :key="technicalInfo.technical_info_id">
                            <td>{{index + 1 + (technicalInfos.current_page - 1) * ITEMS_PER_PAGE}}</td>
                            <td>{{technicalInfo.title}}</td>
                            <td>{{technicalInfo.technical_info_id}}</td>
                            <td :style="technicalInfo.technical_info_id === ParentZindex? { zIndex: 2}: null">
                                <div class="dropdown actions-dropdown">
                                    <button @click="changeParentZindex(technicalInfo.technical_info_id)" class="btn btn-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <font-awesome-icon :icon="['fas', 'ellipsis-h']" size="sm"></font-awesome-icon>
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a @click="$router.push({ name: 'technicalInfoDetails', params: { id: technicalInfo.technical_info_id } })" class="dropdown-item">{{$t('edit')}}</a></li>
                                        <li><a class="dropdown-item warning" @click="deleteItem(technicalInfo.technical_info_id)">{{$t('delete')}}</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
            </table>
        </div>

         <!-- Filter Box -->
        <div class="filter-bar">
            <aside :class="{'opened': filterOpened}">
                <nav>
                    <ul>
                        <li><button :class="{'active': filterOpened}" @click="filterOpened = !filterOpened"><font-awesome-icon :icon="['fas', 'filter']"></font-awesome-icon></button></li>
                    </ul>
                </nav>
                <div class="filter-content">
                    <div class="filter-panel">
                        <div class="headline">
                            <h3>{{$t('filters.title')}}</h3>
                            <button @click="filterOpened = !filterOpened"><font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon></button>
                        </div>
                        <div class="content">
                            <div>
                                <div>
                                    <div class="element">
                                        <v-input type="text" name="title" label="title" v-model="title" @keyup="searchQueue()"></v-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </div>
        <!-- // Filter Box -->

        <Pagination v-if="technicalInfos.total>technicalInfos.per_page" :lastPage="technicalInfos.last_page"
        :activePage="technicalInfos.current_page" @handleClick="changePage"></Pagination>
    </div>
    
</template>


<script>
import BodyHeading from '../../components/BodyHeading.vue';
import Pagination from '../../components/Pagination.vue';
import Input from '../../components/Input.vue';
import { ITEMS_PER_PAGE } from '../../constants';

export default {
    components: {
        BodyHeading,
        Pagination,
        'v-input': Input
    },
    data() {
        return{
            timer: null,
            filterOpened: false,
            title: null,
            technicalInfos: {},
            ParentZindex: null
        }
    },
    created() {
        this.ITEMS_PER_PAGE = ITEMS_PER_PAGE
        if(Object.entries(this.$route.query).length === 0 || this.$route.query.page === ''){
            this.$router.push({ name: 'technicalInfos', query: { page: 1 } })
        }
        this.getTechnicalInfos()
        if(this.$route.query.title){
            this.title = this.$route.query.title
            this.filterOpened = true
        }
    },
    watch: {
        '$route.query'(){
            this.getTechnicalInfos()
        },
        currentLanguage() {
            this.getTechnicalInfos()
        }
    },
    computed: {
        currentLanguage() {
            return this.$store.state.locale.selectedLocale
        },
    },
    methods: {
        searchQueue(){
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                const query = {
                    page: 1,
                    ...(this.title && {title: this.title}),
                }
                this.$router.push({
                    query
                })
            }, 500);
        },
        changeParentZindex(id) {
            this.ParentZindex = id
        },
        getTechnicalInfos() {
            const params = {language: this.currentLanguage, per_page: ITEMS_PER_PAGE, ...this.$route.query}
            this.$store.dispatch("technicalInfo/getAll", params).then(
                (response) => {
                    this.technicalInfos = response;
                },
                (error) => {
                this.$notify({
                        text: error,
                        group: 'failed'
                    });
                }
            )
        },
        deleteItem(id){
            
            this.$swal().then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("technicalInfo/delete", id).then(
                        (message) => {
                            this.$notify({
                                text: message,
                                group: 'success'
                            });
                            this.getTechnicalInfos()
                        },
                        (error) => {
                            let errorMessage = ''
                            for (const err in error) {
                                errorMessage += `${error[err]} <br>`;
                            }
                        this.$notify({
                                text: errorMessage,
                                group: 'failed'
                            });
                        }
                    )
                } 
            })            
        },
        changePage(page=1){
            this.$router.push({ name: 'technicalInfos', query: { ...this.$route.query, page: page } })
        }
    }
}
</script>
