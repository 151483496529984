<template>
    <BodyHeading :borderColor="'rgb(87, 217, 163)'" :title="$t('product.entityName', 2)" :count="products.total"
        :multiLanguageDisabled="false" :listHeading="true" :btnTitle="$t('product.addButtonTitle')"
        :createBtnLink="'productCreate'"></BodyHeading>
    <div class="page-body-main-list d-grid grid-columns">
        <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered">
                <thead>
                    <tr>
                        <th scope="col" style="width:5%"><span>#</span></th>
                        <th scope="col"><span>{{ $t('common.title') }}</span></th>
                        <th scope="col" style="width: 5%"><span>{{ $t('common.id') }}</span></th>
                        <th scope="col"><span>{{ $t('product.activity') }}</span></th>
                        <th scope="col" style="width:5%"><span>{{ $t('actions') }}</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(product, index) in products.data" :key="product.product_id">
                        <td>{{ index + 1 + (products.current_page - 1) * ITEMS_PER_PAGE}}</td>
                        <td>{{ product.title }}</td>
                        <td>{{ product.product_id }}</td>
                        <td>
                            <v-switch v-model="product.product_info.is_active"  @click="changeActivity(index)" :disabled="isSwitchLocked"></v-switch>
                        </td>
                        <td :style="product.product_id === ParentZindex ? { zIndex: 2 } : null">
                            <div class="dropdown actions-dropdown">
                                <button @click="changeParentZindex(product.product_id)" class="btn btn-light"
                                    type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    <font-awesome-icon :icon="['fas', 'ellipsis-h']" size="sm"></font-awesome-icon>
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a class="dropdown-item"
                                            @click="$router.push({ name: 'productDetails', params: { id: product.product_id }, query: { name: product.title } })">{{ $t('edit') }}</a>
                                    </li>
                                    <li><a class="dropdown-item"
                                            @click="$router.push({ name: 'productAddDetails', params: { id: product.product_id }, query: { name: product.title } })">{{ $t('product.addDetails') }}</a>
                                    </li>
                                    <li><a class="dropdown-item"  @click="$router.push({ name: 'productAddDiscounts', params: { id: product.product_id }, query: { name: product.title } })">{{ $t('product.addDiscount') }}</a></li>
                                    <li><a class="dropdown-item" @click="duplicate(product.product_id)">Duplicate</a></li>
                                    <li><a class="dropdown-item warning" @click="deleteItem(product.product_id)">{{ $t('delete') }}</a></li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <Modals ref="productsAssigning" :title="title_selected" @handleClick="attachProductMenus">
            <div class="col">

                <MultiSelect v-model="productMenus" label="product.create.menu" mode="tags" :max="5"
                    :options="async (query) => { return await getMenus(query) }" :delay="1" :resolveOnLoad="true"
                    :searchable="true" :object="true" />
                <div class="mt-2">
                    {{ $t('product.create.defaultMenu') }}:
                    <span v-for="menu in productMenus" :key="menu.value">
                        <input v-model="default_menu_id" :value="menu.value" type="radio" class="btn-check"
                            name="options" :id="'option' + menu.value" autocomplete="off">
                        <label class="btn mb-1 me-2" :for="'option' + menu.value">{{ menu.label }}</label>
                    </span>
                </div>
            </div>
        </Modals>
        <!-- Filter Box -->
        <div class="filter-bar">
            <aside :class="{ 'opened': filterOpened }">
                <nav>
                    <ul>
                        <li>
                            <button :class="{ 'active': filterOpened }" @click="filterOpened = !filterOpened">
                                <font-awesome-icon :icon="['fas', 'filter']"></font-awesome-icon>
                            </button>
                        </li>
                    </ul>
                </nav>
                <div class="filter-content">
                    <div class="filter-panel">
                        <div class="headline">
                            <h3>{{ $t('filters.title') }}</h3>
                            <button @click="filterOpened = !filterOpened"><font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon></button>
                        </div>
                        <div class="content">
                            <div>
                                <div>
                                    <div class="element">
                                        <v-input type="text" name="title" label="common.id" v-model="product_id"
                                            @keyup="searchQueue()"></v-input>
                                    </div>
                                    <div class="element">
                                        <v-input type="text" name="title" label="common.title" v-model="title"
                                            @keyup="searchQueue()"></v-input>
                                    </div>
                                    <div class="element">
                                        <v-input type="text" name="code" label="common.code" v-model="code"
                                            @keyup="searchQueue()"></v-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </div>
        <!-- // Filter Box -->

        <Pagination v-if="products.total > products.per_page" :lastPage="products.last_page"
            :activePage="products.current_page" @handleClick="changePage"></Pagination>
    </div>

</template>
<script>
import BodyHeading from '../../components/BodyHeading.vue';
import Pagination from '../../components/Pagination.vue';
import Switch from '../../components/Switch.vue';
import Input from '../../components/Input.vue';
import { ITEMS_PER_PAGE } from '../../constants';
import Modals from '../../components/Modal.vue';
import MultiSelect from '../../components/MultiSelect.vue'
export default {
    components: {
        BodyHeading,
        Pagination,     
        Modals,
        MultiSelect,
        'v-switch': Switch,
        'v-input': Input
    },
    data() {
        return {
            timer: null,
            filterOpened: false,
            title: null,
            code: null,
            product_id: null,
            products: {},
            title_selected: null,
            categories: [],
            default_menu_id: null,
            selected_product_id: null,
            productMenus: [],
            ParentZindex: null,
            isSwitchLocked: false,
            selectedCategories: null
        }
    },
    created() {
        this.ITEMS_PER_PAGE = ITEMS_PER_PAGE
        if (!this.$route.query.page) {
            this.$router.push({ query: { page: 1 } })
        }
        this.getProducts()
        const { title, product_id } = this.$route.query
        if (title || product_id) {
            this.title = title ?? null
            this.product_id = product_id ?? null
            this.filterOpened = true
        }
    },
    watch: {
        '$route.query'() {
            this.getProducts()
        },                                                                                                                                                                                                      
        selectedLocale() {
            this.getProducts()
        }
    },
    computed: {
        selectedLocale() {
            return this.$store.state.locale.selectedLocale
        },
    },
    methods: {
        attachProductMenus() {
            const urlParams = { product_id: parseInt(this.selected_product_id) } 
            const menus = {
                menus: this.productMenus.map(el => parseInt(el.value)),
                default: parseInt(this.default_menu_id)
            }
            console.log(urlParams, menus);
            this.$store.dispatch('product/menus/setMenus', { urlParams, menus }).then(
                () => {
                    this.$notify({
                        text: 'Aktualisiert',
                        group: 'success'
                    });
                    this.$refs.productsAssigning.hide()
                },
                (error) => {
                    let errorMessage = ''
                    for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                    }
                    this.$notify({
                        text: errorMessage,
                        group: 'failed'
                    });
                }
            )
        },
        async getMenus(query) {
            const queryParams = { language: this.$store.state.locale.selectedLocale, title: query, per_page: 50 }
            return this.$store.dispatch('menu/getAll', queryParams).then(res => {
                return res.data.map(el => ({ label: el.title, value: el.menu_id }));
            })
        },
        getProductMenus(id) {
            const urlParams = { product_id: id }
            this.$store.dispatch('product/menus/getMenus', urlParams).then(res => {
                this.productMenus = res.map(el => ({ label: el.menu_t.title, value: el.id }))
            });
        },
        async assignProducts(id,defaultID,title){
            this.selected_product_id = id
            this.title_selected = title
            this.default_menu_id = defaultID
            this.$refs.productsAssigning.initiateModal()
            this.getProductMenus(id)
        },
        searchQueue() {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                const query = {
                    page: 1,
                    ...(this.title && { title: this.title }),
                    ...(this.product_id && { product_id: this.product_id }),
                    ...(this.code && { code: this.code }),
                }
                this.$router.push({
                    query
                })
            }, 500);
        },
        changeParentZindex(product_id) {
            this.ParentZindex = product_id
        },
        getProducts() {
            const params = { language: this.selectedLocale, per_page: ITEMS_PER_PAGE, ...this.$route.query }
            this.$store.dispatch("product/getAll", params).then(
                (response) => {
                    this.products = response;
                },
                (error) => {
                    this.$notify({
                        text: error,
                        group: 'failed'
                    });
                }
            )
        },
        deleteItem(product_id) {
            this.$swal().then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("product/delete", product_id).then(
                        (message) => {
                            this.$notify({
                                text: message,
                                group: 'success'
                            });
                            this.getProducts()
                        },
                        (error) => {
                            let errorMessage = ''
                            for (const err in error) {
                                errorMessage += `${error[err]} <br>`;
                            }
                            this.$notify({
                                text: errorMessage,
                                group: 'failed'
                            });
                        }
                    )
                }
            })
        },
        duplicate(product_id) {
            this.$store.dispatch("product/duplicate",product_id).then((message) => {
                    this.$notify({
                        text: message,
                        group: 'success'
                    });
                    this.getProducts()
                },(error) => {
                    let errorMessage = ''
                    for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                    }
                    this.$notify({
                        text: errorMessage,
                        group: 'failed'
                    });
                }
            )
        },
        changePage(page = 1) {
            this.$router.push({ query: { ...this.$route.query, page: page } })
        },
        async changeActivity(index) {
            const product = this.products.data[index]
            this.isSwitchLocked = true
            let result
            try {
                if (product.product_info.is_active == 1) {
                    result = await this.$store.dispatch("product/activate", product.product_id)
                } else {
                    result = await this.$store.dispatch("product/deactivate", product.product_id)
                }
                this.$notify({
                    text: result,
                    group: 'success'
                });
            } catch (error) {
                let errorMessage = ''
                for (const err in error) {
                    errorMessage += `${error[err]} <br>`;
                }
                this.$notify({
                    text: errorMessage,
                    group: 'failed'
                });
                product.product_info.is_active = product.product_info.is_active ? "0" : "1"
            }
            this.isSwitchLocked = false
        }
    }
}
</script>
