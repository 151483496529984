<template>
   <BodyHeading v-if="$route.name.includes('Create')" :borderColor="'rgb(87, 217, 163)'"
      :title="$t('shippingInfo.addButtonTitle')" @handleClick="create" :multiLanguageDisabled="true"
      :saveAndCloseBtn="false"></BodyHeading>

   <BodyHeading v-if="$route.name.includes('Details')" :borderColor="'rgb(87, 217, 163)'"
      :title="$t('shippingInfo.editTitle')" v-on="isExist ? { handleClick: edit } : { handleClick: createTranslation }"
      :multiLanguageDisabled="false"></BodyHeading>
   <div class="page-body-main-create">
      <div class="card-wrapper">
         <div class="card-title">{{ $t('generalInformation') }}</div>
         <div class="card">
            <div class="card-body">
               <!--  -->
               <div v-if="!isLoading" class="row">
                  <div class="mb-4">
                     <v-input type="text" name="title" label="shippingInfo.title"
                        :placeholder="defaultLanguageShippingInfo.title" v-model="shippingInfo.title" :isRequired="true"
                        :validateError="v$.shippingInfo.title.$error ? v$.shippingInfo.title.$errors[0].$message : null"></v-input>
                  </div>
                  <div class="mb-4">
                     <TextEditor name="description" label="shippingInfo.description" v-model="shippingInfo.description"
                        :validateError="v$.shippingInfo.description.$error ? v$.shippingInfo.description.$errors[0].$message : null">
                     </TextEditor>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>



<script>
import BodyHeading from '../../components/BodyHeading.vue'
import Input from '../../components/Input.vue';
import TextEditor from '../../components/TextEditor.vue';
import useValidate from '@vuelidate/core';
import { required } from '@/utilities/i18n-validators.js'

// Import TextEditor helpers
import useCkeditorHelper from '../../composables/useCkeditorHelper.js'

export default {
   components: {
      BodyHeading,
      'v-input': Input,
      TextEditor
   },
   setup() {
      const { parseForEditor, parseForServer } = useCkeditorHelper()
      return { parseForEditor, parseForServer }
   },
   data() {
      return {
         v$: useValidate(),
         shippingInfo: {},
         defaultLanguageShippingInfo: {},
         isExist: true,
         isLoading: true
      }
   },
   validations() {
      return {
         shippingInfo: {
            title: { required },
            description: { required }
         }
      }
   },
   computed: {
      currentLanguage() {
         return this.$store.state.locale.selectedLocale
      },
      languages() {
         return this.$store.state.locale.languages
      },
      currentLocale() {
         return this.$store.state.locale.appLocale
      }
   },
   watch: {
      '$route': {
         immediate: true,
         handler() {
            if (this.$route.name.includes('Details')) {
               this.getshippingInfo(this.$route.params.id)
            } else {
               this.isLoading = false
            }
         }
      },
      'languages': {
         immediate: true,
         handler() {
            if (this.$route.name.includes('Details') && this.languages) {
               if (!this.isExist && this.currentLocale === this.currentLanguage) {
                  this.$router.push({ name: 'shippingInfos', query: { page: 1 } })
               }
               this.getshippingInfo(this.$route.params.id, this.currentLocale)
            }
         }
      },
      currentLanguage() {
         if (this.$route.name.includes('Details')) {
            this.isLoading = true
            this.v$.$reset();
            this.shippingInfo = {}
            this.getshippingInfo(this.$route.params.id, this.currentLanguage)
            this.getshippingInfo(this.$route.params.id, this.currentLocale)
         }
      }
   },
   methods: {
      create() {
         this.v$.$validate()
         if (!this.v$.$error) {
            let shippingInfo = { ...this.shippingInfo };
            shippingInfo.language = this.currentLanguage;
            shippingInfo.description = this.parseForServer(shippingInfo.description)
            // console.log(this.shippingInfo)
            this.$store.dispatch("shippingInfo/create", shippingInfo).then(
               (data) => {
                  // console.log(...message);
                  this.$notify({
                     text: data.message,
                     group: 'success'
                  });
                  this.$router.push({ name: 'shippingInfoDetails', params: { id: data.body.shipping_info_id } })
               },
               (error) => {
                  let errorMessage = ''
                  for (const err in error) {
                     errorMessage += `${error[err]} <br>`;
                  }
                  this.$notify({
                     text: errorMessage,
                     group: 'failed'
                  });
               }
            )
         }
      },
      createTranslation() {
         this.v$.$validate()
         if (!this.v$.$error) {
            let shippingInfo = { ...this.shippingInfo };
            shippingInfo.language = this.currentLanguage;
            shippingInfo.shipping_info_id = this.defaultLanguageShippingInfo.shipping_info_id;
            shippingInfo.description = this.parseForServer(shippingInfo.description)
            // console.log(this.shippingInfo)
            this.$store.dispatch("shippingInfo/createTranslation", shippingInfo).then(
               (message) => {
                  // console.log(...message);
                  this.isExist = true;
                  this.$notify({
                     text: message,
                     group: 'success'
                  });
                  // this.$router.push({name: 'dashboard'})
               },
               (error) => {
                  let errorMessage = ''
                  for (const err in error) {
                     errorMessage += `${error[err]} <br>`;
                  }
                  this.$notify({
                     text: errorMessage,
                     group: 'failed'
                  });
               }
            )
         }
      },
      edit() {
         this.v$.$validate()
         if (!this.v$.$error) {
            let shippingInfo = { ...this.shippingInfo };
            shippingInfo.language = this.currentLanguage;
            shippingInfo.shipping_info_id = this.defaultLanguageShippingInfo.shipping_info_id;
            shippingInfo.description = this.parseForServer(shippingInfo.description)
            // console.log(this.shippingInfo)
            this.$store.dispatch("shippingInfo/update", shippingInfo).then(
               (message) => {
                  // console.log(...message);
                  this.$notify({
                     text: message,
                     group: 'success'
                  });
                  // this.$router.push({name: 'dashboard'})
               },
               (error) => {
                  let errorMessage = ''
                  for (const err in error) {
                     errorMessage += `${error[err]} <br>`;
                  }
                  this.$notify({
                     text: errorMessage,
                     group: 'failed'
                  });
               }
            )
         }
      },
      getshippingInfo(id, language = this.currentLanguage) {
         // console.log(this.currentLanguage);
         const params = { language: language, shipping_info_id: id }
         this.$store.dispatch("shippingInfo/fetch", params).then(
            (response) => {
               if (this.currentLocale === language) {
                  this.defaultLanguageShippingInfo = response;
                  if (this.currentLanguage === this.currentLocale) {
                     this.isExist = true;
                     this.shippingInfo = response;
                     this.shippingInfo.description = this.parseForEditor(this.shippingInfo.description)
                     this.isLoading = false
                  }
               } else {
                  this.isExist = true;
                  this.shippingInfo = response;
                  this.shippingInfo.description = this.parseForEditor(this.shippingInfo.description)
                  this.isLoading = false
               }
            },
            () => {
               this.isExist = false;
               if (this.currentLocale === this.currentLanguage) {
                  this.$router.push({ name: 'shippingInfos', query: { page: 1 } })
               }
               this.isLoading = false;
               // let errorMessage = ''
               // for (const err in error) {
               //    errorMessage += `${error[err]} <br>`;
               // }
               // this.$notify({
               //    text: errorMessage,
               //    group: 'failed'
               // });
            }
         )
      },
   }
}
</script>
