<script setup>
import { ref, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useNotification } from "@kyvg/vue3-notification";
import Swal from 'sweetalert2'
import BodyHeading from '../../components/BodyHeading.vue';
import Pagination from '../../components/Pagination.vue';
import VInput from '../../components/Input.vue';
import { ITEMS_PER_PAGE } from '../../constants';

const route = useRoute()
const router = useRouter()
const store = useStore()
const { notify }  = useNotification()

const timer = ref(null)
const filterOpened = ref(false)
const title = ref(null)
const services = ref({})
const ParentZindex = ref(null)
const selectedLocale = computed(() => store.state.locale.selectedLocale)

if (Object.entries(route.query).length === 0 || route.query.page === '') {
  router.push({ name: 'services', query: { page: 1 } })
}

getServices()

if (route.query.title) {
  title.value = route.query.title
  filterOpened.value = true
}

watch(() => route.query, () => {
  getServices()
})

watch(selectedLocale, () => {
  getServices()
})

function getServices() {
  const queryParams = { language: selectedLocale.value, per_page: ITEMS_PER_PAGE, ...route.query }
  store.dispatch("service/getAll", queryParams).then(
    (response) => {
      services.value = response;
    },
    (error) => {
      notify({
        text: error,
        group: 'failed'
      });
    }
  )
}

function searchQueue(){
  clearTimeout(timer.value);
  timer.value = setTimeout(() => {
    const query = {
      page: 1,
      ...(title.value && { title: title.value }),
    }
    router.push({
      query
    })
  }, 500);
}

function changeParentZindex(id) {
  ParentZindex.value = id
}

function deleteItem(id){
  Swal.fire({
    title: '<div class="w-100 text-center"><strong class="text-danger">Be cautious!!!</strong></div>',
    icon: 'warning', text: 'Delete this item may cause disabled products that use this item.'
  }).then((result) => {
    if (result.isConfirmed) {
      store.dispatch("service/delete", { service_id: id }).then(
        (message) => {
          notify({
            text: message,
            group: 'success'
          });
          getServices()
        },
        (error) => {
          let errorMessage = ''
          for (const err in error) {
            errorMessage += `${error[err]} <br>`;
          }
          notify({
            text: errorMessage,
            group: 'failed'
          });
        }
      )
    }
  })
}

function changePage(page = 1){
  router.push({ name: 'services', query: { ...route.query, page: page } });
}

</script>

<template>
  <BodyHeading
  :borderColor="'rgb(87, 217, 163)'"
  :title="$t('service.servicesTitle')"
  :count="services.total"
  :multiLanguageDisabled="false"
  :listHeading="true"
  :btnTitle="$t('service.addButtonTitle')"
  :createBtnLink="'serviceCreate'">
  </BodyHeading>
  
  <div class="page-body-main-list d-grid grid-columns">
    <div class="table-responsive">
      <table class="table table-striped table-hover table-bordered">
        <thead>
          <tr>
            <th scope="col" style="width: 5%"><span>#</span></th>
            <th scope="col"><span>{{$t('service.title')}}</span></th>
            <th scope="col" style="width: 5%"><span>ID</span></th>
            <th scope="col" style="width: 5%"><span>{{$t('actions')}}</span></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(service, index) in services.data" :key="service.service_id">
            <td>{{index + 1 + (services.current_page - 1) * ITEMS_PER_PAGE}}</td>
            <td>{{service.title}}</td>
            <td>{{service.service_id}}</td>
            <td :style="service.service_id === ParentZindex? { zIndex: 2}: null">
              <div class="dropdown actions-dropdown">
                <button @click="changeParentZindex(service.service_id)" class="btn btn-light" type="button"
                  id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <font-awesome-icon :icon="['fas', 'ellipsis-h']" size="sm"></font-awesome-icon>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a @click="$router.push({ name: 'serviceDetails', params: { id: service.service_id } })"
                      class="dropdown-item">{{$t('edit')}}</a>
                  </li>
                  <li>
                    <a class="dropdown-item warning" @click="deleteItem(service.service_id)">{{$t('delete')}}</a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Filter Box -->
    <div class="filter-bar">
      <aside :class="{'opened': filterOpened}">
        <nav>
          <ul>
            <li>
              <button :class="{'active': filterOpened}" @click="filterOpened = !filterOpened">
                <font-awesome-icon :icon="['fas', 'filter']"></font-awesome-icon>
              </button>
            </li>
          </ul>
        </nav>
        <div class="filter-content">
          <div class="filter-panel">
            <div class="headline">
              <h3>{{$t('filters.title')}}</h3>
              <button @click="filterOpened = !filterOpened">
                <font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon>
              </button>
            </div>
            <div class="content">
              <div>
                <div>
                  <div class="element">
                    <v-input type="text" name="title" label="service.title" v-model="title"
                      @update:modelValue="searchQueue"></v-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </div>
    <!-- // Filter Box -->

    <Pagination v-if="services.total>services.per_page" :lastPage="services.last_page" :activePage="services.current_page"
      @handleClick="changePage"></Pagination>
  </div>

</template>