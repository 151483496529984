<template>
   <BodyHeading v-if="$route.name.includes('Create')" :borderColor="'rgb(248, 137, 98)'" :title="$t('coupon.addNewButton')" 
      @handleClick="create" :multiLanguageDisabled="true" :saveAndCloseBtn="false"></BodyHeading>
   
   <BodyHeading v-if="$route.name.includes('Details')" :borderColor="'rgb(248, 137, 98)'" :title="$t('coupon.couponDetails')" 
     @handleClick="edit" :multiLanguageDisabled="true"></BodyHeading>
   <div class="page-body-main-create">
      <div class="card-wrapper">
         <div class="card-title">{{$t('generalInformation')}}</div>
         <div class="card">
            <div class="card-body">
               <!--  -->
              <div class="row" v-if="!isLoading"> 
                  <div class="mb-4">
                     <v-input type="text" name="code" label="coupon.code" v-model="coupon.code" :isRequired="true"
                        :validateError="v$.coupon.code.$error? v$.coupon.code.$errors[0].$message : null"></v-input>
                  </div>
                  <div class="mb-4">
                     <v-input type="text" name="percent" label="coupon.percent" v-model="coupon.percent" :isRequired="true"
                        :validateError="v$.coupon.percent && v$.coupon.percent.$error? v$.coupon.percent.$errors[0].$message : null"></v-input>
                  </div>
                  <div class="mb-4">
                     <label for="expires_at" class="form-label is-required">{{$t('coupon.expiresAt')}}</label>
                     <Datepicker id="expires_at" v-model="coupon.expires_at" :lower-limit="new Date()" class="form-control"
                     :class="v$.coupon.expires_at && v$.coupon.expires_at.$error? 'form-controll-invalid': ''" />
                     <div class="invalid-feedback" v-if="v$.coupon.expires_at && v$.coupon.expires_at.$error">
                        {{v$.coupon.expires_at.$errors[0].$message}}
                     </div>
                  </div>
              </div>
            </div>
         </div>
      </div>
   </div>
</template>



<script>
import BodyHeading from '../../components/BodyHeading.vue'
import Input from '../../components/Input.vue';
import useValidate from '@vuelidate/core';
import { required, numeric } from '@/utilities/i18n-validators.js';
import Datepicker from 'vue3-datepicker'

   export default {
      inject: ['dayjs'],
      components: {
         BodyHeading,
         'v-input': Input,
         Datepicker
      },
      data() {
         return{
            v$: useValidate(),
            coupon: {},
            isExist:true,
            isLoading: false
         }
      },
      validations() {
         return {
            coupon: {
               code: {required},
               percent: {required, numeric},
               expires_at: {required},
            }
         }
      },
      computed: {
         currentLanguage() {
            return this.$store.state.locale.selectedLocale
         }
      },
      watch: {
         '$route': {
            immediate: true,
            handler() {
               if(this.$route.name.includes('Details')){
                  this.getCoupon(this.$route.params.id)
               }
            }
         }
      },
      methods: {
         dateTime(value){
            return this.dayjs(value).format('YYYY-MM-DD');
         },
         getCoupon(id) {
            let params = {id: id};
            this.$store.dispatch("coupon/fetch", params).then(
                (response) => {
                     this.coupon = response;
                     this.coupon.expires_at = new Date(response.expires_at);
                     this.isExist = true;
                     this.isLoading = false;
                },
                () => {
                  this.isExist = false;
                  this.$router.push({ name: 'coupons', query: { page: 1 } })
                  this.isLoading = false;
                  // let errorMessage = ''
                  // for (const err in error) {
                  //    errorMessage += `${error[err]} <br>`;
                  // }
                  // this.$notify({
                  //    text: errorMessage,
                  //    group: 'failed'
                  // });
                }
            )
         },
         create() {
            this.v$.$validate()
            if(!this.v$.$error){
               let tempCoupon = {...this.coupon};
               tempCoupon.language = this.currentLanguage;
               tempCoupon.expires_at = this.dateTime(this.coupon.expires_at)
               this.$store.dispatch("coupon/create", tempCoupon).then(
                  (data) => {
                     this.$notify({
                           text: data.message,
                           group: 'success'
                     });
                     this.$router.push({ name: 'couponDetails', params: { id: data.body.id}})
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         },
         edit() {
            this.v$.$validate()
            if(!this.v$.$error){
               this.$store.dispatch("coupon/update", { 
                  code: this.coupon.code, 
                  percent: this.coupon.percent,
                  id: this.coupon.id,
                  expires_at: this.dateTime(this.coupon.expires_at)
               }).then(
                  (message) => {
                     this.$notify({
                           text: message,
                           group: 'success'
                     });
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         }
      }
   }
</script>
