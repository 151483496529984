<template>
    <BodyHeading :borderColor="'rgb(230, 127, 216)'" :title="$t('page.sidebarsTitle')" :count="sidebars.total" 
      :multiLanguageDisabled="true" :listHeading="true" :btnTitle="$t('page.addSidebarTitle')" :createBtnLink="'pagesSidebarCreate'" :backLink="'content'" :backIcon="'image'"></BodyHeading>
    <div class="page-body-main-list d-grid">
        <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered">
                <thead>
                    <tr>
                        <th scope="col" style="width:5%"><span>#</span></th>
                        <th scope="col" style="width:45%"><span>{{$t('page.sidebarItemTitle')}}</span></th>
                        <th scope="col" style="width:5%"><span>{{$t('actions')}}</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(sidebar, index) in sidebars.data" :key="sidebar.id">
                        <td>{{index + 1 + (sidebars.current_page - 1) * ITEMS_PER_PAGE}}</td>
                        <td>{{sidebar.title}}</td>
                        <td :style="sidebar.id === ParentZindex? { zIndex: 15}: null">
                            <div class="dropdown actions-dropdown">
                                <button @click="changeParentZindex(sidebar.id)" class="btn btn-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <font-awesome-icon :icon="['fas', 'ellipsis-h']" size="sm"></font-awesome-icon>
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a @click="$router.push({ name: 'pagesSidebarDetails', params: { id: sidebar.id } })" class="dropdown-item">{{$t('edit')}}</a></li>
                                    <li><a class="dropdown-item warning" @click="deleteItem(sidebar.id)">{{$t('delete')}}</a></li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <Pagination v-if="sidebars.total>sidebars.per_page" :lastPage="sidebars.last_page"
        :activePage="sidebars.current_page" @handleClick="changePage"></Pagination>
    </div>
    
</template>


<script>
import BodyHeading from '../../components/BodyHeading.vue';
import Pagination from '../../components/Pagination.vue';
import { ITEMS_PER_PAGE } from '../../constants';

export default {
    components: {
        BodyHeading,
        Pagination
    },
    data() {
        return{
            sidebars: {},
            ParentZindex: null
        }
    },
    created() {
        this.ITEMS_PER_PAGE = ITEMS_PER_PAGE
        if(Object.entries(this.$route.query).length === 0 || this.$route.query.page === ''){
            this.$router.push({ name: 'pagesSidebars', query: { page: 1 } })
        }
        this.getSidebars()
    },
    watch: {
        '$route.query'(){
            this.getSidebars();
        },
        currentLanguage() {
            this.sidebars = {}
            this.changePage()
        }
    },
    computed: {
        currentLanguage() {
            return this.$store.state.locale.selectedLocale
        },
    },
    methods: {
        changeParentZindex(id) {
            this.ParentZindex = id
        },
        getSidebars() {
            const params = {language: this.currentLanguage, per_page:ITEMS_PER_PAGE, ...this.$route.query}
            this.$store.dispatch("page/getAllSidebars", params).then(
                (response) => {
                    this.sidebars = response;
                },
                (error) => {
                this.$notify({
                        text: error,
                        group: 'failed'
                    });
                }
            )
        },
        deleteItem(id){
            this.$swal().then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("page/deleteSidebar", id).then(
                        (message) => {
                            this.$notify({
                                text: message,
                                group: 'success'
                            });
                            this.getSidebars()
                        },
                        (error) => {
                            let errorMessage = ''
                            for (const err in error) {
                                errorMessage += `${error[err]} <br>`;
                            }
                        this.$notify({
                                text: errorMessage,
                                group: 'failed'
                            });
                        }
                    )
                } 
            })            
        },
        changePage(page=1){
            this.$router.push({ name: 'pagesSidebars', query: { page: page } })
        }
    }
}
</script>
