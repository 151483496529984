<template>
    <div class="auth-page login-page">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-10 offset-lg-1">
                    <div class="auth-page-body bg-white">
                        <div class="row">
                            <div class="col-md-6 pe-0">
                                <div class="auth-form py-5 px-5">
                                     <div class="auth-heading mt-4">
                                        <div class="row"> 
                                            <div class="mb-3">
                                                <h3>Admin Panel</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <form @submit.prevent="handleLogin">
                                        <div class="row"> 
                                            <div class="mb-4">
                                                <v-input type="email" name="email" label="email-address" v-model="user.email"
                                                :validateError="v$.user.email.$error? v$.user.email.$errors[0].$message : null"></v-input>
                                            </div>
                                        </div>
                                        <div class="row"> 
                                            <div class="mb-4">
                                                <v-input type="password" name="password" label="password" v-model="user.password"
                                                :validateError="v$.user.password.$error? v$.user.password.$errors[0].$message : null"></v-input>
                                            </div>
                                        </div>
                                        <div class="row"> 
                                            <div class="mb-4">
                                                <v-button v-if="!loading" :button-action="true">{{$t('login')}}</v-button>
                                                <v-button v-else :button-action="true" :disabled="true"><span class="px-2"><font-awesome-icon :icon="['fas', 'circle-notch']" spin></font-awesome-icon></span></v-button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-md-6 ps-0 d-none d-md-block">
                                <div class="auth-bg h-100 text-white text-center pt-5">
                                    <div class="auth-bg-overlay"></div>
                                    <h2 class="text-light fw-normal fs-1">{{$t('welcome-back')}}.</h2>
                                    
                                    <a v-if="appLocale === 'en'" @click="changeLang('de')"><span class="fi fi-de me-2"></span><i>Switch to German</i></a>
                                    <a v-if="appLocale === 'de'" @click="changeLang('en')"><span class="fi fi-gb me-2"></span><i>Zu Englisch wechseln</i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Input from '../../components/Input.vue';
import useValidate from '@vuelidate/core';
import { required, email, minLength } from '@/utilities/i18n-validators.js'


export default {
    components: {
        'v-input': Input
    },
    data(){
        return {
            v$: useValidate(),
            user: {email: '', password: ''},
            loading: false
        }
    },
    validations() {
        return {
            user: {
                email: { required, email },
                password: { required, minLength }
            }
        }
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        appLocale() {
            return this.$store.state.locale.appLocale
        }
    },
    created() {
        if(this.loggedIn){
            this.$router.push({name: 'dashboard'});
        }
    },
    methods: {
        handleLogin() {
            this.v$.$validate()
            if(!this.v$.$error){
                this.loading = true            
                this.$store.dispatch("auth/login", this.user).then(
                    (message) => {
                        this.loading = false
                        this.$notify({
                            text: message,
                            group: 'success'
                        });
                        this.$router.push({name: 'dashboard'})
                        this.loading = false
                    },
                    (error) => {
                    this.loading = false
                    this.$notify({
                            text: error,
                            group: 'failed'
                        });
                    }
                )
                        
            }
        },
        changeLang(lang){
           this.$store.commit('locale/changeActiveLanguage', lang)
        }
    }
}
</script>
