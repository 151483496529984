import axios from 'axios';
import Constants from '../constants';
import authHeader from './auth-header'

class HolidayService {
	fetch(holidayId) {
		return axios
			.get(Constants['SERVER_URL'] + `holiday/fetch/${holidayId}`, { headers: authHeader() })
			.then(response => {
				return response.data;
			})
	}
	search(params) {
		return axios
			.get(Constants['SERVER_URL'] + 'holiday/search', { params, headers: authHeader() })
			.then(response => {
				return response.data;
			})
	}
	create(holiday) {
		return axios
			.post(Constants['SERVER_URL'] + 'holiday/create', holiday, { headers: authHeader() })
			.then(response => {
				return response.data;
			})
	}
	update(urlParams, holiday) {
		return axios
			.patch(Constants['SERVER_URL'] + `holiday/update/${urlParams.id}`, holiday, { headers: authHeader() })
			.then(response => {
				return response.data;
			})
	}
	delete(holidayId) {
		return axios
			.delete(Constants['SERVER_URL'] + `holiday/delete/${holidayId}`, { headers: authHeader() })
			.then(response => {
				return response.data;
			})
	}
}

export default new HolidayService();