<template>
   <BodyHeading v-if="$route.name.includes('Create')" :borderColor="'rgb(154, 168, 181)'" :title="$t('country.addButtonTitle')" 
      @handleClick="create" :multiLanguageDisabled="true"></BodyHeading>
   
   <BodyHeading v-if="$route.name.includes('Details')" :borderColor="'rgb(154, 168, 181)'" :title="$t('country.countryDetails')" 
     v-on="isExist ? { handleClick: edit } : { handleClick: createTranslation }" :multiLanguageDisabled="false"></BodyHeading>
   <div class="page-body-main-create">
      <div class="card-wrapper">
         <div class="card-title">{{$t('generalInformation')}}</div>
         <div class="card">
            <div class="card-body">
               <!--  -->
              <div class="row" v-if="!isLoading"> 
                  <div class="mb-4">
                     <v-input type="text" name="name" label="country.name" 
                        :placeholder="defaultLanguageCountry.name" v-model="country.name" :isRequired="true"
                        :validateError="v$.country.name.$error? v$.country.name.$errors[0].$message : null"></v-input>
                  </div>
                  <div class="mb-4">
                     <v-input type="text" name="code" label="country.code" :disabled="isInputDisable"
                        :placeholder="defaultLanguageCountry.code" v-model="country.code" :isRequired="true"
                        :validateError="v$.country.code.$error? v$.country.code.$errors[0].$message : null"></v-input>
                  </div>
                  <div class="mb-4">
                     <TextEditor name="information" label="country.information" v-model="country.info"></TextEditor>
                  </div>
                  <div class="mb-4">
                     <label for="custom-price" class="form-label is-required">{{ $t('country.customInputLabel') }}</label>
                     <input v-model="country.customs_price" id="custom-price" :disabled="isInputDisable" min="0" max="100" type="number"
                        class="form-control mb-2" :placeholder="$t('country.customInputPlaceholder')"
                        :class="{ 'form-controll-invalid': v$.country.customs_price.$error }">
                     <div class="invalid-feedback" v-if="v$.country.customs_price.$error">
                        {{ v$.country.customs_price.$errors[0].$message }}
                     </div>
                  </div>
                  <div class="mb-4">
                     <v-switch v-model="country.has_ust_id" name="hasUstId" label="country.has-ust-id" :disabled="isInputDisable" @change="checkUst"></v-switch>
                  </div>
                  <div class="mb-4">
                     <v-switch v-model="country.tax_required" name="taxRequired" label="country.tax-required" :disabled="isInputDisable" @change="checkTax"></v-switch>
                  </div>
              </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import BodyHeading from '../../components/BodyHeading.vue';
import Input from '../../components/Input.vue';
import Switch from '../../components/Switch.vue';
import useValidate from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';
import { required, between } from '@/utilities/i18n-validators.js';
import TextEditor from '../../components/TextEditor.vue';
import useCkeditorHelper from '../../composables/useCkeditorHelper';

   export default {
      components: {
         BodyHeading,
         'v-input': Input,
         'v-switch': Switch,
         TextEditor
      },
      setup(){
         const {parseForEditor, parseForServer} = useCkeditorHelper()
         return {parseForEditor, parseForServer}
      },
      data() {
         return{
            v$: useValidate(),
            country: {tax_required: 0, has_ust_id: 0},
            defaultLanguageCountry: {},
            isExist:true,
            isLoading: true,
            isInputDisable: false
         }
      },
      validations() {
         return {
            country: {
               name: {required},
               code: {required: requiredIf(() => {
                     return !this.isInputDisable
                  }) 
               },
               customs_price: {required: requiredIf(() => {
                     return !this.isInputDisable
                  }),
                  between: between(0, 100)
               },
            }
         }
      },
      computed: {
         currentLanguage() {
            return this.$store.state.locale.selectedLocale
         },
         languages() {
            return this.$store.state.locale.languages
         },
         currentLocale() {
            return this.$store.state.locale.appLocale
         }
      },
      watch: {
         '$route': {
            immediate: true,
            handler() {
               if(this.$route.name.includes('Details')){
                  this.getCountry(this.$route.params.id)
               }else{
                  this.isLoading = false
               }
            }
         },
         'languages': {
            immediate: true,
            handler(){
               if(this.$route.name.includes('Details') && this.languages){
                  if(!this.isExist && this.currentLocale === this.currentLanguage){
                     this.$router.push({ name: 'countries', query: { page: 1 } })
                  }
                  this.getCountry(this.$route.params.id, this.currentLocale)
                  if(this.currentLocale !== this.currentLanguage){
                     // disable inputs
                     if(!this.isInputDisable){
                        this.isInputDisable = true;
                     }
                  }else if(this.currentLocale === this.currentLanguage && this.isInputDisable){
                     this.isInputDisable = false;
                  }
               }
            }
         },
         currentLanguage() {
            if(this.$route.name.includes('Details')){
               this.isLoading = true
               this.v$.$reset();
               this.country = {tax_required: 0, has_ust_id: 0}
               this.getCountry(this.$route.params.id, this.currentLanguage)
               this.getCountry(this.$route.params.id, this.currentLocale)
               // disable duration input
               if(this.languages){
                  if(this.currentLocale !== this.currentLanguage && !this.isInputDisable){
                        this.isInputDisable = true;
                     }else if(this.currentLocale === this.currentLanguage && this.isInputDisable){
                        this.isInputDisable = false;
                  }
               }
            }
         }
      },
      methods: {
         checkUst(){
            if(this.country.has_ust_id == 1){
               this.country.tax_required = 1
            }
         },
         checkTax(){
            if(this.country.tax_required == 0){
               if(this.country.has_ust_id == 1){
                  this.country.tax_required = 1
               }
            }
         },
         create() {
            this.v$.$validate()
            if(!this.v$.$error){
               this.country.language = this.currentLanguage;
               this.country.code = this.country.code.toUpperCase();
               this.country.info = this.parseForServer(this.country.info)
               if (+this.country.customs_price > 0)
                  this.country.tax_required = 0
               this.$store.dispatch("country/create", this.country).then(
                  (data) => {
                     // console.log(...message);
                     this.$notify({
                           text: data.message,
                           group: 'success'
                     });
                     this.$router.push({ name: 'countryDetails', params: { id: data.body.country_id}})
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                     text: errorMessage,
                     group: 'failed'
                  });
                  }
                  )
            }
         },
         createTranslation(){
            this.v$.$validate()
            if(!this.v$.$error){
               this.country.language = this.currentLanguage;
               this.country.country_id = this.defaultLanguageCountry.country_id;
               this.country.info = this.parseForServer(this.country.info)
               this.$store.dispatch("country/createTranslation", this.country).then(
                  (message) => {
                     // console.log(...message);
                     this.isExist = true;
                     this.$notify({
                        text: message,
                           group: 'success'
                        });
                        // this.$router.push({name: 'dashboard'})
                     },
                     (error) => {
                        let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                        });
                     }
               )
            }
         },
         edit() {
            this.v$.$validate()
            if(!this.v$.$error){
               if (+this.country.customs_price > 0)
                  this.country.tax_required = 0
               const country = {
                  language: this.currentLanguage,
                  country_id: this.defaultLanguageCountry.country_id,
                  name: this.country.name,
                  code: this.country.code.toUpperCase(),
                  info: this.parseForServer(this.country.info),
                  has_ust_id: this.country.has_ust_id,
                  tax_required: this.country.tax_required,
                  customs_price: this.country.customs_price
               }
               this.$store.dispatch("country/update", country).then(
                  (message) => {
                     this.$notify({
                           text: message,
                           group: 'success'
                     });
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         },
         getCountry(id, language = this.currentLanguage) {
            const params = {language: language, country_id: id}
            this.$store.dispatch("country/fetch", params).then(
               (response) => {
                   if(this.currentLocale === language){
                      this.defaultLanguageCountry = response;
                      if(response.country_info) {
                         this.defaultLanguageCountry.customs_price = response.country_info.customs_price
                         this.defaultLanguageCountry.has_ust_id = response.country_info.has_ust_id
                         this.defaultLanguageCountry.code = response.country_info.code
                         this.defaultLanguageCountry.tax_required = response.country_info.tax_required
                        } 
                        if(this.currentLanguage === this.currentLocale){
                           this.isExist = true;
                           this.country = response;
                           this.country.info = this.parseForEditor(this.country.info)
                         if(response.country_info) {
                            this.country.has_ust_id = response.country_info.has_ust_id
                            this.country.code = response.country_info.code
                            this.country.tax_required = response.country_info.tax_required
                            this.country.customs_price = response.country_info.customs_price
                         }
                         this.isLoading = false
                      }
                   }else{
                      this.isExist = true;
                      this.country = response;
                      this.country.info = this.parseForEditor(this.country.info)
                      if(response.country_info) {
                           this.country.customs_price = response.country_info.customs_price
                           this.country.has_ust_id = response.country_info.has_ust_id
                           this.country.code = response.country_info.code
                           this.country.tax_required = response.country_info.tax_required
                        }
                      this.isLoading = false
                   }
                },
                () => {
                  this.isExist = false;
                  if(this.currentLocale === this.currentLanguage){
                     this.$router.push({ name: 'countries', query: { page: 1 } })
                  }
                  this.isLoading = false;
                  // let errorMessage = ''
                  // for (const err in error) {
                  //    errorMessage += `${error[err]} <br>`;
                  // }
                  // this.$notify({
                  //    text: errorMessage,
                  //    group: 'failed'
                  // });
                }
            )
        },
      }
   }
</script>
