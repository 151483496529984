import DownloadCenter from '../../services/download.download'

const download = {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        create(_, download) {
            return DownloadCenter.create(download).then(
                data => {
                    return Promise.resolve(data);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        createTranslation(_, {urlParams, download}) {
            return DownloadCenter.addTranslation(urlParams, download).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        update(_, {urlParams, download}) {
            return DownloadCenter.update(urlParams, download).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        delete(_, urlParams) {
            return DownloadCenter.delete(urlParams).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        deleteValue(_, urlParams) {
            return DownloadCenter.deleteValue(urlParams).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        getAll(_, queryParams) {
            return DownloadCenter.getAll(queryParams).then(
                data => {
                    return Promise.resolve(data.body);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        fetch(_, urlParams) {
            return DownloadCenter.fetch(urlParams).then(
                data => {
                    return Promise.resolve(data.body);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
    },
    
    mutations: {}
}

export default download
