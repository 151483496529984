<template>
   <div class="page-body-heading p-0" style="border-bottom-color: rgb(230, 127, 216)"></div>
   <div class="page-body-main-create mt-3">
      <div class="card-wrapper">
         <h1 class="h2 mb-5">{{$t('content.headTitle')}}</h1>
         <div class="row">
            <div class="col-lg-2">
               <nav class="nav nav-stepper nav-stepper-vertical">
                     <a class="nav-link" @click="filter = 0" :class="{'active': filter == 0}">{{$t('content.all')}}</a>
                     <a class="nav-link" @click="filter = 1" :class="{'active': filter == 1}">{{$t('content.homePage')}}</a>
                     <a class="nav-link" @click="filter = 2" :class="{'active': filter == 2}">{{$t('content.otherPages')}}</a>
                     <a class="nav-link" @click="filter = 3" :class="{'active': filter == 3}">{{$t('content.navigations')}}</a>
               </nav>
            </div>
            <div class="col-lg-10">
               <div class="card">
                  <div class="card-body card-body-grid">
                     <a @click="$router.push({ name: 'homeSliders', query: { page: 1 }})" v-show="filter == 0 || filter == 1" class="item-thumbnail">
                        <div class="item-thumbnail-icon">
                           <span>
                              <font-awesome-icon :icon="['fas', 'home']"></font-awesome-icon>
                           </span>
                        </div> 
                        <div>
                           {{$t('sidebar.homeSliders')}}
                        </div>
                     </a>
                     <a @click="$router.push({ name: 'homeSections', query: { page: 1 }})" v-show="filter == 0 || filter == 1" class="item-thumbnail">
                        <div class="item-thumbnail-icon">
                           <span>
                              <font-awesome-icon :icon="['fas', 'home']"></font-awesome-icon>
                           </span>
                        </div> 
                        <div>
                           {{$t('sidebar.homeSections')}}
                        </div>
                     </a>
                     <a @click="$router.push({ name: 'homes', query: { page: 1 }})" v-show="filter == 0 || filter == 1" class="item-thumbnail">
                        <div class="item-thumbnail-icon">
                           <span>
                              <font-awesome-icon :icon="['fas', 'home']"></font-awesome-icon>
                           </span>
                        </div> 
                        <div>
                           {{$t('sidebar.homePages')}}
                        </div>
                     </a>
                     <!-- <a @click="$router.push({ name: 'contactUsDetails'})" v-show="filter == 0 || filter == 2" class="item-thumbnail">
                        <div class="item-thumbnail-icon">
                           <span>
                              <font-awesome-icon :icon="['fas', 'envelope']"></font-awesome-icon>
                           </span>
                        </div> 
                        <div>
                           {{$t('sidebar.contactUs')}}
                        </div>
                     </a> -->
                     <!-- <a @click="$router.push({ name: 'aboutUsDetails'})" v-show="filter == 0 || filter == 2" class="item-thumbnail">
                        <div class="item-thumbnail-icon">
                           <span>
                              <font-awesome-icon :icon="['fas', 'user-circle']"></font-awesome-icon>
                           </span>
                        </div> 
                        <div>
                           {{$t('sidebar.aboutUs')}}
                        </div>
                     </a> -->
                     <a @click="$router.push({ name: 'pages', query: { page: 1 }})" v-show="filter == 0 || filter == 2" class="item-thumbnail">
                        <div class="item-thumbnail-icon">
                           <span>
                              <font-awesome-icon :icon="['fas', 'file']"></font-awesome-icon>
                           </span>
                        </div> 
                        <div>
                           {{$t('sidebar.pages')}}
                        </div>
                     </a>
                     <a @click="$router.push({ name: 'pagesSidebars', query: { page: 1 }})" v-show="filter == 0 || filter == 3" class="item-thumbnail">
                        <div class="item-thumbnail-icon">
                           <span>
                              <font-awesome-icon :icon="['fas', 'align-left']"></font-awesome-icon>
                           </span>
                        </div> 
                        <div>
                           {{$t('sidebar.pageSidebars')}}
                        </div>
                     </a>
                     <a @click="$router.push({ name: 'footerDetails'})" v-show="filter == 0 || filter == 3" class="item-thumbnail">
                        <div class="item-thumbnail-icon">
                           <span>
                              <font-awesome-icon :icon="['fas', 'align-justify']"></font-awesome-icon>
                           </span>
                        </div> 
                        <div>
                           {{$t('sidebar.footer')}}
                        </div>
                     </a>
                     <a @click="$router.push({ name: 'downloads'})" v-show="filter == 0 || filter == 3" class="item-thumbnail">
                        <div class="item-thumbnail-icon">
                           <span>
                              <font-awesome-icon :icon="['fas', 'align-justify']"></font-awesome-icon>
                           </span>
                        </div> 
                        <div>
                           <span>{{$t('sidebar.download')}}</span>
                        </div>
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
   export default {
      data(){
         return{
               filter: 0
         }
      }
   }
</script>