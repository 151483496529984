import axios from 'axios';
// import store from 'your/store/path/store'
import router from '../router'

// export default function setup() {
//     axios.interceptors.request.use(function(config) {
//         const token = store.getters.token;
//         if(token) {
//             config.headers.Authorization = `Bearer ${token}`;
//         }
//         return config;
//     }, function(err) {
//         return Promise.reject(err);
//     });
// }

export default function setup() {
    axios.interceptors.response.use(function(response) {
        return response;
    }, function(error) {
        if(error.response.status == 401){
            localStorage.removeItem('user');
            router.push({ name: 'login'});
        }
        return Promise.reject(error);
    });
}