import holidayService from '../../services/holiday.service'

const holiday = {
	namespaced: true,

	state: {},

	getters: {},

	actions: {
		search(_, queryParams) {
			return holidayService.search(queryParams).then(
				data => {
					return Promise.resolve(data.body);
				},
				error => {
					return Promise.reject(error);
				}
			)
		},
		fetch(_, holidayId) {
			return holidayService.fetch(holidayId).then(
				data => {
					return Promise.resolve(data.body);
				},
				error => {
					return Promise.reject(error.response.data.errors);
				}
			)
		},
		create(_, holiday) {
			return holidayService.create(holiday).then(
				data => {
					return Promise.resolve(data);
				},
				error => {
					return Promise.reject(error.response.data.errors);
				}
			)
		},
		update(_, { urlParams, holidayDate }) {
			return holidayService.update(urlParams, holidayDate).then(
				data => {
					return Promise.resolve(data.message);
				},
				error => {
					return Promise.reject(error.response.data.errors);
				}
			)
		},
		delete(_, holidayId) {
			return holidayService.delete(holidayId).then(
				data => {
					return Promise.resolve(data.message);
				},
				error => {
					return Promise.reject(error.response.data.errors);
				}
			)
		}
	},

	mutations: {}
}

export default holiday
