<template>
    <teleport to="#modal-target">
        <div class="modal fade" ref="modal" tabindex="-1" aria-labelledby="modal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{title}}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                    <slot></slot>
                    </div>
                    <div class="modal-footer">
                        <v-button :buttonStop="true" size="sm" data-bs-dismiss="modal" :disabled="buttonDisabled || uploaderStatus == 2">{{ $t('cancel') }}</v-button>
                        <v-button :buttonAction="true" size="sm" @click="$emit('handleClick')" :disabled="buttonDisabled || uploaderStatus == 2">{{ $t('save') }}</v-button>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
    inheritAttrs: false,
    props:{
        'title': {
            type: String
        },
        'buttonDisabled': {
            default: false
        }
    },
    computed: {
        uploaderStatus() {
            return this.$store.getters['fileUploader/getUploadStatus'];
        },
    },
    // tell me when modal closed
    mounted(){
        this.$refs.modal.addEventListener("hidden.bs.modal", this.modalClosed)
    },
    data(){
        return{
            modal: null
        }
    },
    methods: {
        initiateModal(){
            this.modal = new Modal(this.$refs.modal);
            this.modal.show();
        },
        hide(){
            this.modal.hide();
        },
        modalClosed(){
            this.$emit('modalClosed');
        }
    }
}
</script>