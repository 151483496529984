import axios from 'axios';
import Constants from '../constants';
import authHeader from './auth-header'


class CouponService {
    fetch(params) {
      return axios
          .get(Constants['SERVER_URL']+'coupon/fetch/'+`${params.id}`, {headers:authHeader()})
          .then(response => {
              return response.data;
          })
    }
    getAll(params) {
        return axios
            .get(Constants['SERVER_URL']+'coupon/search', {params: params,headers:authHeader()})
            .then(response => {
                return response.data;
            })
    }
    create(user) {
        return axios
          .post(Constants['SERVER_URL']+'coupon/create', user, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    edit(user){
      return axios
        .post(Constants['SERVER_URL']+'coupon/update', user, {headers:authHeader()})
        .then(response => {    
          return response.data;
      })
    }
    delete(id) {
        return axios
          .delete(Constants['SERVER_URL']+'coupon/delete/'+ id, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
}



export default new CouponService();