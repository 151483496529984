import axios from 'axios';
import Constants from '../constants';
import authHeader from './auth-header'


class LocaleService {
    getAllLanguages() {
        return axios
            .get(Constants['SERVER_URL']+'languages/app/languages', {headers:authHeader()})
            .then(response => {
                return response.data;
            })
    }
}



export default new LocaleService();