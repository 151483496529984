import axios from 'axios';
import Constants from '../constants';
import authHeader from './auth-header'

class ProductService {
  attachPortfolio(product) {
    return axios
      .post(Constants['SERVER_URL'] + 'products/attach/portfolio/' + `${product.product_id}`, product, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
  getPortfolio(urlParams, queryParams) {
    return axios
      .get(Constants['SERVER_URL'] + 'products/get/portfolio/' + `${urlParams.product_id}`, { params: queryParams, headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
  attachTechnicalInfo(product) {
    return axios
      .post(Constants['SERVER_URL'] + 'products/attach/technical-info/' + `${product.product_id}`, product, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
  getTechnicalInfo(urlParams, queryParams) {
    return axios
      .get(Constants['SERVER_URL'] + 'products/get/technical-info/' + `${urlParams.product_id}`, { params: queryParams, headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
  activate(product_id) {
    return axios
      .get(Constants['SERVER_URL'] + 'products/activate/' + `${product_id}`, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
  deactivate(product_id) {
    return axios
      .get(Constants['SERVER_URL'] + 'products/deactivate/' + `${product_id}`, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
  fetch(urlParams) {
    return axios
      .get(Constants['SERVER_URL'] + 'products/fetch/' + `${urlParams.product_id}/${urlParams.language}`, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
  getAll(queryParams) {
    return axios
      .get(Constants['SERVER_URL'] + 'products/search', { params: queryParams, headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
  create(product) {
    return axios
      .post(Constants['SERVER_URL'] + 'products/create', product, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
  createTranslation(urlParams, product) {
    return axios
      .post(Constants['SERVER_URL'] + 'products/add/translation/' + urlParams.product_id, product, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
  edit(urlParams, product) {
    return axios
      .patch(Constants['SERVER_URL'] + 'products/update/' + `${urlParams.product_id}/${urlParams.language}`, product, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
  duplicate(product_id) {
    return axios
      .get(Constants['SERVER_URL'] + 'products/duplicate/' + product_id, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
  delete(product_id) {
    return axios
      .delete(Constants['SERVER_URL'] + 'products/delete/' + product_id, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
  getServices(urlParams) {
    return axios
      .get(Constants['SERVER_URL'] + 'products/get/services/' + urlParams.product_id, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
  setServices(urlParams, serviceIdList) {
    return axios
      .post(Constants['SERVER_URL'] + 'products/attach/services/' + urlParams.product_id, serviceIdList, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
  getExcludedServiceValues(urlParams) {
    return axios
      .get(Constants['SERVER_URL'] + 'products/exclude/services/' + urlParams.product_id, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
  setExcludedServiceValues(urlParams, excludedValues) {
    return axios
      .post(Constants['SERVER_URL'] + 'products/exclude/services/' + urlParams.product_id, excludedValues, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
  getWorkingDays(urlParams) {
    return axios
      .get(Constants['SERVER_URL'] + 'products/get/working-days/' + `${urlParams.product_id}`, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
  setWorkingDays(urlParams, durationsList) {
    return axios
      .post(Constants['SERVER_URL'] + 'products/attach/working-days/' + urlParams.product_id, durationsList, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
  getMenus(urlParams) {
    return axios.get(Constants['SERVER_URL'] + 'products/get/menus/' + urlParams.product_id, { headers: authHeader() })
    .then(response => {
      return response.data
    })
  }
  setMenus(urlParams, menus) {
    return axios
      .post(Constants['SERVER_URL'] + 'products/attach/menus/' + urlParams.product_id, menus, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
  getOptions(urlParams) {
    return axios.get(Constants['SERVER_URL'] + `products/option/fetch/with-values/${urlParams.product_id}/${urlParams.language}`, { headers: authHeader() })
    .then(response => {
      return response.data
    })
  }
  setOptions(urlParams, productOptions) {
    return axios
    .post(Constants['SERVER_URL'] + 'products/option/attach/' + `${urlParams.product_id}/${urlParams.language}`, productOptions, { headers: authHeader() })
    .then(response => {
      return response.data
    })
  }
  setOptionValues(urlParams, productOptionValues) {
    return axios
    .post(Constants['SERVER_URL'] + 'products/option-values/attach/' + `${urlParams.product_id}`, productOptionValues, { headers: authHeader() })
    .then(response => {
      return response.data
    })
  }
  setTranslatedOptions(urlParams, productOptions) {
    return axios
    .post(Constants['SERVER_URL'] + 'products/option/add/translation/' + `${urlParams.product_id}/${urlParams.language}`, productOptions, { headers: authHeader() })
    .then(response => {
      return response.data
    })
  }
  getExcludedOptionValues(urlParams) {
    return axios.get(Constants['SERVER_URL'] + `products/option-values/exclude/${urlParams.product_id}`, { headers: authHeader() })
    .then(response => {
      return response.data
    })
  }
  setExcludedOptionValues(urlParams, excludedValues) {
    return axios
    .post(Constants['SERVER_URL'] + 'products/option-values/exclude/' + `${urlParams.product_id}`, excludedValues, { headers: authHeader() })
    .then(response => {
      return response.data
    })
  }
  getDiscounts(urlParams, queryParams) {
    return axios.get(Constants['SERVER_URL'] + `products/all/discount/${urlParams.product_id}`, { params:queryParams, headers: authHeader() })
    .then(response => {
      return response.data
    })
  }
  setDiscounts(urlParams, discounts) {
    return axios.post(Constants['SERVER_URL'] + `products/attach/discount/${urlParams.product_id}`, discounts, { headers: authHeader() })
    .then(response => {
      return response.data
    })
  }
  deleteDiscounts(urlParams) {
    return axios.delete(Constants['SERVER_URL'] + `products/delete/discount/${urlParams.product_id}`, { headers: authHeader() })
    .then(response => {
      return response.data
    })
  }
}

export default new ProductService()
