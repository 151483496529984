import AuthService from '../../services/auth.service'
import dayjs from 'dayjs'

const latestUserInfo = JSON.parse(localStorage.getItem('user'));

function checkUserInfo(item){
    const now = dayjs(new Date()).format('YYYY-MM-DD HH:MM:SS');
    if(item){
        // console.log(now);
        // console.log(item.expires_at);
        if(now > item.expires_at){
            localStorage.removeItem('user');
            return null;
        }else{
            return item
        }
    }
    return null;
}
const user = checkUserInfo(latestUserInfo);
const initialState = user ? {status:{loggedIn: true}, user} : {status:{loggedIn: false},user: null};
const auth = {
    namespaced: true,
    state: initialState,
    getters: {
        getUserInfo(state){
            return state.user.user
        }
    },
    actions: {
        login({commit}, userInfo) {
            return AuthService.login(userInfo).then(
                data => {
                    // console.log(data);
                    // return;
                    commit('loginSuccess', data.body);
                    return Promise.resolve(data.message);
                },
                error => {
                    // console.log(error.response.data.errors);
                    commit('loginFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        logout({commit}){
            AuthService.logout();
            commit('logout')
        }
    },
    mutations: {
        loginSuccess(state, user){
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
            if(localStorage.getItem('user')){
                localStorage.setItem('user', null);
            }
        },
        logout(state){
            state.status.loggedIn = false;
            state.user = null;
        }
    }
}


export default auth
