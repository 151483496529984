import optionService from '../../services/option.service'


const option = {
    namespaced: true,
    state: {
    },
    getters: {
        
    },
    actions: {
        create(_,option){
            return optionService.create(option).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        createTranslation(_,option){
            return optionService.createTranslation(option).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        update(_,option){
            return optionService.edit(option).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        delete(_,id){
            return optionService.delete(id).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        deleteOptionValue(_,id){
            return optionService.deleteOptionValue(id).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        getAll(_,params){
            return optionService.getAll(params).then(
                data => {
                    return Promise.resolve(data.body);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        fetch(_,params){
            return optionService.fetch(params).then(
                data => {
                    return Promise.resolve(data.body);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        }
    },
    mutations: {
        
    }
}

export default option
