import footerService from '../../services/footer.service'


const footer = {
    namespaced: true,
    state: {
    },
    getters: {
        
    },
    actions: {
        update(_,[footer, params]){
            return footerService.edit([footer, params]).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        fetch(_,params){
            return footerService.fetch(params).then(
                data => {
                    return Promise.resolve(data.body);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        }
    },
    mutations: {
        
    }
}

export default footer
