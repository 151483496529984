import axios from 'axios';
import Constants from '../constants';
import authHeader from './auth-header'


class DurationService {
    fetch(params) {
      return axios
          .get(Constants['SERVER_URL']+'durations/fetch/'+`${params.duration_id}/${params.language}`, {headers:authHeader()})
          .then(response => {
              return response.data;
          })
    }
    getAll(params) {
        return axios
            .get(Constants['SERVER_URL']+'durations/search', {params: params,headers:authHeader()})
            .then(response => {
                return response.data;
            })
    }
    create(duration) {
        return axios
          .post(Constants['SERVER_URL']+'durations/create', duration, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    createTranslation(duration) {
        return axios
          .post(Constants['SERVER_URL']+'durations/add/translation/'+ duration.duration_id, duration, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    edit(duration){
      return axios
        .patch(Constants['SERVER_URL']+'durations/update/'+ `${duration.duration_id}/${duration.language}`, duration, {headers:authHeader()})
        .then(response => {    
          return response.data;
      })
    }
    delete(duration_id) {
        return axios
          .delete(Constants['SERVER_URL']+'durations/delete/'+ duration_id, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
}



export default new DurationService();