<template>
  <notifications group="success" classes="appear-toast toast-sucess"/>
  <notifications group="failed" classes="appear-toast toast-failed"/>
  <router-view></router-view>
</template>



<script>
  export default {
    name: 'App',
    
    computed: {
        appLocale(){
          return this.$store.state.locale.appLocale
        }
    },

    watch: {
      appLocale(newLanguageStatus){
         this.$i18n.locale = newLanguageStatus;
      }
    }
  }
</script>
<style>
</style>
