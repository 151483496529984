<template>
    <BodyHeading :borderColor="'rgb(248, 137, 98)'" :title="$t('coupon.coupons')" :count="coupons.total" 
      :multiLanguageDisabled="true" :listHeading="true" :btnTitle="$t('coupon.addNewButton')" :createBtnLink="'couponCreate'" ></BodyHeading>
    <div class="page-body-main-list d-grid grid-columns">
        <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" style="width: 5%"><span>#</span></th>
                            <th scope="col"><span>{{$t('coupon.code')}}</span></th>
                            <th scope="col"><span>{{$t('coupon.percent')}}</span></th>
                            <th scope="col"><span>{{$t('coupon.expiresAt')}}</span></th>
                            <th scope="col" style="width: 5%"><span>{{$t('actions')}}</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(coupon, index) in coupons.data" :key="coupon.id">
                            <td>{{index + 1 + (coupons.current_page - 1) * ITEMS_PER_PAGE}}</td>
                            <td>{{coupon.code}}</td>
                            <td>{{coupon.percent}}</td>
                            <td>{{dateTime(coupon.expires_at)}}</td>
                            <td :style="coupon.id === ParentZindex? { zIndex: 2}: null">
                                <div class="dropdown actions-dropdown">
                                    <button @click="changeParentZindex(coupon.id)" class="btn btn-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <font-awesome-icon :icon="['fas', 'ellipsis-h']" size="sm"></font-awesome-icon>
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a @click="$router.push({ name: 'couponDetails', params: { id: coupon.id } })" class="dropdown-item">{{$t('edit')}}</a></li>
                                        <li><a class="dropdown-item warning" @click="deleteItem(coupon.id)">{{$t('delete')}}</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
            </table>
        </div>
        
        <!-- Filter Box -->
        <div class="filter-bar">
            <aside :class="{'opened': filterOpened}">
                <nav>
                    <ul>
                        <li><button :class="{'active': filterOpened}" @click="filterOpened = !filterOpened"><font-awesome-icon :icon="['fas', 'filter']"></font-awesome-icon></button></li>
                    </ul>
                </nav>
                <div class="filter-content">
                    <div class="filter-panel">
                        <div class="headline">
                            <h3>{{$t('filters.title')}}</h3>
                            <button @click="filterOpened = !filterOpened"><font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon></button>
                        </div>
                        <div class="content">
                            <div>
                                <div>
                                    <div class="element">
                                        <v-input type="text" name="title" label="coupon.code" v-model="code" @keyup="searchQueue()"></v-input>
                                    </div>
                                    <div class="element">
                                        <v-input type="text" name="title" label="coupon.fromPercent" v-model="from_percent" @keyup="searchQueue()"></v-input>
                                    </div>
                                    <div class="element">
                                        <v-input type="text" name="title" label="coupon.toPercent" v-model="to_percent" @keyup="searchQueue()"></v-input>
                                    </div>
                                    <div class="element">
                                        <label for="from_date" class="form-label">{{$t('coupon.fromDate')}}</label>
                                        <Datepicker id="from_date" v-model="from_date" class="form-control" :clearable="true" @update:modelValue="searchQueue()" />
                                    </div>
                                    <div class="element">
                                        <label for="to_date" class="form-label">{{$t('coupon.toDate')}}</label>
                                        <Datepicker id="to_date" v-model="to_date" class="form-control" :clearable="true" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </div>
        <!-- // Filter Box -->

        <Pagination v-if="coupons.total>coupons.per_page" :lastPage="coupons.last_page"
        :activePage="coupons.current_page" @handleClick="changePage"></Pagination>
    </div>
    
</template>


<script>
import BodyHeading from '../../components/BodyHeading.vue';
import Pagination from '../../components/Pagination.vue';
import Input from '../../components/Input.vue';
import Datepicker from 'vue3-datepicker'
import { ITEMS_PER_PAGE } from '../../constants';

export default {
    inject: ['dayjs'],
    components: {
        BodyHeading,
        Pagination,
        'v-input': Input,
        Datepicker
    },
    data() {
        return{
            timer: null,
            filterOpened: false,
            code: null,
            from_percent: null,
            to_percent: null,
            from_date: null,
            to_date: null,
            coupons: {},
            ParentZindex: null
        }
    },
    created() {
        this.ITEMS_PER_PAGE = ITEMS_PER_PAGE
        if(Object.entries(this.$route.query).length === 0 || this.$route.query.page === ''){
            this.$router.push({ name: 'coupons', query: { page: 1 } })
        }
        this.getCoupons()
        const { code, from_percent, to_percent, from_date, to_date } = this.$route.query
        this.code = code ? code : null
        this.from_percent = from_percent ? from_percent : null
        this.to_percent = to_percent ? to_percent : null
        this.from_date = from_date ? from_date : null
        this.to_date = to_date ? to_date : null
        if (code || from_percent || to_percent || from_date || to_date) {
            this.filterOpened = true
        }
    },
    watch: {
        '$route.query'(){
            this.getCoupons()
        },
        currentLanguage() {
            this.getCoupons()
        }
    },
    computed: {
        currentLanguage() {
            return this.$store.state.locale.selectedLocale
        },
    },
    methods: {
        searchQueue(){
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                const query = {
                    page: 1,
                    ...(this.code && {code: this.code}),
                    ...(this.from_percent && {from_percent: this.from_percent}),
                    ...(this.to_percent && {to_percent: this.to_percent}),
                    ...(this.from_date && {from_date: this.from_date}),
                    ...(this.to_date && {to_date: this.to_date}),
                }
                this.$router.push({
                    query
                })
            }, 500);
        },
        dateTime(value){
            return this.dayjs(value).format('YYYY-MM-DD');
        },
        changeParentZindex(id) {
            this.ParentZindex = id
        },
        getCoupons() {
            const params = {language: this.currentLanguage, per_page:ITEMS_PER_PAGE, ...this.$route.query}
            this.$store.dispatch("coupon/getAll", params).then(
                (response) => {
                    this.coupons = response;
                },
                (error) => {
                this.$notify({
                        text: error,
                        group: 'failed'
                    });
                }
            )
        },
        deleteItem(id){
            
            this.$swal().then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("coupon/delete", id).then(
                        (message) => {
                            this.$notify({
                                text: message,
                                group: 'success'
                            });
                            this.getCoupons()
                        },
                        (error) => {
                            let errorMessage = ''
                            for (const err in error) {
                                errorMessage += `${error[err]} <br>`;
                            }
                        this.$notify({
                                text: errorMessage,
                                group: 'failed'
                            });
                        }
                    )
                } 
            })            
        },
        changePage(page){
            this.$router.push({ name: 'coupons', query: { ...this.$route.query, page: page } })
        }
    }
}
</script>
