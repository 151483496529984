import productService from '../../../../services/product.service'

const productPortfolio = {
    namespaced: true,
    actions: {
        getPortfolio(_, { urlParams, queryParams }) {
            return productService.getPortfolio(urlParams, queryParams).then(
                data => {
                    return Promise.resolve(data.body)
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        attachPortfolio(_, product) {
            return productService.attachPortfolio(product).then(
                data => {
                    return Promise.resolve(data)
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        }
    }
}

export default productPortfolio
