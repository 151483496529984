<template>
   <BodyHeading v-if="$route.name.includes('Create')" :borderColor="'rgb(154, 168, 181)'" :title="$t('postMethod.addButtonTitle')" 
      @handleClick="create" :multiLanguageDisabled="true"></BodyHeading>
   
   <BodyHeading v-if="$route.name.includes('Details')" :borderColor="'rgb(154, 168, 181)'" :title="$t('postMethod.postMethodsDetails')" 
     v-on="isExist ? { handleClick: edit } : { handleClick: createTranslation }" :multiLanguageDisabled="false"></BodyHeading>
   <div class="page-body-main-create">
      <div class="card-wrapper">
         <div class="card-title">{{$t('generalInformation')}}</div>
         <div class="card">
            <div class="card-body">
               <!--  -->
              <div class="row" v-if="!isLoading"> 
                  <div class="mb-4">
                     <v-input type="text" name="title" label="postMethod.title" :placeholder="defaultLanguagePostMethod.title" v-model="postMethod.title" :isRequired="true"
                        :validateError="v$.postMethod.title.$error? v$.postMethod.title.$errors[0].$message : null"></v-input>
                  </div>
              </div>
            </div>
         </div>
      </div>
   </div>
</template>



<script>
import BodyHeading from '../../components/BodyHeading.vue'
import Input from '../../components/Input.vue';
import useValidate from '@vuelidate/core';
import { required } from '@/utilities/i18n-validators.js'


   export default {
      components: {
         BodyHeading,
         'v-input': Input
      },
      data() {
         return{
            v$: useValidate(),
            postMethod: {},
            defaultLanguagePostMethod: {},
            isExist:true,
            isLoading: true
         }
      },
      validations() {
         return {
            postMethod: {
               title: {required},
            }
         }
      },
      computed: {
         currentLanguage() {
            return this.$store.state.locale.selectedLocale
         },
         languages() {
            return this.$store.state.locale.languages
         },
         currentLocale() {
            return this.$store.state.locale.appLocale
         }
      },
      watch: {
         '$route': {
            immediate: true,
            handler() {
               if(this.$route.name.includes('Details')){
                  this.getPostMethod(this.$route.params.id)
               }else{
                  this.isLoading = false
               }
            }
         },
         'languages': {
            immediate: true,
            handler(){
               if(this.$route.name.includes('Details') && this.languages){
                  if(!this.isExist && this.currentLocale === this.currentLanguage){
                     this.$router.push({ name: 'postMethods', query: { page: 1 } })
                  }
                  this.getPostMethod(this.$route.params.id, this.currentLocale)
               }
            }
         },
         currentLanguage() {
            if(this.$route.name.includes('Details')){
               this.isLoading = true
               this.v$.$reset();
               this.postMethod = {}
               this.getPostMethod(this.$route.params.id, this.currentLanguage)
               this.getPostMethod(this.$route.params.id, this.currentLocale)
            }
         }
      },
      methods: {
         create() {
            this.v$.$validate()
            if(!this.v$.$error){
               this.postMethod.language = this.currentLanguage;
               // console.log(this.postMethod)
               this.$store.dispatch("postMethod/create", this.postMethod).then(
                  (data) => {
                     // console.log(...message);
                     this.$notify({
                           text: data.message,
                           group: 'success'
                     });
                     this.$router.push({ name: 'postMethodDetails', params: { id: data.body.post_method_id}})
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         },
         createTranslation(){
            this.v$.$validate()
            if(!this.v$.$error){
               this.postMethod.language = this.currentLanguage;
               this.postMethod.post_method_id = this.defaultLanguagePostMethod.post_method_id;
               // console.log(this.postMethod)
               this.$store.dispatch("postMethod/createTranslation", this.postMethod).then(
                  (message) => {
                     // console.log(...message);
                     this.isExist = true;
                     this.$notify({
                           text: message,
                           group: 'success'
                     });
                     // this.$router.push({name: 'dashboard'})
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         },
         edit() {
            this.v$.$validate()
            if(!this.v$.$error){
              this.postMethod.language = this.currentLanguage;
              this.postMethod.post_method_id = this.defaultLanguagePostMethod.post_method_id;
               // console.log(this.postMethod)
               this.$store.dispatch("postMethod/update", this.postMethod).then(
                  (message) => {
                     // console.log(...message);
                     this.$notify({
                           text: message,
                           group: 'success'
                     });
                     // this.$router.push({name: 'dashboard'})
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         },
         getPostMethod(id, language = this.currentLanguage) {
            // console.log(this.currentLanguage);
            const params = {language: language, post_method_id: id}
            this.$store.dispatch("postMethod/fetch", params).then(
                (response) => {
                   if(this.currentLocale === language){
                      this.defaultLanguagePostMethod = response;
                      if(this.currentLanguage === this.currentLocale){
                         this.isExist = true;
                         this.postMethod = response;
                         this.isLoading = false
                      }
                   }else{
                      this.isExist = true;
                      this.postMethod = response;
                      this.isLoading = false
                   }
                },
                () => {
                  this.isExist = false;
                  if(this.currentLocale === this.currentLanguage){
                     this.$router.push({ name: 'postMethods', query: { page: 1 } })
                  }
                  this.isLoading = false;
                  // let errorMessage = ''
                  // for (const err in error) {
                  //    errorMessage += `${error[err]} <br>`;
                  // }
                  // this.$notify({
                  //    text: errorMessage,
                  //    group: 'failed'
                  // });
                }
            )
        },
      }
   }
</script>
