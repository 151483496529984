<template>
    <nav class="navbar navbar-expand-lg h-100">
        <div class="container-fluid flex-row-reverse">
            <!-- <a class="navbar-brand" href="#">Navbar</a> -->
            <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDarkDropdown" aria-controls="navbarNavDarkDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDarkDropdown"> -->
                <ul class="navbar-nav">
                    <li class="nav-item dropstart">
                        <a class="nav-link" @click="this.$router.push({name: 'orders', query: { page: 1 }})">
                            <span>
                                <font-awesome-icon :icon="['far', 'bell']" size="2x"></font-awesome-icon>
                                <span class="badge badge-order">{{unseenOrdersCount}}</span>
                            </span>
                        </a>
                        <!-- <a class="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <font-awesome-icon :icon="['far', 'bell']" size="2x"></font-awesome-icon>
                        </a> -->
                        <!-- <ul class="dropdown-menu" aria-labelledby="navbarDarkDropdownMenuLink">
                            <li><a class="dropdown-item" href="#">Action</a></li>
                            <li><a class="dropdown-item" href="#">Another action</a></li>
                            <li><a class="dropdown-item" href="#">Something else here</a></li>
                        </ul> -->
                    </li>
                </ul>
            <!-- </div> -->
        </div>
    </nav>
</template>

<script>
export default {
    computed:{
        unseenOrdersCount(){
            return this.$store.getters['order/getUnseenOrdersCount']
        }
    }
}
</script>
