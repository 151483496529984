import axios from 'axios';
import Constants from '../constants';
import authHeader from './auth-header'


class OrderService {
    fetch(params) {
      return axios
          .get(Constants['SERVER_URL']+'invoices/fetch/'+`${params.id}`, {headers:authHeader()})
          .then(response => {
              return response.data;
          })
    }
    getAll(params) {
      return axios
            .get(Constants['SERVER_URL']+'invoices/search', {params: params,headers:authHeader()})
            .then(response => {
                return response.data;
            })
    }
    getCsv(params) {
      return axios
            .get(Constants['SERVER_URL']+'invoices/get_csv/'+`${params.id}`, {headers:authHeader()})
            .then(response => {
                return response.data;
            })
    }
    getAddresses(params){
      return axios
            .get(Constants['SERVER_URL']+'invoices/addresses/'+`${params.id}`, {headers:authHeader()})
            .then(response => {
                return response.data;
            })
    }
    getAddressProducts(params){
      return axios
            .get(Constants['SERVER_URL']+'invoices/addresses/products/'+`${params.id}`, {headers:authHeader()})
            .then(response => {
                return response.data;
            })
    }
    setNeededImagesCount(payment_intent, body) {
        return axios
          .post(`${Constants['SERVER_URL']}invoices/products/number-of-image/${payment_intent}`, body, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    getImage(params) {
        return axios
          .get(Constants['SERVER_URL']+'invoices/addresses/products/images/fetch/'+ `${params.product_id}`+ '/' +`${params.image_id}`, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    deleteImage(imageId) {
        return axios
          .delete(`${Constants['SERVER_URL']}invoices/addresses/products/images/delete/${imageId}`, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    getOrdersCount() {
        return axios
          .get(Constants['SERVER_URL']+'invoices/fetch/unseen/count', {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    downloadCSV(filename) {
        return axios.get(Constants['SERVER_URL'] + `invoices/addresses/products/xml/download/${filename}`, { headers: authHeader(), responseType: 'blob' })
    }
    cancelOrder(paymentIntent) {
        return axios
          .post(Constants['SERVER_URL'] + `invoices/cancel/${paymentIntent}`,{} , { headers: authHeader() })
          .then(response => {    
            return response.data;
          })
    }
    completeOrder(paymentIntent) {
        return axios
          .post(Constants['SERVER_URL'] + `invoices/change/status/${paymentIntent}`,{} , { headers: authHeader() })
          .then(response => {    
            return response.data;
          })
    }
    getLogs(paymentIntent) {
        return axios
          .get(`${Constants['SERVER_URL']}invoices/email/logs/${paymentIntent}`, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
}



export default new OrderService();
