import taxService from '../../services/tax.service'


const tax = {
    namespaced: true,
    state: {
    },
    getters: {
        
    },
    actions: {
        // create(_,tax){
        //     return taxService.create(tax).then(
        //         data => {
        //             // console.log(data);
        //             // commit('getAllLanguagesSuccess', data.body)
        //             return Promise.resolve(data);
        //         },
        //         error => {
        //             // commit('getAllLanguagesFailure');
        //             return Promise.reject(error.response.data.errors);
        //         }
        //     )
        // },
        // createTranslation(_,tax){
        //     return taxService.createTranslation(tax).then(
        //         data => {
        //             // console.log(data);
        //             // commit('getAllLanguagesSuccess', data.body)
        //             return Promise.resolve(data.message);
        //         },
        //         error => {
        //             // commit('getAllLanguagesFailure');
        //             return Promise.reject(error.response.data.errors);
        //         }
        //     )
        // },
        update(_,tax){
            return taxService.edit(tax).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data.message);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        // delete(_,id){
        //     return taxService.delete(id).then(
        //         data => {
        //             return Promise.resolve(data.message);
        //         },
        //         error => {
        //             return Promise.reject(error.response.data.errors);
        //         }
        //     )
        // },
        // getAll(_,params){
        //     return taxService.getAll(params).then(
        //         data => {
        //             // console.log(data);
        //             // commit('getAllLanguagesSuccess', data.body)
        //             return Promise.resolve(data.body);
        //         },
        //         error => {
        //             // commit('getAllLanguagesFailure');
        //             return Promise.reject(error);
        //         }
        //     )
        // },
        fetch(){
            return taxService.fetch().then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data.body);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        }
    },
    mutations: {
        
    }
}

export default tax
