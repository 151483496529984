import axios from 'axios';
import Constants from '../constants';
import authHeader from './auth-header'


class HomeSectionService {
    fetch(params) {
      return axios
          .get(Constants['SERVER_URL']+'homes/sections/fetch/'+`${params.section_id}/${params.language}`, {headers:authHeader()})
          .then(response => {
              return response.data;
          })
    }
    getAll(params) {
        return axios
            .get(Constants['SERVER_URL']+'homes/sections/search', {params: params,headers:authHeader()})
            .then(response => {
                return response.data;
            })
    }
    create(section) {
        return axios
          .post(Constants['SERVER_URL']+'homes/sections/create', section, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    createTranslation(section) {
        return axios
          .post(Constants['SERVER_URL']+'homes/sections/add/translation/'+ section.section_id, section, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    edit(section){
      return axios
        .patch(Constants['SERVER_URL']+'homes/sections/update/'+ `${section.section_id}/${section.language}`, section, {headers:authHeader()})
        .then(response => {    
          return response.data;
      })
    }
    delete(section_id) {
        return axios
          .delete(Constants['SERVER_URL']+'homes/sections/delete/'+ section_id, {headers:authHeader()})
          .then(response => {    
            return response.data;
        })
    }
}



export default new HomeSectionService();