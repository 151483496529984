<template>
    <BodyHeading :borderColor="'rgb(154, 168, 181)'" :title="'Post Methods'" :count="postMethods.total" 
      :multiLanguageDisabled="false" :listHeading="true" :btnTitle="$t('postMethod.addButtonTitle')" :createBtnLink="'postMethodCreate'" :backLink="'settings'" :backIcon="'cog'"></BodyHeading>
    <div class="page-body-main-list d-grid">
        <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" style="width:5%"><span>#</span></th>
                            <th scope="col" style="width:90%"><span>{{$t('postMethod.title')}}</span></th>
                            <th scope="col" style="width:5%"><span>{{$t('actions')}}</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(postMethod, index) in postMethods.data" :key="postMethod.post_method_id">
                            <td>{{index + 1 +(postMethods.current_page-1) * ITEMS_PER_PAGE}}</td>
                            <td>{{postMethod.title}}</td>
                            <td :style="postMethod.post_method_id === ParentZindex? { zIndex: 2}: null">
                                <div class="dropdown actions-dropdown">
                                    <button @click="changeParentZindex(postMethod.post_method_id)" class="btn btn-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <font-awesome-icon :icon="['fas', 'ellipsis-h']" size="sm"></font-awesome-icon>
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a @click="$router.push({ name: 'postMethodDetails', params: { id: postMethod.post_method_id } })" class="dropdown-item">{{$t('edit')}}</a></li>
                                        <li><a class="dropdown-item warning" @click="deleteItem(postMethod.post_method_id)">{{$t('delete')}}</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
            </table>
        </div>
        <Pagination v-if="postMethods.total>postMethods.per_page" :lastPage="postMethods.last_page"
        :activePage="postMethods.current_page" @handleClick="changePage"></Pagination>
    </div>
    
</template>


<script>
import BodyHeading from '../../components/BodyHeading.vue';
import Pagination from '../../components/Pagination.vue';
import { ITEMS_PER_PAGE } from '../../constants';

export default {
    components: {
        BodyHeading,
        Pagination
    },
    data() {
        return{
            postMethods: {},
            ParentZindex: null
        }
    },
    created() {
        this.ITEMS_PER_PAGE = ITEMS_PER_PAGE
        if(Object.entries(this.$route.query).length === 0 || this.$route.query.page === ''){
            this.$router.push({ name: 'postMethods', query: { page: 1 } })
        }
        this.getPostMethods()
    },
    watch: {
        '$route.query'(){
            this.getPostMethods()
        },
        currentLanguage() {
            this.getPostMethods()
        }
    },
    computed: {
        currentLanguage() {
            return this.$store.state.locale.selectedLocale
        },
    },
    methods: {
        changeParentZindex(id) {
            this.ParentZindex = id
        },
        getPostMethods() {
            const params = {language: this.currentLanguage, per_page: ITEMS_PER_PAGE, ...this.$route.query}
            this.$store.dispatch("postMethod/getAll", params).then(
                (response) => {
                    this.postMethods = response;
                },
                (error) => {
                this.$notify({
                        text: error,
                        group: 'failed'
                    });
                }
            )
        },
        deleteItem(id){
            this.$swal({text:'Post Method cannot be deleted, if it has relations with other Addresses, Users, invoices or orders.'}).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("postMethod/delete", id).then(
                        (message) => {
                            this.$notify({
                                text: message,
                                group: 'success'
                            });
                            this.getPostMethods()
                        },
                        (error) => {
                            let errorMessage = ''
                            for (const err in error) {
                                errorMessage += `${error[err]} <br>`;
                            }
                        this.$notify({
                                text: errorMessage,
                                group: 'failed'
                            });
                        }
                    )
                } 
            })            
        },
        changePage(page=1){
            this.$router.push({ name: 'postMethods', query: { page: page } })
        }
    }
}
</script>
