import store from '../store'

export default function authHeader() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.access_token) {
      return { 
          'Authorization': 'Bearer ' + user.access_token,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Accept-Language': store.state.locale.appLocale
      };
    } else {
      return {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Accept-Language': store.state.locale.appLocale
      };
    }
}
