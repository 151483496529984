<template>
    <BodyHeading :borderColor="'rgb(87, 217, 163)'" :title="$t('shippingInfo.shippingInfosTitle')" :count="shippingInfos.total" 
      :createDisabled="shippingInfos.total >= 1" :multiLanguageDisabled="false" :listHeading="true" :btnTitle="$t('shippingInfo.addButtonTitle')" :createBtnLink="'shippingInfoCreate'" ></BodyHeading>
    <div class="page-body-main-list d-grid">
        <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" style="width: 5%"><span>#</span></th>
                            <th scope="col"><span>Title</span></th>
                            <th scope="col" style="width: 5%"><span>ID</span></th>
                            <th scope="col" style="width: 5%"><span>Actions</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(shippingInfo, index) in shippingInfos.data" :key="shippingInfo.shipping_info_id">
                            <td>{{index + 1 + ( shippingInfos.current_page - 1) * ITEMS_PER_PAGE}}</td>
                            <td>{{shippingInfo.title}}</td>
                            <td>{{shippingInfo.shipping_info_id}}</td>
                            <td :style="shippingInfo.shipping_info_id === ParentZindex? { zIndex: 2}: null">
                                <div class="dropdown actions-dropdown">
                                    <button @click="changeParentZindex(shippingInfo.shipping_info_id)" class="btn btn-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <font-awesome-icon :icon="['fas', 'ellipsis-h']" size="sm"></font-awesome-icon>
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a @click="$router.push({ name: 'shippingInfoDetails', params: { id: shippingInfo.shipping_info_id } })" class="dropdown-item">{{$t('edit')}}</a></li>
                                        <li><a class="dropdown-item warning" @click="deleteItem(shippingInfo.shipping_info_id)">{{$t('delete')}}</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
            </table>
        </div>
        <Pagination v-if="shippingInfos.total>shippingInfos.per_page" :lastPage="shippingInfos.last_page"
        :activePage="shippingInfos.current_page" @handleClick="changePage"></Pagination>
    </div>
    
</template>


<script>
import BodyHeading from '../../components/BodyHeading.vue';
import Pagination from '../../components/Pagination.vue';
import { ITEMS_PER_PAGE } from '../../constants';

export default {
    components: {
        BodyHeading,
        Pagination
    },
    data() {
        return{
            shippingInfos: {},
            ParentZindex: null
        }
    },
    created() {
        this.ITEMS_PER_PAGE = ITEMS_PER_PAGE
        if(Object.entries(this.$route.query).length === 0 || this.$route.query.page === ''){
            this.$router.push({ name: 'shippingInfos', query: { page: 1 } })
        }
        this.getshippingInfos()
    },
    watch: {
        '$route.query'(){
            this.getshippingInfos()
        },
        currentLanguage() {
            this.getshippingInfos()
        }
    },
    computed: {
        currentLanguage() {
            return this.$store.state.locale.selectedLocale
        },
    },
    methods: {
        changeParentZindex(id) {
            this.ParentZindex = id
        },
        getshippingInfos() {
            const params = {language: this.currentLanguage, per_page: ITEMS_PER_PAGE, ...this.$route.query}
            this.$store.dispatch("shippingInfo/getAll", params).then(
                (response) => {
                    this.shippingInfos = response;
                },
                (error) => {
                this.$notify({
                        text: error,
                        group: 'failed'
                    });
                }
            )
        },
        deleteItem(id){
            this.$swal().then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("shippingInfo/delete", id).then(
                        (message) => {
                            this.$notify({
                                text: message,
                                group: 'success'
                            });
                            this.getshippingInfos()
                        },
                        (error) => {
                            let errorMessage = ''
                            for (const err in error) {
                                errorMessage += `${error[err]} <br>`;
                            }
                        this.$notify({
                                text: errorMessage,
                                group: 'failed'
                            });
                        }
                    )
                } 
            })            
        },
        changePage(page){
            this.$router.push({ name: 'shippingInfos', query: { page: page } })
        }
    }
}
</script>
