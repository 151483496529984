<script setup>
import { ref, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import Swal from 'sweetalert2'

// Import components
import BodyHeading from '../../components/BodyHeading.vue'
import CurrencyInput from '../../components/CurrencyInput.vue'
import VInput from '../../components/Input.vue'
import FileUploader from '../../components/FileUploader.vue'
import { useNotification } from "@kyvg/vue3-notification"
import { ValidateEach } from '@vuelidate/components'

// Import validations
import useValidate from '@vuelidate/core'
import { required, requiredIf, minLength, numeric } from '@/utilities/i18n-validators.js'

const store = useStore()
const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const { notify } = useNotification()

// state
const defaultLanguageService = ref({})
const exists = ref(true)
const isLoading = ref(true)
const inputDisabled = ref(false)
const infoImage = ref([])

// setup validation
const service = ref({
  title: '',
  values: [{}],
  image: null
})
const serviceRules = computed(() => ({
  service: {
    title: { required },
    values: { required, minLength: minLength(1) },
    height: { required: requiredIf(() => infoImage.value[0]), numeric },
    width: { required: requiredIf(() => infoImage.value[0]), numeric }
  },

}))
const v$ = useValidate(serviceRules, { service })

const serviceValueRules = {
  title: { required },
  duration: { required },
  price: { required },
}
// computed
const selectedLocale = computed(() => store.state.locale.selectedLocale)

const appLocale = computed(() => store.state.locale.appLocale)

const handleClickFunction = computed(() => {
  if (route.name.includes('Create'))
    return { handleClick: create }
  else {
    if (exists.value)
      return { handleClick: edit }
    else return { handleClick: createTranslation }
  }
})

const translationId = computed(() => {
  const service_translation_id = route.name.includes('Details') ? service.value.id : null
  return { service_translation_id }
})

// watch
watch(() => route.name, async (value) => {
  if (value.includes('Details')) {
    if (!defaultLanguageService.value?.title) {
      await getService(route.params.id, appLocale.value)
    }
    if (exists.value && selectedLocale.value !== appLocale.value)
      await getService(route.params.id)
  } else {
    isLoading.value = false
  }
}, { immediate: true })

watch(selectedLocale, (value) => {
  if (route.name.includes('Details')) {
    isLoading.value = true
    v$.value.$reset();
    service.value = {
      title: '',
      values: [],
      image: null
    }
    infoImage.value = []
    getService(route.params.id, value)
  }
})

// functions
async function getService(id, language = selectedLocale.value) {
  const urlParams = { language: language, service_id: id }
  return store.dispatch("service/fetch", urlParams).then(
    (response) => {
      exists.value = true
      service.value = response
      if (service.value.image) {
        infoImage.value.push({ id: null, name: service.value.image })
      }
      isLoading.value = false
      if (appLocale.value === language) {
        service.value = response
        defaultLanguageService.value = response
        inputDisabled.value = false
      } else {
        if (response.values.length == 0) {
          service.value = {
            title: '',
            values: defaultLanguageService.value.values.map((el) => ({ service_value_id: el.service_value_id, title: '', duration: el.duration, price: el.price })),
            image: null
          }
        }
        inputDisabled.value = true
      }
    }).catch(() => {
      exists.value = false
      isLoading.value = false
      if (defaultLanguageService?.value.values?.length > 0) {
        service.value = {
          title: '',
          values: defaultLanguageService.value.values.map((el) => ({ service_value_id: el.service_value_id, title: '', duration: el.duration, price: el.price })),
          image: null
        }
        inputDisabled.value = true
      } else {
        notify({
          text: t('errors.notFoundInDefaultLocale', { entity: t('service.entity') }),
          group: "failed"
        })
        router.push({ name: 'services', query: { page: 1 } })
      }
    }
    )
}

async function create() {
  const formIscorrect = await v$.value.$validate()
  if (formIscorrect) {
    if (infoImage.value.length > 0)
      service.value.image = infoImage.value[0].name
    else service.value.image = null
    service.value.language = selectedLocale.value;
    store.dispatch("service/create", service.value).then(
      (data) => {
        notify({
          text: data.message,
          group: 'success'
        });
        router.push({ name: 'serviceDetails', params: { id: data.body.service_id } })
      },
      (error) => {
        let errorMessage = ''
        for (const err in error) {
          errorMessage += `${error[err]} <br>`;
        }
        notify({
          text: errorMessage,
          group: 'failed'
        });
      }
    )
  }
}

async function createTranslation() {
  const formIscorrect = await v$.value.$validate()
  if (formIscorrect) {
    const translatedService = structuredClone(service.value)
    if (infoImage.value.length > 0)
      translatedService.image = infoImage.value[0].name
    else translatedService.image = null
    translatedService.values = translatedService.values.map(item => ({ service_value_id: item.service_value_id, title: item.title }))
    translatedService.language = selectedLocale.value;
    const urlParams = { service_id: defaultLanguageService.value.service_id }
    store.dispatch("service/createTranslation", { urlParams, service: translatedService }).then(
      (message) => {
        exists.value = true;
        notify({
          text: message,
          group: 'success'
        });
      },
      (error) => {
        let errorMessage = ''
        for (const err in error) {
          errorMessage += `${error[err]} <br>`;
        }
        notify({
          text: errorMessage,
          group: 'failed'
        });
      }
    )
  }
}

async function edit() {
  const formIscorrect = await v$.value.$validate()
  if (formIscorrect) {
    if (infoImage.value.length > 0)
      service.value.image = infoImage.value[0].name
    else service.value.image = null
    const urlParams = { service_id: defaultLanguageService.value.service_id, language: selectedLocale.value }
    store.dispatch("service/update", { urlParams, service: service.value }).then(
      (message) => {
        notify({
          text: message,
          group: 'success'
        });
      },
      (error) => {
        let errorMessage = ''
        for (const err in error) {
          errorMessage += `${error[err]} <br>`;
        }
        notify({
          text: errorMessage,
          group: 'failed'
        });
      }
    )
  }
}

function addNewValue() {
  service.value.values.push({})
}

function removeValue(index) {
  Swal.fire({
    title: '<div class="w-100 text-center"><strong class="text-danger">Be cautious!!!</strong></div>',
    icon: 'warning', text: 'Deleting this item may disable products that use this item.'
  }).then((result) => {
    if (result.isConfirmed) {
      store.dispatch("service/deleteValue", { service_value_id: service.value.values[index].service_value_id }).then(
        (message) => {
          notify({
            text: message,
            group: 'success'
          });
          service.value.values.splice(index, 1)
          if (selectedLocale.value !== appLocale.value)
            defaultLanguageService.value.values.splice(index, 1)
        },
        (error) => {
          let errorMessage = ''
          for (const err in error) {
            errorMessage += `${error[err]} <br>`;
          }
          notify({
            text: errorMessage,
            group: 'failed'
          });
        }
      )
    }
  })
}

// function removeValue(index) {
//   service.value.values.splice(index, 1)
//   if (selectedLocale.value !== appLocale.value)
//     defaultLanguageService.value.values.splice(index, 1)
// }
</script>

<template>
  <BodyHeading :borderColor="'rgb(87, 217, 163)'"
    :title="$route.name.includes('Create') ? $t('service.addButtonTitle') : $t('service.editTitle')"
    v-on="handleClickFunction" :multiLanguageDisabled="$route.name.includes('Create')" />
  <div class="page-body-main-create">
    <div class="card-wrapper">
      <div class="card-title">{{ $t('generalInformation') }}</div>
      <div class="card">
        <div class="card-body">
          <div v-if="!isLoading">
            <div class="mb-4">
              <v-input v-model="v$.service.title.$model" type="text" name="title" label="service.title"
                :placeholder="defaultLanguageService.title" :isRequired="true"
                :validateError="v$.service.title.$error ? v$.service.title.$errors[0].$message : null"></v-input>
            </div>
            <div class="mb-4">
              <label for="values" class="form-label is-required"><strong>{{ $t('service.values') }}</strong></label>
              <div id="values" class="row">
                <ValidateEach v-for="(item, index) in service.values" :key="item.id" :state="item"
                  :rules="serviceValueRules">
                  <template #default="{ v }">
                    <div class="col-md-4 mb-4">
                      <label :for="`valueTitle${index}`" class="form-label is-required">{{ $t('service.title') }}</label>
                      <input class="form-control" type="text" v-model="v.title.$model" :id="`valueTitle${index}`"
                        :placeholder="defaultLanguageService.values?.length > index ? defaultLanguageService.values[index].title : ''"
                        :class="v.title.$error ? 'form-controll-invalid' : ''">
                      <div v-for="(error, errorIndex) in v.title.$errors" :key="errorIndex" class="invalid-feedback">
                        {{ error.$message }}
                      </div>
                    </div>
                    <div class="col-md-4 mb-4">
                      <label :for="`valueDuration${index}`" class="form-label is-required">{{
                        $t('service.valueDurationLabel') }}</label>
                      <input class="form-control" type="number" v-model="v.duration.$model" :disabled="inputDisabled" :id="`valueDuration${index}`"
                        :placeholder="defaultLanguageService.values?.length > index ? defaultLanguageService.values[index].duration : ''"
                        :class="v.duration.$error ? 'form-controll-invalid' : ''">
                      <div v-for="(error, errorIndex) in v.duration.$errors" :key="errorIndex" class="invalid-feedback">
                        {{ error.$message }}
                      </div>
                    </div>
                    <div class="col-md-4 mb-4">
                      <currency-input v-model="v.price.$model" :disabled="inputDisabled" label="service.valuePriceLabel"
                        :name="`valuePrice${index}`"
                        :validateError="v.price.$error ? v.price.$errors[0].$message : false">
                        <template #suffix>
                          <button class="btn button-stop px-3" :disabled="inputDisabled" @click="removeValue(index)">
                            <font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon>
                          </button>
                          <button v-if="index === service.values.length - 1" class="btn button-action px-3"
                            :disabled="inputDisabled" @click="addNewValue">
                            <font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon>
                          </button>
                        </template>
                      </currency-input>
                    </div>
                  </template>
                </ValidateEach>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-wrapper">
      <div class="card-title">{{ $t('media') }}</div>
      <div class="card">
        <div class="card-body">
          <div v-if="!isLoading" class="row">
            <div class="col-12 mb-4">
              <FileUploader v-model="infoImage" :isRequired="true" type="image" name="service-info-image-uploader"
                label="service.chooseInfoImageLabel" fetchUrl="image/service" uploadUrl="services/images/upload"
                deleteUrl="services/images/delete" :multiple="false" :ownerId="translationId" />
            </div>
            <div class="col-12 col-md-6">
              <v-input v-model="v$.service.width.$model" type="text" name="image-width" label="service.imageWidth"
                :validateError="v$.service.width.$error ? v$.service.width.$errors[0].$message : null"></v-input>
            </div>
            <div class="col-12 col-md-6">
              <v-input v-model="v$.service.height.$model" type="text" name="image-height" label="service.imageHeight"
                :validateError="v$.service.height.$error ? v$.service.height.$errors[0].$message : null"></v-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
