import portfolioService from '../../services/portfolio.service'


const portfolio = {
    namespaced: true,
    state: {
    },
    getters: {
        
    },
    actions: {
        create(_,portfolio){
            return portfolioService.create(portfolio).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        createTranslation(_,portfolio){
            return portfolioService.createTranslation(portfolio).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data.message);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        update(_,portfolio){
            return portfolioService.edit(portfolio).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data.message);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        sortImages(_,images){
            return portfolioService.sortImages(images).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        delete(_,id){
            return portfolioService.delete(id).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        getAll(_,params){
            return portfolioService.getAll(params).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data.body);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error);
                }
            )
        },
        fetch(_,params){
            return portfolioService.fetch(params).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data.body);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error.response.data.errors);
                }
            )
        }
    },
    mutations: {
        
    }
}

export default portfolio
