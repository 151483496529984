import productService from '../../../../services/product.service'

const productWorkingDays = {
    namespaced: true,
    actions: {
        getWorkingDays(_, urlParams) {
            return productService.getWorkingDays(urlParams).then(
                data => {
                    return Promise.resolve(data.body);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        setWorkingDays(_, { urlParams, durationsList }) {
            return productService.setWorkingDays(urlParams, durationsList).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        }
    }
}

export default productWorkingDays
