import * as validators from '@vuelidate/validators'
import { i18n } from '../plugins/vue-i18n-plugin'

const { t } = i18n.global
const withI18nMessage = validators.createI18nMessage({ t })

export const required = withI18nMessage(validators.required)
export const requiredIf = withI18nMessage(validators.requiredIf, { withArguments: true })
export const alphaNum = withI18nMessage(validators.alphaNum)
export const decimal = withI18nMessage(validators.decimal)
export const email = withI18nMessage(validators.email)
export const minLength = withI18nMessage(validators.minLength, { withArguments: true })
export const between = withI18nMessage(validators.between, { withArguments: true })
export const numeric = withI18nMessage(validators.numeric)
export const sameAs = withI18nMessage(validators.sameAs, { withArguments: true })
export const mustBeFive = validators.helpers.withMessage(t('validations.mustBeFive'), validators.helpers.regex(/^[0-9]{5}$/))
