<template>
   <BodyHeading v-if="$route.name.includes('Create')" :borderColor="'rgb(87, 217, 163)'" :title="$t('portfolio.addButtonTitle')" 
      @handleClick="create" :multiLanguageDisabled="true"></BodyHeading>
   
   <BodyHeading v-if="$route.name.includes('Details')" :borderColor="'rgb(87, 217, 163)'" :title="$t('portfolio.editTitle')" 
     @handleClick="edit" :multiLanguageDisabled="true"></BodyHeading>
   <div class="page-body-main-create">
      <div class="card-wrapper">
         <div class="card-title">{{$t('generalInformation')}}</div>
         <div class="card">
            <div class="card-body">
              <div class="row" v-if="!isLoading">
                  <div class="mb-4">
                     <v-input type="text" name="title" label="portfolio.title" v-model="portfolio.title" :isRequired="true"
                        :validateError="v$.portfolio.title.$error? v$.portfolio.title.$errors[0].$message : null"></v-input>
                  </div>
                  <div class="mb-4">
                     <v-button class="button-stop" style="margin:0" @click="$refs.imageSortModal.initiateModal()">
                        {{$t('portfolio.imageSorting') }}
                     </v-button>
                  </div>
                  <div class="mb-4">
                     <FileUploader v-model="images" :name="'portfolio-uploader'" label="portfolio.images"
                     uploadUrl="portfolio/images/upload" deleteUrl="portfolio/images/delete" fetchUrl="image/portfolio"
                     :validateError="v$.images.$error? v$.images.$errors[0].$message : null"/>
                  </div>
              </div>
            </div>
         </div>
      </div>
   </div>
   <Modal ref="imageSortModal" :title="$t('portfolio.imageSorting')" @handleClick="sortCombinationImages">
      <div class="col">
      <draggable v-model="images" item-key="id" tag="ul" class="list-group my-4"> 
         <template #item="{ element }">
            <li class="list-group-item">
            <div class="row d-flex align-items-center">
               <div class="col-12 col-md-3 d-flex align-items-center">
                  <span class="drag-handler me-2 d-flex cursor-move">
                  <font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
                  <font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
                  </span>
                  <img :src="getImageUrl(element)" alt="portfolio image image">
               </div>
               <div class="col-12 col-md-8">
                  {{ element.name }}
               </div>
            </div>
            </li>
         </template>
      </draggable>
      </div>
   </Modal>
</template> -->

<script>
import BodyHeading from '../../components/BodyHeading.vue'
import Input from '../../components/Input.vue';
import useValidate from '@vuelidate/core';
import { required } from '@/utilities/i18n-validators.js';
import FileUploader from '../../components/FileUploader.vue'
import Modal from '../../components/Modal.vue'
import draggable from 'vuedraggable'
import Constants from '../../constants';

export default {
      components: {
         BodyHeading,
         'v-input': Input,
         FileUploader,
         Modal,
         draggable
      },
      data() {
         return{
            v$: useValidate(),
            portfolio: {images_id:[]},
            images: [],
            isExist:true,
            isLoading: true,
         }
      },
      validations() {
         return {
            portfolio: {
               title: {required},
            },
            images: {required}
         }
      },
      watch: {
         '$route': {
            immediate: true,
            handler() {
               if(this.$route.name.includes('Details')){
                  this.getPortfolio(this.$route.params.id)
               }else{
                  this.isLoading = false
               }
            }
         }
      },
      methods: {
         create() {
            this.v$.$validate()
            if(!this.v$.$error){
               this.images.forEach(el => {
                  this.portfolio.images_id.push(el.id)
               });
               this.$store.dispatch("portfolio/create", this.portfolio).then(
                  (data) => {
                     this.$notify({
                           text: data.message,
                           group: 'success'
                     });
                     this.$router.push({ name: 'portfolios', query: { page: 1 } })
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         },
         edit() {
            this.v$.$validate()
            if(!this.v$.$error){
               this.images.forEach(el => {
                  this.portfolio.images_id.push(el.id)
               });
               this.portfolio.portfolio_id = this.$route.params.id
               this.$store.dispatch("portfolio/update", this.portfolio).then(
                  (message) => {
                     this.$notify({
                           text: message,
                           group: 'success'
                     });
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         },
         getPortfolio(id) {
            const params = {portfolio_id: id}
            this.$store.dispatch("portfolio/fetch", params).then(
                (response) => {
                  this.isExist = true;
                  this.portfolio.title = response.title;
                  response.images.forEach((el) => {
                     this.images.push({id: el.id, name: el.path, arrange: el.arrange})
                  });
                  this.images = this.images.sort((a, b) => a.arrange - b.arrange);
                  console.log("🚀 ~ getPortfolio ~ this.images:", this.images)
                  this.isLoading = false
                },
                () => {
                  this.isExist = false;
                  this.$router.push({ name: 'portfolios', query: { page: 1 } })
                  this.isLoading = false;
                }
            )
         },
         getImageUrl(image) {
            return Constants['SERVER_PUBLIC_URL'] + `fetch/image?path=/image/portfolio&image=${image.name}`
         },
         async sortCombinationImages() {
            const images = this.images.map((image, index) => ({id: image.id, arrange: index+1}))
            await this.$store.dispatch("portfolio/sortImages", images).then(
               (message) => {
                  this.$notify({
                     text: message,
                     group: "success",
                  })
                  this.$refs.imageSortModal.hide()
                  this.$router.go(0);
               },
               (error) => {
                  this.$notify({
                     text: error,
                     group: "failed",
                  })
               }
            )
         }
      }
   }
</script>

<style lang="scss" scoped>
  li{
    img {
      max-width: 160px;
      height:auto;
    }
  }
</style>