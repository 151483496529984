import axiosInstance from './auth.config.js';
import authHeader from './auth-header';

class AuthService {
  login(user) {
      return axiosInstance.post('/auth/login', {
          email: user.email,
          password: user.password
      }, {
          headers: authHeader()
      }).then(response => {
          if (response.data.body.access_token) {
              localStorage.setItem('user', JSON.stringify(response.data.body));
          }
          return response.data;
      }).catch(error => {
          console.error("There was an error logging in:", error);
      });
  }

  logout() {
      localStorage.removeItem('user');  
  }
}

export default new AuthService();