<template>
    <BodyHeading :borderColor="'rgb(154, 168, 181)'" :title="$t('menu.menus')" :count="menus.total" 
      :multiLanguageDisabled="false" :listHeading="true" :btnTitle="$t('menu.addButtonTitle')" :createBtnLink="'menuCreate'" :backLink="'settings'" :backIcon="'cog'"></BodyHeading>
    <div class="page-body-main-list d-grid grid-columns">
        <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th scope="col"  style="width: 5%"><span>#</span></th>
                            <th scope="col"><span>{{$t('menu.title')}}</span></th>
                            <th scope="col"><span>{{$t('menu.level')}}</span></th>
                            <th scope="col"><span>{{$t('menu.activity')}}</span></th>
                            <th scope="col"  style="width: 5%"><span>{{$t('actions')}}</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(menu, index) in menus.data" :key="menu.menu_id">
                            <td>{{index + 1 + (menus.current_page - 1) * ITEMS_PER_PAGE}}</td>
                            <td>{{menu.title}}</td>
                            <td>{{menu.menu_info.level}}</td>
                            <td>
                                <v-switch v-model="menu.is_active" @click="changeActivity(index)" :disabled="isSwitchLocked"></v-switch>
                            </td>
                            <td :style="menu.menu_id === ParentZindex? { zIndex: 2}: null">
                                <div class="dropdown actions-dropdown">
                                    <button @click="changeParentZindex(menu.menu_id)" class="btn btn-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <font-awesome-icon :icon="['fas', 'ellipsis-h']" size="sm"></font-awesome-icon>
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a @click="$router.push({ name: 'menuDetails', params: { id: menu.menu_id } })" class="dropdown-item">{{$t('edit')}}</a></li>
                                        <li><a @click="openModal(menu.menu_info.id)" class="dropdown-item">{{$t('menu.sortSubmenus')}}</a></li>
                                        
                                        <li><a class="dropdown-item warning" @click="deleteItem(menu.menu_id)">{{$t('delete')}}</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
            </table>
        </div>

        <!-- Filter Box -->
        <div class="filter-bar">
            <aside :class="{'opened': filterOpened}">
                <nav>
                    <ul>
                        <li><button :class="{'active': filterOpened}" @click="filterOpened = !filterOpened"><font-awesome-icon :icon="['fas', 'filter']"></font-awesome-icon></button></li>
                        <li><button @click="openModal()"><font-awesome-icon :icon="['fas', 'sort']"></font-awesome-icon></button></li>
                    </ul>
                </nav>
                <div class="filter-content">
                    <div class="filter-panel">
                        <div class="headline">
                            <h3>{{$t('filters.title')}}</h3>
                            <button @click="filterOpened = !filterOpened"><font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon></button>
                        </div>
                        <div class="content">
                            <div>
                                <div>
                                    <div class="element">
                                        <v-input type="text" name="title" label="menu.title" v-model="title" @keyup="searchQueue()"></v-input>
                                    </div>
                                    <div class="element">
                                        <MultiSelect label="menu.level" :options="[{label:'1', value:'1'},{label:'2', value:'2'},{label:'3', value:'3'}]" v-model="level"
                                        @change="searchQueue()" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </div>
        <!-- // Filter Box -->

        <Pagination v-if="menus.total>menus.per_page" :lastPage="menus.last_page"
        :activePage="menus.current_page" @handleClick="changePage"></Pagination>

        <Modal ref="submenusSortModal" :title="$t('menu.sortSubmenus')" @handleClick="sortSubmenus">
            <div v-if="selectedMenus" class="col">
                <draggable v-model="selectedMenus" item-key="menu_info.id" tag="ul" class="list-group my-4">
                    <template #item="{ element }">
                        <li class="list-group-item">
                            <div>
                                <span class="drag-handler me-3 mp-1 d-inline-block cursor-move">
                                    <font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
                                    <font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
                                </span>
                                {{element.title}}
                            </div>
                        </li>
                    </template>
                </draggable>
            </div>
            <div v-if="selectedProducts" class="col">
                <draggable v-model="selectedProducts" item-key="level" tag="ul" class="list-group my-4">
                    <template #item="{ element }">
                        <li class="list-group-item">
                            <div>
                                <span class="drag-handler me-3 mp-1 d-inline-block cursor-move">
                                    <font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
                                    <font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
                                </span>
                                {{element.title}}
                            </div>
                        </li>
                    </template>
                </draggable>
            </div>
        </Modal>

        <Modals ref="productsAssigning" :title="selected_title" @handleClick="saveCategories">
            <div class="col">
                <MultiSelect v-model="selectedCategories" label="product.create.menu" mode="tags" 
                :options="async (query) => { return await getProducts(query) }" :delay="0" :resolveOnLoad="true"
                :searchable="true" :object="true" />
                <div class="mt-2">
                    <span v-for="menu in selectedCategories" :key="menu.value">
                        <input v-model="menu.value" :value="menu.value" type="radio" class="btn-check"
                            name="options" :id="'option' + menu.value" autocomplete="off">
                        <label class="btn mb-1 me-2" :for="'option' + menu.value">{{ menu.label }}</label>
                    </span>
                </div>
            </div>
        </Modals>
    </div>
    
</template>

<script>
import BodyHeading from '../../components/BodyHeading.vue';
import Switch from '../../components/Switch.vue';
import Pagination from '../../components/Pagination.vue';
import MultiSelect from '../../components/MultiSelect.vue';
import Input from '../../components/Input.vue';
import Modal from '../../components/Modal.vue';
import Modals from '../../components/Modal.vue';
import draggable from 'vuedraggable'
import { ITEMS_PER_PAGE } from '../../constants';

export default {
    components: {
        BodyHeading,
        Pagination,
        'v-switch': Switch,
        'v-input': Input,
        MultiSelect,
        Modal,
        Modals,
        draggable
    },
    data() {
        return{
            timer: null,
            filterOpened: false,
            title: null,
            level: null,
            menus: {},
            selected_title: null,
            selected_category: null,
            products: null,
            ParentZindex: null,
            isSwitchLocked: false,
            selectedMenus: null,
            selectedProducts: null,
            selectedCategories: null,
        }
    },
    created() {
        this.ITEMS_PER_PAGE = ITEMS_PER_PAGE
        if(Object.entries(this.$route.query).length === 0 || this.$route.query.page === ''){
            this.$router.push({ name: 'menus', query: { page: 1 } })
        }
        this.getMenus()
        if(this.$route.query.title || this.$route.query.level){
            this.title = this.$route.query.title || null
            this.level = this.$route.query.level || null
            this.filterOpened = true
        }
    },
    watch: {
        '$route.query'(){
            console.log('watch', this.level);
            // if(parseInt(this.level) !== 3) {
                console.log('Checked');
                this.getMenus()
            // }
        },
        currentLanguage() {
            this.getMenus()
        },
    },
    computed: {
        currentLanguage() {
            return this.$store.state.locale.selectedLocale
        },
    },
    methods: {
        async getProducts(query) {
            const queryParams = { language: this.$store.state.locale.selectedLocale, title: query,custom: 'yes', per_page: 50 }
            return this.$store.dispatch('menu/getProducts', queryParams).then(res => {
                return res.map(el => ({ label: el.title, value: el.product_id }));
            })
        },
        async getAlreadyAddedProducts(query) {
            const queryParams = { language: this.$store.state.locale.selectedLocale, title: query,sub_id:this.selected_category, custom: 'done', per_page: 50 }
            return this.$store.dispatch('menu/getProducts', queryParams).then(res => {
                this.selectedCategories = res.map(el => ({ label: el.title, value: el.product_id }));
            })
        },
        searchQueue(){
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                const query = {
                    page: 1,
                    ...(this.title && {title: this.title}),
                    ...(this.level && {level: this.level}),
                }
                this.$router.push({
                    query
                })
            }, 500);
        },
        changeParentZindex(id) {
            this.ParentZindex = id
        },
        getMenus() {
            const params = {language: this.currentLanguage, per_page: ITEMS_PER_PAGE, ...this.$route.query}
            this.$store.dispatch("menu/getAll", params).then(
                (response) => {
                    this.menus = response;
                    if(this.menus.data.length){
                        this.menus.data.forEach((el, index) => {
                           this.menus.data[index].is_active = el.menu_info.is_active 
                        });
                    }
                },
                (error) => {
                this.$notify({
                        text: error,
                        group: 'failed'
                    });
                }
            )
        },
        deleteItem(id){
            this.$swal().then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("menu/delete", id).then(
                        (message) => {
                            this.$notify({
                                text: message,
                                group: 'success'
                            });
                            this.getMenus()
                        },
                        (error) => {
                            let errorMessage = ''
                            for (const err in error) {
                                errorMessage += `${error[err]} <br>`;
                            }
                        this.$notify({
                                text: errorMessage,
                                group: 'failed'
                            });
                        }
                    )
                } 
            })            
        },
        changePage(page=1){
            this.$router.push({ name: 'menus', query: { ...this.$route.query, page: page } })
        },
        changeActivity(index){
            // console.log(this.menus.data[index].is_active)
            this.isSwitchLocked = true;
            let params = {menu_id: this.menus.data[index].menu_id}
            if(this.menus.data[index].is_active){
                this.$store.dispatch("menu/activate", params).then(
                    (message) => {
                        this.$notify({
                            text: message,
                            group: 'success'
                        });
                        this.isSwitchLocked = false
                    },
                    (error) => {
                    this.$notify({
                            text: error,
                            group: 'failed'
                        });
                        this.isSwitchLocked = false
                    }
                )
            }else{
                this.$store.dispatch("menu/deactivate", params).then(
                    (message) => {
                        this.$notify({
                            text: message,
                            group: 'success'
                        });
                        this.isSwitchLocked = false
                    },
                    (error) => {
                    this.$notify({
                            text: error,
                            group: 'failed'
                        });
                        this.isSwitchLocked = false
                    }
                )
            }
        },
        async openModal(menuId){
            if(parseInt(this.level) === 3) {
                this.selectedMenus = null;
                const params = {
                    page: 1,
                    products: 'getthem',
                    per_page: 100,
                    parent_id: menuId,
                    language: this.currentLanguage
                }
                var products = await this.$store.dispatch("menu/getAll", params).then(
                    (response) => {
                        return Promise.resolve(response)
                    },
                    (error) => {
                        this.$notify({
                            text: error,
                            group: 'failed'
                        });
                    }   
                );
             
                var allProducts = [];

                for(const a in products) {
                    console.log("🚀 ~ openModal ~ products:", products)
                    allProducts.push({
                        menu_id: products[a].sort_category_id,
                        title: products[a].title,
                        language: products[a].language,
                        menu_info: {
                            id: products[a].product_id,
                            arrange: parseInt(products[a].arrange),
                            is_active: 1,
                            parent_id: this.level,
                            thumbnail_image: "",
                            created_at: "",
                            updated_at: ""

                        }
                    });
                }

                this.selectedProducts = allProducts;
                this.selectedProducts.sort((a, b) => +a.menu_info.arrange > +b.menu_info.arrange ? 1 : -1)
                console.log(products, this.selectedProducts);
                this.$refs.submenusSortModal.initiateModal()

            } else {
                const params = {
                    page: 1,
                    per_page: 30,
                    language: this.currentLanguage
                }

                if(menuId) params.parent_id = menuId
                else params.level = 1

                this.selectedMenus = await this.$store.dispatch("menu/getAll", params).then(
                    (response) => {
                        return Promise.resolve(response.data)
                    },
                    (error) => {
                        this.$notify({
                            text: error,
                            group: 'failed'
                        });
                    }   
                )

                this.selectedMenus.sort((a, b) => +a.menu_info.arrange > +b.menu_info.arrange ? 1 : -1)
                this.$refs.submenusSortModal.initiateModal()
            }
        },
        sortSubmenus() {
            if(parseInt(this.level) === 3) {
                if (this.selectedProducts.length > 1) {
                    const menus = this.selectedProducts.map((el, index) => { return { id: el.menu_info.id, arrange: index + 1, menu_id: el.menu_id  } });
                    this.$store.dispatch("menu/sort", { menus, level: 'products'}).then(
                        (message) => {
                            this.$notify({
                                text: message,
                                group: 'success'
                            });
                            this.$refs.submenusSortModal.hide()
                        },
                        (error) => {
                            this.$notify({
                                text: error,
                                group: 'failed'
                            });
                            this.$refs.submenusSortModal.hide()
                        }
                    )
                }
            } else {
                if (this.selectedMenus.length > 1) {
                    const menus = this.selectedMenus.map((el, index) => { return { id: el.menu_info.id, arrange: index + 1 } })
                    this.$store.dispatch("menu/sort", menus).then(
                        (message) => {
                            this.$notify({
                                text: message,
                                group: 'success'
                            });
                            this.$refs.submenusSortModal.hide()
                        },
                        (error) => {
                            this.$notify({
                                text: error,
                                group: 'failed'
                            });
                            this.$refs.submenusSortModal.hide()
                        }
                    )
                }
            }

        },
        saveCategories() {
            var categories = this.selectedCategories;
            for(const a in categories) {
                const urlParams = { product_id: categories[a].value }
                const menus = {
                    menus: this.selected_category
                }
                this.$store.dispatch('product/menus/setMenus', { urlParams, menus })
                .then(
					(message) => {
                        console.log(message)
						// this.$notify({
						// 	text: message,
						// 	group: 'success'
						// });
                        // this.selectedCategories = null;
                        // this.selected_category = null;
                        // this.$refs.productsAssigning.hide()
					},
					(error) => {
                        console.log(error)
						// this.$notify({
						// 	text: error,
						// 	group: 'failed'
						// });
					}
				)
            }
            this.$notify({
                text: 'success',
                group: 'success'
            });
            this.selectedCategories = null;
            this.selected_category = null;
            this.$refs.productsAssigning.hide()

        }
    }
}
</script>
