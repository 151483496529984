<template>
    <div id="sidebar" class="d-flex flex-column h-100">
        <!-- Logo & Title -->
        <a class="d-flex me-md-auto sidebar-logo w-100">
            <img src="@/assets/images/seven-fav.png" height="32" class="me-3">
            <div class="d-inline-block">
                <span class="fs-6">SEVEN Displays</span>
                <small>V {{ appVersion }}</small>
            </div>
        </a>
        <!-- Menus -->
        <div class="order-2 flex-grow-1 flex-shrink-1" :class="!sidebarCollapsedStatus? 'scroll-handler':''">
            <ul class="accordion menus" id="sidebarItems">
                <!-- 1 -->
                <li class="simple-item">
                    <router-link :to="{name: 'dashboard'}">
                        <span class="color-1"><font-awesome-icon :icon="['fas', 'tachometer-alt']"></font-awesome-icon></span>
                        <span>{{$t('sidebar.dashboard')}}</span>
                    </router-link>
                </li>
                <!-- 2 -->
                <li class="accordion-item">
                    <a class="accordion-button" :class="!sidebarCollapsedStatus? 'collapsed':''" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCatalog" aria-expanded="false" aria-controls="collapseCatalog">
                        <span class="color-2"><font-awesome-icon :icon="['far', 'clone']"></font-awesome-icon></span>
                        <span>{{$t('sidebar.catalogues')}}</span>
                    </a>
                    <div v-if="!sidebarCollapsedStatus" id="collapseCatalog" class="accordion-collapse collapse" data-bs-parent="#sidebarItems">
                        <ul>
                            <li class="accordion-item-inner">
                                <router-link :to="{name: 'options', query: { page: 1 }}">
                                    <span>{{$t('sidebar.options')}}</span>
                                </router-link>
                            </li>
                            <li class="accordion-item-inner">
                                <router-link :to="{name: 'products', query: { page: 1 }}">
                                    <span>{{$t('sidebar.products')}}</span>
                                </router-link>
                            </li>
                            <li class="accordion-item-inner">
                                <router-link :to="{name: 'portfolios', query: { page: 1 }}">
                                    <span>{{$t('sidebar.portfolios')}}</span>
                                </router-link>
                            </li>
                            <li class="accordion-item-inner">
                                <router-link :to="{name: 'technicalInfos', query: { page: 1 }}">
                                    <span>{{$t('sidebar.technicalInfos')}}</span>
                                </router-link>
                            </li>
                            <li class="accordion-item-inner">
                                <router-link :to="{name: 'shippingInfos', query: { page: 1 }}">
                                    <span>{{$t('sidebar.shippingInfos')}}</span>
                                </router-link>
                            </li>
                            <li class="accordion-item-inner">
                                <router-link :to="{name: 'services', query: { page: 1 }}">
                                    <span>{{$t('sidebar.services')}}</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>

                    <div class="sub-menu-collapse border-color-2">
                        <ul>
                            <li>
                                <router-link :to="{name: 'options', query: { page: 1 }}">
                                    {{$t('sidebar.options')}}
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{name: 'products', query: { page: 1 }}">
                                    {{$t('sidebar.products')}}
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{name: 'portfolios', query: { page: 1 }}">
                                    {{$t('sidebar.portfolios')}}
                                </router-link>
                            </li>
                             <li>
                                <router-link :to="{name: 'technicalInfos', query: { page: 1 }}">
                                    {{$t('sidebar.technicalInfos')}}
                                </router-link>
                            </li>
                             <li>
                                <router-link :to="{name: 'shippingInfos', query: { page: 1 }}">
                                    {{$t('sidebar.shippingInfos')}}
                                </router-link>
                            </li>
                             <li>
                                <router-link :to="{name: 'services', query: { page: 1 }}">
                                    {{$t('sidebar.services')}}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </li>
                <!--  -->
                <li class="accordion-item">
                    <a class="accordion-button" :class="!sidebarCollapsedStatus? 'collapsed':''" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOrders" aria-expanded="false" aria-controls="collapseOrders">
                        <span class="color-3"><font-awesome-icon :icon="['fas', 'shopping-cart']"></font-awesome-icon></span>
                        <span>Orders</span>
                    </a>
                    
                    <div v-if="!sidebarCollapsedStatus" id="collapseOrders" class="accordion-collapse collapse" data-bs-parent="#sidebarItems">
                        <ul>
                            <li class="accordion-item-inner">
                                <router-link :to="{name: 'orders', query: { page: 1 }}">
                                    <span>{{$t('sidebar.overview')}}</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>

                    <div class="sub-menu-collapse border-color-3">
                        <ul>
                            <li>
                                <router-link :to="{name: 'orders', query: { page: 1 }}">
                                    {{$t('sidebar.overview')}}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </li>
                <!--  -->
                <li class="accordion-item">
                    <a class="accordion-button" :class="!sidebarCollapsedStatus? 'collapsed':''" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMarketing" aria-expanded="false" aria-controls="collapseMarketing">
                        <span class="color-4"><font-awesome-icon :icon="['fas', 'users']"></font-awesome-icon></span>
                        <span>{{$t('sidebar.marketing')}}</span>
                    </a>
                    
                    <div v-if="!sidebarCollapsedStatus" id="collapseMarketing" class="accordion-collapse collapse" data-bs-parent="#sidebarItems">
                        <ul>
                            <li class="accordion-item-inner">
                                <router-link :to="{name: 'coupons', query: { page: 1 }}">
                                    <span>{{$t('sidebar.coupons')}}</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>

                    <div class="sub-menu-collapse border-color-4">
                        <ul>
                            <li>
                                <router-link :to="{name: 'coupons', query: { page: 1 }}">
                                    {{$t('sidebar.coupons')}}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </li>
                <!--  -->
                <li class="simple-item">
                    <router-link :to="{name: 'content'}">
                        <span class="color-7"><font-awesome-icon :icon="['fas', 'image']"></font-awesome-icon></span>
                        <span>{{$t('sidebar.content')}}</span>
                    </router-link>
                </li>

                <li class="accordion-item">
                    <a class="accordion-button" :class="!sidebarCollapsedStatus? 'collapsed':''" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMedia" aria-expanded="false" aria-controls="collapseMedia">
                        <span class="color-4"><font-awesome-icon :icon="['fas', 'image']"></font-awesome-icon></span>
                        <span>{{$t('sidebar.media')}}</span>
                    </a>
                    
                    <div v-if="!sidebarCollapsedStatus" id="collapseMedia" class="accordion-collapse collapse" data-bs-parent="#sidebarItems">
                        <ul>
                            <li class="accordion-item-inner">
                                <router-link :to="{name: 'media', query: { page: 1 }}">
                                    <span>{{$t('sidebar.productComb')}}</span>
                                </router-link>
                            </li>
                            <li class="accordion-item-inner">
                                <router-link :to="{name: 'portfolio', query: { page: 1 }}">
                                    <span>{{$t('sidebar.portfolio')}}</span>
                                </router-link>
                            </li>
                            <li class="accordion-item-inner">
                                <router-link :to="{name: 'slider', query: { page: 1 }}">
                                    <span>{{$t('sidebar.slider')}}</span>
                                </router-link>
                            </li>
                            <li class="accordion-item-inner">
                                <router-link :to="{name: 'menu', query: { page: 1 }}">
                                    <span>{{$t('sidebar.menu')}}</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="sub-menu-collapse border-color-4">
                        <ul>
                            <li>
                                <router-link :to="{name: 'media', query: { page: 1 }}">
                                    {{$t('sidebar.coupons')}}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </li>

                <!-- <li class="simple-item">
                    <router-link :to="{name: 'media', query: { page: 1 }}">
                        <span class="color-7"><font-awesome-icon :icon="['fas', 'image']"></font-awesome-icon></span>
                        <span>{{$t('sidebar.media')}}</span>
                    </router-link>
                </li> -->
                <!--  -->
                <li class="simple-item">
                    <router-link :to="{name: 'settings'}">
                        <span class="color-5"><font-awesome-icon :icon="['fas', 'cog']"></font-awesome-icon></span>
                        <span>{{$t('sidebar.settings')}}</span>
                    </router-link>
                </li>
            </ul>
        </div>
        <!-- User Profile Menu -->
        <div class="menu-bottom order-3">
            <ul class="accordion mb-0" id="accordionUserProfile">
                <li class="simple-item simple-item-collapse">
                    <a id="xl-collapse" @click="toggleSidebar">
                        <span v-if="!sidebarCollapsedStatus">
                            <font-awesome-icon :icon="['far', 'arrow-alt-circle-left']"></font-awesome-icon>
                        </span>
                        <span v-else-if="sidebarCollapsedStatus">
                            <font-awesome-icon :icon="['far', 'arrow-alt-circle-right']"></font-awesome-icon>
                        </span>
                        <span>{{$t('sidebar.collapse')}}</span>
                    </a>
                </li>
            </ul>
            <ul class="accordion accordion-bottom" id="accordionUserProfile">
                <li class="accordion-item">
                    <a class="accordion-button" :class="!sidebarCollapsedStatus? 'collapsed':''" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBottom" aria-expanded="false" aria-controls="collapseBottom">
                        <img src="@/assets/images/user.jpg" alt="" width="32" height="32" class="rounded-circle me-2">
                        <strong>{{`${userInfo.first_name} ${userInfo.last_name}`}}</strong>
                    </a>
                    <div id="collapseBottom" class="accordion-collapse collapse" data-bs-parent="#accordionUserProfile">
                        <ul>
                            <li class="simple-item">
                                <a @click="logout">
                                    <span><font-awesome-icon :icon="['fas', 'sign-out-alt']"></font-awesome-icon></span>
                                    <span>{{$t('sidebar.logout')}}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'sidebarCollapsed'
    ],
    data() {
        return{
            userInfo: null,
            appVersion: process.env.VUE_APP_VERSION
        }
    },
    computed: {
        sidebarCollapsedStatus(){
            return this.sidebarCollapsed;
        }
    },
    created(){
        this.userInfo = this.$store.getters['auth/getUserInfo'];
    },
    methods : {
        toggleSidebar() {
            this.$emit('toggle-sidebar');
        },
        logout(){
            this.$store.dispatch("auth/logout", this.aboutUs)
            this.$router.push({ name: 'login'})
        }
    }
}
</script>
