<script setup>
import { ref, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

// Import components
import BodyHeading from '../../components/BodyHeading.vue'
import VInput from '../../components/Input.vue'
import { useNotification } from "@kyvg/vue3-notification"

// Import validations
import useValidate from '@vuelidate/core'
import { required } from '@/utilities/i18n-validators.js'

const store = useStore()
const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const { notify } = useNotification()

// state
const defaultLanguageService = ref({})
const exists = ref(true)
const isLoading = ref(true)
const inputDisabled = ref(false)
const infoImage = ref([])

// setup validation
const service = ref({
  title: '',
})
const serviceRules = computed(() => ({
  service: {
    title: { required }
  },
}))
const v$ = useValidate(serviceRules, { service })

// computed
const selectedLocale = computed(() => store.state.locale.selectedLocale)

const appLocale = computed(() => store.state.locale.appLocale)

const handleClickFunction = computed(() => {
  if (route.name.includes('create'))
    return { handleClick: create }
  else {
      return { handleClick: edit }
  }
})

// watch
watch(() => route.name, async (value) => {
  if (value.includes('downloadDetails')) {
    if (!defaultLanguageService.value?.title) {
      await getService(route.params.id, appLocale.value)
    }
    if (exists.value && selectedLocale.value !== appLocale.value)
      await getService(route.params.id)
  } else {
    isLoading.value = false
  }
}, { immediate: true })

watch(selectedLocale, (value) => {
  if (route.name.includes('Details')) {
    isLoading.value = true
    v$.value.$reset();
    service.value = {
      title: '',
      values: [],
      image: null
    }
    infoImage.value = []
    getService(route.params.id, value)
  }
})

// functions
async function getService(id, language = selectedLocale.value) {
  const urlParams = { language: language, service_id: id }
  return store.dispatch("download/fetch", urlParams).then(
    (response) => {
      exists.value = true
      service.value = response
      if (service.value.image) {
        infoImage.value.push({ id: null, name: service.value.image })
      }
      isLoading.value = false
      if (appLocale.value === language) {
        service.value = response
        defaultLanguageService.value = response
        inputDisabled.value = false
      } else {
        if (response.values.length == 0) {
          service.value = {
            title: '',
            values: defaultLanguageService.value.values.map((el) => ({ service_value_id: el.service_value_id, title: '', duration: el.duration, price: el.price })),
            image: null
          }
        }
        inputDisabled.value = true
      }
    }).catch(() => {
      exists.value = false
      isLoading.value = false
      if (defaultLanguageService?.value.values?.length > 0) {
        service.value = {
          title: '',
          values: defaultLanguageService.value.values.map((el) => ({ service_value_id: el.service_value_id, title: '', duration: el.duration, price: el.price })),
          image: null
        }
        inputDisabled.value = true
      } else {
        notify({
          text: t('errors.notFoundInDefaultLocale', { entity: t('service.entity') }),
          group: "failed"
        })
        router.push({ name: 'downloads', query: { page: 1 } })
      }
    }
    )
}

async function create() {
  const formIscorrect = await v$.value.$validate()
  if (formIscorrect) {
    if (infoImage.value.length > 0)
      service.value.image = infoImage.value[0].name
    else service.value.image = null
    service.value.language = selectedLocale.value;
    store.dispatch("download/create", service.value).then(
      (data) => {
        notify({
          text: data.message,
          group: 'success'
        });
        router.push({ name: 'downloads', params: { id: data.body.id } })
      },
      (error) => {
        let errorMessage = ''
        for (const err in error) {
          errorMessage += `${error[err]} <br>`;
        }
        notify({
          text: errorMessage,
          group: 'failed'
        });
      }
    )
  }
}

async function edit() {
  const formIscorrect = await v$.value.$validate()
  if (formIscorrect) {
    
    const urlParams = { 
      title: service.value.title, 
      service_id: route.params.id, 
      language: selectedLocale.value 
    }
    store.dispatch("download/update", { urlParams, download: route.params.id }).then(
      (message) => {
        notify({
          text: message,
          group: 'success'
        });
      },
      (error) => {
        let errorMessage = ''
        for (const err in error) {
          errorMessage += `${error[err]} <br>`;
        }
        notify({
          text: errorMessage,
          group: 'failed'
        });
      }
    )
  }
}

</script>

<template>
  <BodyHeading :borderColor="'rgb(87, 217, 163)'"
    :title="$route.name.includes('Create') ? $t('download.addButtonTitle') : $t('download.editTitle')"
    v-on="handleClickFunction" :multiLanguageDisabled="$route.name.includes('Create')" />
  <div class="page-body-main-create">
    <div class="card-wrapper">
      <div class="card-title">{{ $t('generalInformation') }}</div>
      <div class="card">
        <div class="card-body">
          <div v-if="!isLoading">
            <div class="mb-4">
              <v-input v-model="v$.service.title.$model" type="text" name="title" label="download.title"
                :placeholder="defaultLanguageService.title" :isRequired="true"
                :validateError="v$.service.title.$error ? v$.service.title.$errors[0].$message : null"></v-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
