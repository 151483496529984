import ProductCombinationsService from '../../../../services/productCombinations.service'

const productCombinations = {
	namespaced: true,
	actions: {
		fetchCount(_, urlParams) {
			return ProductCombinationsService.fetchCount(urlParams).then(
				data => {
					return Promise.resolve(data.body)
				},
				error => {
					return Promise.reject(error.response.data.errors)
				}
			)
		},
		setDefault(_, urlParams) {
			return ProductCombinationsService.setDefault(urlParams).then(
				data => {
					return Promise.resolve(data.message)
				},
				error => {
					return Promise.reject(error.response.data.errors)
				}
			)
		},
		updateActivation(_, urlParams) {
			return ProductCombinationsService.editActivation(urlParams).then(
				data => {
					return Promise.resolve(data.message)
				},
				error => {
					return Promise.reject(error.response.data.errors)
				}
			)
		},
		update(_, { urlParams, combination }) {
			return ProductCombinationsService.edit(urlParams, combination).then(
				data => {
					return Promise.resolve(data.message)
				},
				error => {
					return Promise.reject(error.response.data.errors)
				}
			)
		},
		fetch(_, urlParams) {
			return ProductCombinationsService.fetch(urlParams).then(
				data => {
					return Promise.resolve(data.body)
				},
				error => {
					return Promise.reject(error.response.data.errors)
				}
			)
		},
		getAll(_, { urlParams, queryParams }) {
			return ProductCombinationsService.getAll(urlParams, queryParams).then(
				data => {
					return Promise.resolve(data.body)
				},
				error => {
					return Promise.reject(error.response.data.errors)
				}
			)
		},
		sortImages(_, { urlParams, images }) {
			const sortedImages = {}
			sortedImages.images = images.map((el, index) => { return { id: el.id, arrange: index + 1 } })
			return ProductCombinationsService.sortImages(urlParams, sortedImages).then(
				data => {
					return Promise.resolve(data.message)
				},
				error => {
					return Promise.reject(error.response.data.errors)
				}
			)
		}
	}
}

export default productCombinations
