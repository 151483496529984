import axios from 'axios';
import Constants from '../constants';
import authHeader from './auth-header'


class HomeSliderService {
    fetch(params) {
      return axios
          .get(Constants['SERVER_URL']+'homes/sliders/fetch/'+`${params.slider_id}/${params.language}`, {headers:authHeader()})
          .then(response => {
              return response.data;
          })
    }
    getAll(params) {
        return axios
            .get(Constants['SERVER_URL']+'homes/sliders/search', {params: params,headers:authHeader()})
            .then(response => {
                return response.data;
            })
    }
    create(slider) {
        return axios
          .post(Constants['SERVER_URL']+'homes/sliders/create', slider, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    createTranslation(slider) {
        return axios
          .post(Constants['SERVER_URL']+'homes/sliders/add/translation/'+ slider.slider_id, slider, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    edit(slider){
      return axios
        .patch(Constants['SERVER_URL']+'homes/sliders/update/'+ `${slider.slider_id}`, slider, {headers:authHeader()})
        .then(response => {    
          return response.data;
      })
    }
    delete(slider_id) {
        return axios
          .delete(Constants['SERVER_URL']+'homes/sliders/delete/'+ slider_id, {headers:authHeader()})
          .then(response => {    
            return response.data;
        })
    }
    deleteSlide(slider_id) {
      return axios
        .delete(Constants['SERVER_URL']+'homes/sliders/images/delete/slider/'+ slider_id, {headers:authHeader()})
        .then(response => {    
          return response.data;
      })
    }
}



export default new HomeSliderService();