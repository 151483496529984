import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faAlignJustify,
  faAlignLeft,
  faAngleDoubleDown,
  faAngleDoubleUp,
  faBars,
  faCalendarAlt,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircleNotch,
  faClone as fasClone,
  faClock, faCog,
  faCoins,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExclamationCircle,
  faEye,
  faFile,
  faFileDownload,
  faFilter,
  faGlobeAmericas,
  faHome,
  faHourglass,
  faImage,
  // faPercent,
  faPlus,
  faShareSquare,
  faShoppingCart,
  faSignOutAlt,
  faSort,
  faTable,
  faTachometerAlt,
  faTimes,
  faTrash,
  faTruck,
  faUser,
  faUserCircle,
  faUserPlus,
  faUsers
} from '@fortawesome/free-solid-svg-icons'
import { faArrowAltCircleLeft, faArrowAltCircleRight, faBell, faClone as farClone } from '@fortawesome/free-regular-svg-icons'

library.add(
  faAlignJustify,
  faAlignLeft,
  faAngleDoubleDown,
  faAngleDoubleUp,
  faBars,
  faCalendarAlt,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircleNotch,
  fasClone,
  faClock, faCog,
  faCoins,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExclamationCircle,
  faEye,
  faFile,
  faFileDownload,
  faFilter,
  faGlobeAmericas,
  faHome,
  faHourglass,
  faImage,
  // faPercent,
  faPlus,
  faShareSquare,
  faShoppingCart,
  faSignOutAlt,
  faSort,
  faTable,
  faTachometerAlt,
  faTimes,
  faTrash,
  faTruck,
  faUser,
  faUserCircle,
  faUserPlus,
  faUsers
)
library.add(faArrowAltCircleLeft, faArrowAltCircleRight, faBell, farClone)

export default {
  install: (app) => {
    app.component("font-awesome-icon", FontAwesomeIcon)
  }
}
