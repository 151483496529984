<template>
   <BodyHeading v-if="$route.name.includes('Create')" :borderColor="'rgb(230, 127, 216)'" :title="$t('home.addButtonTitle')" 
      @handleClick="create" :multiLanguageDisabled="true"></BodyHeading>
   
   <BodyHeading v-if="$route.name.includes('Details')" :borderColor="'rgb(230, 127, 216)'" :title="$t('home.editTitle')" 
     v-on="isExist ? { handleClick: edit } : { handleClick: createTranslation }" :multiLanguageDisabled="false"></BodyHeading>
   <div class="page-body-main-create">
      <div class="card-wrapper">
         <div class="card-title">{{$t('generalInformation')}}</div>
         <div class="card">
            <div class="card-body">
              <!--  -->
              <div class="row" v-if="!isLoading">
                  <div class="mb-4">
                     <v-input type="text" name="title" label="home.title" :placeholder="defaultLanguageHome.title" v-model="home.title" :isRequired="true"
                        :validateError="v$.home.title.$error? v$.home.title.$errors[0].$message : null"></v-input>
                  </div>
                  <div class="mb-4">
                     <TextEditor name="description" label="home.description" title v-model="home.description" :validateError="v$.home.description && v$.home.description.$error? v$.home.description.$errors[0].$message : null"
                     ></TextEditor>
                     <!--  -->
                  </div>
                  <div class="mb-4" v-if="!isMenuInputDisable">
                     <MultiSelect label="home.slider"
                        :options="async (query) => {return await getSliders(query)}" :isRequired="true" 
                        :modelValue="home.slider" @update:modelValue="home.slider = $event"
                        :searchable="true" :delay="0" :resolveOnLoad="true" :object="true"
                        :validateError="v$.home.slider.$errors[0]? v$.home.slider.$errors[0].$message : null"
                     />
                  </div>
                  <div class="mb-4" v-if="!isMenuInputDisable">
                     <MultiSelect label="home.sections"
                        :options="async (query) => {return await getSections(query)}" :isRequired="true"
                        :modelValue="home.sections_t" @update:modelValue="home.sections_t = $event"
                        :searchable="true" :delay="0" :resolveOnLoad="true" mode="tags" :object="true"
                        :validateError="v$.home.sections_t.$errors[0]? v$.home.sections_t.$errors[0].$message : null"
                     />
                  </div>
              </div>
            </div>
         </div>
      </div>
      <!--  -->
      <div class="card-wrapper">
         <div class="card-title">SEO</div>
         <div class="card">
            <div class="card-body">
               <div class="row" v-if="!isLoading">
                  <div class="mb-4">
                     <v-input type="text" name="seo_title" label="Meta Titel" v-model="home.seo_title" :isRequired="true"></v-input>
                  </div>
                
                  <div class="mb-4">
                     <v-textarea rows="6" cols="10" name="seo_description" label="Meta Description" v-model="home.seo_description"></v-textarea>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import BodyHeading from '../../components/BodyHeading.vue';
import Textarea from '../../components/Textarea.vue';
import TextEditor from '../../components/TextEditor.vue';
import Input from '../../components/Input.vue';
import MultiSelect from '../../components/MultiSelect.vue';
import useValidate from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';
import { required } from '@/utilities/i18n-validators.js';

// Import TextEditor helpers
import useCkeditorHelper from '../../composables/useCkeditorHelper.js'

   export default {
      components: {
         BodyHeading,
         'v-input': Input,
         'v-textarea': Textarea,
         MultiSelect,
         TextEditor
      },
      setup() {
         const { parseForEditor, parseForServer } = useCkeditorHelper()
         return { parseForEditor, parseForServer }
      },
      data() {
         return{
            v$: useValidate(),
            home: {sections_t: []},
            defaultLanguageHome: {sections_t: []},
            isExist:true,
            isLoading: true,
            isMenuInputDisable: false,
         }
      },
      validations() {
         return {
            home: {
               title: {required},
               // seo_title: {required},
               // seo_description: {required},
               // description: {required},
               sections_t: {required: requiredIf(() => {
                     return !this.isMenuInputDisable
                  }) 
               },
               slider: {required: requiredIf(() => {
                     return !this.isMenuInputDisable
                  }) 
               },
            },
         }
      },
      computed: {
         currentLanguage() {
            return this.$store.state.locale.selectedLocale
         },
         languages() {
            return this.$store.state.locale.languages
         },
         currentLocale() {
            return this.$store.state.locale.appLocale
         }
      },
      watch: {
         '$route': {
            immediate: true,
            handler() {
               if(this.$route.name.includes('Details')){
                  this.getHome(this.$route.params.id)
               }else{
                  this.isLoading = false
               }
            }
         },
         'languages': {
            immediate: true,
            handler(){
               if(this.$route.name.includes('Details') && this.languages){
                  if(!this.isExist && this.currentLocale === this.currentLanguage){
                     this.$router.push({ name: 'homes', query: { page: 1 } })
                  }
                  this.getHome(this.$route.params.id, this.currentLocale)
                  if(this.currentLocale !== this.currentLanguage){
                     // disable menu input
                     if(!this.isMenuInputDisable){
                        this.isMenuInputDisable = true;
                     }
                  }else if(this.currentLocale === this.currentLanguage && this.isMenuInputDisable){
                     this.isMenuInputDisable = false;
                  }
               }
            }
         },
         currentLanguage() {
            if(this.$route.name.includes('Details')){
               this.isLoading = true
               this.v$.$reset();
               this.home = {sections_t: []}
               this.getHome(this.$route.params.id, this.currentLanguage)
               this.getHome(this.$route.params.id, this.currentLocale)
               // disable menu input
               if(this.languages){
                  if(this.currentLocale !== this.currentLanguage && !this.isMenuInputDisable){
                        this.isMenuInputDisable = true;
                     }else if(this.currentLocale === this.currentLanguage && this.isMenuInputDisable){
                        this.isMenuInputDisable = false;
                  }
               }
            }
         }
      },
      methods: {
         create() {
            this.v$.$validate()
            if(!this.v$.$error){
               let home = {...this.home};
               home.language = this.currentLanguage;
               if(home.description){
                  home.description = this.parseForServer(home.description)
               }
               home.sections_id = [];
               if(home.sections_t[0]){
                  home.sections_t.forEach(el => {
                     home.sections_id.push(el.value)
                  })
               }
               if(home.slider){
                  home.slider_id = home.slider.value
               }
               this.$store.dispatch("home/create", home).then(
                  (data) => {
                     // console.log(...message);
                     this.$notify({
                           text: data.message,
                           group: 'success'
                     });
                     this.$router.push({ name: 'homeDetails', params: { id: data.body.home_id}})
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         },
         createTranslation(){
            this.v$.$validate()
            if(!this.v$.$error){
               let home = {...this.home};
               home.language = this.currentLanguage;
               if(home.description){
                  home.description = this.parseForServer(home.description)
               }
               if(home.slider){
                  home.slider_id = home.slider.value
               }
               home.home_id = this.defaultLanguageHome.home_id;
               this.$store.dispatch("home/createTranslation", home).then(
                  (message) => {
                     // console.log(...message);
                     this.isExist = true;
                     this.$notify({
                           text: message,
                           group: 'success'
                     });
                     // this.$router.push({name: 'dashboard'})
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         },
         edit() {
            this.v$.$validate()
            if(!this.v$.$error){
              let home = {...this.home};
               home.language = this.currentLanguage;
               if(home.description){
                  home.description = this.parseForServer(home.description)
               }
               home.sections_id = [];
               if(home.sections_t[0]){
                  home.sections_t.forEach(el => {
                     home.sections_id.push(el.value)
                  })
               }else if(this.defaultLanguageHome.home_info.sections_t[0]){
                  this.defaultLanguageHome.home_info.sections_t.forEach(el => {
                     home.sections_id.push(el.h_section_id)
                  })
               }
               if(home.slider){
                  home.slider_id = home.slider.value;
               }else if(this.defaultLanguageHome.home_info.slider){
                  home.slider_id = this.defaultLanguageHome.home_info.slider.id
               }
              home.home_id = this.defaultLanguageHome.home_id;
               // console.log(this.menu)
               this.$store.dispatch("home/update", home).then(
                  (message) => {
                     // console.log(...message);
                     this.$notify({
                           text: message,
                           group: 'success'
                     });
                     // this.$router.push({name: 'dashboard'})
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         },
         getHome(id, language = this.currentLanguage) {
            const params = {language: language, home_id: id}
            this.$store.dispatch("home/fetch", params).then(
                (response) => {
                   if(this.currentLocale === language){
                      this.defaultLanguageHome = response;
                      if(this.currentLanguage === this.currentLocale){
                         this.isExist = true;
                         this.home.title = response.home_info.title ?? '';
                         this.home.seo_title = response.seo_meta?.title ?? '';
                         this.home.seo_meta = response.seo_meta?.meta ?? '';
                         this.home.seo_description = response.seo_meta?.description ?? '';
                         if(response.description){
                            this.home.description = this.parseForEditor(response.description)
                         }
                         if(response.home_info){
                           if(response.home_info.slider){
                            this.home.slider = {label:response.home_info.slider.title ,value: response.home_info.slider.id};
                           }
                           if(response.home_info.sections_t && !this.home.sections_t.length){
                              response.home_info.sections_t.forEach(el => {
                                 this.home.sections_t.push({label:el.title ,value: el.h_section_id})
                              })
                           }
                         }
                         
                         this.isLoading = false
                      }
                   }else{
                      this.isExist = true;
                      this.home.title = response.home_info.title;
                         if(response.description){
                            this.home.description = this.parseForEditor(response.description)
                         }
                         if(response.home_info){
                           
                           if(response.home_info.slider){
                            this.home.slider = {label:response.home_info.slider.title ,value: response.home_info.slider.id};
                           }
                           if(response.home_info.sections_t && !this.home.sections_t.length){
                              response.home_info.sections_t.forEach(el => {
                                 this.home.sections_t.push({label:el.title ,value: el.h_section_id})
                              })
                           }
                         }
                      this.isLoading = false
                   }
                },
                () => {
                  this.isExist = false;
                  if(this.currentLocale === this.currentLanguage){
                     this.$router.push({ name: 'homes', query: { page: 1 } })
                  }
                  this.isLoading = false;
                }
            )
         },
         getSliders(query) {
            const params = {language: this.currentLanguage, per_page: 30}
            if(query){
                params.title = query
            }
            return this.$store.dispatch("homeSlider/getAll", params).then(
                (response) => {
                    let sliders = []
                    response.data.forEach(el => {
                        sliders.push({label:el.title ,value: el.id})
                    })
                    return sliders
                },
                (error) => {
                this.$notify({
                        text: error,
                        group: 'failed'
                    });
                }
            )
         },
         getSections(query) {
            const params = {language: this.currentLanguage, per_page: 30}
            if(query){
                params.title = query
            }
            return this.$store.dispatch("homeSection/getAll", params).then(
                (response) => {
                    let sections = []
                    response.data.forEach(el => {
                        sections.push({label:el.title ,value: el.h_section_id})
                    })
                    return sections
                },
                (error) => {
                this.$notify({
                        text: error,
                        group: 'failed'
                    });
                }
            )
         },
      }
   }
</script>
