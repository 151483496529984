<script setup>
import BodyHeading from '../../components/BodyHeading.vue'
import VInput from '../../components/Input.vue'
import { ref, computed, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import useValidate from '@vuelidate/core'
import { required, minLength } from '@/utilities/i18n-validators.js'
import { ValidateEach } from '@vuelidate/components'
import { useNotification } from "@kyvg/vue3-notification"
const router = useRouter()
const route = useRoute()
const store = useStore()
const { t } = useI18n()
const { notify } = useNotification()

const option = ref({
   title: '',
   option_values: []
})
const defaultLanguageOption = ref({})
const exists = ref(true)
const isLoading = ref(true)
const inputDisabled = ref(false) // Can't add or remove values for translation
const newValue = ref('')

const optionRules = computed(() => ({
   option: {
      title: { required },
      option_values: { required, minLength: minLength(1) }
   },
}))
const optionValueRules = {
   title: { required },
}
const v$ = useValidate(optionRules, { option })

const selectedLocale = computed(() => store.state.locale.selectedLocale)
const appLocale = computed(() => store.state.locale.appLocale)
const handleClickFunction = computed(() => {
   if (route.name.includes('Create'))
      return { handleClick: create }
   else {
      if (exists.value)
         return { handleClick: edit }
      else return { handleClick: createTranslation }
   }
})

watch(() => route.name, async (value) => {
   if (value?.includes('Details')) {
      if (!defaultLanguageOption.value?.title) {
         await getOption(route.params.id, appLocale.value)
      }
      if (exists.value && selectedLocale.value !== appLocale.value)
         await getOption(route.params.id)
   } else {
      isLoading.value = false
   }
}, { immediate: true })

watch(selectedLocale, (value) => {
   if (route.name.includes('Details')) {
      isLoading.value = true
      v$.value.$reset()
      option.value = {
         title: '',
         option_values: []
      }
      getOption(route.params.id, value)
   }
})

async function getOption(id, language = selectedLocale.value) {
   const urlParams = { language: language, option_id: id }
   store.dispatch("option/fetch", urlParams).then(
      (response) => {
         exists.value = true
         option.value = response
         isLoading.value = false
         if (appLocale.value === language) {
            defaultLanguageOption.value = response
            inputDisabled.value = false
         } else {
            inputDisabled.value = true
         }
      },
      () => {
         exists.value = false
         isLoading.value = false
         if (defaultLanguageOption?.value.option_values?.length > 0) {
            option.value = {
               title: '',
               option_values: defaultLanguageOption.value.option_values.map((el) => ({ option_value_id: el.option_value_id, title: '' }))
            }
            inputDisabled.value = true
         } else {
            notify({
               text: t('errors.notFoundInDefaultLocale', { entity: t('option.entity') }),
               group: "failed"
            })
            router.push({ name: 'options', query: { page: 1 } })
         }
      }
   )
}

function create() {
   v$.value.$touch()
   v$.value.$validate()
   if (!v$.value.$error) {
      option.value.language = selectedLocale.value
      store.dispatch("option/create", option.value).then(
         (data) => {
            notify({
               text: data.message,
               group: 'success'
            });
            router.push({ name: 'optionDetails', params: { id: data.body.option_id } })
         },
         (error) => {
            let errorMessage = ''
            for (const err in error) {
               errorMessage += `${error[err]} <br>`;
            }
            notify({
               text: errorMessage,
               group: 'failed'
            });
         }
      )
   }
}

function createTranslation() {
   v$.value.$validate()
   if (!v$.value.$error) {
      option.value.language = selectedLocale.value;
      option.value.option_id = defaultLanguageOption.value.option_id;
      store.dispatch("option/createTranslation", option.value).then(
         (message) => {
            exists.value = true;
            notify({
               text: message,
               group: 'success'
            });
         },
         (error) => {
            let errorMessage = ''
            for (const err in error) {
               errorMessage += `${error[err]} <br>`;
            }
            notify({
               text: errorMessage,
               group: 'failed'
            });
         }
      )
   }
}

function edit() {
   v$.value.$validate()
   if (!v$.value.$error) {
      option.value.language = selectedLocale.value;
      option.value.option_id = defaultLanguageOption.value.option_id;
      store.dispatch("option/update", option.value).then(
         (message) => {
            notify({
               text: message,
               group: 'success'
            });
         },
         (error) => {
            let errorMessage = ''
            for (const err in error) {
               errorMessage += `${error[err]} <br>`;
            }
            notify({
               text: errorMessage,
               group: 'failed'
            });
         }
      )
   }
}

function addNewValue() {
   if (newValue.value || newValue.value !== "") {
      option.value.option_values.push({ title: newValue.value })
      newValue.value = ''
   }
}

function removeValue(index) {
   const optionValue = option.value.option_values[index]
   store.dispatch("option/deleteOptionValue", optionValue.option_value_id).then(
         (message) => {
            notify({
               text: message,
               group: 'success'
            });
            option.value.option_values.splice(index, 1)
            if (selectedLocale.value !== appLocale.value)
               defaultLanguageOption.value.option_values.splice(index, 1)
         },
         (error) => {
            let errorMessage = ''
            for (const err in error) {
               errorMessage += `${error[err]} <br>`;
            }
            notify({
               text: errorMessage,
               group: 'failed'
            });
         }
      )
}
</script>

<template>
   <BodyHeading :borderColor="'rgb(87, 217, 163)'"
      :title="$route.name.includes('Create') ? $t('option.addButtonTitle') : $t('option.editTitle')"
      v-on="handleClickFunction" :multiLanguageDisabled="$route.name.includes('Create')" />

   <div class="page-body-main-create">
      <div class="card-wrapper">
         <div class="card-title">{{ $t('generalInformation') }}</div>
         <div class="card">
            <div class="card-body">
               <div class="row" v-if="!isLoading">
                  <div class="mb-4">
                     <v-input v-model="v$.option.title.$model" type="text" name="title" label="option.title"
                        :placeholder="defaultLanguageOption.title" :isRequired="true"
                        :validateError="v$.option.title.$error ? v$.option.title.$errors[0].$message : null"></v-input>
                  </div>
                  <div class="mb-4">
                     <label for="values" class="form-label is-required">{{ $t('option.values') }}</label>
                     <div id="values">
                        <ValidateEach v-for="(item, index) in option.option_values" :key="item.id" :state="item"
                           :rules="optionValueRules">
                           <template #default="{ v }">
                              <div class="mb-4">
                                 <div class="input-group">
                                    <input class="form-control" type="text" v-model="v.title.$model"
                                       :placeholder="defaultLanguageOption.option_values ? defaultLanguageOption.option_values[index].title : ''"
                                       :class="v.title.$error ? 'form-controll-invalid' : ''">
                                    <button class="btn button-stop px-3" :disabled="inputDisabled"
                                       @click="removeValue(index)">
                                       <font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon>
                                    </button>
                                 </div>
                                 <div v-for="(error, errorIndex) in v.title.$errors" :key="errorIndex"
                                    class="invalid-feedback">
                                    {{ error.$message }}
                                 </div>
                              </div>
                           </template>
                        </ValidateEach>
                     </div>
                     <div class="mb-4">
                        <div class="input-group">
                           <input v-model="newValue" type="text" class="form-control" :disabled="inputDisabled"
                              :placeholder="t('option.newValue')">
                           <button class="btn button-action px-3" :disabled="inputDisabled" @click="addNewValue">
                              <font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon>
                           </button>
                           <div v-for="(error, errorIndex) in v$.option.option_values.$errors" :key="errorIndex"
                              class="invalid-feedback">
                              {{ error.$message }}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
