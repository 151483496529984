<template>
    <BodyHeading :borderColor="'rgb(230, 127, 216)'" :title="$t('home.homesTitle')" :count="homes.total" 
      :multiLanguageDisabled="false" :listHeading="true" :btnTitle="$t('home.addButtonTitle')" :createBtnLink="'homeCreate'" :backLink="'content'" :backIcon="'image'"></BodyHeading>
    <div class="page-body-main-list d-grid">
        <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" style="width: 5%"><span>#</span></th>
                            <th scope="col"><span>{{$t('home.title')}}</span></th>
                            <th scope="col"><span>{{$t('home.status')}}</span></th>
                            <th scope="col" style="width: 5%"><span>{{$t('actions')}}</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(home, index) in homes.data" :key="home.id">
                            <td>{{index + 1 + (homes.current_page - 1) * ITEMS_PER_PAGE}}</td>
                            <td>{{home.title}}</td>
                            <td>
                                <v-switch v-model="home.is_active" @click="changeActivity(index)" :disabled="isSwitchLocked"></v-switch>
                            </td>
                            <td :style="home.id === ParentZindex? { zIndex: 2}: null">
                                <div class="dropdown actions-dropdown">
                                    <button @click="changeParentZindex(home.id)" class="btn btn-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <font-awesome-icon :icon="['fas', 'ellipsis-h']" size="sm"></font-awesome-icon>
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a @click="$router.push({ name: 'homeDetails', params: { id: home.id } })" class="dropdown-item">{{$t('edit')}}</a></li>
                                        <li><a class="dropdown-item warning" @click="deleteItem(home.id)">{{$t('delete')}}</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
            </table>
        </div>
        <Pagination v-if="homes.total>homes.per_page" :lastPage="homes.last_page"
        :activePage="homes.current_page" @handleClick="changePage"></Pagination>
    </div>
    
</template>


<script>
import BodyHeading from '../../components/BodyHeading.vue';
import Switch from '../../components/Switch.vue';
import Pagination from '../../components/Pagination.vue';
import { ITEMS_PER_PAGE } from '../../constants';


export default {
    components: {
        BodyHeading,
        Pagination,
        'v-switch': Switch
    },
    data() {
        return{
            homes: {},
            ParentZindex: null,
            isSwitchLocked: false
        }
    },
    created() {
        this.ITEMS_PER_PAGE = ITEMS_PER_PAGE
        if(Object.entries(this.$route.query).length === 0 || this.$route.query.page === ''){
            this.$router.push({ name: 'homes', query: { page: 1 } })
        }
        this.getHomes()
    },
    watch: {
        '$route.query'(){
            this.getHomes()
        },
        currentLanguage() {
            this.getHomes()
        }
    },
    computed: {
        currentLanguage() {
            return this.$store.state.locale.selectedLocale
        },
    },
    methods: {
        changeParentZindex(id) {
            this.ParentZindex = id
        },
        getHomes() {
            const params = {language: this.currentLanguage, per_page: ITEMS_PER_PAGE, ...this.$route.query}
            this.$store.dispatch("home/getAll", params).then(
                (response) => {
                    this.homes = response;
                },
                (error) => {
                this.$notify({
                        text: error,
                        group: 'failed'
                    });
                }
            )
        },
        deleteItem(id){
            
            this.$swal().then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("home/delete", id).then(
                        (message) => {
                            this.$notify({
                                text: message,
                                group: 'success'
                            });
                            this.getHomes()
                        },
                        (error) => {
                            let errorMessage = ''
                            for (const err in error) {
                                errorMessage += `${error[err]} <br>`;
                            }
                        this.$notify({
                                text: errorMessage,
                                group: 'failed'
                            });
                        }
                    )
                } 
            })            
        },
        changePage(page=1){
            this.$router.push({ name: 'homes', query: { page: page } })
            this.getHomes();
        },
        changeActivity(index){
            this.isSwitchLocked = true;
            this.homes.data.forEach(home => {
                home.is_active = 0
            });
            let params = {home_id: this.homes.data[index].id}
            this.$store.dispatch("home/activate", params).then(
                (message) => {
                    this.getHomes(this.$route.query.page);
                    this.$notify({
                        text: message,
                        group: 'success'
                    });
                    this.isSwitchLocked = false
                },
                (error) => {
                this.$notify({
                        text: error,
                        group: 'failed'
                    });
                    this.isSwitchLocked = false
                }
            )
        }
    }
}
</script>
