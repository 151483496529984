<script setup>
import { ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
// import { useI18n } from 'vue-i18n',watch 

// Import components
import BodyHeading from '../../components/BodyHeading.vue'
import VInput from '../../components/Input.vue'
import FileUploader from '../../components/FileUploader.vue'
import { useNotification } from "@kyvg/vue3-notification"

// Import validations
import useValidate from '@vuelidate/core'
import { required } from '@/utilities/i18n-validators.js'

const store = useStore()
const router = useRouter()
const route = useRoute()
// const { t } = useI18n()
const { notify } = useNotification()

// state
const defaultLanguageService = ref({})
const exists = ref(true)
const isLoading = ref(false)
// const inputDisabled = ref(false)
const infoImage = ref([])

// setup validation
const service = ref({
  title: '',
  image: null
})
const serviceRules = computed(() => ({
  service: {
    title: { required }
  },

}))
const v$ = useValidate(serviceRules, { service })

// computed
const selectedLocale = computed(() => store.state.locale.selectedLocale)

// const appLocale = computed(() => store.state.locale.appLocale)

const handleClickFunction = computed(() => {
  if (route.name.includes('createFile'))
    return { handleClick: create }
  else {
    if (exists.value)
      return { handleClick: edit }
    else return { handleClick: createTranslation }
  }
})

const translationId = computed(() => {
  const service_translation_id = route.name.includes('updateFile') ? service.value.id : null
  return { service_translation_id }
})

// // watch
// watch(() => route.name, async (value) => {
//   console.log("🚀 ~ watch ~ value:", value)
//   if (value.includes('createFile')) {
//     if (!defaultLanguageService.value?.title) {
//     //   await getService(route.params.id, appLocale.value)
//     }
//     if (exists.value && selectedLocale.value !== appLocale.value)
//     //   await getService(route.params.id)
//   } else if (route.name.includes('updateFile')) {
//     isLoading.value = true
//     v$.value.$reset();
//     service.value = {
//       title: '',
//       values: [],
//       image: null
//     }
//     infoImage.value = []
//     getService(route.params.id, value)
//   }
// }, { immediate: true })

// watch(selectedLocale, (value) => {
//   if (route.name.includes('updateFile')) {
//     isLoading.value = true
//     v$.value.$reset();
//     service.value = {
//       title: '',
//       values: [],
//       image: null
//     }
//     infoImage.value = []
//     getService(route.params.id, value)
//   }
// })

// // functions
// async function getService(id, language = selectedLocale.value) {
//   const urlParams = { language: language, service_id: id }
//   return store.dispatch("downloadfiles/fetch", urlParams).then(
//     (response) => {
//       console.log("🚀 ~ getService ~ response:", response)
//       exists.value = true
//       service.value = response
//       if (service.value.image) {
//         infoImage.value.push({ id: null, name: service.value.image })
//       }
//       isLoading.value = false
//       if (appLocale.value === language) {
//         service.value = response
//         defaultLanguageService.value = response
//         inputDisabled.value = false
//       } else {
//         if (response.values.length == 0) {
//           service.value = {
//             title: '',
//             values: defaultLanguageService.value.values.map((el) => ({ service_value_id: el.service_value_id, title: '', duration: el.duration, price: el.price })),
//             image: null
//           }
//         }
//         inputDisabled.value = true
//       }
//     }).catch(() => {
//       exists.value = false
//       isLoading.value = false
//       if (defaultLanguageService?.value.values?.length > 0) {
//         service.value = {
//           title: '',
//           values: defaultLanguageService.value.values.map((el) => ({ service_value_id: el.service_value_id, title: '', duration: el.duration, price: el.price })),
//           image: null
//         }
//         inputDisabled.value = true
//       } else {
//         notify({
//           text: t('errors.notFoundInDefaultLocale', { entity: t('service.entity') }),
//           group: "failed"
//         })
//         router.push({ name: 'AddFiles', query: { page: 1 } })
//       }
//     }
//     )
// }

async function create() {
  const formIscorrect = await v$.value.$validate()
  if (formIscorrect) {
    if (infoImage.value.length > 0)
      service.value.image = infoImage.value[0].name
    else service.value.image = null
    service.value.language = selectedLocale.value;
    service.value.download_id = route.params.id;

    store.dispatch("downloadfiles/create", service.value).then(
      (data) => {
        notify({
          text: data.message,
          group: 'success'
        });
        router.push({ name: 'AddFiles', params: { id: data.body.service_id } })
      },
      (error) => {
        let errorMessage = ''
        for (const err in error) {
          errorMessage += `${error[err]} <br>`;
        }
        notify({
          text: errorMessage,
          group: 'failed'
        });
      }
    )
  }
}

async function createTranslation() {
  const formIscorrect = await v$.value.$validate()
  if (formIscorrect) {
    const translatedService = structuredClone(service.value)
    if (infoImage.value.length > 0)
      translatedService.image = infoImage.value[0].name
    else translatedService.image = null
    translatedService.values = translatedService.values.map(item => ({ service_value_id: item.service_value_id, title: item.title }))
    translatedService.language = selectedLocale.value;
    const urlParams = { service_id: defaultLanguageService.value.service_id }
    store.dispatch("downloadfiles/createTranslation", { urlParams, service: translatedService }).then(
      (message) => {
        exists.value = true;
        notify({
          text: message,
          group: 'success'
        });
      },
      (error) => {
        let errorMessage = ''
        for (const err in error) {
          errorMessage += `${error[err]} <br>`;
        }
        notify({
          text: errorMessage,
          group: 'failed'
        });
      }
    )
  }
}

async function edit() {
  const formIscorrect = await v$.value.$validate()
  if (formIscorrect) {
    if (infoImage.value.length > 0)
      service.value.image = infoImage.value[0].name
    else service.value.image = null
    const urlParams = { service_id: defaultLanguageService.value.service_id, language: selectedLocale.value }
    store.dispatch("downloadfiles/update", { urlParams, service: service.value }).then(
      (message) => {
        notify({
          text: message,
          group: 'success'
        });
      },
      (error) => {
        let errorMessage = ''
        for (const err in error) {
          errorMessage += `${error[err]} <br>`;
        }
        notify({
          text: errorMessage,
          group: 'failed'
        });
      }
    )
  }
}

</script>

<template>
  <BodyHeading :borderColor="'rgb(87, 217, 163)'"
    :title="$route.name.includes('createFile') ? $t('subdownload.addButtonTitle') : $t('subdownload.editTitle')"
    v-on="handleClickFunction" :multiLanguageDisabled="$route.name.includes('createFile')" />
  <div class="page-body-main-create">
    <div class="card-wrapper">
      <div class="card-title">{{ $t('generalInformation') }}</div>
      <div class="card">
        <div class="card-body">
          <div v-if="!isLoading">
            <div class="mb-4">
              <v-input v-model="v$.service.title.$model" type="text" name="title" label="subdownload.title"
                :placeholder="defaultLanguageService.title" :isRequired="true"
                :validateError="v$.service.title.$error ? v$.service.title.$errors[0].$message : null"></v-input>
            </div>
            <div class="col-12 mb-4">
              <FileUploader v-model="infoImage" :isRequired="true" type="file" name="download-info-image-uploader"
                label="subdownload.chooseInfoImageLabel" fetchUrl="image/download" uploadUrl="download/images/upload"
                deleteUrl="download/images/delete" :multiple="false" :ownerId="translationId" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
