import axios from 'axios';
import Constants from '../constants';
import authHeader from './auth-header'


class Media {
  combinations(queryParams) {
    return axios
      .get(Constants['SERVER_URL'] + 'image/combinations', { params: queryParams, headers: authHeader() })
      .then(response => {
        return response.data;
      })
  }

  invoice_products(queryParams) {
    return axios
      .get(Constants['SERVER_URL'] + 'image/menu-cover', { params: queryParams, headers: authHeader() })
      .then(response => {
        return response.data;
      })
  }

  portfolio(queryParams) {
    return axios
      .get(Constants['SERVER_URL'] + 'image/portfolio', { params: queryParams, headers: authHeader() })
      .then(response => {
        return response.data;
      })
  }

  product(queryParams) {
    return axios
      .get(Constants['SERVER_URL'] + 'image/product-files', { params: queryParams, headers: authHeader() })
      .then(response => {
        return response.data;
      })
  }

  slider_image(queryParams) {
    return axios
      .get(Constants['SERVER_URL'] + 'image/slider', { params: queryParams, headers: authHeader() })
      .then(response => {
        return response.data;
      })
  }

  menu(queryParams) {
    return axios
      .get(Constants['SERVER_URL'] + 'image/menu-cover', { params: queryParams, headers: authHeader() })
      .then(response => {
        return response.data;
      })
  }
  
  combination_update(service) {
    return axios
      .post(Constants['SERVER_URL'] + 'image/rename-combination', service , { headers: authHeader() })
      .then(response => {
        return response.data;
      })
  }

  portfolio_update(service) {
    return axios
      .post(Constants['SERVER_URL'] + 'image/rename-portfolio', service , { headers: authHeader() })
      .then(response => {
        return response.data;
      })
  }

  update_slider(service) {
    return axios
      .post(Constants['SERVER_URL'] + 'image/rename-slider', service , { headers: authHeader() })
      .then(response => {
        return response.data;
      })
  }

  save_menu(service) {
    return axios
      .post(Constants['SERVER_URL'] + 'image/rename-menu-cover', service , { headers: authHeader() })
      .then(response => {
        return response.data;
      })
  }
  
}

export default new Media();
