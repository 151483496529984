import axios from 'axios';
import Constants from '../constants';
import authHeader from './auth-header'


class UserService {
    fetch(params) {
      return axios
          .get(Constants['SERVER_URL']+'users/fetch/'+`${params.user_id}`, {headers:authHeader()})
          .then(response => {
              return response.data;
          })
    }
    getAll(params) {
        return axios
            .get(Constants['SERVER_URL']+'users/search', {params: params,headers:authHeader()})
            .then(response => {
                return response.data;
            })
    }
    create(user) {
        return axios
          .post(Constants['SERVER_URL']+'users/create', user, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    createTranslation(user) {
        return axios
          .post(Constants['SERVER_URL']+'users/add/translation/'+ user.user_id, user, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    edit(user){
      return axios
        .patch(Constants['SERVER_URL']+'users/update/'+ `${user.user_id}`, user, {headers:authHeader()})
        .then(response => {    
          return response.data;
      })
    }
    delete(user_id) {
        return axios
          .delete(Constants['SERVER_URL']+'users/delete/'+ user_id, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
}



export default new UserService();