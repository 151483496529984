import Media from '../..//services/media.service'

const media = {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        combination_update(_, media) {
            return Media.combination_update(media).then(
                data => {
                    return Promise.resolve(data);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        portfolio_update(_, media) {
            return Media.portfolio_update(media).then(
                data => {
                    return Promise.resolve(data);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        combinations(_, queryParams) {
            return Media.combinations(queryParams).then(
                data => {
                    return Promise.resolve(data);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        portfolio(_, queryParams) {
            return Media.portfolio(queryParams).then(
                data => {
                    return Promise.resolve(data);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        
        slider_image(_, queryParams) {
            return Media.slider_image(queryParams).then(
                data => {
                    return Promise.resolve(data);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        update_slider(_, media) {
            return Media.update_slider(media).then(
                data => {
                    return Promise.resolve(data);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        save_menu(_, media) {
            return Media.save_menu(media).then(
                data => {
                    return Promise.resolve(data);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        menu(_, queryParams) {
            return Media.menu(queryParams).then(
                data => {
                    return Promise.resolve(data);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        
    },
    
    mutations: {}
}

export default media
