import productService from '../../../../services/product.service'

const productServices = {
	namespaced: true,
	actions: {
		getServices(_, urlParams) {
			return productService.getServices(urlParams).then(
				data => {
					return Promise.resolve(data.body)
				},
				error => {
					return Promise.reject(error.response.data.errors)
				}
			)
		},
		setServices(_, { urlParams, serviceIdList }) {
			return productService.setServices(urlParams, serviceIdList).then(
				data => {
					return Promise.resolve(data.body)
				},
				error => {
					return Promise.reject(error.response.data.errors)
				}
			)
		},
		getExcludedServiceValues(_, urlParams) {
			return productService.getExcludedServiceValues(urlParams).then(
				data => {
					return Promise.resolve(data.body)
				},
				error => {
					return Promise.reject(error.response.data.errors)
				}
			)
		},
		setExcludedServiceValues(_, { urlParams, excludedValues }) {
			return productService.setExcludedServiceValues(urlParams, excludedValues).then(
				data => {
					return Promise.resolve(data.body)
				},
				error => {
					return Promise.reject(error.response.data.errors)
				}
			)
		},
	}
}

export default productServices
