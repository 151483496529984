<template>
    <BodyHeading :borderColor="'rgb(154, 168, 181)'" :title="$t('duration.productionTimes')" :count="durations.total" 
      :multiLanguageDisabled="false" :listHeading="true" :btnTitle="$t('duration.addButtonTitle')" :createBtnLink="'durationCreate'" :backLink="'settings'" :backIcon="'cog'"></BodyHeading>
    <div class="page-body-main-list d-grid">
        <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" style="width:5%"><span>#</span></th>
                            <th scope="col" style="width:45%"><span>{{$t('duration.title')}}</span></th>
                            <th scope="col" style="width:45%"><span>{{$t('duration.duration')}}</span></th>
                            <th scope="col" style="width:5%"><span>{{$t('actions')}}</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(duration, index) in durations.data" :key="duration.duration_id">
                            <td>{{index + 1 + (durations.current_page - 1) * ITEMS_PER_PAGE}}</td>
                            <td>{{duration.title}}</td>
                            <td v-if="duration.duration_info">{{duration.duration_info.duration}}</td>
                            <td v-else>-</td>
                            <td :style="duration.duration_id === ParentZindex? { zIndex: 2}: null">
                                <div class="dropdown actions-dropdown">
                                    <button @click="changeParentZindex(duration.duration_id)" class="btn btn-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <font-awesome-icon :icon="['fas', 'ellipsis-h']" size="sm"></font-awesome-icon>
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a @click="$router.push({ name: 'durationDetails', params: { id: duration.duration_id } })" class="dropdown-item">{{$t('edit')}}</a></li>
                                        <li><a class="dropdown-item warning" @click="deleteItem(duration.duration_id)">{{$t('delete')}}</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
            </table>
        </div>
        <Pagination v-if="durations.total>durations.per_page" :lastPage="durations.last_page"
        :activePage="durations.current_page" @handleClick="changePage"></Pagination>
    </div>
    
</template>


<script>
import BodyHeading from '../../components/BodyHeading.vue';
import Pagination from '../../components/Pagination.vue';
import { ITEMS_PER_PAGE } from '../../constants';


export default {
    components: {
        BodyHeading,
        Pagination
    },
    data() {
        return{
            durations: {},
            ParentZindex: null
        }
    },
    created() {
        this.ITEMS_PER_PAGE = ITEMS_PER_PAGE
        if(Object.entries(this.$route.query).length === 0 || this.$route.query.page === ''){
            this.$router.push({ name: 'durations', query: { page: 1 } })
        }
        this.getDurations()
    },
    watch: {
        '$route.query'(){
            this.getDurations()
        },
        currentLanguage() {
            this.getDurations()
        }
    },
    computed: {
        currentLanguage() {
            return this.$store.state.locale.selectedLocale
        },
    },
    methods: {
        changeParentZindex(id) {
            this.ParentZindex = id
        },
        getDurations() {
            const params = {language: this.currentLanguage, per_page:ITEMS_PER_PAGE, ...this.$route.query}
            this.$store.dispatch("duration/getAll", params).then(
                (response) => {
                    // console.log(response)
                    this.durations = response;
                },
                (error) => {
                this.$notify({
                        text: error,
                        group: 'failed'
                    });
                }
            )
        },
        deleteItem(id){
            this.$swal({text:'Production time cannot be deleted, if it has relations with other addresses, products, invoices or orders.'}).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("duration/delete", id).then(
                        (message) => {
                            this.$notify({
                                text: message,
                                group: 'success'
                            });
                            this.getDurations()
                        },
                        (error) => {
                            let errorMessage = ''
                            for (const err in error) {
                                errorMessage += `${error[err]} <br>`;
                            }
                        this.$notify({
                                text: errorMessage,
                                group: 'failed'
                            });
                        }
                    )
                } 
            })            
        },
        changePage(page=1){
            this.$router.push({ name: 'durations', query: { page: page } })
            this.getDurations();
        }
    }
}
</script>
