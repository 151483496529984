<script setup>
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

// import components
import MultiSelect from '../../components/MultiSelect.vue'
import VInput from '../../components/Input.vue'
import VSwitch from '../../components/Switch.vue'
import CurrencyInput from '../../components/CurrencyInput.vue'
import draggable from 'vuedraggable'
import { useNotification } from "@kyvg/vue3-notification"

// Import validators
import useValidate from '@vuelidate/core'
import { required } from '@/utilities/i18n-validators.js'

// Import vue-i18n
import { useI18n } from 'vue-i18n'

const { notify } = useNotification()
const route = useRoute()
const store = useStore()
const { t } = useI18n()

// state
const options = ref([])
const productOptions = ref([])
const shownCardIndex = ref(0)
const optionsCollapsed = ref(false)
const isLoading = ref(true)

// computed
const selectedLocale = computed(() => store.state.locale.selectedLocale)
const appLocale = computed(() => store.state.locale.appLocale)
const isTranslationMode = computed(() => selectedLocale.value !== appLocale.value)

// setup validation
const productOptionRules = {
	productOptions: {
		required
	}
}
const v$ = useValidate(productOptionRules, { productOptions })

// watch
watch(selectedLocale, () => {
	isLoading.value = true
	v$.value.$reset()
	productOptions.value = []
	getOptions()
	getProductOptions()
})

// methods
function getOptions(query) {
	const queryParams = { per_page: 50, language: selectedLocale.value }
	if (query) {
		queryParams.title = query
	}
	return store.dispatch('option/getAll', queryParams).then(res => {
		res.data.forEach(element => {
			element.option_values = []
			element.combinedLabel = `${element.option_id} - ${element.title}`
		});
		options.value = res.data
		return Promise.resolve(res.data)
	})
}

function getOptionValues(optionId) {
	const urlParams = { option_id: optionId, language: selectedLocale.value }
	return store.dispatch('option/fetch', urlParams).then(res => {
		return Promise.resolve(res.option_values)
	}).catch(() => Promise.resolve([]))
}

function getProductOptions() {
	const urlParams = { product_id: route.params.id, language: selectedLocale.value }
	store.dispatch('product/options/getOptions', urlParams).then(res => {
		const temp = Object.values(res).map(obj => ({...obj, combinedLabel: `${obj.option_id} - ${obj.title}` }))
		productOptions.value = Object.values(temp)
		// sort options
		productOptions.value.sort((a, b) => +a.arrange > +b.arrange ? 1 : -1)
		// sort option values
		for (const option of productOptions.value) {
			// assign if undefined
			option.option_values ??= []

			option.option_values.sort((a, b) => +a.arrange > +b.arrange ? 1 : -1)
		}
	}).catch(error => {
		notify({
			text: error,
			group: 'failed'
		})
	})
}

function getOptionPlaceholder(optionId) {
	return options.value.find(el => el.option_id == optionId)?.title || ''
}

function save() {
	if (!isTranslationMode.value)
		edit()
	else editTranslation()
}

async function edit() {
	const valid = await v$.value.$validate()
	if (!valid)
		return
	const urlParams = { product_id: route.params.id, language: selectedLocale.value }
	let payload = {
		options: productOptions.value.map((el, index) => ({
			option_id: el.option_id,
			title: el.title,
			has_no_select: el.has_no_select ?? 0,
			arrange: index + 1
		}))
	}
	await store.dispatch('product/options/setOptions', { urlParams, payload }).then(() => {
		// reset payload
		payload = {
			optionValues: []
		}
		for (const option of productOptions.value) {
			for (let i = 0; i < option.option_values?.length; i++) {
				payload.optionValues.push({
					option_id: option.option_values[i].option_id,
					option_value_id: option.option_values[i].option_value_id,
					price: !option.option_values[i].price ? 0 : option.option_values[i].price,
					stock: ((option.option_values[i].stock || option.option_values[i].stock == 0) && option.option_values[i].stock !== "") ? option.option_values[i].stock : null,
					arrange: i + 1
				})
			}
		}
		return store.dispatch('product/options/setOptionValues', { urlParams, payload }).then(res => {
			notify({
				text: res,
				group: 'success'
			})
		})
	}).catch(error => {
		let errorMessage = ''
		for (const err of error) {
			errorMessage += `${err} <br>`;
		}
		notify({
			text: errorMessage,
			group: 'failed'
		})
	})
}

async function editTranslation() {
	const valid = await v$.value.$validate()
	if (!valid)
		return
	const urlParams = { product_id: route.params.id, language: selectedLocale.value }
	const payload = {
		options: productOptions.value.map((el) => ({
			option_id: el.option_id,
			title: el.title
		}))
	}
	store.dispatch('product/options/setTranslatedOptions', { urlParams, payload }).then(res => {
		notify({
			text: res,
			group: 'success'
		})
	}).catch(error => {
		let errorMessage = ''
		for (const err of error) {
			errorMessage += `${err} <br>`;
		}
		notify({
			text: errorMessage,
			group: 'failed'
		})
	})
}

getProductOptions()
isLoading.value = false

defineExpose({
	save
})
</script>

<template>
	<div class="page-body-main-create">
		<div class="card-wrapper">
			<slot name="nav-stepper" />
			<div class="card">
				<div class="card-body">
					<!-- {{productOptionsList}}
            {{'------------------------------------------------------'}}
            {{OptionValues}} -->
					<div class="card">
						<div class="card-header d-flex">
							<div class="flex-grow-1">
								<label class="form-label is-required fw-bold">{{
									t('product.addOptions.chooseOptionsLabel')
								}}</label>
								<MultiSelect v-model="productOptions" resolve-on-load object searchable
									:options="async (query) => { return await getOptions(query) }" :is-required="true" :delay="100"
									:validateError="v$.productOptions.$error ? v$.productOptions.$errors[0].$message : null" mode="tags"
									label-prop="combinedLabel" value-prop="option_id" track-by="title" class="mb-2" />
							</div>
							<div class="ms-4 my-auto">
								<span v-if="optionsCollapsed" @click="optionsCollapsed = false"
									class="d-inline-block pt-sm-3 mt-1 cursor-pointer">
									<font-awesome-icon :icon="['fas', 'angle-double-down']" size="lg"></font-awesome-icon>
								</span>
								<span v-else @click="optionsCollapsed = true" class="d-inline-block pt-sm-3 mt-1 cursor-pointer">
									<font-awesome-icon :icon="['fas', 'angle-double-up']" size="lg"></font-awesome-icon>
								</span>
							</div>
						</div>
						<div aria-expanded="true" class="collapse" :class="{ 'show': !optionsCollapsed }">
							<draggable v-model="productOptions" item-key="option_id" tag="ul" class="list-group"
								:disabled="isTranslationMode">
								<template #item="{ element }">
									<li class="list-group-item">
										<div class="d-flex align-items-center">
											<span class="drag-handler flex-shrink-0 me-3 mp-1 d-inline-block cursor-move"
												:class="{ 'not-draggable': isTranslationMode }">
												<font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
												<font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
											</span>
											<span class="me-2" style="flex-shrink: 0;">{{ element.option_id }} -</span>
											<v-input v-model="element.title" :placeholder="getOptionPlaceholder(element.option_id)"
												class="flex-grow-1 me-2" />
											<v-switch label="product.addOptions.no-select" name="noselect" v-model="element.has_no_select"
												class="mb-md-4 mb-2 flex-nowrap" :disabled="isTranslationMode" />
										</div>
									</li>
								</template>
							</draggable>
						</div>
					</div>
					<div v-if="!isTranslationMode">
						<div v-for="(option, index) in productOptions" :key="option.option_id" class="card">
							<div class="card-header d-flex">
								<div class="flex-grow-1">
									<label class="form-label is-required fw-bold">
										{{ `${option.option_id} - ${option.title}` }}
									</label>
									<MultiSelect v-model="option.option_values"
										:options="async () => await getOptionValues(option.option_id)" class="mb-2" mode="tags" object
										:delay="100" searchable label-prop="title" value-prop="option_value_id" track-by="title" />
								</div>
								<div class="ms-4 my-auto">
									<span v-if="shownCardIndex !== index" @click="shownCardIndex = index"
										class="d-inline-block pt-sm-3 mt-1 cursor-pointer">
										<font-awesome-icon :icon="['fas', 'angle-double-down']" size="lg"></font-awesome-icon>
									</span>
									<span v-else @click="shownCardIndex = null" class="d-inline-block pt-sm-3 mt-1 cursor-pointer">
										<font-awesome-icon :icon="['fas', 'angle-double-up']" size="lg"></font-awesome-icon>
									</span>
								</div>
							</div>
							<div v-if="option.option_values" :aria-expanded="index == 0" class="collapse"
								:class="{ 'show': shownCardIndex == index }">
								<draggable v-model="option.option_values" item-key="option_value_id" tag="ul" class="list-group"
									:disabled="isTranslationMode">
									<template #item="{ element }">
										<li class="list-group-item">
											<div class="d-flex align-items-center">
												<span class="drag-handler flex-shrink-0 me-3 mp-1 d-inline-block cursor-move"
													:class="{ 'not-draggable': isTranslationMode }">
													<font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
													<font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
												</span>
												<div class="row flex-grow-1">
													<div class="col-md-7 my-auto">
														{{ element.title }}
													</div>
													<div class="col-md-3">
														<currency-input v-model="element.price" :name="'price-new'" :disabled="isTranslationMode" />
													</div>
													<div class="col-md-2">
														<input v-model.number="element.stock" type="number" class="form-control"
															:placeholder="t('product.addOptions.stock')">
													</div>
												</div>
											</div>
										</li>
									</template>
								</draggable>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style>
.not-draggable {
	cursor: no-drop !important;
}
</style>
