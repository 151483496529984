import localeService from '../../services/locale-service'

const languages = null;
const appLocale = localStorage.getItem('appLocale') ? localStorage.getItem('appLocale') : 'de';
const selectedLocale = localStorage.getItem('selectedLocale') ? localStorage.getItem('selectedLocale') : 'de';

const locale = {
    namespaced: true,
    state: {
        appLocale,
        languages,
        selectedLocale
    },
    getters: {
    },
    actions: {
        getAllLanguages({commit}){
            return localeService.getAllLanguages().then(
                data => {
                    // console.log(data);
                    commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data);
                },
                error => {
                    commit('getAllLanguagesFailure');
                    return Promise.reject(error);
                }
            )
        }

    },
    mutations: {
        changeActiveLanguage(state, payload){
            state.appLocale = payload;
            localStorage.setItem('appLocale', payload);
        },
        changeCurrentLanguage(state, payload){
            state.selectedLocale = payload;
            localStorage.setItem('selectedLocale', payload);
        },
        getAllLanguagesSuccess(state, languages){
            state.languages = languages;
        },
        getAllLanguagesFailure(state){
            state.languages = null;
        }
    }
}

export default locale
