<template>
   <div>
     <BodyHeading
       v-if="$route.name.includes('Create')"
       :borderColor="'rgb(230, 127, 216)'"
       :title="$t('homeSlider.addButtonTitle')"
       @handleClick="create"
       :multiLanguageDisabled="false"
       :saveAndCloseBtn="false"
     ></BodyHeading>
 
     <BodyHeading
       v-if="$route.name.includes('Details')"
       :borderColor="'rgb(230, 127, 216)'"
       :title="$t('homeSlider.editTitle')"
       v-on="isExist ? { handleClick: edit } : { handleClick: createTranslation }"
       :multiLanguageDisabled="false"
     ></BodyHeading>
 
     <div class="page-body-main-create">
       <div class="card-wrapper">
         <div class="card-title">{{$t('generalInformation')}}</div>
         <div class="card">
           <div class="card-body">
             <div class="row" v-if="!isLoading">
               <div class="mb-4">
                 <v-input
                   type="text"
                   name="title"
                   label="homeSlider.title"
                   :placeholder="defaultLanguageHomeSlider.title"
                   v-model="homeSlider.title"
                   :isRequired="true"
                   :validateError="v$.homeSlider.title.$error ? v$.homeSlider.title.$errors[0].$message : null"
                 ></v-input>
               </div>
             </div>
             <div class="row" v-if="!isLoading">
               <label class="form-label is-required">{{$t('homeSlider.uploaderBoxLabel')}}</label>
               <draggable v-model="slides" :itemKey="index" class="accordion accordion-uploader" :key="slides.length" @change="updateSlideOrder">
                 <template #item="{ index }">
                   <div class="accordion-item">
                     <h2 class="accordion-header" :id="`uploaderBox-heading-${slides[index]}`">
                       <button class="accordion-button" :class="slides[index] != 0 ? 'collapsed' : ''" type="button" data-bs-toggle="collapse"
                         :data-bs-target="`#uploaderBox-${slides[index]}`" aria-expanded="true" :aria-controls="`uploaderBox-${slides[index]}`"
                         :style="{ borderColor: slidesWithError[slides[index]] ? 'red' : '' }">
                         {{ `${$t('homeSlider.slideTitle')} #${slides[index]+1}` }}
                       </button>
                     </h2>
                     <div :id="`uploaderBox-${slides[index]}`" class="accordion-collapse collapse" 
                       :aria-labelledby="`uploaderBox-heading-${slides[index]}`" data-bs-parent="#accordionPanelsStayOpenExample">
                       <div class="accordion-body">
                         <FileUploader v-model="desktopImages[slides[index]]" :name="'home-slider-uploader'" label="homeSlider.coverDesktop" :language="currentLanguage"
                           uploadUrl="homes/sliders/images/upload" deleteUrl="homes/sliders/images/delete" fetchUrl="image/home/slider" :multiple="false"
                           :couple="{id: checkSlideId(slides[index]), for: 'desktop'}" @uploaderStatus="disableDeleteBtn = $event == 1? false: true" />
                         <FileUploader v-model="mobileImages[slides[index]]" :name="'home-slider-uploader'" label="homeSlider.coverMobile" :language="currentLanguage"
                           uploadUrl="homes/sliders/images/upload/mobile" deleteUrl="homes/sliders/images/delete/mobile" fetchUrl="image/home/slider" :multiple="false"
                           :couple="{id: checkSlideId(slides[index]), for: 'mobile'}" @uploaderStatus="disableDeleteBtn = $event == 1? false: true" />
                         <div class="mb-4">
                           <v-input type="text" name="title" label="homeSlider.link" v-model="links[slides[index]]" :placeholder="'https://'"></v-input>
                         </div>
                         <div class="mt-4 mb-2">
                           <span @click="disableDeleteBtn ? '' : deleteSlide(slides[index])" class="text-danger cursor-pointer text-decoration-underline"
                             :style="{ opacity: disableDeleteBtn ? '.5' : '1' }">{{$t('delete')}}</span>
                         </div>
                       </div>
                     </div>
                   </div>
                 </template>
               </draggable>
 
               <div class="d-flex justify-content-center pt-4">
                 <button @click="addSlide" class="btn button-action px-3"><font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon></button>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </template>
 
 <script>
 import BodyHeading from '../../components/BodyHeading.vue';
 import Input from '../../components/Input.vue';
 import FileUploader from '../../components/FileUploader.vue'
 import useValidate from '@vuelidate/core';
 import { required } from '@/utilities/i18n-validators.js'
 import draggable from 'vuedraggable'
 
 export default {
   components: {
     BodyHeading,
     'v-input': Input,
     FileUploader,
     draggable
   },
   data() {
     return {
       v$: useValidate(),
       homeSlider: {},
       defaultLanguageHomeSlider: {},
       isExist: true,
       isLoading: true,
       isMenuInputDisable: false,
       mobileImages: [],
       desktopImages: [],
       slides: [],
       links: {},
       disableDeleteBtn: false,
       slidesWithError: {}
     };
   },
   validations() {
     return {
       homeSlider: {
         title: {required},
       }
     };
   },
   computed: {
     currentLanguage() {
       return this.$store.state.locale.selectedLocale;
     },
     languages() {
       return this.$store.state.locale.languages;
     },
     currentLocale() {
       return this.$store.state.locale.appLocale;
     }
   },
   watch: {
     '$route': {
       immediate: true,
       handler() {
         if(this.$route.name.includes('Details')){
           this.getHomeSlider(this.$route.params.id);
         }else{
           this.isLoading = false;
         }
       }
     },
     'languages': {
       immediate: true,
       handler(){
         if(this.$route.name.includes('Details') && this.languages){
           if(!this.isExist && this.currentLocale === this.currentLanguage){
             this.$router.push({ name: 'homeSliders', query: { page: 1 } });
           }
           this.getHomeSlider(this.$route.params.id, this.currentLocale);
           if(this.currentLocale !== this.currentLanguage){
             if(!this.isMenuInputDisable){
               this.isMenuInputDisable = true;
             }
           }else if(this.currentLocale === this.currentLanguage && this.isMenuInputDisable){
             this.isMenuInputDisable = false;
           }
         }
       }
     },
     currentLanguage() {
       if(this.$route.name.includes('Details')){
         this.isLoading = true;
         this.v$.$reset();
         this.homeSlider = {};
         this.mobileImages = [];
         this.desktopImages = [];
         this.links = {};
         this.getHomeSlider(this.$route.params.id, this.currentLanguage);
         this.getHomeSlider(this.$route.params.id, this.currentLocale);
         if(this.languages){
           if(this.currentLocale !== this.currentLanguage && !this.isMenuInputDisable){
             this.isMenuInputDisable = true;
           }else if(this.currentLocale === this.currentLanguage && this.isMenuInputDisable){
             this.isMenuInputDisable = false;
           }
         }
       }
     }
   },
   methods: {
     updateSlideOrder(newOrder) {
       const oldIndex = newOrder.moved.oldIndex;
       const newIndex = newOrder.moved.newIndex;
 
       if (oldIndex === newIndex) return;
 
       // Remove the elements from their old positions
       const movedMobileImage = this.mobileImages.splice(oldIndex, 1)[0];
       const movedDesktopImage = this.desktopImages.splice(oldIndex, 1)[0];
 
       // Insert the elements at their new positions
       this.mobileImages.splice(newIndex, 0, movedMobileImage);
       this.desktopImages.splice(newIndex, 0, movedDesktopImage);
       this.edit('refresh');
     },
     simulateOrderChange() {
       // Simulating a drag-and-drop operation
       const newOrder = {
         moved: {
           oldIndex: 0,
           newIndex: 1
         }
       };
       this.updateSlideOrder(newOrder);
     },
     generateUniqueId(index) {
       return `slide-${index}`;
     },
     addSlide(){
       this.slides.push(this.slides.length > 0 ? this.slides[this.slides.length - 1] + 1 : 0);
     },
     deleteSlide(index){
       if(this.desktopImages[index] && this.desktopImages[index][0]){
         this.$swal().then((result) => {
           if (result.isConfirmed) {
             this.$store.dispatch("homeSlider/deleteSlide", this.desktopImages[index][0].id).then(
               (message) => {
                 this.$notify({
                   text: message,
                   group: 'success'
                 });
                 delete this.mobileImages[index];
                 delete this.desktopImages[index];
                 this.slides.splice(index, 1);
               },
               (error) => {
                 let errorMessage = '';
                 for (const err in error) {
                   errorMessage += `${error[err]} <br>`;
                 }
                 this.$notify({
                   text: errorMessage,
                   group: 'failed'
                 });
               }
             );
           }
         });
       } else if(this.mobileImages[index] && this.mobileImages[index][0]){
         this.$swal().then((result) => {
           if (result.isConfirmed) {
             this.$store.dispatch("homeSlider/deleteSlide", this.mobileImages[index][0].id).then(
               (message) => {
                 this.$notify({
                   text: message,
                   group: 'success'
                 });
                 delete this.mobileImages[index];
                 delete this.desktopImages[index];
                 this.slides.splice(index, 1);
               },
               (error) => {
                 let errorMessage = '';
                 for (const err in error) {
                   errorMessage += `${error[err]} <br>`;
                 }
                 this.$notify({
                   text: errorMessage,
                   group: 'failed'
                 });
               }
             );
           }
         });
       } else {
         this.slides.splice(index, 1);
       }
     },
     checkSlideId(index){
       if(this.desktopImages[index] && this.desktopImages[index][0]){
         return this.desktopImages[index][0].id;
       }else if(this.mobileImages[index] && this.mobileImages[index][0]){
         return this.mobileImages[index][0].id;
       }else{
         return null;
       }
     },
     customValidation(){
       this.slidesWithError = {};
       this.slides.forEach(key => {
         if(!this.desktopImages[key] || this.desktopImages[key] && !this.desktopImages[key][0].desktopName){
           if(!this.mobileImages[key] || this.mobileImages[key] && !this.mobileImages[key][0].mobileName){
             this.slidesWithError[key] = true;
           }
         }
       });
       if(Object.keys(this.slidesWithError).length == 0){
         return true;
       }else{
         return true;
       }
     },
     create() {
       this.v$.$validate();
       this.customValidation();
       if(!this.v$.$error && !Object.keys(this.slidesWithError).length){
         this.homeSlider.language = this.currentLanguage;
         this.homeSlider.images_id = [];
         Object.keys(this.desktopImages).forEach(key => {
           this.homeSlider.images_id.push({id: this.desktopImages[key][0].id, link: this.links[key]? this.links[key] : null});
         });
         Object.keys(this.mobileImages).forEach(key => {
           if(this.mobileImages[key] && !this.desktopImages[key]){
             this.homeSlider.images_id.push({id: this.mobileImages[key][0].id, link: this.links[key]? this.links[key] : null});
           }
         });
         this.$store.dispatch("homeSlider/create", this.homeSlider).then(
           (data) => {
             this.$notify({
               text: data.message,
               group: 'success'
             });
             this.$router.push({ name: 'homeSliderDetails', params: { id: data.body.id}});
           },
           (error) => {
             let errorMessage = '';
             for (const err in error) {
               errorMessage += `${error[err]} <br>`;
             }
             this.$notify({
               text: errorMessage,
               group: 'failed'
             });
           }
         );
       }
     },
     createTranslation(){
       this.v$.$validate();
       this.customValidation();
       if(!this.v$.$error && !Object.keys(this.slidesWithError).length){
         this.homeSlider.language = this.currentLanguage;
         this.homeSlider.images_id = [];
         Object.keys(this.desktopImages).forEach(key => {
           this.homeSlider.images_id.push({id: this.desktopImages[key][0].id, link: this.links[key]? this.links[key] : null});
         });
         Object.keys(this.mobileImages).forEach(key => {
           if(this.mobileImages[key] && !this.desktopImages[key]){
             this.homeSlider.images_id.push({id: this.mobileImages[key][0].id, link: this.links[key]? this.links[key] : null});
           }
         });
         this.homeSlider.slider_id = this.defaultLanguageHomeSlider.id;
         this.$store.dispatch("homeSlider/createTranslation", this.menu).then(
           (message) => {
             this.isExist = true;
             this.$notify({
               text: message,
               group: 'success'
             });
           },
           (error) => {
             let errorMessage = '';
             for (const err in error) {
               errorMessage += `${error[err]} <br>`;
             }
             this.$notify({
               text: errorMessage,
               group: 'failed'
             });
           }
         );
       }
     },
     refreshPage() {
         window.location.reload();
         // this.getHomeSlider(this.$route.params.id, this.currentLanguage);
      },
     edit(title=null) {
       this.v$.$validate();
       this.customValidation();
       this.homeSlider.language = this.currentLanguage;
       this.homeSlider.images_id = [];
       Object.keys(this.desktopImages).forEach(key => {
         this.homeSlider.images_id.push({id: this.desktopImages[key][0].id, link: this.links[key]? this.links[key] : null, sorting:key});
       });
       Object.keys(this.mobileImages).forEach(key => {
         if(this.mobileImages[key] && !this.desktopImages[key]){
           this.homeSlider.images_id.push({id: this.mobileImages[key][0].id, link: this.links[key]? this.links[key] : null});
         }
       });
       this.homeSlider.slider_id = this.defaultLanguageHomeSlider.id;
       this.$store.dispatch("homeSlider/update", this.homeSlider).then(
         (message) => {
           this.$notify({
             text: message,
             group: 'success'
           });
           if(title === "refresh") this.refreshPage();
           //this.getHomeSlider(this.$route.params.id, this.currentLanguage);
         },(error) => {
           let errorMessage = '';
           for (const err in error) {
             errorMessage += `${error[err]} <br>`;
           }
           this.$notify({
             text: errorMessage,
             group: 'failed'
           });
         }
       );
     },
     getHomeSlider(id, language = this.currentLanguage) {
       this.isLoading = true;
       const params = {language: language, slider_id: id};
       this.$store.dispatch("homeSlider/fetch", params).then(
         (response) => {
           if(this.currentLocale === language){
             this.defaultLanguageHomeSlider = response;
             if(this.currentLanguage === this.currentLocale){
               this.isExist = true;
               this.homeSlider.title = response.title;
               if(response.images.length && !this.desktopImages.length && !this.mobileImages.length) {
                 this.slides = [];
                 response.images.sort((a, b) => a.sorting - b.sorting);
                 response.images.forEach((el, index) => {
                   if(el.path){
                     this.desktopImages[index] = [{id: el.id, desktopName: el.path}];
                   }
                   if(el.mobile_path){
                     this.mobileImages[index] = [{id: el.id, mobileName: el.mobile_path}];
                   }
                   if(el.link){
                     this.links[index] = el.link;
                   }
                   this.slides.push(index);
                 });
               }
               this.isLoading = false;
             }
           }else{
             this.isExist = true;
             this.homeSlider.title = response.title;
             if(response.images.length && !this.desktopImages.length && !this.mobileImages.length) {
               this.slides = [];
               response.images.sort((a, b) => a.sorting - b.sorting);
               response.images.forEach((el, index) => {
                 if(el.path){
                   this.desktopImages[index] = [{id: el.id, desktopName: el.path}];
                 }
                 if(el.mobile_path){
                   this.mobileImages[index] = [{id: el.id, mobileName: el.mobile_path}];
                 }
                 if(el.link){
                   this.links[index] = el.link;
                 }
                 this.slides.push(index);
               });
             }
             this.isLoading = false;
           }
         },
         () => {
           this.isExist = false;
           if(this.currentLocale === this.currentLanguage){
             this.$router.push({ name: 'homeSliders', query: { page: 1 } });
           }
           this.isLoading = false;
         }
       );
     }
   }
 };
 </script>
 
 <style>
 /* Your styles here */
 </style>
 