<template>
  <label v-if="label" :for="name" class="form-label" :class="isRequired ? 'is-required' : ''">{{ $t(label) }}</label>
  <div :id="name" class="input-group">
    <input
      ref="inputRef"
      type="text"
      :value="formattedValue"
      v-bind="$attrs"
      class="form-control"
      placeholder="Enter Price"
      :class="validateError ? 'form-controll-invalid' : ''"
    >
    <slot name="suffix"></slot>
  </div>
  <div class="invalid-feedback" v-if="validateError">
    {{ validateError }}
  </div>
</template>

<script>
import { watch } from 'vue'
import { useCurrencyInput } from 'vue-currency-input'

export default {
  name: 'CurrencyInput',
  props: {
    name: {
      type: String,
      default: ''
    },
    label: {
      default: ''
    },
    isRequired: {
      default: false
    },
    modelValue: {
      default: 0,
    },
    validateError: {
      default: null
    },
    options: {
      default: {
        locale: 'de',
        currency: 'EUR',
        precision: 2,
        hideCurrencySymbolOnFocus: false,
        hideGroupingSeparatorOnFocus: false
      }
    }
  },
  setup(props) {
    const { formattedValue, inputRef, setValue } = useCurrencyInput(props.options)

    watch(() => props.modelValue, (value) => { // Vue 2: props.value
      setValue(value)
    })

    return { inputRef, formattedValue }
  }
}
</script>