<template>
   <BodyHeading v-if="$route.name.includes('Create')" :borderColor="'rgb(154, 168, 181)'" :title="$t('user.addUser')" 
      @handleClick="create" :multiLanguageDisabled="true" :saveAndCloseBtn="false"></BodyHeading>
   
   <BodyHeading v-if="$route.name.includes('Details')" :borderColor="'rgb(154, 168, 181)'" :title="$t('user.userDetails')" 
     @handleClick="edit" :multiLanguageDisabled="true"></BodyHeading>
   <div class="page-body-main-create">
      <div class="card-wrapper">
         <div class="card-title">{{$t('generalInformation')}}</div>
         <div class="card">
            <div class="card-body">
               <!--  -->
              <div class="row" v-if="!isLoading">
                  <div class="col-lg-6">
                     <div class="mb-4">
                        <MultiSelect label="user.roleId" :options="roles" v-model="user.role_id" :isRequired="true"
                        :validateError="v$.user.role_id.$error? v$.user.role_id.$errors[0].$message : null" />
                     </div>
                  </div>    
                 <div class="col-lg-6">
                    <div class="mb-4">
                     <MultiSelect label="user.gender" :options="[{label:'Mr.', value:'male'},{label:'Ms., Mrs.', value:'female'}]" v-model="user.gender" :isRequired="true"
                        :validateError="v$.user.gender.$error? v$.user.gender.$errors[0].$message : null" />
                     </div>
                 </div>
                 <div class="col-lg-6">
                    <div class="mb-4">
                     <v-input type="text" name="first-name" label="user.firstName" :placeholder="defaultLanguageUser.first_name" v-model="user.first_name" :isRequired="true"
                        :validateError="v$.user.first_name.$error? v$.user.first_name.$errors[0].$message : null"></v-input>
                  </div>
                 </div>
                  <div class="col-lg-6">
                     <div class="mb-4">
                        <v-input type="text" name="last-name" label="user.lastName" :placeholder="defaultLanguageUser.last_name" v-model="user.last_name" :isRequired="true"
                           :validateError="v$.user.last_name.$error? v$.user.last_name.$errors[0].$message : null"></v-input>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="mb-4">
                        <v-input type="text" name="email" label="user.email" :placeholder="defaultLanguageUser.email" v-model="user.email" :isRequired="true"
                           :validateError="v$.user.email.$error? v$.user.email.$errors[0].$message : null"></v-input>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="mb-4">
                        <v-input type="text" name="phone" label="user.phone" :placeholder="defaultLanguageUser.phone" v-model="user.phone" :isRequired="true"
                           :validateError="v$.user.phone.$error? v$.user.phone.$errors[0].$message : null"></v-input>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="mb-4">
                        <v-input type="password" name="password" label="user.password" :placeholder="defaultLanguageUser.password" v-model="user.password" :isRequired="true"
                           :validateError="v$.user.password.$error? v$.user.password.$errors[0].$message : null"></v-input>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="mb-4">
                        <v-input type="password" name="password-confirmation" label="user.passwordConfirmation" :placeholder="defaultLanguageUser.password_confirmation" v-model="user.password_confirmation" :isRequired="true"
                           :validateError="v$.user.password_confirmation.$error? v$.user.password_confirmation.$errors[0].$message : null"></v-input>
                     </div>
                  </div>
                  <div class="col-lg-6">
                      <div class="mb-4">
                        <v-input type="text" name="company" label="user.company" :placeholder="defaultLanguageUser.company" v-model="user.company"
                           :validateError="v$.user.company.$error? v$.user.company.$errors[0].$message : null"></v-input>
                     </div>
                  </div>
                  <div class="col-lg-6">
                      <div class="mb-4">
                        <v-input type="text" name="address" label="user.address" :placeholder="defaultLanguageUser.address" v-model="user.address"
                           :validateError="v$.user.address.$error? v$.user.address.$errors[0].$message : null"></v-input>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="mb-4">
                        <v-input type="text" name="additional-address" label="user.additionalAddress" :placeholder="defaultLanguageUser.additional_address" v-model="user.additional_address"
                           :validateError="v$.user.additional_address.$error? v$.user.additional_address.$errors[0].$message : null"></v-input>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="mb-4">
                        <v-input type="text" name="postcode" label="user.postcode" :placeholder="defaultLanguageUser.postcode" v-model="user.postcode"
                           :validateError="v$.user.postcode.$error? v$.user.postcode.$errors[0].$message : null"></v-input>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="mb-4">
                        <v-input type="text" name="city" label="user.city" :placeholder="defaultLanguageUser.city" v-model="user.city"
                           :validateError="v$.user.city.$error? v$.city.city.$errors[0].$message : null"></v-input>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="mb-4">
                        <MultiSelect label="user.country" :options="countries" v-model="user.country_id" :isRequired="true"
                           :validateError="v$.user.country_id.$error? v$.user.country_id.$errors[0].$message : null" />
                     </div>
                  </div>        
              </div>
            </div>
         </div>
      </div>
   </div>
</template>



<script>
import BodyHeading from '../../components/BodyHeading.vue'
import MultiSelect from '../../components/MultiSelect.vue'
import Input from '../../components/Input.vue';
import useValidate from '@vuelidate/core';
import { required, email, numeric, minLength, sameAs, mustBeFive } from '@/utilities/i18n-validators.js'

   export default {
      components: {
         BodyHeading,
         'v-input': Input,
         MultiSelect
      },
      data() {
         return{
            v$: useValidate(),
            user: {},
            defaultLanguageUser: {},
            countries: [],
            roles: [],
            isExist:true,
            isLoading: true,
            emailChecker: ''
         }
      },
      validations() {
         return {
            user: {
               first_name: {required},
               last_name: {required},
               email: {required, email},
               phone: {numeric},
               gender: {required},
               password: {required, minLength: minLength(8)},
               password_confirmation: {required, sameAs: sameAs(this.user.password)},
               company: {},
               additional_address: {},
               address: {},
               postcode: {numeric, mustBeFive},
               city: {},
               country_id: {required},
               role_id: {required},
            }
         }
      },
      computed: {
         currentLanguage() {
            return this.$store.state.locale.selectedLocale
         }
      },
      watch: {
         '$route': {
            immediate: true,
            handler() {
                  this.getCountries()
                  this.getRoles()
            }
         }
      },
      methods: {
         getCountries(){
            const params = {language: this.currentLanguage, page: 1, per_page: 30}
            this.$store.dispatch("country/getAll", params).then(
                (response) => {
                    if(response.data.length){
                        response.data.forEach(el => {
                            this.countries.push({value: el.country_id, label: el.name})
                        });
                    }
                    if(this.countries.length && this.roles.length){
                       if(this.$route.name.includes('Details')){
                           this.getUser(this.$route.params.id)
                        }else{
                           this.isLoading= false
                        }
                    }
                },
                (error) => {
                this.$notify({
                        text: error,
                        group: 'failed'
                    });
                }
            )
         },
         getRoles(){
            const params = {language: this.currentLanguage, page: 1, per_page: 30}
            this.$store.dispatch("role/getAll", params).then(
                (response) => {
                   if(response.data.length){
                        response.data.forEach(el => {
                            this.roles.push({value: el.role_id, label: el.title})
                        });
                    }
                    if(this.countries.length && this.roles.length){
                        if(this.$route.name.includes('Details')){
                           this.getUser(this.$route.params.id)
                        }else{
                           this.isLoading= false
                        }
                    }
                },
                (error) => {
                this.$notify({
                        text: error,
                        group: 'failed'
                    });
                }
            )
         },
         getUser(id) {
            // console.log(this.currentLanguage);
            const params = {user_id: id}
            this.$store.dispatch("user/fetch", params).then(
                (response) => {
                     this.emailChecker = response.email;
                     this.defaultLanguageUser = response;
                     this.user = response;
                     this.isExist = true;
                     this.isLoading = false;
                },
                () => {
                  this.isExist = false;
                  this.$router.push({ name: 'users', query: { page: 1 } })
                  this.isLoading = false;
                  // let errorMessage = ''
                  // for (const err in error) {
                  //    errorMessage += `${error[err]} <br>`;
                  // }
                  // this.$notify({
                  //    text: errorMessage,
                  //    group: 'failed'
                  // });
                }
            )
         },
         create() {
            this.v$.$validate()
            if(!this.v$.$error){
               this.user.language = this.currentLanguage;
               console.log(this.user)
               this.$store.dispatch("user/create", this.user).then(
                  (data) => {
                     // console.log(data);
                     this.$notify({
                           text: data.message,
                           group: 'success'
                     });
                     this.$router.push({ name: 'userDetails', params: { id: data.body.id}})
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         },
         edit() {
            this.v$.$validate()
            if(!this.v$.$error){
              let userTemp = {...this.user};
              userTemp.user_id = this.defaultLanguageUser.id;
              if(this.emailChecker === userTemp.email){
                 delete userTemp.email
              }
               this.$store.dispatch("user/update", userTemp).then(
                  (message) => {
                     // console.log(...message);
                     this.$notify({
                           text: message,
                           group: 'success'
                     });
                     // this.$router.push({name: 'dashboard'})
                  },
                  (error) => {
                     let errorMessage = ''
                     for (const err in error) {
                        errorMessage += `${error[err]} <br>`;
                     }
                  this.$notify({
                           text: errorMessage,
                           group: 'failed'
                     });
                  }
               )
            }
         }
      }
   }
</script>
