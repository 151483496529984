<script setup>
import { ref, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Constants from '../../constants/index.js'

// Import vue-i18n
import { useI18n } from 'vue-i18n'

// import components
import BodyHeading from "../../components/BodyHeading.vue"
import Modal from "../../components/Modal.vue"
import CurrencyInput from "../../components/CurrencyInput.vue"
import VInput from "../../components/Input.vue"
import FileUploader from "../../components/FileUploader.vue"
import VSwitch from "../../components/Switch.vue"
import MultiSelect from "../../components/MultiSelect.vue"
import draggable from 'vuedraggable'
import { useNotification } from '@kyvg/vue3-notification'

// import validations
import useValidate from "@vuelidate/core"
import { decimal } from '@/utilities/i18n-validators.js'

const { notify } = useNotification()
const { t } = useI18n()

const router = useRouter()
const route = useRoute()
const store = useStore()

// states
const ParentZindex = ref(null)
const combinations = ref([])
const singleCombination = ref({})
const selectedOptionValues = ref([])
const productOptions = ref([])
const hasImage = ref(0)
const isActive = ref(0)
const isLoading = ref(true)
const isLoadingModal = ref(true)
const images = ref([])
const uploadUrl = ref(null)
const deleteUrl = ref(null)
const isSwitchLocked = ref(false)
const page = ref(1)
const disableLoadMore = ref(false)
const disableDeleteBtn = ref(false)
const filterOpened = ref(false)
const modal = ref(null)
const imageSortModal = ref(null)

const rules = {
  singleCombination: {
    additional_price: { decimal },
  }
}

const v$ = useValidate(rules, { singleCombination })

// computed
const selectedLocale = computed(() => store.state.locale.selectedLocale)

// watch
watch(selectedLocale, () => {
  combinations.value = []
  page.value = 1
  getCombinations(route.params.id)

})

function getImageUrl(image) {
  return Constants['SERVER_PUBLIC_URL'] + `fetch/image?path=/image/product/combination&image=${image.path}`
}

function checkStatus(id) {
  if (images.value.length && singleCombination.value.is_active != 1) {
    changeActivity(id)
    combinations.value.forEach((el) => {
      if (el.id == singleCombination.value.id) {
        el.is_active = 1
      }
    })
  } else if (!images.value.length && singleCombination.value.is_active == 1) {
    changeActivity(id)
    combinations.value.forEach((el) => {
      if (el.id == singleCombination.value.id) {
        el.is_active = 0
      }
    })
  }
}

let debounceTimer
function filterResult() {
  combinations.value = []
  clearTimeout(debounceTimer)
  debounceTimer = setTimeout(() => {
    getCombinations(
      route.params.id,
      1
    )
  }, 300)
}

function loadMore() {
  page.value += 1
  getCombinations(route.params.id, page.value)
}

function changeParentZindex(id) {
  ParentZindex.value = id
}

function openModal(id) {
  isLoadingModal.value = true
  singleCombination.value = {}
  images.value = []
  getCombination(id)
  modal.value.initiateModal()
}

function getCombinations(id, page = 1) {
  let sortedOptionValues = JSON.parse(JSON.stringify(selectedOptionValues.value.filter(item=>item)))
  if (selectedOptionValues.value.length > 0) {
    sortedOptionValues.sort((a, b)=> Number(a) - Number(b))
  }
  const urlParams = {
    product_id: id,
    language: selectedLocale.value,
  }
  const queryParams = {
    per_page: 50,
    page: page,
  }
  if (selectedOptionValues.value.length > 0) queryParams.optionValueIds = sortedOptionValues.toString()
  if (isActive.value == 1) queryParams.is_active = 1
  if (hasImage.value == 1) queryParams.have_image = 1

  store.dispatch("product/combinations/getAll", { urlParams, queryParams }).then(
    (response) => {
      if (response.length) {
        combinations.value.push(...response)
        if (response.length < 50) disableLoadMore.value = true
      } else {
        disableLoadMore.value = true
      }
      isLoading.value = false
    },
    (error) => {
      notify({
        text: error,
        group: "failed",
      })
      router.push({ name: "products", query: { page: 1 } })
      isLoading.value = false
    }
  )
}

function getCombination(id) {
  const urlParams = { combination_id: id, language: selectedLocale.value }
  store.dispatch("product/combinations/fetch", urlParams).then(
    (response) => {
      uploadUrl.value = `products/combinations/images/upload/${id}`
      deleteUrl.value = "products/combinations/images/delete"
      response.images.sort((a, b) => a.arrange > b.arrange ? 1 : -1)
      singleCombination.value = response
      if (response.images.length && !images.value.length) {
        response.images.forEach((el) => {
          images.value.push({ id: el.id, name: el.path })
        })
      }
      isLoadingModal.value = false
    },
    (error) => {
      notify({
        text: error,
        group: "failed",
      })
      // close modal later
      isLoadingModal.value = false
    }
  )
}

function edit() {
  v$.value.$validate()
  if (!v$.value.$error) {
    const urlParams = { combination_id: singleCombination.value.id }
    store.dispatch("product/combinations/update", { urlParams, combination: singleCombination.value }).then(
      (message) => {
        notify({
          text: message,
          group: "success",
        })
        modal.value.hide()
        combinations.value.forEach((el, index) => {
          if (el.id == singleCombination.value.id) {
            el.additional_price = singleCombination.value.additional_price
            el.image_count = images.value.length ? images.value.length : 0
            if (el.image_count == 0 && el.is_default == 1) {
              changeDefaultPrice(el.id, index, true)
            }
          }
        })
      },
      (error) => {
        let errorMessage = ""
        for (const err in error) {
          errorMessage += `${error[err]} <br>`
        }
        notify({
          text: errorMessage,
          group: "failed",
        })
      }
    )
  }
}

function changeActivity(id) {
  isSwitchLocked.value = true
  const urlParams = { combination_id: id }
  store.dispatch("product/combinations/updateActivation", urlParams).then(
    (message) => {
      notify({
        text: message,
        group: "success",
      })
      isSwitchLocked.value = false
    },
    (error) => {
      notify({
        text: error,
        group: "failed",
      })
      isSwitchLocked.value = false
    }
  )
}

function changeDefaultPrice(combination_id, index, deactivete = false) {
  isSwitchLocked.value = true
  if (deactivete) {
    combinations.value[index].is_default = 0
  } else {
    combinations.value[index].is_default = combinations.value[index].is_default ? 1 : 0
  }
  let urlParams = {
    combination_id: combination_id,
    product_id: route.params.id,
  }
  store.dispatch("product/combinations/setDefault", urlParams).then(
    (message) => {
      notify({
        text: message,
        group: "success",
      })
      if (combinations.value[index].is_default) {
        combinations.value.forEach((el, i) => {
          if (index != i) {
            el.is_default = 0
          }
        })
      }
      isSwitchLocked.value = false
    },
    (error) => {
      notify({
        text: error,
        group: "failed",
      })
      combinations.value[index].is_default = 0
      isSwitchLocked.value = false
    }
  )
}

function getProductOptions() {
  const urlParams = { product_id: route.params.id, language: selectedLocale.value }
  store.dispatch('product/options/getOptions', urlParams).then(res => {
    productOptions.value = Object.values(res)
    // sort options
    productOptions.value.sort((a, b) => +a.arrange > +b.arrange ? 1 : -1)
    // sort option values
    for (const option of productOptions.value) {
      // assign if undefined
      option.option_values ??= []

      option.option_values.sort((a, b) => +a.arrange > +b.arrange ? 1 : -1)
    }
  }).catch(error => {
    notify({
      text: error,
      group: 'failed'
    })
  })
}

async function sortCombinationImages() {
  const urlParams = { combination_id: singleCombination.value.id }
  const images = singleCombination.value.images
  await store.dispatch("product/combinations/sortImages", { urlParams, images }).then(
    (message) => {
      notify({
        text: message,
        group: "success",
      })
      imageSortModal.value.hide()
    },
    (error) => {
      notify({
        text: error,
        group: "failed",
      })
    }
  )
}

getProductOptions()
getCombinations(route.params.id)
</script>

<template>
  <BodyHeading :borderColor="'rgb(87, 217, 163)'" :title="$t('product.combinations.heading')" :name="$route.query.name"
    :actionBtnVisibility="true" :multiLanguageDisabled="false"></BodyHeading>
  <div class="page-body-main-list d-grid grid-columns">
    <div class="page-body-main-create">
      <div class="card-wrapper">
      <slot name="nav-stepper"></slot>
        <div class="card-title d-flex justify-content-between">
          {{ `${$t("product.combinations.heading")} (${combinations.length})` }}
        </div>
        <div class="card">
          <div class="card-body card-body-table">
            <div class="table-responsive mt-0">
              <table class="table table-striped table-hover table-bordered">
                <thead style="z-index: 1">
                  <tr>
                    <th scope="col" style="width: 5%">
                      <span>{{ $t("product.combinations.id") }}</span>
                    </th>
                    <th scope="col" style="min-width: 300px">
                      <span>{{ $t("product.combinations.combinationOf") }}</span>
                    </th>
                    <th scope="col" style="min-width: 120px">
                      <span>{{ $t("product.combinations.defaultPrice") }}</span>
                    </th>
                    <th scope="col">
                      <span>{{ $t("product.combinations.price") }}</span>
                    </th>
                    <th scope="col" style="min-width: 150px">
                      <span>{{ $t("product.combinations.additionalPrice") }}</span>
                    </th>
                    <th scope="col">
                      <span>{{ $t("product.combinations.images") }}</span>
                    </th>
                    <th scope="col">
                      <span>{{ $t("product.combinations.status") }}</span>
                    </th>
                    <th scope="col" style="width: 5%">
                      <span>{{ $t("actions") }}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(combination, index) in combinations" :key="combination.id">
                    <td>{{ combination.id }}</td>
                    <td>
                      <span>{{ combination.option_values }}</span>
                      <!-- <span v-for="(optionValue, i) in combination.option_values" :key="i">{{ optionValue.title }}<span
                          v-if="combination.option_values.length - 1 != i">, </span></span> -->
                    </td>
                    <td>
                      <v-switch v-model="combination.is_default" @click="changeDefaultPrice(combination.id, index)"
                        :disabled="isSwitchLocked || combination.image_count == 0"></v-switch>
                    </td>
                    <td>{{ combination.price }}</td>
                    <td>
                      {{ combination.additional_price }}
                    </td>
                    <td>
                      <span v-if="combination.image_count == 0">
                        <font-awesome-icon :icon="['fas', 'times']" style="color: #ff4688"></font-awesome-icon>
                      </span>
                      <span v-else>
                        <font-awesome-icon :icon="['fas', 'check']" style="color: #82c91e"></font-awesome-icon>
                        {{ `&nbsp;(${combination.image_count})` }}
                      </span>
                    </td>
                    <td>
                      <v-switch v-model="combination.is_active" @click="changeActivity(combination.id)"
                        :disabled="isSwitchLocked || combination.is_default || combination.image_count == 0"></v-switch>
                    </td>
                    <td :style="combination.id === ParentZindex ? { zIndex: 2 } : null">
                      <div class="dropdown actions-dropdown">
                        <button @click="changeParentZindex(combination.id)" class="btn btn-light" type="button"
                          id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                          <font-awesome-icon :icon="['fas', 'ellipsis-h']" size="sm"></font-awesome-icon>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li>
                            <a class="dropdown-item" @click="openModal(combination.id)">{{ $t("edit") }}</a>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="!disableLoadMore" class="text-center">
              <button class="my-3 btn button-stop small ms-0" @click="loadMore">
                {{ $t("loadMore") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Filter Box -->
    <div class="filter-bar">
      <aside :class="{ 'opened': filterOpened }">
        <nav>
          <ul>
            <li>
              <button :class="{ 'active': filterOpened }" @click="filterOpened = !filterOpened">
                <font-awesome-icon :icon="['fas', 'filter']"></font-awesome-icon>
              </button>
            </li>
          </ul>
        </nav>
        <div class="filter-content">
          <div class="filter-panel">
            <div class="headline">
              <h3>{{ $t('filters.title') }}</h3>
              <button @click="filterOpened = !filterOpened"><font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon></button>
            </div>
            <div class="content">
              <div>
                <div>
                  <div v-for="(option, index) in productOptions" :key="option.option_id" class="element">
                    <label class="form-label">{{ option.title }}</label>
                    <div class="row mb-2">
                      <div class=" mb-1">
                        <MultiSelect v-model="selectedOptionValues[index]" mode="single"
                          label-prop="title" value-prop="option_value_id"
                          :options="option.option_values" @change="filterResult" />
                      </div>
                    </div>
                  </div>

                  <div class="element">
                    <label class="form-label">{{ t('product.activity') }}</label>
                    <div class="row mb-2">
                      <div class=" mb-1">
                        <v-switch v-model="isActive" @update:modelValue="filterResult" />
                      </div>
                    </div>
                  </div>

                  <div class="element">
                    <label class="form-label">{{ $t('product.combinations.images') }}</label>
                    <div class="row mb-2">
                      <div class=" mb-1">
                        <v-switch v-model="hasImage" @update:modelValue="filterResult" />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </div>
    <!-- // Filter Box -->
  </div>

  <!-- Edit Combination Modal -->
  <Modal ref="modal" :title="$t('product.combinations.editCombination')" @handleClick="edit"
    @modalClosed="checkStatus(singleCombination.id)">
    <div class="row" v-if="!isLoadingModal">
      <div class="col-md-6 mb-4">
        <v-input type="text" name="title" label="product.combinations.id" v-model="singleCombination.id"
          :disabled="true"></v-input>
      </div>
      <div class="col-md-6 mb-4">
        <currency-input :disabled="true" name="price" label="product.combinations.price"
          v-model="singleCombination.price" />
      </div>
      <div class="col-md-6 mb-4">
        <currency-input v-model="v$.singleCombination.additional_price.$model" name="additionalPrice"
          label="product.combinations.additionalPrice"
          :validateError="v$.singleCombination.additional_price.$error ? v$.singleCombination.additional_price.$errors[0].$message : null"></currency-input>
        <small v-if="singleCombination.additional_price || singleCombination.additional_price != ''">
          total: {{ + singleCombination.price + +singleCombination.additional_price }} </small>
        <small v-else>total: {{ singleCombination.price }}</small>
      </div>
      <div class="col-md-6 mb-4">
        <v-input name="variationid" label="Variation-article-number"
          v-model="singleCombination.variationid" />
      </div>
    </div>
    <v-button class="button-stop" style="margin:0" @click="$refs.imageSortModal.initiateModal()">{{
      $t('product.combinations.imageSorting')
    }}</v-button>
    <div class="row" v-if="!isLoadingModal && uploadUrl && deleteUrl">
      <div class="col-12 mb-2">
        <FileUploader v-model="images" :name="'product-video-uploader'" label="product.combinations.images"
          :uploadUrl="uploadUrl" :deleteUrl="deleteUrl" fetchUrl="image/product/combination"
          @uploaderStatus="disableDeleteBtn = $event == 1 ? false : true" />
      </div>
    </div>
  </Modal>

  <Modal ref="imageSortModal" :title="$t('product.combinations.imageSorting')" @handleClick="sortCombinationImages">
    <div class="col">
      <draggable v-model="singleCombination.images" item-key="id" tag="ul" class="list-group my-4">
        <template #item="{ element }">
          <li class="list-group-item">
            <div class="row d-flex align-items-center">
              <div class="col-12 col-md-3 d-flex align-items-center">
                <span class="drag-handler me-2 d-flex cursor-move">
                  <font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
                  <font-awesome-icon :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
                </span>
                  <img :src="getImageUrl(element)" alt="product combinaton image">
              </div>
              <div class="col-12 col-md-8">
                {{ element.path }}
              </div>
            </div>
          </li>
        </template>
      </draggable>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
  li{
    img {
      max-width: 160px;
      height:auto;
    }
  }
</style>
