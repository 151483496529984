<template>
    <BodyHeading :borderColor="'rgb(230, 127, 216)'" :title="$t('homeSection.homeSectionsTitle')" :count="homeSections.total" 
      :multiLanguageDisabled="false" :listHeading="true" :btnTitle="$t('homeSection.addButtonTitle')" :createBtnLink="'homeSectionCreate'" :backLink="'content'" :backIcon="'image'"></BodyHeading>
    <div class="page-body-main-list d-grid">
        <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" style="width: 5%"><span>#</span></th>
                            <th scope="col"><span>{{$t('homeSection.title')}}</span></th>
                            <th scope="col" style="width: 5%"><span>{{$t('actions')}}</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(homeSection, index) in homeSections.data" :key="homeSection.id">
                            <td>{{index + 1 + (homeSections.current_page - 1) * ITEMS_PER_PAGE}}</td>
                            <td>{{homeSection.title}}</td>
                            <td :style="homeSection.id === ParentZindex? { zIndex: 2}: null">
                                <div class="dropdown actions-dropdown">
                                    <button @click="changeParentZindex(homeSection.id)" class="btn btn-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <font-awesome-icon :icon="['fas', 'ellipsis-h']" size="sm"></font-awesome-icon>
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a @click="$router.push({ name: 'homeSectionDetails', params: { id: homeSection.h_section_id } })" class="dropdown-item">{{$t('edit')}}</a></li>
                                        <li><a class="dropdown-item warning" @click="deleteItem(homeSection.h_section_id)">{{$t('delete')}}</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
            </table>
        </div>
        <Pagination v-if="homeSections.total>homeSections.per_page" :lastPage="homeSections.last_page"
        :activePage="homeSections.current_page" @handleClick="changePage"></Pagination>
    </div>
    
</template>


<script>
import BodyHeading from '../../components/BodyHeading.vue';
import Pagination from '../../components/Pagination.vue';
import { ITEMS_PER_PAGE } from '../../constants';

export default {
    components: {
        BodyHeading,
        Pagination
    },
    data() {
        return{
            homeSections: {},
            ParentZindex: null,
            isSwitchLocked: false
        }
    },
    created() {
        this.ITEMS_PER_PAGE = ITEMS_PER_PAGE
        if(Object.entries(this.$route.query).length === 0 || this.$route.query.page === ''){
            this.$router.push({ name: 'homeSections', query: { page: 1 } })
        }
        this.getHomeSections()
    },
    watch: {
        '$route.query'(){
            this.getHomeSections()
        },
        currentLanguage() {
            this.getHomeSections()
        }
    },
    computed: {
        currentLanguage() {
            return this.$store.state.locale.selectedLocale
        },
    },
    methods: {
        changeParentZindex(id) {
            this.ParentZindex = id
        },
        getHomeSections() {
            const params = {language: this.currentLanguage, per_page: ITEMS_PER_PAGE, ...this.$route.query}
            this.$store.dispatch("homeSection/getAll", params).then(
                (response) => {
                    this.homeSections = response;
                },
                (error) => {
                this.$notify({
                        text: error,
                        group: 'failed'
                    });
                }
            )
        },
        deleteItem(id){
            this.$swal().then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("homeSection/delete", id).then(
                        (message) => {
                            this.$notify({
                                text: message,
                                group: 'success'
                            });
                            this.getHomeSections()
                        },
                        (error) => {
                            let errorMessage = ''
                            for (const err in error) {
                                errorMessage += `${error[err]} <br>`;
                            }
                        this.$notify({
                                text: errorMessage,
                                group: 'failed'
                            });
                        }
                    )
                } 
            })            
        },
        changePage(page=1){
            this.$router.push({ name: 'homeSections', query: { page: page } })
            this.getHomeSections();
        }
    }
}
</script>
