import newsletterService from '../../services/newsletter.service'


const user = {
    namespaced: true,
    state: {
    },
    getters: {
        
    },
    actions: {
        delete(_,params){
            return newsletterService.delete(params).then(
                data => {
                    return Promise.resolve(data.message);
                },
                error => {
                    return Promise.reject(error.response.data.errors);
                }
            )
        },
        getAll(_,params){
            return newsletterService.getAll(params).then(
                data => {
                    // console.log(data);
                    // commit('getAllLanguagesSuccess', data.body)
                    return Promise.resolve(data.body);
                },
                error => {
                    // commit('getAllLanguagesFailure');
                    return Promise.reject(error);
                }
            )
        }
    },
    mutations: {
        
    }
}

export default user