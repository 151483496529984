import axios from 'axios';
import Constants from '../constants';
import authHeader from './auth-header'


class UserService {
    getAll(params) {
        return axios
            .get(Constants['SERVER_URL']+'newsletter/search', {params: params,headers:authHeader()})
            .then(response => {
                return response.data;
            })
    }
    delete(params) {
        return axios
          .delete(Constants['SERVER_URL']+'newsletter/search/', {params: params,headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
}



export default new UserService();