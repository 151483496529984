<template>
    <BodyHeading :borderColor="'rgb(154, 168, 181)'" :title="$t('country.countries')" :count="countries.total" 
      :multiLanguageDisabled="false" :listHeading="true" :btnTitle="$t('country.addButtonTitle')" :createBtnLink="'countryCreate'" :backLink="'settings'" :backIcon="'cog'"></BodyHeading>
    <div class="page-body-main-list d-grid grid-columns">
        <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" style="width:5%"><span>#</span></th>
                            <th scope="col" style="width:90%"><span>{{$t('country.name')}}</span></th>
                            <!-- <th scope="col"><span>Activity</span></th> -->
                            <th scope="col" style="width:5%"><span>{{$t('actions')}}</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(country, index) in countries.data" :key="country.country_id">
                            <td>{{index + 1 + (countries.current_page - 1) * ITEMS_PER_PAGE}}</td>
                            <td>{{country.name}}</td>
                            <!-- <td>
                                <v-switch v-model="country.country_info.is_active" @click="changeActivity(index-1)" :disabled="isSwitchLocked"></v-switch>
                            </td> -->
                            <td :style="country.country_id === ParentZindex? { zIndex: 2}: null">
                                <div class="dropdown actions-dropdown">
                                    <button @click="changeParentZindex(country.country_id)" class="btn btn-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <font-awesome-icon :icon="['fas', 'ellipsis-h']" size="sm"></font-awesome-icon>
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a @click="$router.push({ name: 'countryDetails', params: { id: country.country_id } })" class="dropdown-item">{{$t('edit')}}</a></li>
                                        <li><a @click="$router.push({ name: 'countryPostMethods', query: { id: country.country_id, name: country.name } })" class="dropdown-item">{{$t('country.attachPostMethods')}}</a></li>
                                        <li><a class="dropdown-item warning" @click="deleteItem(country.country_id)">{{$t('delete')}}</a></li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
            </table>
        </div>
        <!-- Filter Box -->
        <div class="filter-bar">
            <aside :class="{'opened': filterOpened}">
                <nav>
                    <ul>
                        <li><button :class="{'active': filterOpened}" @click="filterOpened = !filterOpened"><font-awesome-icon :icon="['fas', 'filter']"></font-awesome-icon></button></li>
                    </ul>
                </nav>
                <div class="filter-content">
                    <div class="filter-panel">
                        <div class="headline">
                            <h3>{{$t('filters.title')}}</h3>
                            <button @click="filterOpened = !filterOpened"><font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon></button>
                        </div>
                        <div class="content">
                            <div>
                                <div>
                                    <div class="element">
                                        <v-input type="text" name="name" label="country.name" v-model="name" @keyup="searchQueue()"></v-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </div>
        <!-- // Filter Box -->

        <Pagination v-if="countries.total>countries.per_page" :lastPage="countries.last_page"
        :activePage="countries.current_page" @handleClick="changePage"></Pagination>
    </div>
    
</template>


<script>
import BodyHeading from '../../components/BodyHeading.vue';
import Pagination from '../../components/Pagination.vue';
// import Switch from '../../components/Switch.vue';
import Input from '../../components/Input.vue';
import { ITEMS_PER_PAGE } from '../../constants';

export default {
    components: {
        BodyHeading,
        Pagination,
        // 'v-switch': Switch,
        'v-input': Input
    },
    data() {
        return{
            timer: null,
            filterOpened: false,
            name: null,
            countries: {},
            ParentZindex: null,
            isSwitchLocked: false
        }
    },
    created() {
        this.ITEMS_PER_PAGE = ITEMS_PER_PAGE
        if(Object.entries(this.$route.query).length === 0 || this.$route.query.page === ''){
            this.$router.push({ name: 'countries', query: { page: 1 } })
        }
        this.getCountries()
        if(this.$route.query.name){
            this.name = this.$route.query.name
            this.filterOpened = true
        }
    },
    watch: {
        '$route.query'(){
            this.getCountries()
        },
        currentLanguage() {
            this.getCountries()
        }
    },
    computed: {
        currentLanguage() {
            return this.$store.state.locale.selectedLocale
        },
    },
    methods: {
        searchQueue(){
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.getCountries();
                const query = {
                    page: 1,
                    ...(this.name && {name: this.name}),
                }
                this.$router.push({
                    query
                })
            }, 500);
        },
        changeParentZindex(id) {
            this.ParentZindex = id
        },
        getCountries() {
            const params = {language: this.currentLanguage, per_page: ITEMS_PER_PAGE, ...this.$route.query}
            this.$store.dispatch("country/getAll", params).then(
                (response) => {
                    this.countries = response;
                },
                (error) => {
                this.$notify({
                        text: error,
                        group: 'failed'
                    });
                }
            )
        },
        deleteItem(id){
            this.$swal({text:'Country cannot be deleted, if it has relations with other Addresses, Users, invoices or orders.'}).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("country/delete", id).then(
                        (message) => {
                            this.$notify({
                                text: message,
                                group: 'success'
                            });
                            this.getCountries()
                        },
                        (error) => {
                            console.log(error);
                            let errorMessage = ''
                            for (const err in error) {
                                errorMessage += `${error[err]} <br>`;
                            }
                            this.$notify({
                                text: errorMessage,
                                group: 'failed'
                            });
                        }
                    )
                } 
            })            
        },
        changePage(page=1){
            this.$router.push({ name: 'countries', query: { ...this.$route.query, page: page } })
        },
        changeActivity(index){
            // console.log(this.countries.data[index].country_info.is_active);
            this.isSwitchLocked = true;
            let params = {country_id: this.countries.data[index].country_id}
            if(this.countries.data[index].country_info.is_active){
                this.$store.dispatch("country/activate", params).then(
                    (message) => {
                        this.$notify({
                            text: message,
                            group: 'success'
                        });
                        this.isSwitchLocked = false
                    },
                    (error) => {
                        let errorMessage = ''
                        for (const err in error) {
                            errorMessage += `${error[err]} <br>`;
                        }
                        this.$notify({
                            text: errorMessage,
                            group: 'failed'
                        });
                        this.countries.data[index].country_info.is_active = 0
                        this.isSwitchLocked = false
                    }
                )
            }else{
                this.$store.dispatch("country/deactivate", params).then(
                    (message) => {
                        this.$notify({
                            text: message,
                            group: 'success'
                        });
                        this.isSwitchLocked = false
                    },
                    (error) => {
                        let errorMessage = ''
                        for (const err in error) {
                            errorMessage += `${error[err]} <br>`;
                        }
                        this.$notify({
                            text: errorMessage,
                            group: 'failed'
                        });
                        this.isSwitchLocked = false
                    }
                )
            }
        }
    }
}
</script>
