import productService from '../../../../services/product.service'

const productOptions = {
    namespaced: true,
    actions: {
        getOptions(_, urlParams) {
            return productService.getOptions(urlParams).then(
                data => {
                    return Promise.resolve(data.body)
                }, error => {
                    return Promise.reject(error.response.data.errors)
                })
        },
        setOptions(_, { urlParams, payload }) {
            return productService.setOptions(urlParams, payload).then(
                data => {
                    return Promise.resolve(data.message)
                },
                error => {
                    return Promise.reject(error.response.data.errors)
                }
            )
        },
        setOptionValues(_, { urlParams, payload }) {
            return productService.setOptionValues(urlParams, payload).then(
                data => {
                    return Promise.resolve(data.message)
                },
                error => {
                    return Promise.reject(error.response.data.errors)
                }
            )
        },
        setTranslatedOptions(_, { urlParams, payload }) {
            return productService.setTranslatedOptions(urlParams, payload).then(
                data => {
                    return Promise.resolve(data.message)
                },
                error => {
                    return Promise.reject(error.response.data.errors)
                }
            )
        },
        getExcludedOptionValues(_, urlParams) {
            return productService.getExcludedOptionValues(urlParams).then(
                data => {
                    return Promise.resolve(data.body)
                },
                error => {
                    return Promise.reject(error.response.data.errors)
                }
            )
        },
        setExcludedOptionValues(_, { urlParams, excludedValues }) {
            return productService.setExcludedOptionValues(urlParams, excludedValues).then(
                data => {
                    return Promise.resolve(data.message)
                },
                error => {
                    return Promise.reject(error.response.data.errors)
                }
            )
        }
    }
}

export default productOptions
