import { createI18n } from 'vue-i18n'
import store from '../store'

import en from '../locales/en.json'
import de from '../locales/de.json'

const messages = {
  en: en,
  de: de
};

export const i18n = createI18n({
  legacy: false,
  locale: store.state.locale.appLocale,
  globalInjection: true,
  messages: messages,
  fallbackLocale: 'en'
});

export default {
  install: (app) => {
    app.use(i18n)
  }
}
