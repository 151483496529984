<script setup>
import { ref } from 'vue'
import BodyHeading from './BodyHeading.vue'

const viewRef = ref(null)
function handleClick() {
  viewRef.value.save()
}
</script>

<template>
  <BodyHeading :borderColor="'rgb(87, 217, 163)'" :title="$route.query.name" :multiLanguageDisabled="$route.name !== 'productAddOptions'"
    :saveAndCloseBtn="!$route.name.includes('Create')" @handleClick="handleClick" />
  <router-view v-slot="{Component}">
    <component ref="viewRef" :is="Component">
      <template #nav-stepper>
        <nav class="nav nav-stepper">
          <a class="nav-link" :class="{active: $route.name == 'productAddDetails'}" @click="$router.replace({ name: 'productAddDetails', params: { id: $route.params.id }, query: {name: $route.query.name} })">{{$t('product.workingDays')}}</a>
          <a class="nav-link" :class="{active: $route.name == 'productAddServices'}" @click="$router.replace({ name: 'productAddServices', params: { id: $route.params.id }, query: {name: $route.query.name} })">{{$t('product.chooseServices')}}</a>
          <a class="nav-link" :class="{active: $route.name == 'productExcludeServiceValues'}" @click="$router.replace({ name: 'productExcludeServiceValues', params: { id: $route.params.id }, query: {name: $route.query.name} })">{{$t('product.excludeServiceValues')}}</a>
          <a class="nav-link" :class="{active: $route.name == 'productAddOptions'}" @click="$router.replace({ name: 'productAddOptions', params: { id: $route.params.id }, query: {name: $route.query.name} })">{{$t('product.chooseOptions')}}</a>
          <a class="nav-link" :class="{active: $route.name == 'productExcludeOptionValues'}" @click="$router.replace({ name: 'productExcludeOptionValues', params: { id: $route.params.id }, query: {name: $route.query.name} })">{{$t('product.excludeOptionValues')}}</a>
          <a class="nav-link" :class="{active: $route.name == 'productCombinations'}" @click="$router.replace({ name: 'productCombinations', params: { id: $route.params.id }, query: {name: $route.query.name} })">{{$t('product.combinations.heading')}}</a>
        </nav>
      </template>
    </component>
  </router-view>
</template>
