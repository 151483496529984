<script setup>
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { between, required } from '@/utilities/i18n-validators.js'

// import components
import MultiSelect from '../../components/MultiSelect.vue'
import useValidate from '@vuelidate/core'
import { ValidateEach } from '@vuelidate/components'
import { useNotification } from "@kyvg/vue3-notification"

// setup validation
const v$ = useValidate()
const rules = {
	durationId: { required },
	price: {
		required,
		between: between(0, 100000)
	},
}

const { notify } = useNotification()
const route = useRoute()
const store = useStore()

// state
const durations = ref([])
const durationsPriceList = ref([])
const isLoading = ref(true)

// computed
const selectedLocale = computed(() => store.state.locale.selectedLocale)

// watch 
watch(durationsPriceList, () => {
	durations.value.forEach(el => {
		if (el.disabled) {
			delete el.disabled
		}
	})
	durationsPriceList.value.forEach(dpEl => {
		durations.value.forEach(dEl => {
			if (dEl.value == dpEl.durationId) {
				dEl.disabled = true
			}
		})
	})
})

// methods
function setDefaults(index) {
	durationsPriceList.value.forEach((el, i) => {
		if (index !== i) {
			el.default = 0
		} else {
			el.default = 1,
				el.price = 0
		}
	})
}

function durationsPriceListAddItem() {
	durationsPriceList.value.unshift({ default: 0 })
}

function durationsPriceListDeleteItem(index) {
	if (durationsPriceList.value.length > 1) {
		durationsPriceList.value.splice(index, 1)
	}
}

function edit() {
	v$.value.$validate()
	if (!v$.value.$error) {
		const durationsList = { durations: [...durationsPriceList.value] }
		const urlParams = { product_id: route.params.id }
		store.dispatch("product/workingDays/setWorkingDays", { urlParams, durationsList }).then(
			(message) => {
				notify({
					text: message,
					group: 'success'
				});
			},
			(error) => {
				let errorMessage = ''
				for (const err in error) {
					errorMessage += `${error[err]} <br>`;
				}
				notify({
					text: errorMessage,
					group: 'failed'
				});
			}
		)
	}
}

function getDurationsPriceList(id) {
	const urlParams = { product_id: id }
	store.dispatch("product/workingDays/getWorkingDays", urlParams).then(
		(response) => {
			if (response.length) {
				response.forEach(el => {
					durationsPriceList.value.push({ durationId: el.duration_id, price: el.price, default: el.default_value })
				})
			} else {
				durationsPriceListAddItem()
			}
			isLoading.value = false;
		},
		() => {
			isLoading.value = false;
		}
	)
}

function getDurations() {
	durations.value = []
	const params = { language: selectedLocale.value, per_page: 30 }
	store.dispatch("duration/getAll", params).then(
		(response) => {
			response.data.forEach(el => {
				durations.value.push({ label: el.title, value: el.duration_id })
			})
			getDurationsPriceList(route.params.id)
		},
		(error) => {
			notify({
				text: error,
				group: 'failed'
			});
		}
	)
}

getDurations()

defineExpose({
	save: edit
})
</script>

<template>
	<div class="page-body-main-create">
		<div class="card-wrapper">
			<slot name="nav-stepper"></slot>
			<div class="card-title">{{ $t('product.workingDays') }}</div>
			<div class="card">
				<div class="card-body">
					<ValidateEach v-for="(durationsPrice, index) in durationsPriceList" :key="durationsPrice"
						:state="durationsPrice" :rules="rules">
						<template #default="{ v }">
							<div class="row">
								<div class="col-md-5 col-sm-6">
									<MultiSelect label="product.workingDay.durations" :options="durations" :isRequired="true" class="mb-2"
										:modelValue="v.durationId.$model" @update:modelValue="v.durationId.$model = $event" :searchable="true"
										:validateError="v.durationId.$errors[0] ? v.durationId.$errors[0].$message : null" />
									<div class="form-check">
										<input class="form-check-input" type="radio" name="flexRadioDefault" :id="`flexRadioDefault${index}`"
											:checked="durationsPrice.default == 1 ? true : false" @change="setDefaults(index)">
										<label class="form-check-label" :for="`flexRadioDefault${index}`">
											{{ $t('product.workingDay.setDefault') }}
										</label>
									</div>
								</div>
								<div class="col-md-5 col-sm-6">
									<label for="" class="form-label is-required">{{ $t('product.workingDay.taxInputLabel') }}</label>
									<input v-model="v.price.$model" :disabled="durationsPrice.default == 1" type="number"
										class="form-control mb-2" :placeholder="$t('product.workingDay.taxInputPlaceholder')"
										:class="{ 'form-controll-invalid': v.price.$errors[0] }">
									<div class="invalid-feedback" v-if="v.price.$errors[0]">
										{{ v.price.$errors[0].$message }}
									</div>
								</div>
								<div class="col-md-2">
									<div class="d-flex justify-content-start mt-md-4 my-2 pt-md-2">
										<button v-if="durationsPriceList.length != 1" class="btn button-stop px-3"
											@click="durationsPriceListDeleteItem(index)">
											<font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon>
										</button>
										<button v-if="durations.length !== durationsPriceList.length && index == 0"
											class="btn button-action px-3" @click="durationsPriceListAddItem">
											<font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon>
										</button>
									</div>
								</div>
							</div>
							<hr v-if="durationsPriceList.length != 1">
						</template>
					</ValidateEach>
				</div>
			</div>
		</div>
	</div>
</template>
