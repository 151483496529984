import axios from 'axios';
import Constants from '../constants';
import authHeader from './auth-header'


class PopupService {
    fetch(params) {
      return axios
          .get(Constants['SERVER_URL']+'popups/fetch/'+`${params.popup_id}/${params.language}`, {headers:authHeader()})
          .then(response => {
              return response.data;
          })
    }
    getAll(params) {
        return axios
            .get(Constants['SERVER_URL']+'popups/search', {params: params,headers:authHeader()})
            .then(response => {
                return response.data;
            })
    }
    create(technicalInfo) {
        return axios
          .post(Constants['SERVER_URL']+'popups/create', technicalInfo, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    createTranslation(technicalInfo) {
        return axios
          .post(Constants['SERVER_URL']+'popups/add/translation/'+ technicalInfo.popup_id, technicalInfo, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    edit(technicalInfo){
      return axios
        .patch(Constants['SERVER_URL']+'popups/update/'+ `${technicalInfo.popup_id}/${technicalInfo.language}`, technicalInfo, {headers:authHeader()})
        .then(response => {    
          return response.data;
      })
    }
    delete(popup_id) {
        return axios
          .delete(Constants['SERVER_URL']+'popups/delete/'+ popup_id, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    activate(params) {
      return axios
          .get(Constants['SERVER_URL']+'popups/activate/'+`${params.popup_id}`, {headers:authHeader()})
          .then(response => {
              return response.data;
          })
    }
    deactivate(params) {
      return axios
          .get(Constants['SERVER_URL']+'popups/deactivate/'+`${params.popup_id}`, {headers:authHeader()})
          .then(response => {
              return response.data;
          })
    }
}



export default new PopupService();