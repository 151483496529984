import axios from 'axios';
import Constants from '../constants';
import authHeader from './auth-header'


class ShippingInfoService {
    fetch(params) {
      return axios
          .get(Constants['SERVER_URL']+'shipping-info/fetch/'+`${params.shipping_info_id}/${params.language}`, {headers:authHeader()})
          .then(response => {
              return response.data;
          })
    }
    getAll(params) {
        return axios
            .get(Constants['SERVER_URL']+'shipping-info/search', {params: params,headers:authHeader()})
            .then(response => {
                return response.data;
            })
    }
    create(shippingInfo) {
        return axios
          .post(Constants['SERVER_URL']+'shipping-info/create', shippingInfo, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    createTranslation(shippingInfo) {
        return axios
          .post(Constants['SERVER_URL']+'shipping-info/add/translation/'+ shippingInfo.shipping_info_id, shippingInfo, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
    edit(shippingInfo){
      return axios
        .patch(Constants['SERVER_URL']+'shipping-info/update/'+ `${shippingInfo.shipping_info_id}/${shippingInfo.language}`, shippingInfo, {headers:authHeader()})
        .then(response => {    
          return response.data;
      })
    }
    delete(shipping_info_id) {
        return axios
          .delete(Constants['SERVER_URL']+'shipping-info/delete/'+ shipping_info_id, {headers:authHeader()})
          .then(response => {    
            return response.data;
          })
    }
}



export default new ShippingInfoService();