<template>
    <div class="page-body-pagination">
    <div class="divider"></div>
        <nav>
            <ul class="pagination">
                <li class="page-item" :class="activePage === 1? 'disabled' :''" @click="$emit('handleClick',1)">
                <a class="page-link" tabindex="-1" :aria-disabled="activePage === 1"><font-awesome-icon :icon="['fas', 'chevron-left']" size="sm"></font-awesome-icon><font-awesome-icon :icon="['fas', 'chevron-left']" size="sm"></font-awesome-icon></a>
                </li>
                <li class="page-item" v-if="activePage-2>=1"><a class="page-link" @click="$emit('handleClick',activePage-2)">{{activePage-2}}</a></li>
                <li class="page-item" v-if="activePage-1>=1"><a class="page-link" @click="$emit('handleClick',activePage-1)">{{activePage-1}}</a></li>
                <li class="page-item active" aria-current="page">
                    <a class="page-link" @click="$emit('handleClick',activePage)">{{activePage}}</a>
                </li>
                <li class="page-item" v-if="activePage+1<=lastPage"><a class="page-link" @click="$emit('handleClick',activePage+1)">{{activePage+1}}</a></li>
                <li class="page-item" v-if="activePage+2<=lastPage"><a class="page-link" @click="$emit('handleClick',activePage+2)">{{activePage+2}}</a></li>
                <li class="page-item" :class="lastPage === 1 || activePage === lastPage? 'disabled' :''" @click="$emit('handleClick',lastPage)">
                <a class="page-link" :aria-disabled="lastPage === 1 || activePage === lastPage"><font-awesome-icon :icon="['fas', 'chevron-right']" size="sm"></font-awesome-icon><font-awesome-icon :icon="['fas', 'chevron-right']" size="sm"></font-awesome-icon></a>
                </li>
            </ul>
        </nav>
    </div>
</template>


<script>

export default {
    props:{
        lastPage: {
            type: Number,
            default: 1
        },
        activePage: {
            type: Number,
            default: 1
        }
    }
}
</script>
