<template>
    <label v-if="label" :for="name" class="form-label" :class="isRequired?'is-required' : ''">{{$t(label)}}</label>
    <input :disabled="disabled" :placeholder="placeholder" v-bind="$attrs" :id="name" :value="modelValue" @input="$emit('update:modelValue',$event.target.value)" class="form-control" :class="validateError? 'form-controll-invalid': ''">
    <div class="invalid-feedback" v-if="validateError">
        {{validateError}}
    </div>
</template>


<script>

export default {
    props:{
        name: {
            type: String,
            default: ''
        },
        modelValue: {
            default: ''
        },
        label: {
            default: ''
        },
        placeholder: {
            default: ''
        },
        validateError: {
            default: null
        },
        isRequired: {
            default: false
        },
        disabled: {
            default: false
        }
    }
}
</script>
