<template>
  <label v-if="label" :for="name" class="form-label" :class="isRequired?'is-required' : ''">{{ $t(label) }}</label>
  <Multiselect v-if="options"
    :mode="mode"
    :disabled="disabled"
    :placeholder="placeholder"
    v-bind="$attrs" 
    :id="name"
    :options="options"
    :value="modelValue"
    :valueProp="valueProp"
    :label="labelProp"
    :class="validateError? 'form-controll-invalid': ''"
    ref="selector"
    @input="$emit('update:modelValue', $event)"
    @change="$emit('change', $event)"
    @search-change="$emit('search-change', $event)"
  />
  <div class="invalid-feedback" v-if="validateError">
    {{ validateError }}
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'

export default {
    inheritAttrs: false,
    components: {
        Multiselect,
    },
    props:{
        valueProp: {
            default: 'value'
        },
        labelProp: {
            default: 'label'
        },
        mode: {
            type: String,
            default: 'single'
        },
        name: {
            type: String,
            default: ''
        },
        modelValue: {
            default: ''
        },
        label: {
            default: ''
        },
        placeholder: {
            default: ''
        },
        validateError: {
            default: null
        },
        isRequired: {
            default: false
        },
        disabled: {
            default: false
        },
        options: {
            default: []
        },
    },
    mounted() {
        // console.log(this.modelValue)
        // console.log(this.options)
        if(this.modelValue){
            // if(this.mode === 'single'){
                this.values = this.modelValue;
            // }else{
            //     this.values = this.modelValue
            // }
            // this.$refs.selector.select(this.values, this.options)
        }
    }
}

</script>
<style>
    .multiselect-option span{
        text-transform: capitalize !important;
    }
</style>