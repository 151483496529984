<template>
    <BodyHeading :borderColor="'rgb(87, 217, 163)'" :title="$t('Medienzentrum')"
                :multiLanguageDisabled="false" :listHeading="true" :btnTitle="$t('product.addButtonTitle')"
                :createBtnLink="'productCreate'"></BodyHeading>
    <div class="page-body-main-list d-grid grid-columns">
        <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered">
                <thead>
                    <tr>
                        <th scope="col" style="width:5%"><span>#</span></th>
                        <th scope="col"><span>File</span></th>
                        <th scope="col"><span>{{ $t('common.title') }}</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(comb, s) in portfolios.data" :key="comb.id">
                        <td>{{s + 1 + (portfolios.current_page - 1) * 15}}</td>
                        <td width="20%">
                            <img width="200" :src="getMenuUrl(comb.path)" alt="product combination image">
                        </td>
                        <td>
                            {{ comb.path }} <br>
                            <v-input 
                                style="width:50%;float:left" 
                                v-model="comb.newFileName" 
                                @input="addExtensionToInput($event, comb)"
                            ></v-input>
                            <button @click="saveportfolio(comb)" class="btn button-action d-none d-sm-inline-block">Save</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Pagination v-if="portfolios.total > portfolios.per_page" :lastPage="portfolios.last_page"
            :activePage="portfolios.current_page" @handleClick="changePort"></Pagination>
        </div>               
    </div>
</template>
<script>
import BodyHeading from '../../components/BodyHeading.vue';
import Pagination from '../../components/Pagination.vue';
import Constants from '../../constants/index.js'
import VInput from '../../components/Input.vue'
export default {
    components: {
        BodyHeading,
        Pagination,
        'v-input': VInput
    },
    data() {
        return {
            activeTabIndex: 0,
            timer: null,
            filterOpened: false,
            title: null,
            code: null,
            product_id: null,
            combinations: {},
            portfolios: {},
            sliderimages: {},
            menuimages: {},
            title_selected: null,
            categories: [],
            default_menu_id: null,
            selected_product_id: null,
            productMenus: [],
            ParentZindex: null,
            isSwitchLocked: false,
            selectedCategories: null,
            image: ''
        }
    },
    created() {
        this.portfolio()
    },
    watch: {
        '$route.query'(){
            this.portfolio()
        }
    },
    methods: {
        changePort(page) {
            this.$router.push({ query: { ...this.$route.query, page } });
        },
        initializeportfolios() {
            this.portfolios.data.forEach(comb => {
                comb.newFileName = comb.path.split('/').pop();
            });
        },
        getMenuUrl(image) {
            return Constants['SERVER_PUBLIC_URL'] + `fetch/image?path=/image/portfolio&image=${image}`
        },
        portfolio() {
            const params = { language: this.selectedLocale, per_page: 15, ...this.$route.query }
            this.$store.dispatch("media/portfolio", params).then(
                (response) => {
                    this.portfolios = response;
                    this.initializeportfolios();
                },
                (error) => {
                    this.$notify({
                        text: error,
                        group: 'failed'
                    });
                }
            )
        },
        addExtensionToInput(event, comb) {
            let inputValue = event.target.value;
            const currentExtension = comb.path.split('.').pop(); // Get the original file extension
            
            // Ensure the user hasn't removed the extension
            if (!inputValue.includes('.')) {
                inputValue = `${inputValue}.${currentExtension}`;
                event.target.value = inputValue; // Update the input field with the extension
            }

            // Set the cursor position before the extension
            const baseName = inputValue.slice(0, inputValue.lastIndexOf('.'));
            const cursorPosition = baseName.length;
            event.target.setSelectionRange(cursorPosition, cursorPosition);

            // Store the updated name separately without modifying the original path
            comb.newFileName = inputValue;
        },
        saveportfolio(comb) {
            const updatedData = {
                id: comb.id,
                path: comb.newFileName, 
            };
            this.$store.dispatch("media/portfolio_update", updatedData).then(
                (data) => {
                    this.$notify({
                        text: data.message,
                        group: 'success'
                    })
                },
                (error) => {
                    let errorMessage = ''
                    for (const err in error) {
                        errorMessage += `${error[err]} <br>`
                    }
                    this.$notify({
                        text: errorMessage,
                        group: 'failed'
                    })
                }
            );
        }
    }
}
</script>
