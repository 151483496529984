import productService from '../../../../services/product.service'

const productMenus = {
    namespaced: true,
    actions: {
        getMenus(_, urlParams) {
            return productService.getMenus(urlParams).then(
                data => {
                    return Promise.resolve(data.body)
                },
                error => {
                    return Promise.reject(error.response.data.errors)
                }
            )
        },
        setMenus(_, { urlParams, menus }) {
            return productService.setMenus(urlParams, menus).then(
                data => {
                    return Promise.resolve(data.message)
                },
                error => {
                    return Promise.reject(error.response.data.errors)
                }
            )
        }
    }
}

export default productMenus
