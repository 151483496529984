import axios from 'axios';
import Constants from '../constants';
import authHeader from './auth-header'


class ProductCombinationsService {
	async fetchCount(urlParams) {
		const response = await axios
			.get(Constants['SERVER_URL'] + `products/combinations/all/count/${urlParams.product_id}`, { headers: authHeader() });
		return response.data;
	}
	setDefault(urlParams) {
		return axios
			.post(Constants['SERVER_URL'] + `products/combinations/set/default/${urlParams.product_id}/${urlParams.combination_id}`, {}, { headers: authHeader() })
			.then(response => {
				return response.data;
			})
	}
	getAll(urlParams, queryParams) {
		return axios
			.get(Constants['SERVER_URL'] + `products/combinations/all/${urlParams.product_id}/${urlParams.language}`, { params: queryParams, headers: authHeader() })
			.then(response => {
				return response.data;
			})
	}
	fetch(urlParams) {
		return axios
			.get(Constants['SERVER_URL'] + `products/combinations/fetch/info/${urlParams.combination_id}/${urlParams.language}`, { headers: authHeader() })
			.then(response => {
				return response.data;
			})
	}
	edit(urlParams, combination) {
		return axios
			.post(Constants['SERVER_URL'] + `products/combinations/update/info/${urlParams.combination_id}`, combination, { headers: authHeader() })
			.then(response => {
				return response.data;
			})
	}
	editActivation(urlParams) {
		return axios
			.post(Constants['SERVER_URL'] + `products/combinations/change/activation/${urlParams.combination_id}`, {}, { headers: authHeader() })
			.then(response => {
				return response.data;
			})
	}
	sortImages(urlParams, sortedImages) {
		return axios
			.post(Constants['SERVER_URL'] + `products/combinations/images/arrange/${urlParams.combination_id}`, sortedImages, { headers: authHeader() })
			.then(response => {
				return response.data
			})
	}
}

export default new ProductCombinationsService()