import axios from 'axios';
import Constants from '../constants';
import authHeader from './auth-header'


class TaxService {
    fetch() {
      return axios
          .get(Constants['SERVER_URL']+'setting/tax/fetch', {headers:authHeader()})
          .then(response => {
              return response.data;
          })
    }
    // getAll(params) {
    //     return axios
    //         .get(Constants['SERVER_URL']+'contact-us/search', {params: params,headers:authHeader()})
    //         .then(response => {
    //             return response.data;
    //         })
    // }
    // create(contactUs) {
    //     return axios
    //       .post(Constants['SERVER_URL']+'contact-us/create', contactUs, {headers:authHeader()})
    //       .then(response => {    
    //         return response.data;
    //       })
    // }
    // createTranslation(contactUs) {
    //     return axios
    //       .post(Constants['SERVER_URL']+'contact-us/add/translation/'+ contactUs.contact_us_id, contactUs, {headers:authHeader()})
    //       .then(response => {    
    //         return response.data;
    //       })
    // }
    edit(tax){
      return axios
        .post(Constants['SERVER_URL']+'setting/tax/update', tax, {headers:authHeader()})
        .then(response => {    
          return response.data;
      })
    }
    // delete(contact_us_id) {
    //     return axios
    //       .delete(Constants['SERVER_URL']+'contact-us/delete/'+ contact_us_id, {headers:authHeader()})
    //       .then(response => {    
    //         return response.data;
    //       })
    // }
}



export default new TaxService();